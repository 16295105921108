import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, fade } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../utils/IntlMessages';
import Button from '@material-ui/core/Button';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import { NavLink, useLocation } from 'react-router-dom';
import * as firebaseFunctions from '../../../../services/firebase/functions';
import { fetchError } from '../../../../redux/actions';
import Cookies from 'universal-cookie';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  
  signinButtonsContainer: {
    '& > div, button, p': {
      [theme.breakpoints.down('xs')]: {
        width: "100%"
      },
    }
  }
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

//variant = 'default', 'standard', 'bgColor'
const SignUp = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  let query = useQuery();
  const refCode = query.get('refCode');
  const planId = query.get('plan');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles({ variant });

  const [userData, setUserData] = useState({
    name: '',
    email: '',
    password: '',
  });

  useEffect(() => {
    const cookies = new Cookies();
    if (planId) {
      cookies.set('subscriptionPlanId', planId, { path: '/' });
    }

    return () => {};
  }, []);

  const onSubmit = () => {
    dispatch(
      AuhMethods[method].onRegister({
        name: userData.name,
        email: userData.email,
        password: userData.password,
        roles: { customer: 'customer' },
        referralCode: refCode,
      }),
    );
  };

  const handleChange = event => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/auth/sign-up-img.png'} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={'/images/logo.png'} />
        </Box>
        <Box>{dispatch(AuhMethods[method].getSocialMediaIcons(true))}</Box>
        <Box mb={3} textAlign={'center'} fontSize={'16px'}>
          OR
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Create an account
        </Typography>
        <ValidatorForm onSubmit={onSubmit} onError={errors => console.log(errors)}>
          <Box mb={2}>
            <TextValidator
              label={<IntlMessages id="appModule.name" />}
              fullWidth
              name="name"
              id="name"
              validators={['required']}
              errorMessages={['This field is required!']}
              onChange={handleChange}
              value={userData.name || ''}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={2}>
            <TextValidator
              label={<IntlMessages id="appModule.email" />}
              name="email"
              id="email"
              fullWidth
              validators={['required']}
              errorMessages={['This field is required!']}
              onChange={handleChange}
              value={userData.email || ''}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={2}>
            <TextValidator
              type="password"
              name="password"
              id="password"
              validators={['required']}
              errorMessages={['This field is required!']}
              label={<IntlMessages id="appModule.password" />}
              fullWidth
              onChange={handleChange}
              value={userData.password || ''}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>

          <Box className={classes.signinButtonsContainer}
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'center' }}
            justifyContent={{ sm: 'space-between' }}
            mb={3}>
            <Box mb={{ xs: 2, sm: 0 }}>
              <Button type="submit" variant="contained" color="primary">
                <IntlMessages id="appModule.register" />
              </Button>
            </Box>

            <Typography>
              <NavLink to="/signin">
                <Button type="button" variant="contained" color="primary">
                  <IntlMessages id="signUp.alreadyMember" />
                </Button>
              </NavLink>
            </Typography>
          </Box>
        </ValidatorForm>

        <Typography className={classes.textAcc}>
          Have an account?
          <NavLink to="/signin" style={{color:"rgba(255, 255, 255, 0.87)"}}>Sign In</NavLink>
        </Typography>
        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignUp;
