import React from 'react';
import GridContainer from '@jumbo/components/GridContainer';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { Document, Page } from 'react-pdf';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(theme => ({
    cardRoot: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& > div': {
            height: '100%',
        },
        '& .slick-slider': {
            height: '100%',
            '&.bottom .slick-dots': {
                bottom: 10,
                width: 'auto',
                marginLeft: 35,
            },
        },
        '& .slick-list, & .slick-track, & .slick-slide > div': {
            height: '100%',
        },
        '& .slick-dots': {
            bottom: 0,
        },
        '& .slick-dots li': {
            marginLeft: 2,
            marginRight: 2,
            '& button, & button:before': {
                padding: 0,
            },
        },
    },
    cardRootBorder: {
        borderRadius: '10px'
    },
    sliderImgLg: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '40%',
        },
        '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
        },
    },
    sliderImgLgPadding: {
        width: '100%',
        padding: '20px',
        [theme.breakpoints.up('sm')]: {
            width: '40%',
        },
        '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
        },
    },
    titleRoot: {
        fontSize: '2em',
        marginBottom: '15px',
    },
    subTitle: {
        fontSize: '17px',
        padding: '15px'
    },
    sliderImgLg: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '40%',
        },
        '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'scale-down',
        },
    },
    sliderContentFeatures: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 20,
    },
    paraWithMargin: {
        marginBottom: '20px'
    },
    centerContent: {
        '@media screen and (min-width: 600px)': {
            justifyContent: "center"
        }
    }
}));

export default function DiscordInstructions() {
    const classes = useStyles();
    const history = useHistory();
    const [numPages, setNumPages] = React.useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <PageContainer heading="Discord Steps to follow">
            <GridContainer className={classes.centerContent} style={{ marginBottom: "10px" }}>
                <Document
                    file="Discord Bot Setup Instruction.pdf"
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {/* <Page pageNumber={1} /> */}
                    {[1, 2, 3, 4, 5, 6, 7, 8].map((page, index) => (
                        <Page key={'page_' + index} pageNumber={page} />
                    ))}
                </Document>
            </GridContainer>
            </PageContainer>)

    // return (
    //     <Box>
    //         <GridContainer style={{ marginBottom: "10px" }}>
    //             <Grid item xs={12} sm={12}>
    //                 <h1 style={{ textAlign: "center" }}>Steps To Add Market Action Bot To Your Discord Server</h1>
    //             </Grid>

    //             <CmtCard className={clsx(classes.cardRoot, classes.cardRootBorder)} style={{ marginBottom: '25px' }}>
    //                 <Box className={classes.sliderContentFeatures}>
    //                     <Typography component="div" variant="h2" className={classes.titleRoot}>
    //                         1.) Add Market Action Bot to your Discord Server by clicking on the button below.
    //                           </Typography>
    //                     <Box className={classes.subTitle} component="p" mb={4} color="text.secondary">
    //                         <Link href={"https://discord.com/oauth2/authorize?client_id=831861797824495646&scope=bot%20applications.commands&permissions=294205451360&redirect_uri=" + appConstants.AppUrl + "/discord-callback"} target={'_blank'}>
    //                             <Button
    //                                 variant="contained"
    //                                 color="primary"
    //                                 size="large"
    //                                 htmltype="button">
    //                                 Invite Bot To Discord Server
    //                                   </Button>
    //                         </Link>
    //                     </Box>

    //                     <Typography component="div" variant="h2" className={classes.titleRoot}>
    //                         2.) Once the bot has been added. Make sure the Bot has the right permission to read and post messages in the appropriate channel.  Run the following command to test permission.
    //                           </Typography>

    //                     <Typography component="div" variant="h2" className={classes.titleRoot}>
    //                         3.) After verifying permission go to the Discord Settings Page and enable the various alerts that you want to add to your channels.
    //                           </Typography>


    //                     <Typography component="div" variant="h2" className={classes.titleRoot}>
    //                         4.) Once enabled copy the command from the <NavLink to='/discord-settings' color="inherit"> setting </NavLink> page and paste it into the channel on your discord where you want to show your alerts.
    //                         </Typography>

    //                 </Box>
    //             </CmtCard>

    //             <CmtCard className={clsx(classes.cardRoot, classes.cardRootBorder)} style={{ marginBottom: '25px' }}>

    //                 <Box className={classes.subTitle} component="span" mb={4} color="text.secondary">
    //                 <Typography component="p" className={classes.paraWithMargin}>
    //                 If any problems <a href="mailto:marketaction.live@gmail.com" target="_top">click here to send us email</a>.
    //                 </Typography>


    //                   <Typography component="p" className={classes.paraWithMargin}>
    //                     <b>Commands List with examples:</b>
    //                     </Typography>

    //                     <Typography component="p" className={classes.paraWithMargin}>
    //                     Verify Permissions.
    //                     <br/>
    //                     !ma-permission-check
    //                     </Typography>
    //                     <Typography component="p" className={classes.paraWithMargin}>
    //                     Add Bot to channel
    //                     <br/>
    //                      !reg-ma-bot -UserID={'{MarketActionUserId}'} -AlertId={'{MarketActionDiscordAlertId}'}
    //                     </Typography>

    //                     <Typography component="p" className={classes.paraWithMargin}>
    //                      Ticker Summary
    //                     <br/>
    //                         !maflow {'{ticker}'}
    //                         <br/>
    //                         You can add multiple tickers separated by comma(,)
    //                     </Typography>

    //                     {/* <Typography component="p" className={classes.paraWithMargin}>
    //                     Remove Bot from Channel
    //                     </Typography> */}
    //                     </Box>
    //             </CmtCard>
    //         </GridContainer>
    //     </Box>
    // );
}