import React, { useState } from 'react';
import './index.css';
import * as staticFunctions from './dashboardFunctions';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtAdvCardContent from '@coremat/CmtAdvCard/CmtAdvCardContent';
import CmtAdvCard from '@coremat/CmtAdvCard';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ResponsiveContainer } from 'recharts';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import ReactSpeedometer from 'react-d3-speedometer';


const useStyles = makeStyles(theme => ({
    cardContentRoot: {
        '& .MuiGrid-container': {
            alignItems: 'center',
        },
    },
    cardContentTitle: {
        marginBottom: 4,
    },
    speedometerRoot: {
        '& text': {
            fill: theme.palette.text.primary + '!important',
        },
        '& .current-value': {
            paddingTop: 10,
        },
        '& g.pointer': {
            fill: theme.palette.text.primary + '!important',
        },
    }
}));

export default function SentimentScoreWidget(props) {
    const theme = useTheme();
    const classes = useStyles();
    const [sentimentScoreWidgetHidden, setSentimentScoreWidgetHidden] = useState(false);

    if (!sentimentScoreWidgetHidden) {
        const callPutRationWidgetActionHandler = event => {
            switch (event.value) {
                case 'closeSentimentScore': {
                    return setSentimentScoreWidgetHidden(true);
                }
                default:
                    return true;
            }
        };

        return (
            <Grid item xs={12} sm={12} md={6}>
                <CmtAdvCard>
                    <CmtCardHeader
                        className="pt-2 pb-2"
                        actionsPos="top-corner"
                        actions={[{ label: 'Close', value: 'closeSentimentScore' }]}
                        title={
                            <Typography
                                {...{
                                    variant: 'h4',
                                    component: 'div',
                                }}>
                                Sentiment Score
                </Typography>
                        }
                        actionHandler={callPutRationWidgetActionHandler}
                    />

                    <CmtAdvCardContent
                        className={classes.cardContentRoot}
                        title={
                            <Typography
                                {...{
                                    variant: 'h1',
                                    component: 'div',
                                    className: classes.cardContentTitle,
                                }}>
                                <Typography style={{ color: '#00b399' }}>
                                    Total Bullish: {staticFunctions.getTotalSentiment(props.optionsData, 'bullish')} = ${staticFunctions.getTotalSentimentPremium(props.optionsData, 'bullish')}
                                </Typography>
                                <Typography style={{ color: '#f44336' }}>
                                    Total Bearish: {staticFunctions.getTotalSentiment(props.optionsData, 'bearish')} = ${staticFunctions.getTotalSentimentPremium(props.optionsData, 'bearish')}
                                </Typography>
                            </Typography>
                        }
                        reverseDir>
                        <ResponsiveContainer width="100%" height={125}>
                            <Box className={classes.speedometerRoot} textAlign="right">
                                <ReactSpeedometer
                                    width={200}
                                    height={125}
                                    value={staticFunctions.getSentimentScore(props.optionsData)}
                                    maxSegmentLabels={0}
                                    segments={4}
                                    ringWidth={25}
                                    needleColor={theme.palette.text.secondary}
                                    needleHeightRatio={0.5}
                                    needleTransitionDuration={2000}
                                    minValue={0}
                                    maxValue={100}
                                    needleTransition="easeElastic"
                                    segmentColors={['#E00930', '#FF8C00', '#FFCA41', '#8DCD03']}
                                    currentValueText={staticFunctions.getSentimentScoreText(props.optionsData)}
                                    valueTextFontSize={'12px'}
                                    textColor={theme.palette.text.primary}
                                />
                            </Box>
                        </ResponsiveContainer>
                    </CmtAdvCardContent>
                </CmtAdvCard>
            </Grid>
        );
    } else {
        return '';
    }
}
