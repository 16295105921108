import React, { useMemo, useEffect, useState } from 'react';
import GridContainer from '@jumbo/components/GridContainer';
import PremiumSummaryWidget from './PremiumSummaryWidget';
import MostActiveSummaryWidget from './MostActiveSummaryWidget';
import BullishBearishSummaryWidget from './BullishBearishSummaryWidget';
import RepeaterSummaryWidget from './RepeaterSummaryWidget';
import UnusualSummaryWidget from './UnusualSummaryWidget';
import { useSelector } from 'react-redux';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import TimeSessionModal from '../../Shared/TimeSessionModal';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import * as staticFunctions from '../Dashboard/dashboardFunctions';
import moment from 'moment-timezone';
import OptionsWorker from '../../../services/WebWorkers/options.worker';
import WebWorker from "../../../services/WebWorkers/workerSetup";
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { Grid } from '@material-ui/core';

export default function Summary() {
  const { optionsData, selectedDate } = useSelector(({ firebaseStore }) => firebaseStore);
  const [filteredOptionsData, setFilteredOptionsData] = React.useState([]);

  const [isTimeSessionShow, showTimeSession] = useState(false);
  const [timeSession, setTimeSession] = useState("session");
  const [currentFilter, setCurrentFilter] = React.useState([]);


  // useEffect(() => {
  //   var filtered = optionsData.filter(f => f.symbol !== '');
  //   if (timeSession !== 'session') {
  //     var timeType; var tVal = timeSession;
  //     if (tVal.includes("m")) timeType = "minutes"
  //     else {
  //       if (parseInt(tVal) == 1) timeType = "hour"
  //       else timeType = "hours"
  //     }
  //     tVal = parseInt(tVal);
  //     const now = moment().tz('America/New_York').format("YYYY-MM-DDTHH:mm:ss.SSSZ");

  //     filtered = filtered.filter(row => {
  //       const subtractTime = staticFunctions.getSubtractDate(now, tVal, timeType);
  //       if (row.processedDate >= subtractTime) {
  //         return row;
  //       }
  //     });
  //   }

  //   setFilteredOptionsData(filtered);

  //   return () => { }
  // }, [optionsData, timeSession])

  useEffect(() => {
    var filtered = optionsData.filter(f => f.symbol !== '');
    // filter parameters with time and filter values
    const newFilter = timeSession == 'session' ? currentFilter : [...currentFilter, {timeValue : timeSession, value : 'time'}]
    // if (timeSession !== 'session') {
      // console.log({currentFilter, timeSession, optionsData});
      var optionsWorker = new WebWorker(OptionsWorker);

      optionsWorker.postMessage({
        optionsData: filtered,
        currentFilter: newFilter,
        type: 'summary'
      });
  
      optionsWorker.onmessage = event => {
        filtered = event.data;
        setFilteredOptionsData(filtered);
      }
    // }
    // else {
    //   setFilteredOptionsData(filtered);
    // }

    return () => { }
  }, [optionsData, timeSession, currentFilter]);

  const PremiumSummaryWidgetMemo = useMemo(() => <PremiumSummaryWidget optionsData={filteredOptionsData} />, [filteredOptionsData]);
  const MostActiveSummaryWidgetMemo = useMemo(() => <MostActiveSummaryWidget optionsData={filteredOptionsData} />, [filteredOptionsData]);
  const BullishSummaryWidgetMemo = useMemo(() => <BullishBearishSummaryWidget CurrentSentiment="Bullish" optionsData={filteredOptionsData} />, [filteredOptionsData]);
  const BearishSummaryWidgetMemo = useMemo(() => <BullishBearishSummaryWidget CurrentSentiment="Bearish" optionsData={filteredOptionsData} />, [filteredOptionsData]);
  const RepeaterSummaryWidgetMemo = useMemo(() => <RepeaterSummaryWidget optionsData={filteredOptionsData} />, [filteredOptionsData]);
  const UnusualSummaryWidgetMemo = useMemo(() => <UnusualSummaryWidget optionsData={filteredOptionsData} />, [filteredOptionsData]);

  const onTimeSelect = (value) => {
    showTimeSession(false)
    setTimeSession(value);
  }

  const onFilterListChanged = (event, newValue) => {
    setCurrentFilter(newValue);
  };
  return (
    <PageContainer heading={
      <Grid container spacing={6} columns={{ xs: 12, md: 6, sm: 6 }} alignItems='flex-end'>
        <Grid item xs={12} sm={6} md={6} >
          Market Action Summary
        </Grid>
        <Grid item sm={6} md={6} xs={12}>
          <div style={{display : "flex",flexDirection : "row", justifyContent : "space-between",alignItems : "end", justifySelf : "start"}}>
            <Autocomplete
                style={{width : "100%", paddingRight : 20}}

                multiple
                id="filterDDL"
                size="small"
                value={currentFilter}
                onChange={onFilterListChanged}
                options={staticFunctions.filtersDdlData}
                getOptionLabel={option => option.name}
                filterSelectedOptions
                renderInput={params => <TextField {...params} label="Filters" />}
              />
            <WatchLaterIcon title={"Time Session"} style={{ float: "right", cursor: "pointer" }} onClick={() => showTimeSession(true)} />
            </div>
        </Grid>
      </Grid>
    }>
      <GridContainer>
        {PremiumSummaryWidgetMemo}
        {MostActiveSummaryWidgetMemo}
        {BullishSummaryWidgetMemo}
        {BearishSummaryWidgetMemo}
        {RepeaterSummaryWidgetMemo}
        {UnusualSummaryWidgetMemo}

        <TimeSessionModal
          onTimeSelect={(val) => onTimeSelect(val)}
          selectedValue={timeSession}
          onRequestClose={() => showTimeSession(false)}
          visibility={isTimeSessionShow} />

      </GridContainer>
    </PageContainer>
  );
}
