import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Box, Chip, Input } from '@material-ui/core';
import * as staticFunctions from '../Dashboard/dashboardFunctions';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(4),
    flex: 1,
    textAlign: 'center',
  },
  btn_root: {
    padding: '1em 0',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(2),
    },
  },
  box_container: {
    display: 'flex',
    alignItems: 'center',
  },
  btnCont: {
    border: '2px solid',
    width: '100px',
    display: 'flex',
    borderRadius: '50px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 3px 0 0',
    padding: '5px 0',
  },
  midPoint: {
    margin: '0 10px',
  },
  chip: {
    margin: '0 10px 10px 0',
    borderRadius: '50px !important',
    height: '45px',
    borderWidth: '2px',
    '& .MuiChip-label': {
      color: 'white',
    },
    '& .MuiChip-root': {
      borderRadius: '0',
    },
  },
  slider_root: {
    width: 300,
  },
  textfeild: {
    width: '100px',
    margin: '0 5px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px !important',
      height: '40px',
    },
    '& .MuiOutlinedInput-input': {
      textAlign: 'center',
    },
    '& .MuiInputBase-input': {
      borderRadius: '50px !important',
      height: '40px',
      textAlign: 'center',
    },
  },
  action_buttons: {
    display: 'flex',
    justifyContent: 'center',
    margin: '1em 0',
    '& > *': {
      margin: theme.spacing(2),
    },
    position: 'fixed',
    bottom: '0px',
    margin: 'auto',
    width: '100%',
    padding: '10px',
    backgroundColor: '#2e2e2e'
  },
  slider_comp: {
    '& .MuiSlider-thumb': {
      transform: 'scale(1.5)',
    },
  },
  chip_box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  mainPopupDiv: {
    paddingBottom: "50px"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const INITIAL_COLORS = {
  Etf: 'outlined',
  Stock: 'outlined',
  Calls: 'outlined',
  Puts: 'outlined',
  Otm: 'outlined',
  Itm: 'outlined',
  Unusual: 'outlined',
  Sweep: 'outlined',
  'Vol Over OI': 'outlined',
  'Golden Sweeps': 'outlined',
  'Directional': 'outlined',
};

export default function MobileFilterPopup(props) {
  var premiumStartPrice = staticFunctions.nFormatterNoMulitplyWithSeparator(props.premiumPrice[0], 1);
  var premiumEndPrice = staticFunctions.nFormatterNoMulitplyWithSeparator(props.premiumPrice[1], 1);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [currentFilter, setCurrentFilter] = React.useState(props.currentFilter);
  const [cpc, setCpc] = React.useState([0, 100]);
  const [backgrounds, setBackgrounds] = React.useState(INITIAL_COLORS);
  const [premiumStartUnit, setPremiumStartUnit] = React.useState(premiumStartPrice.unit);
  const [premiumEndUnit, setPremiumEndUnit] = React.useState(premiumEndPrice.unit);

  const [premiumStart, setPremiumStart] = React.useState(premiumStartPrice.outputNum);
  const [premiumEnd, setPremiumEnd] = React.useState(premiumEndPrice.outputNum);

  // const [steps, setSteps] = React.useState(0);
  const handleDteChange = (event, newValue) => {
    props.setDte(newValue);
  };

  function premiumLabelFormat(num) {
    return staticFunctions.nFormatterNoMulitply(num, 1);
  }

  const handleInputDteChange = event => {
    if (event.target.value > 365 || !event.target.value) {
      event.target.value = 365;
    }
    if (event.target.name === 'upper') {
      props.setDte([event.target.value, props.dte[1]]);
    } else if (event.target.name === 'lower') {
      props.setDte([props.dte[0], event.target.value]);
    }
  };

  const handlePPChange = (event, newValue) => {
    props.setPremiumPrice(newValue);
    var val1 = staticFunctions.nFormatterNoMulitplyWithSeparator(newValue[0], 1);
    var val2 = staticFunctions.nFormatterNoMulitplyWithSeparator(newValue[1], 1);

    setPremiumStartUnit(val1.unit);
    setPremiumEndUnit(val2.unit);

    setPremiumStart(val1.outputNum);
    setPremiumEnd(val2.outputNum);
  };

  const handleInputPPChange = event => {
    var val = parseFloat(event.target.value);
    val = val * 1e3;
    if (val > 1500 * 1e3) {
      val = 1500 * 1e3;
    }
    if (val == 0 || !val) {
      val = 1500 * 1e3;
    }

    var formatted = staticFunctions.nFormatterNoMulitplyWithSeparator(val, 1);
    if (event.target.name === 'endPremium') {
      setPremiumEndUnit(formatted.unit);
      setPremiumEnd(formatted.outputNum);
      props.setPremiumPrice([props.premiumPrice[0], val]);
    } else if (event.target.name === 'startPremium') {
      setPremiumStartUnit(formatted.unit);
      setPremiumStart(formatted.outputNum);
      props.setPremiumPrice([val, props.premiumPrice[1]]);
    }
  };

  // console.log(ppUnit);
  const handleCpcChange = (event, newValue) => {
    props.setCpc(newValue);
  };
  const handleInputCpcChange = event => {
    if (event.target.value > 100 || !event.target.value) {
      event.target.value = 100;
    }
    if (event.target.name === 'upper') {
      props.setCpc([event.target.value, props.cpc[1]]);
    } else if (event.target.name === 'lower') {
      props.setCpc([props.cpc[0], event.target.value]);
    }
  };
  const handleClickOpen = () => {
    setCurrentFilter(props.currentFilter);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleFilter = () => {
    props.callback(currentFilter);
    handleClose();
  };
  const handleReset = () => {
    props.reset();
    setBackgrounds(INITIAL_COLORS);
    handleClose();
  };
  const myFunc = e => {
    const newVal = {
      name: e.currentTarget.getAttribute('name'),
      value: e.currentTarget.getAttribute('value'),
    };
    if (currentFilter.find(item => item.name === newVal.name) !== undefined) {
      let newFilters = currentFilter.filter(item => item.name !== newVal.name);
      setBackgrounds({ ...backgrounds, [newVal.name]: 'outlined' });
      setCurrentFilter(newFilters);
    } else {
      setBackgrounds({ ...backgrounds, [newVal.name]: 'default' });
      setCurrentFilter([...currentFilter, newVal]);
    }
  };

  useEffect(() => {
    props.callback(currentFilter);
  }, [currentFilter]);


  return (
    <Box>
      <Button className="openFilterBtn" variant="outlined" color="primary" onClick={handleClickOpen} />
      <Dialog fullScreen open={open} onClose={handleClose} className={classes.mainPopupDiv} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h2" className={classes.title}>
              Market Action Filter
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Box className={classes.btn_root}>
          <Typography>Days To Expiration</Typography>
          <Box className={classes.box_container}>
            <TextField
              variant="outlined"
              type="text"
              value={props.dte[0]}
              onChange={handleInputDteChange}
              name="upper"
              className={classes.textfeild}
            />
            <Typography className={classes.midPoint}>to</Typography>
            <TextField
              variant="outlined"
              type="text"
              value={props.dte[1]}
              onChange={handleInputDteChange}
              name="lower"
              className={classes.textfeild}
            />
            <span>Days {props.dte[1] >= 365 && '+'}</span>
          </Box>
          <Box className={classes.slider_root}>
            <Slider
              onChange={handleDteChange}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              step={1}
              max={365}
              name="dte"
              value={props.dte}
              className={classes.slider_comp}
            />
          </Box>
        </Box>
        <Divider />

        <Box className={classes.btn_root}>
          <Typography>Premium Price</Typography>
          <Box className={classes.box_container}>
            <span>$</span>
            <TextField
              variant="outlined"
              type="text"
              value={premiumStart}
              onChange={handleInputPPChange}
              name="startPremium"
              className={classes.textfeild}
            />
            <span>{premiumStartUnit}</span>
            <Typography className={classes.midPoint}>to</Typography>
            <span>$</span>
            <TextField
              variant="outlined"
              type="text"
              value={premiumEnd}
              onChange={handleInputPPChange}
              name="endPremium"
              className={classes.textfeild}
            />
            <span>
              {premiumEndUnit}
              {props.premiumPrice[1] >= 1500000 && '+'}
            </span>
          </Box>
          <Box className={classes.slider_root}>
            <Slider
              onChange={handlePPChange}
              valueLabelDisplay="auto"
              valueLabelFormat={premiumLabelFormat}
              aria-labelledby="range-slider"
              step={50000}
              max={1500000}
              name="premiumPrice"
              value={props.premiumPrice}
              className={classes.slider_comp}
            />
          </Box>
        </Box>
        <Divider />
        <Box className={classes.btn_root}>
          <Typography>Contract Price(cost per contract)</Typography>
          <Box className={classes.box_container}>
            <span>$</span>
            <TextField
              variant="outlined"
              type="text"
              value={props.cpc && props.cpc[0]}
              onChange={handleInputCpcChange}
              name="upper"
              className={classes.textfeild}
            />
            <Typography className={classes.midPoint}>to</Typography>
            <span>$</span>
            <TextField
              variant="outlined"
              type="text"
              value={props.cpc && props.cpc[1]}
              onChange={handleInputCpcChange}
              name="lower"
              className={classes.textfeild}
            />
            <span>{props.cpc[1] >= 100 && '+'}</span>
          </Box>
          <Box className={classes.slider_root}>
            <Slider
              onChange={handleCpcChange}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              step={10}
              max={100}
              name="cpc"
              value={props.cpc}
              className={classes.slider_comp}
            />
          </Box>
        </Box>
        <Divider />

        {/* lower buttons  */}
        <Box className={classes.btn_root}>
          <Typography>Equity Type</Typography>
          <Box className={classes.chip_box}>
            <Chip
              variant={`${backgrounds.Stock}`}
              size="medium"
              label="STOCK"
              color="primary"
              name="Stock"
              value="stock"
              onClick={e => myFunc(e)}
              // onClick={handleToggle}
              className={classes.chip}
            />
            <Chip
              label="ETF"
              variant={`${backgrounds.Etf}`}
              color="primary"
              name="Etf"
              value="etf"
              onClick={e => myFunc(e)}
              className={classes.chip}
            />
          </Box>
        </Box>
        <Divider />
        <Box className={classes.btn_root}>
          <Typography>Contract</Typography>
          <Box className={classes.chip_box}>
            <Chip
              label="CALLS"
              variant={`${backgrounds.Calls}`}
              color="primary"
              name="Calls"
              value="calls"
              onClick={e => myFunc(e)}
              className={classes.chip}
            />
            <Chip
              label="PUTS"
              variant={`${backgrounds.Puts}`}
              color="primary"
              name="Puts"
              value="puts"
              onClick={e => myFunc(e)}
              className={classes.chip}
            />
            <Chip
              label="OTM"
              variant={`${backgrounds.Otm}`}
              color="primary"
              name="Otm"
              value="otm"
              onClick={e => myFunc(e)}
              className={classes.chip}
            />
            <Chip
              label="ITM"
              variant={`${backgrounds.Itm}`}
              color="primary"
              name="Itm"
              value="itm"
              onClick={e => myFunc(e)}
              className={classes.chip}
            />
          </Box>
        </Box>
        <Divider />
        <Box className={classes.btn_root}>
          <Typography>Flow Type</Typography>
          <Box className={classes.chip_box}>
            <Chip
              label="UNUSUAL"
              variant={`${backgrounds.Unusual}`}
              color="primary"
              name="Unusual"
              value="unusual"
              onClick={e => myFunc(e)}
              className={classes.chip}
            />
            <Chip
              label="SWEEP"
              variant={`${backgrounds.Sweep}`}
              color="primary"
              name="Sweep"
              value="sweep"
              onClick={e => myFunc(e)}
              className={classes.chip}
            />
            <Chip
              label="VOL>OI"
              variant={`${backgrounds['Vol Over OI']}`}
              color="primary"
              name="Vol Over OI"
              value="vooi"
              onClick={e => myFunc(e)}
              className={classes.chip}
            />
            <Chip
              label="GOLDEN SWEEP"
              variant={`${backgrounds['Golden Sweeps']}`}
              color="primary"
              name="Golden Sweeps"
              value="golden_sweep"
              onClick={e => myFunc(e)}
              className={classes.chip}
            />
             <Chip
              label="DIRECTIONAL"
              variant={`${backgrounds['Directional']}`}
              color="primary"
              name="Directional"
              value="directional"
              onClick={e => myFunc(e)}
              className={classes.chip}
            />
          </Box>
        </Box>
        <Divider />

        <Box className={classes.action_buttons}>
          <Button autoFocus variant="contained" size="small" onClick={handleReset} color="primary">
            Reset
          </Button>
          <Button autoFocus variant="contained" size="small" onClick={() => handleFilter()} color="primary">
            Filter
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
}
