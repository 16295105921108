import React from 'react';
import { fade, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { Box, TextField, Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles(theme => ({
  root: {
    backgroundColor: fade(theme.palette.common.dark, 0.05),
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
}))(MuiAccordionDetails);

export default function Faq() {
    const [searchValue, setSearchValue] = React.useState('');

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    }

    const questions = [
        {
            id: 1,
            question: "How to add a coupon?",
            answer: "You can add a coupon code into the \"Add promotion code\" field located on the left hand side. If you are using a mobile device, you can find this field by accessing \"view details\" at the top right corner."
        },
        {
            id: 2,
            question: "Trial Questions?",
            answer: "Once you sign up for the 7 day trial, your credit card will not be charged. You can cancel anytime within the trial period without any obligations.Once the trial will be over you will be charged the subscrion amount that you have selected. If you cancel within the 7 day trial and decide to come back sometime later, you are not entitled to another trial period."
        },
        {
            id: 3,
            question: "Do you offer refunds?",
            answer: "Since services are non­tangible, irrevocable, digital goods; we do not issue refunds. But we do offer a 7 day trial."
        }
    ];

    const filtered = questions.filter(f => {
        if(searchValue === '' || searchValue === undefined || searchValue === null){
            return true;
        }

        if(f.question.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 || f.answer.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0){
          return true;
        }
        return false;
    });

  return (
    <Box>
        <GridContainer style={{marginBottom: "10px"}}>
            <Grid item xs={12} sm={12}>
                <h1 style={{textAlign:"center"}}>How can we help you?</h1>
            </Grid>
            <Grid item xs={12} sm={12} style={{textAlign:"center"}}>
                <TextField placeholder={"Search.."} style={{width:"60%"}} onChange={handleSearchChange} value={searchValue} name="searchValue"></TextField>
            </Grid>
        </GridContainer>

        {filtered.map(function(question, index){
            return (
            <Accordion key={index} square expanded={true}>
                <AccordionSummary aria-controls="panel2d-content">
                    <Typography>{question.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    {question.answer}
                 </Typography>
                </AccordionDetails>
            </Accordion>)
          })}

          {filtered.length === 0 && <Typography component="h1" style={{marginTop:"10px",textAlign:"center"}}>No results in FAQ's contained the term '{searchValue}'.</Typography>}
    </Box>
  );
}