import React, { useState } from 'react';
import { Box, fade, TextField, Button, Grid, InputAdornment } from '@material-ui/core';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import blue from '@material-ui/core/colors/blue';
import PersonIcon from '@material-ui/icons/Person';
import CreateIcon from '@material-ui/icons/Create';
import { auth, firebase } from '../../../services/configs/config';
import { AuhMethods } from '../../../services/auth';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as FirestoreService from '../../../services/auth/firebase/FirebaseRealTimeDb';
import * as firebaseFunctions from '../../../services/firebase/functions';
import { fetchError } from '../../../redux/actions';
import { useDispatch } from 'react-redux';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useEffect } from 'react';
import { updatePhoneNo } from 'redux/actions/Auth';
import MuiPhoneNumber from 'material-ui-phone-number';
import { EmailAuthProvider, PhoneAuthProvider, RecaptchaVerifier, linkWithPhoneNumber, reauthenticateWithCredential, unlink, updatePassword, updateProfile } from 'firebase/auth';
const phone = require('phone');

const useStyles = makeStyles(theme => ({
  iconView: {
    backgroundColor: fade(blue['500'], 0.1),
    color: blue['500'],
    padding: 8,
    borderRadius: 4,
    '& .MuiSvgIcon-root': {
      display: 'block',
    },
    '&.web': {
      backgroundColor: fade(theme.palette.warning.main, 0.1),
      color: theme.palette.warning.main,
    },
    '&.phone': {
      backgroundColor: fade(theme.palette.success.main, 0.15),
      color: theme.palette.success.dark,
    },
  },
  wordAddress: {
    wordBreak: 'break-all',
    cursor: 'pointer',
  },
}));

const actions = [
  {
    icon: <CreateIcon />,
    label: 'Edit',
    value: 'edit',
  },
  {
    label: 'Change Password',
    value: 'changePassword',
  },
];

const Contact = ({ userDetail, userSubscription }) => {
  const MySwal = withReactContent(Swal);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { email, displayName, userDetails } = userDetail;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [editProfile, setEditProfile] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [userData, setUserData] = useState({
    displayName: displayName,
    phoneNumber: userDetail.phoneNumber,
    ReferralCode: userDetails.ReferralCode,
    IsSubscribedToNewsletter: userDetails.IsSubscribedToNewsletter,
  });
  const [phoneNo, setPhoneNo] = React.useState({
    phone: '',
    isValid: false,
  });

  const [confirm, setConfirm] = React.useState('');

  const [otp, setOtp] = React.useState('');
  const [timer, setTimer] = React.useState(30);
  const [loading, setLoading] = React.useState(false);
  const [isPhoneNoDifferent, setIsPhoneNoDifferent] = React.useState(false);

  const captchaRef = React.useRef(null);

  function linkPhone() {
    //For testing

    const testPhn = userData.phoneNumber;
    const reCaptchaVerifier = window.recaptchaVerifier;

    linkWithPhoneNumber(auth.currentUser, testPhn, reCaptchaVerifier)
      .then(result => {
        if (timer === 0) {
          setTimer(30);
          // reCaptchaVerifier.clear();
        }
        setConfirm(result);
        console.log('result ', result);
        dispatch(fetchError('Verification code sent to phone ' + userData.phoneNumber));
      })
      .catch(error => {
        // reCaptchaVerifier.clear();
        console.error('err ', error);
        if (error.code === 'auth/invalid-phone-number') {
          // alert('Invalid phone number.');
          dispatch(fetchError(`Invalid phone number.`));
        }
        setLoading(false);
      });
  }

  function verifyPhone() {
    
    unlink(auth.currentUser, PhoneAuthProvider.PROVIDER_ID)
      .then(res => {
        linkPhone();
      })
      .catch(error => {
        console.log(error);
        linkPhone();
      });
  }

  // useEffect(() => {
  //   var phoneReg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  //   if (userData.phoneNumber && phoneReg.test(userData.phoneNumber) && userData.phoneNumber !== userDetail.phoneNumber) {
  //     setIsPhoneNoDifferent(true);
  //   } else {
  //     setIsPhoneNoDifferent(false);
  //   }
  // }, [userData.phoneNumber]);

  useEffect(() => {
    if (confirm && timer > 0) {
      const tim = setTimeout(() => {
        setTimer(val => val - 1);
      }, 1000);
      return () => {
        clearTimeout(tim);
      };
    } else if (timer === 0) {
      setOtp('');
    }
  }, [timer, confirm]);

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(captchaRef.current, {
      size: 'invisible',
      callback: function(response) {
        console.log('resp ', response);
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
      'expired-callback': function() {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
      },
    }, auth);
    window.recaptchaVerifier.render().then(function(widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
  }, []);

  const handleVerifyPhone = () => {
    setOtp('');
    verifyPhone();
  };

  const handleVerifyOtp = () => {
    confirm
      .confirm(otp)
      .then(ree => {
        const {
          user: { phoneNumber },
        } = ree || {};
        if (phoneNumber) {
          dispatch(fetchError(`Phone number updated successfully.`));
          dispatch(updatePhoneNo(phoneNumber));
          setConfirm('');
          setIsPhoneNoDifferent(false);
          // alert('Phone number saved successfully.');
        }
      })
      .catch(error => {
        console.log('err ', error);
        if (error.code === 'auth/invalid-verification-code') {
          dispatch(fetchError(`Oops ! Invalid OTP.Try again.`));
        } else if ('auth/credential-already-in-use') {
          dispatch(fetchError(`This Phone number is already in use.`));
        }
      });
  };
  // const handleBack = () => {
  //   setConfirm(null);
  //   setPhoneNo({
  //     phone: '',
  //     isValid: false,
  //   });
  //   setLoading(false);
  //   setTimer(30);
  // };

  const handleCheckedChange = event => {
    setUserData({ ...userData, [event.target.name]: event.target.checked });
  };

  const [userPassword, setUserPassword] = useState({
    CurrentPassword: '',
    NewPassword: '',
    NewPasswordConfirm: '',
  });

  const profileSectionHandler = event => {
    switch (event.value) {
      case 'edit': {
        return setEditProfile(true);
      }
      case 'changePassword': {
        return setOpen(true);
      }
      default:
        return true;
    }
  };

  const handleChange = event => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    });
  };

  const handleProfileEditClose = () => {
    setUserData({
      displayName: displayName,
      phoneNumber: userDetail.phoneNumber,
      IsSubscribedToNewsletter: userDetails.IsSubscribedToNewsletter,
    });
    setEditProfile(false);
    setConfirm('');
    setTimer(30);
  };

  function saveProfileData() {
    updateProfile(auth.currentUser, { displayName: userData.displayName })
      .then(function() {
        FirestoreService.addUpdateUserExtraData(
          auth.currentUser,
          {
            PhoneNumber: userData.phoneNumber,
            ReferralCode: userData.ReferralCode,
            IsSubscribedToNewsletter: userData.IsSubscribedToNewsletter,
          },
          function(res) {
            dispatch(AuhMethods[CurrentAuthMethod].getAuthUser());

            if (userDetails.IsSubscribedToNewsletter !== userData.IsSubscribedToNewsletter) {
              if (userData.IsSubscribedToNewsletter === true) {
                var listId = firebaseFunctions.FreePlanListId;
                if (userSubscription != null) {
                  listId = userSubscription.planProduct.stripe_metadata_SendGrid_ListId;
                }

                firebaseFunctions.callfunction(
                  'subscribeNewsletter',
                  {
                    email: email,
                    listId: listId,
                    name: userData.displayName,
                    isUpdate: true,
                  },
                  res => {
                    if (res.data.status !== 1) {
                      dispatch(fetchError(res.data.msg));
                    }
                  },
                );
              } else {
                firebaseFunctions.callfunction(
                  'unSubscribeNewsletter',
                  {
                    email: email,
                  },
                  res => {
                    if (res.data.status !== 1) {
                      dispatch(fetchError(res.data.msg));
                    }
                  },
                );
              }
            }
          },
        );
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  const handlePasswordChange = event => {
    setUserPassword({
      ...userPassword,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setUserPassword({
      CurrentPassword: '',
      NewPassword: '',
      NewPasswordConfirm: '',
    });
    setOpen(false);
  };

  const handleBack = () => {
    setConfirm('');
    setUserData(val => ({
      ...val,
      phoneNumber: userDetail.phoneNumber,
    }));
    setLoading(false);
    setTimer(30);
  };

  const reauthenticate = currentPassword => {
    var user = auth.currentUser;
    var cred = EmailAuthProvider.credential(user.email, currentPassword);
    return reauthenticateWithCredential(user, cred);
  };

  function handleChangePassword() {
    reauthenticate(userPassword.CurrentPassword)
      .then(() => {
        var user = auth.currentUser;
        updatePassword(user, userPassword.NewPassword)
          .then(() => {
            MySwal.fire({
              icon: 'success',
              title: 'success',
              text: 'Password updated Successfully.',
            });
            handleClose();
          })
          .catch(error => {
            MySwal.fire({
              icon: 'error',
              title: 'error',
              text: error.message,
            });
          });
      })
      .catch(error => {
        MySwal.fire({
          icon: 'error',
          title: 'error',
          text: error.message,
        });
      });
  }
  const handlePhoneNo = val => {
    if (phone(val).length > 0) {
      setUserData({
        phoneNumber: val,
      });
      setIsPhoneNoDifferent(true);
    } else {
      setIsPhoneNoDifferent(false);
    }
  };

  ValidatorForm.addValidationRule('isPasswordMatch', () => {
    if (userPassword.NewPassword !== userPassword.NewPasswordConfirm) {
      return false;
    }
    return true;
  });

  return (
    <CmtCard>
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={open}
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
        <ValidatorForm onSubmit={handleChangePassword} onError={errors => console.log(errors)}>
          <DialogContent>
            <Grid item xs={12}>
              <Box>
                <TextValidator
                  fullWidth
                  style={{ margin: 8 }}
                  id="CurrentPassword"
                  name="CurrentPassword"
                  validators={['required']}
                  errorMessages={['This field is required!']}
                  value={userPassword.CurrentPassword || ''}
                  onChange={handlePasswordChange}
                  label="Current Password"
                  type="password"
                />
              </Box>
              <Box>
                <TextValidator
                  fullWidth
                  style={{ margin: 8 }}
                  id="NewPassword"
                  name="NewPassword"
                  validators={['required']}
                  errorMessages={['This field is required!']}
                  value={userPassword.NewPassword || ''}
                  onChange={handlePasswordChange}
                  label="New Password"
                  type="password"
                />
              </Box>
              <Box>
                <TextValidator
                  fullWidth
                  style={{ margin: 8 }}
                  id="NewPasswordConfirm"
                  name="NewPasswordConfirm"
                  validators={['required', 'isPasswordMatch']}
                  errorMessages={['This field is required!', 'Confirm Password does not match!']}
                  value={userPassword.NewPasswordConfirm || ''}
                  onChange={handlePasswordChange}
                  label="Re Enter New Password"
                  type="password"
                />
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>

      <CmtCardHeader title="Contact" actions={actions} actionHandler={profileSectionHandler} />
      <CmtCardContent>
        <ValidatorForm onSubmit={saveProfileData} onError={errors => console.log(errors)}>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box className={classes.iconView}>
              <PersonIcon />
            </Box>
            {editProfile ? (
              <Box ml={5}>
                <TextValidator
                  id="Name"
                  name="displayName"
                  label="Name"
                  style={{ margin: 8 }}
                  placeholder="Name"
                  fullWidth
                  onChange={handleChange}
                  value={userData.displayName || ''}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  validators={['required']}
                  errorMessages={['This field is required!']}
                />
              </Box>
            ) : (
              <Box ml={5}>
                <Box component="span" fontSize={12} color="text.secondary">
                  Name
                </Box>
                <Box component="p" className={classes.wordAddress} fontSize={16} color="text.primary">
                  {displayName}
                </Box>
              </Box>
            )}
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box className={classes.iconView}>
              <MailOutlineIcon />
            </Box>
            <Box ml={5}>
              <Box component="span" fontSize={12} color="text.secondary">
                Email
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="a" href={`mailto:${email}`}>
                  {email}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box className={clsx(classes.iconView, 'phone')}>
              <LocalPhoneIcon />
            </Box>

            {editProfile ? (
              <Grid container justifycontent="space-between" alignitems="center" style={{ paddingLeft: 16 }}>
                {!confirm ? (
                  <Grid item>
                    <Grid container justifycontent="space-between" alignitems="center">
                      <Grid item>
                        <MuiPhoneNumber
                          margin="normal"
                          autoFocus
                          disableAreaCodes={true}
                          label="Please enter mobile number"
                          fullWidth
                          defaultCountry={'us'}
                          onChange={handlePhoneNo}
                          className={classes.textFieldRoot}
                          value={userData.phoneNumber}
                        />
                      </Grid>

                      <Grid item style={{ marginLeft: 20, marginTop: 20 }}>
                        <Button variant="contained" onClick={handleVerifyPhone} disabled={!isPhoneNoDifferent}>
                          Change Phone No
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item>
                    <Grid container justifycontent="space-between" alignitems="flex-end">
                      <Grid item>
                        <TextField
                          id="otp"
                          name="otp"
                          label="Verification code"
                          style={{ margin: 8 }}
                          placeholder="Enter verification code"
                          fullWidth
                          onChange={e => setOtp(e.target.value)}
                          value={otp}
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                {timer > 0 ? (
                                  <h4> {timer} s</h4>
                                ) : (
                                  <Button id="verify-phone" onClick={handleVerifyPhone}>
                                    Resend
                                  </Button>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item style={{ marginLeft: 20, marginTop: 20 }}>
                        <Button variant="contained" onClick={handleVerifyOtp}>
                          Verify
                        </Button>
                        <Button
                          style={{ marginLeft: 8 }}
                          variant="contained"
                          color="primary"
                          size="medium"
                          startIcon={<KeyboardBackspaceIcon />}
                          onClick={handleBack}>
                          Change phone no
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Box ml={5}>
                <Box component="span" fontSize={12} color="text.secondary">
                  Phone
                </Box>
                <Box component="p" className={classes.wordAddress} fontSize={16} color="text.primary">
                  {userDetail.phoneNumber}
                </Box>
              </Box>
            )}
          </Box>

          {editProfile && (
            <Box display="flex" alignItems="center">
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={userData.IsSubscribedToNewsletter || false}
                      onChange={handleCheckedChange}
                      name="IsSubscribedToNewsletter"
                      color="primary"
                    />
                  }
                  label="Subscribe to newsletter"
                />
              </FormGroup>
            </Box>
          )}

          {editProfile ? (
            <Box display="flex" justifyContent="flex-end">
              <Box>
                <Button onClick={handleProfileEditClose}>Cancel</Button>
                <Button type="submit">Save</Button>
              </Box>
            </Box>
          ) : (
            ''
          )}
        </ValidatorForm>
      </CmtCardContent>
      <div id="recaptcha-container" ref={ref => (captchaRef.current = ref)} />
    </CmtCard>
  );
};

export default Contact;

Contact.prototype = {
  userDetail: PropTypes.object.isRequired,
};
