import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtAdvCardContent from '@coremat/CmtAdvCard/CmtAdvCardContent';
import CmtAdvCard from '@coremat/CmtAdvCard';
import { makeStyles } from '@material-ui/core/styles';

import * as staticFunctions from '../Dashboard/dashboardFunctions';
import moment from 'moment-timezone';
import * as firebaseFunctions from '../../../services/firebase/functions';
import { fetchError } from '../../../redux/actions';
import { useDispatch } from 'react-redux';

function getText() {
  var today = moment.tz('America/New_York');
  let text = today.format('MM/DD/YYYY');
  var marketStartDate = moment.tz(moment(), 'America/New_York');
  marketStartDate.set({ hour: 0, minute: 0, seconds: 1 });
  var marketCloseDate = moment.tz(moment(), 'America/New_York');
  marketCloseDate.set({ hour: 16, minute: 0, seconds: 0 });
  if (today.isoWeekday() === 5 && today.isAfter(marketCloseDate)) {
    today = today.add(3, 'days');
    text = today.format('MM/DD/YYYY');
  } else if (today.isoWeekday() === 6) {
    today = today.add(2, 'days');
    text = today.format('MM/DD/YYYY');
  } else if (today.isoWeekday() === 7) {
    today = today.add(1, 'days');
    text = today.format('MM/DD/YYYY');
  }
  return text;
}
const useStyles = makeStyles(theme => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1em',
  },
  summPrices: {
    display: 'flex',
    marginTop: '2em',
    marginBottom: '2em',
  },
  summPrices_first: {
    marginRight: '4em',
  },
  tableStyle: {
    marginBottom: '5px',
  },
  tableBorder: {
    border: `solid 1px ${theme.palette.borderColor.main}`,
  },
  tableRowCellRoot: {
    fontSize: 12,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 16,
    letterSpacing: 0.4,
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      paddingRight: 24,
    },
  },
  itemRow: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    padding: '10px 20px',
  },
  tickerSpan: {
    marginRight: '10px',
  },
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      paddingRight: 24,
    },
    '& .Cmt-avatar-more': {
      fontSize: 10,
      color: theme.palette.primary.main,
    },
  },
}));
function PriceLevelSummaryWidget({ selectedSymbol }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [priceLevelSummaryHidden, setpriceLevelSummaryHidden] = React.useState(false);
  const [response, setResponse] = useState(null);
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    setResponse(null);
    if (!priceLevelSummaryHidden) {
      if (selectedSymbol) {
        firebaseFunctions.callfunction('getPriceLevels', { symbol: selectedSymbol }, function(res) {
          if (res.data.status === 1) {
            setResponse(res.data.response);

            setAllData(
              [
                { title: '1st Resistance Point', value: res.data.response.upsideLevels[0] },
                { title: '3rd Resistance Point', value: res.data.response.upsideLevels[2] },
                { title: '3rd Support Level', value: res.data.response.downsideLevels[2] },
                { title: '2nd Resistance Point', value: res.data.response.upsideLevels[1] },
                { title: '2nd Support Level', value: res.data.response.downsideLevels[1] },
                { title: 'Current Price', value: res.data.response.currentPrice },
                { title: '1st Support Level', value: res.data.response.downsideLevels[0] },
              ].sort(function(a, b) {
                return b.value - a.value;
              }),
            );
          } else {
            dispatch(fetchError(res.data.msg));
          }
        });
      }
      return () => {};
    }
  }, [selectedSymbol, priceLevelSummaryHidden]);

  const priceLevelSummaryActionHandler = event => {
    switch (event.value) {
      case 'close': {
        return setpriceLevelSummaryHidden(true);
      }
      default:
        return true;
    }
  };

  if (priceLevelSummaryHidden) {
    return '';
  }

  return (
    <Grid item xs={12} sm={6} md={4}>
      <CmtAdvCard>
        <CmtCardHeader
          className="pt-2 pb-2"
          actionsPos="top-corner"
          actions={[{ label: 'Close', value: 'close' }]}
          actionHandler={priceLevelSummaryActionHandler}
          title={
            <Typography
              {...{
                variant: 'h4',
                component: 'div',
              }}>
              Price Levels for {getText()} (BETA)
            </Typography>
          }
        />
        {response && (
          <CmtAdvCardContent style={{ marginTop: '5px' }}>
            <Box>
              <div className={``}>
                <div>
                  {response &&
                    allData.map((item, index) => {
                      if (item.title === 'Current Price') {
                        return (
                          <div key={index} className={`${classes.itemRow}`}>
                            <div>
                              {staticFunctions.priceLevelTextFormatter({
                                value: item.title,
                                difference: response.pointDiff,
                                style: {},
                              })}
                            </div>
                            <div style={{ textAlign: 'right', whiteSpace: 'wrap' }}>
                              {item.value.toFixed(2)}{' '}
                              {staticFunctions.priceLevelTextFormatter({
                                value: ` ${response.sign}${response.pointDiff.toFixed(2)}`,
                                difference: response.pointDiff,
                                style: { fontSize: '12px', marginRight: '0.4em' },
                              })}
                              {staticFunctions.priceLevelTextFormatter({
                                value: `(${response.sign}${response.percentDiff}%)`,
                                difference: response.pointDiff,
                                style: { fontSize: '12px' },
                              })}
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div key={index} className={`${classes.itemRow}`}>
                            <div>{item.title}</div>
                            <div style={{ textAlign: 'right' }}>{item.value.toFixed(2)} </div>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            </Box>
          </CmtAdvCardContent>
        )}
      </CmtAdvCard>
    </Grid>
  );
}

export default PriceLevelSummaryWidget;
