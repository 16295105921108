import { useEffect } from 'react';
import * as FirestoreService from '../../../services/auth/firebase/FirebaseRealTimeDb';
import { useLocation } from 'react-router-dom';
import { SetDiscordTokens } from '../../../redux/actions/Firebase';
import * as firebaseFunctions from '../../../services/firebase/functions';
import { fetchError } from '../../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const MySwal = withReactContent(Swal);

export default function DiscordCallback() {
    const dispatch = useDispatch();
    let query = useQuery();
    const { authUser } = useSelector(({ auth }) => auth);
    const authCode = query.get('code');
    const guild = query.get('guild_id');

    useEffect(() => {
        if (!guild && authCode) {
            firebaseFunctions.callfunction(
                'discord-validateAuthCodeServer',
                {
                    code: authCode
                },
                function (res) {
                    if (res.data.status === 1) {
                        if(authUser.email.toLowerCase() != res.data.userData.email.toLowerCase()){
                            MySwal.fire({
                                icon: 'error',
                                title: 'error',
                                text: 'Discord email does not match with your MarketAction email. Email should be same in Discord and as well as in MarketAction.',
                              }).then((res) => {
                                window.close();
                            });
                            return false;
                        }

                        res.data.discordData.DiscordId = res.data.userData.id;

                        FirestoreService.addUpdateDiscordTokenData(res.data.discordData, function () {
                            dispatch(SetDiscordTokens(res.data.discordData));
                            window.opener.location.reload();
                            window.close();
                        });
                    } else {
                        dispatch(fetchError(res.data.msg));
                        window.close();
                    }
                },
            );
        }
        else {
            window.close();
        }

        return () => {
        }
    }, []);

    return '';
}
