import React, { useEffect, forwardRef } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { lighten } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as FirestoreService from '../../../services/auth/firebase/FirebaseRealTimeDb';
import MessageIcon from '@material-ui/icons/Message';
import * as firebaseFunctions from '../../../services/firebase/functions';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: lighten(theme.palette.background.paper, 0.1),
    },
}));

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const MySwal = withReactContent(Swal);

export default function DiscordChannels({ open, handleOpenDialog, handleCloseDialog, discordFilterData }) {
    const classes = useStyles();
    const [discordChannels, setdiscordChannels] = React.useState([]);
    const { authUser } = useSelector(({ auth }) => auth);

    const sweetAlerts = (variant, msg) => {
        const Toast = MySwal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: msg,
        });
    };

    useEffect(() => {
        setdiscordChannels([]);

        var discordFilterDataRef = null;
        if (discordFilterData != null) {
            discordFilterDataRef = FirestoreService.getDiscordChannels(discordFilterData.id, function(subscribedChannels) {
                subscribedChannels.forEach(async (subscribedChannel) => {
                    var data = subscribedChannel.data();
                    var discordServer = await FirestoreService.getDiscordServer(data.ServerId);
                    var discordServerData = {};
                    if(discordServer.exists()){
                        discordServerData = discordServer.data();
                    }

                    data.id = subscribedChannel.id;
                    data.Title = discordFilterData.Title;
                    data.AlertId = discordFilterData.id;
                    data.IsServerActive = discordServerData.IsActive ?? false;
                    setdiscordChannels(discordChannels => [...discordChannels, data]);
                });
            });
        }

        return () => { 
            if(discordFilterDataRef != null){
                discordFilterDataRef();
            }
         }
    }, [discordFilterData])

    const channelsGridColumns = [
        {
            title: 'Alert Title',
            field: 'Title',
            editable: 'never'
        },
        {
            title: 'Server Name',
            field: 'ServerName',
            editable: 'never'
        },
        {
            title: 'Channel Name',
            field: 'ChannelName',
            editable: 'never'
        },
        {
            title: 'Server Status',
            field: 'IsServerActive',
            editable: 'never',
            render: rowData => {
                return <span>{rowData.IsServerActive === true || rowData.IsServerActive === 'true' ? <CheckIcon style={{color:'green'}} /> : <ClearIcon style={{color:'red'}}/> }</span>
            },
        },
    ]

    const handleClickOpen = () => {
        handleOpenDialog();
    };

    const handleClose = () => {
        handleCloseDialog();
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Discord Subscribed Channels</DialogTitle>
                <DialogContent>
                    <MaterialTable
                        className={classes.root}
                        icons={tableIcons}
                        title="Subscribed Channels"
                        columns={channelsGridColumns}
                        options={{
                            pageSize: 5,
                            pageSizeOptions: [5, 10, 20],
                            actionsColumnIndex: -1,
                        }}
                        actions={[
                            rowData => ({
                                icon: () => <MessageIcon />,
                                tooltip: 'Sends a Test Message to this channel',
                                onClick: (event, rowData) => {
                                    firebaseFunctions.callfunction("sendTestMessageToChannel", {
                                        "userId": authUser.uid,
                                        "channelId": rowData.ChannelId,
                                        "alertId": rowData.AlertId
                                      }, function (res) {
                                        if (res.data.status === 1) {
                                            sweetAlerts('success', 'Test Message successfully sent!');
                                          //dispatch(fetchSuccess(res.data.msg));
                                        }
                                        else {
                                            sweetAlerts('error', 'Something went wrong!');
                                          //dispatch(fetchError(res.data.msg));
                                        }
                                      });
                                }
                            })
                        ]}
                        data={discordChannels}
                        editable={{
                            onRowDelete: oldData =>
                                new Promise(resolve => {
                                    FirestoreService.deleteDiscordChannel(oldData);

                                    const dataDelete = [...discordChannels];
                                    const index = oldData.tableData.id;
                                    dataDelete.splice(index, 1);
                                    setdiscordChannels([...dataDelete]);

                                    resolve();
                                }),
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
          </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
