import { useEffect } from 'react';
import * as FirestoreService from './firebase/FirebaseRealTimeDb';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as firebaseFunctions from '../firebase/functions';
import { fetchError, fetchSuccess } from '../../redux/actions';
import { UpdateLoadSubscription, SetDiscordTokens } from '../../redux/actions/Firebase';
import moment from 'moment-timezone';
import { auth } from '../configs/config';
import Cookies from 'universal-cookie';
import { setAuthUser } from '../../redux/actions/Auth';
import { signInWithCustomToken } from 'firebase/auth';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function DiscordLoginCallback() {
    const dispatch = useDispatch();
    let query = useQuery();
    const authCode = query.get('code');

    useEffect(() => {
        if (authCode) {
            firebaseFunctions.callfunction(
                'discord-validateAuthCode',
                {
                    code: authCode
                },
                function (res) {
                    if (res.data.status === 1) {
                        res.data.discordData.DiscordId = res.data.userData.id;

                        try {
                            signInWithCustomToken(auth, res.data.customToken).then(async authUser => {
                                dispatch(UpdateLoadSubscription(false));
                                await storeExtraUserFields(dispatch, authUser, res.data.discordData, '', window);
                            });
                        } catch (error) {
                            dispatch(fetchError(error.message));
                        }
                    } else {
                        dispatch(fetchError(res.data.msg));
                    }
                },
            );
        }
        else {
            window.close();
        }

        return () => {
        }
    }, []);


    async function storeExtraUserFields(dispatch, authUser, discordData, referralCode, window) {
        firebaseFunctions.callfunction(
            'registerFirebaseUser',
            {
                uid: authUser.user.uid,
                refCode: referralCode === null || referralCode === undefined ? '' : referralCode,
                createDate: moment().format('YYYY-MM-DD H:mm:ss'),
            },
            async res => {
                if (res.data.status === 1 || res.data.status === 3) {
                    await FirestoreService.addUpdateDiscordTokenData(discordData, function () {
                        dispatch(SetDiscordTokens(discordData));
                    });

                    await FirestoreService.getUserExtraData(async doc => {
                        var userDetails = {};
                        var isAdmin = false;
                        if (doc.exists()) {
                            userDetails = doc.data();
                        }

                        var user = authUser.user;
                        await FirestoreService.getIsAdmin(async doc => {
                            if (doc.exists()) {
                                isAdmin = doc.data().IsAdmin;
                            }

                            dispatch(
                                setAuthUser({
                                    uid: user.uid,
                                    displayName: user.displayName,
                                    email: user.email,
                                    photoURL: user.photoURL,
                                    token: user.refreshToken,
                                    phoneNumber: user.phoneNumber,
                                    userDetails: userDetails,
                                    isAdmin: isAdmin,
                                }),
                            );

                            dispatch(fetchSuccess());
                        });
                    });
                } else {
                    dispatch(fetchError(res.data.msg));
                }
                window.close();
            },
        );

        const cookies = new Cookies();
        cookies.set('fromSignup', 'true', {
            path: '/',
            expires: new Date(new Date().setMinutes(new Date().getMinutes() + 5)),
        });
    }

    return '';
}
