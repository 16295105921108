import React, { useContext } from 'react';
import AppContext from '../../../contextProvider/AppContextProvider/AppContext';
import CmtVerticalLayout from '../../../../../@coremat/CmtLayouts/Vertical';
import CmtHeader from '../../../../../@coremat/CmtLayouts/Vertical/Header';
import Header from '../../partials/Header';
import CmtSidebar from '../../../../../@coremat/CmtLayouts/Vertical/Sidebar';
import SidebarHeader from '../../partials/SidebarHeader';
import SideBar from '../../partials/SideBar';
import CmtContent from '../../../../../@coremat/CmtLayouts/Vertical/Content';
import ContentLoader from '../../../ContentLoader';
import { SIDEBAR_TYPE } from '../../../../constants/ThemeOptions';
import clsx from 'clsx';
import CmtFooter from '../../../../../@coremat/CmtLayouts/Vertical/Footer';
import Footer from '../../partials/Footer';
import { useSelector } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { NavLink } from 'react-router-dom';
import GoogleAds from '../../../../../routes/Shared/GoogleAds';

const VerticalMinimal = ({ className, children }) => {
  const { drawerBreakPoint, headerType, isSidebarFixed, sidebarStyle, sidebarSize, showFooter } = useContext(AppContext);
  const { userSubscription } = useSelector(({ firebaseStore }) => firebaseStore);
  const [open, setOpen] = React.useState(true);

  return (
    <CmtVerticalLayout
      drawerBreakPoint={drawerBreakPoint}
      sidebarWidth={sidebarSize}
      className={clsx('verticalMinimalLayout', className)}>
      <CmtHeader type={headerType}>
        <Header />
      </CmtHeader>
      <CmtSidebar type={SIDEBAR_TYPE.MINI} isSidebarFixed={isSidebarFixed} {...sidebarStyle}>
        <SidebarHeader />
        <SideBar />
      </CmtSidebar>
      <CmtContent>
      {(userSubscription == null && open == true && window.location.pathname.toLowerCase() !== '/my-account' && window.location.pathname.toLowerCase() !== '/plans' && window.location.pathname.toLowerCase() !== '/discord-plans') && <MuiAlert elevation={6} style={{marginBottom:"20px"}} variant="filled" severity="info"
       action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => {
            setOpen(false);
          }}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }>
        Welcome to MarketAction.Live. Under our free plan, limited data available & is delayed by 15 minutes. To unlock all Market Action data & features please subscribe to our paid plan. <NavLink to="/plans">
                 Click here
                </NavLink> to upgrade!
        </MuiAlert>}

        {userSubscription == null && window.location.pathname.toLowerCase() === '/my-account' && 
        <GoogleAds />
        }

        {children}
        <ContentLoader />
      </CmtContent>
      {showFooter && (
        <CmtFooter type="static">
          <Footer />
        </CmtFooter>
      )}
    </CmtVerticalLayout>
  );
};

export default VerticalMinimal;
