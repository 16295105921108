import React, {useState} from 'react';
import { Box, fade, IconButton, makeStyles, Popover, Tooltip, Typography } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import CmtCardContent from '../../../../../../@coremat/CmtCard/CmtCardContent';
import CmtCard from '../../../../../../@coremat/CmtCard';
import clsx from 'clsx';
import { useStaticState } from '@material-ui/pickers';

import { useSelector, useDispatch } from 'react-redux';
import { UpdateSelectedDate } from '../../../../../../redux/actions/Firebase';
import moment from 'moment-timezone';
import Hidden from '@material-ui/core/Hidden';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchError } from 'redux/actions';
import "./react-datepicker.css";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: 4,
      paddingBottom: 4,
    },
    '& .Cmt-card-content': {
      padding: '0 0 16px !important',
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
  iconRoot: {
    color: fade(theme.palette.common.white, 0.38),
    '&:hover, &:focus': {
      color: theme.palette.common.white,
    },
  },
  scrollbarRoot: {
    height: 300,
    padding: 16,
  },
  popoverRoot: {
    '& .MuiPopover-paper': {
      width: 375,
    },
  },
}));

const HeaderFilter = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { userSubscription, selectedDate } = useSelector(({ firebaseStore }) => firebaseStore);
  const [dateRange, setDateRange] = React.useState({});

  const onOpenPopOver = event => {
    setAnchorEl(event.currentTarget);
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  const handleDateChange = (dates, d) => {
    const [start, end] = dates;
    dispatch(UpdateSelectedDate({
      StartDate: moment(start.toDate()),
      EndDate: moment(end.toDate()) 
    }));
    setAnchorEl(null);
  };

  const { pickerProps } = useStaticState({
    value: selectedDate,
    onChange: handleDateChange,
  });

  function disableWeekends(date) {
    return date.day() === 0 || date.day() === 6;
  }

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const onChange = (dates) => {
    const [start, end] = dates;

    var startDate = moment(start);
    var endDate = moment(end);
    if (endDate.diff(startDate, 'days') >= 7) {
      dispatch(fetchError("Date range can not exceed more than 7 days."));
      return;
    }

    setStartDate(start);
    setEndDate(end);

    if (start != undefined && end != undefined && start != null && end != null) {

      dispatch(UpdateSelectedDate({
        StartDate: moment(start),
        EndDate: moment(end)
      }));
      setAnchorEl(null);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filters-popover' : undefined;
  return (
    <Box>
      <Hidden xsDown>
        <Typography component={'span'} style={{ fontSize: '13px' }}>
          {moment(selectedDate.StartDate).format('MM/DD/YYYY')} to {moment(selectedDate.EndDate).format('MM/DD/YYYY')}
        </Typography>
      </Hidden>
      <Tooltip title={moment(selectedDate.StartDate).format('MM/DD/YYYY') + " to " + moment(selectedDate.EndDate).format('MM/DD/YYYY')}>
        <IconButton onClick={onOpenPopOver} className={clsx(classes.iconRoot, 'Cmt-appIcon')}>
          <EventIcon />
        </IconButton>
      </Tooltip>
      <Popover
        className={classes.popoverRoot}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <CmtCard className={classes.cardRoot}>
          <CmtCardContent>
            {/* <Calendar {...pickerProps} shouldDisableDate={disableWeekends} /> */}
            {/* <DateRangePicker open={open} toggle={() => setAnchorEl(null)} onChange={(range) => setDateRange(range)} /> */}
            <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
          </CmtCardContent>
        </CmtCard>
      </Popover>
    </Box>
  );
};

export default HeaderFilter;
