import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Cookies from 'universal-cookie';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import * as firebaseFunctions from '../../services/firebase/functions';
import { fetchError, fetchSuccess } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';

export default function NewsletterPopup() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const cookies = new Cookies();
  const { userSubscription } = useSelector(({ firebaseStore }) => firebaseStore);
  const { authUser } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (authUser === null && cookies.get('newsletterShown') !== 'true' && window.location.href.replace(/\/$/, "") === window.location.origin) {
      var timeoutFunc = setTimeout(function() {
        setOpen(true);
      }, 5000);

      return () => {
        clearTimeout(timeoutFunc);
      }
    }
  }, [authUser, cookies]);

  const handleClose = () => {
    cookies.set('newsletterShown', 'true', { path: '/' });
    setOpen(false);
  };

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const onSubscribeUserEmail = () => {
    var listId = firebaseFunctions.SignupPopupListId;
    if(userSubscription != null) {
      listId = userSubscription.planProduct.stripe_metadata_SendGrid_ListId;
    }

    firebaseFunctions.callfunction("subscribeNewsletter", {
      "email": email,
      "listId": listId
    }, (res) => {
      if(res.data.status === 3) {
        dispatch(fetchError(res.data.msg));
        return;
      }

      if(res.data.status === 1){
        dispatch(fetchSuccess(res.data.msg));
        handleClose();
      }
      else{
        dispatch(fetchError(res.data.msg));
      }
    });
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
      <ValidatorForm onSubmit={onSubscribeUserEmail} onError={errors => console.log(errors)}>
      <DialogContent>
        <DialogContentText>
             Subscribe to our newsletter and get a weekly update on the latest Market Action along with options flow tips and app updates!
        </DialogContentText>
       
          <TextValidator autoFocus value={email || ''} name="email" margin="dense" label="Email Address" type="email" 
          fullWidth onChange={handleEmailChange} 
          validators={['required', 'isEmail']}
          InputLabelProps={{
            shrink: true,
          }}
          errorMessages={['Please enter a email to subscribe!', 'Please enter a valid email!']}
          />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Subscribe
        </Button>
      </DialogActions>
        </ValidatorForm>
    </Dialog>
  );
}
