import {
  Set_Options,
  Set_Option,
  Set_UserWatchlists,
  Set_UserSubscription,
  Update_LoadSubscription,
  Update_SelectedDate,
  Set_SubscriptionPlans,
  Update_LoadSubscriptionPlans,
  Set_DiscordTokens
} from '@jumbo/constants/ActionTypes';

export const SetOptions = data => {
  return {
    type: Set_Options,
    payload: data
  };
};

export const SetOption = data => {
  return {
    type: Set_Option,
    payload: data
  };
};

export const SetUserWatchlists = data => {
  return {
    type: Set_UserWatchlists,
    payload: data,
  };
};

export const SetUserSubscription = data => {
  return {
    type: Set_UserSubscription,
    payload: data,
  };
};

export const UpdateLoadSubscription = data => {
  return {
    type: Update_LoadSubscription,
    payload: data,
  };
};

export const UpdateSelectedDate = data => {
  return {
    type: Update_SelectedDate,
    payload: data,
  };
};

export const SetSubscriptionPlans = data => {
  return {
    type: Set_SubscriptionPlans,
    payload: data,
  };
};

export const UpdateLoadSubscriptionPlans = data => {
  return {
    type: Update_LoadSubscriptionPlans,
    payload: data,
  };
};

export const SetDiscordTokens = data => {
  return {
    type: Set_DiscordTokens,
    payload: data,
  };
};