import React from 'react';
import CmtCard from '@coremat/CmtCard';
import { Box, Button } from '@material-ui/core';
import CmtImage from '@coremat/CmtImage';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import Plans from '../Plans';
import GoogleAds from '../../Shared/GoogleAds';
import clsx from  'clsx';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      height: '100%',
    },
    '& .slick-slider': {
      height: '100%',
      '&.bottom .slick-dots': {
        bottom: 10,
        width: 'auto',
        marginLeft: 35,
      },
    },
    '& .slick-list, & .slick-track, & .slick-slide > div': {
      height: '100%',
    },
    '& .slick-dots': {
      bottom: 0,
    },
    '& .slick-dots li': {
      marginLeft: 2,
      marginRight: 2,
      '& button, & button:before': {
        padding: 0,
      },
    },
  },
  cardRootBorder: {
    borderRadius: '10px',
  },
  sliderImgLg: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '40%',
    },
    '& img': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
  },
  sliderImgLgPadding: {
    width: '100%',
    padding: '20px',
    [theme.breakpoints.up('sm')]: {
      width: '40%',
    },
    '& img': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
  },
  sliderContent: {
    width: '100%',
    padding: 20,
    [theme.breakpoints.up('sm')]: {
      width: '60%',
    },
    [theme.breakpoints.up('xl')]: {
      padding: 40,
    },
  },
  titleRoot: {
    fontSize: '2em',
    [theme.breakpoints.up('xl')]: {
      fontSize: '3em',
    },
    marginBottom: '15px',
  },
  subTitle: {
    [theme.breakpoints.up('xl')]: {
      fontSize: '24px',
    },
    fontSize: '17px',
  },
  sliderImgLg: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '40%',
    },
    '& img': {
      height: '100%',
      width: '100%',
      objectFit: 'scale-down',
    },
  },
  sliderContent: {
    width: '100%',
    padding: 20,
    [theme.breakpoints.up('sm')]: {
      width: '60%',
    },
    [theme.breakpoints.up('xl')]: {
      padding: 40,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  sliderContentFeatures: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('sm')]: {
      width: '55%',
    },
    [theme.breakpoints.up('xl')]: {
      padding: 40,
    },
    textAlign: 'center',
  },
  signupBtn: {
    [theme.breakpoints.up('sm')]: {
      padding: '16px 40px',
      fontSize: '20px',
    },
  },
}));

export default function Home() {
  const classes = useStyles();
  const history = useHistory();

  const data = [
    {
      title: 'Market Sentiment ',
      image: '/images/home/Market-Action-Sentiment.jpg',
      desc: 'Real Time market sentiment based on unusual options order flow.',
    },
    {
      title: 'Advanced Filters',
      image: '/images/home/Market-Action-Filters.jpg',
      desc:
        'Easily filter out the data for symbols and options premiums to align with your personal trading strategy. Use numeric data ranges to filter out data, or use our prebuilt filters for quick filtering.',
    },
    {
      title: 'Mobile friendly UI',
      image: '/images/home/Market-Action-Mobile-UI.jpg',
      desc:
        'An app experience that works seamlessly in Web, tablets or mobile devices. Multiple dashboards designed to help you follow the options flow on any desktop or mobile devices.',
    },
    {
      title: 'Realtime Market Action Summary',
      image: '/images/home/Market-Action-Summary.jpg',
      desc:
        'Helping you analyze the Action in real time. Follow the Market Action in realtime on our summary dashboard. Market’s moves fast and it is hard to keep an eye on all the Market Activity and analyze it at the same time. At Market Action, We analyze all the data and bring forward information in our widgets to help you identify the most active trades occurring in real time.',
    },
    {
      title: 'Tickers Flow Analysis',
      image: '/images/home/Market-Action-Ticker-Analysis.jpg',
      desc:
        'Quickly Analyze the options flow for a specific ticker and get a general direction on what the flow is predicting, what strike’s or options contracts are being traded throughout the day.',
    },
    {
      title: 'Historical Data',
      image: '/images/home/Market-Action-Historical.jpg',
      desc: 'Search data for various time frames to check historical order flow.',
    },
    {
      title: 'Watch lists',
      image: '/images/home/Market-Action-WatchLists.jpg',
      desc:
        'Filter out information for symbols that you want to watch options activity for by creating watchlists. Create unlimited watchlists for stocks & etfs you want to follow.',
    },
    {
      title: 'Push Alerts',
      image: '/images/home/Market-Action-Push-Alerts.jpg',
      desc: 'Don’t miss out on key alerts. Get alerts on desktop even when you are not logged in.',
    },
  ];

  return (
    <Box>
      <CmtCard className={classes.cardRoot}>
        {' '}
        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} height={1}>
          <Box className={classes.sliderContent}>
            <Typography component="h1" className={classes.titleRoot}>
              Real Time Option Market Flow
            </Typography>
            <Box component="p" mb={6} color="text.secondary" className={classes.subTitle}>
              Get fast Market Action data on what symbols and tickers the “smart money” is trading. The Market Action app
              provides a web and mobile app platform to uncover unusual options trades in near real time data for retail
              options traders.
            </Box>
            <Box>
              <Button
                onClick={() => history.push('/plans')}
                variant="contained"
                color="primary"
                size="large"
                className={clsx(classes.btnRoot, classes.signupBtn)}
                htmltype="button">
                {/* Start your 7 Days Free Trial */}
                Sign up for Free Trial
              </Button>
            </Box>
          </Box>

          <Box className={classes.sliderImgLg}>
            <CmtImage alt="detail" src="/images/home/Market-Action-Banner-Dashboard.jpg" />
          </Box>
        </Box>
      </CmtCard>

    {/* <GoogleAds /> */}

      <Typography component="h1" style={{textAlign:"center", marginTop:"25px", marginBottom:"20px", fontSize:"30px"}}>Features Built for Retail Traders</Typography>


      {data.map((row, index) => {
        if (index % 2 === 0) {
          return (
            <CmtCard
              key={'featureSection_' + index}
              className={clsx(classes.cardRoot, classes.cardRootBorder)}
              style={{ marginBottom: '30px' }}>
              <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} height={1}>
                <Box className={classes.sliderImgLgPadding}>
                  <CmtImage alt="detail" src={row.image} />
                </Box>
                <Box className={classes.sliderContentFeatures}>
                  <Typography component="div" variant="h2" className={classes.titleRoot}>
                    {row.title}
                  </Typography>
                  <Box className={classes.subTitle} component="p" mb={4} color="text.secondary">
                    {row.desc}
                  </Box>
                </Box>
              </Box>
            </CmtCard>
          );
        } else {
          return (
            <CmtCard
              key={'featureSection_' + index}
              className={clsx(classes.cardRoot, classes.cardRootBorder)}
              style={{ marginBottom: '30px' }}>
              <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} height={1}>
                <Box className={classes.sliderContentFeatures}>
                  <Typography component="div" variant="h2" className={classes.titleRoot}>
                    {row.title}
                  </Typography>
                  <Box className={classes.subTitle} component="p" mb={4} color="text.secondary">
                    {row.desc}
                  </Box>
                </Box>
                <Box className={classes.sliderImgLgPadding}>
                  <CmtImage alt="detail" src={row.image} />
                </Box>
              </Box>
            </CmtCard>
          );
        }
      })}

      <Plans />
    </Box>
  );
}
