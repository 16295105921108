import React, { forwardRef, useEffect } from 'react';
import GridContainer from '@jumbo/components/GridContainer';
import moment from 'moment-timezone';
import { Grid } from '@material-ui/core';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import * as staticFunctions from '../Dashboard/dashboardFunctions';

import CmtCard from '@coremat/CmtCard';
import CmtCarouselRow from '@coremat/CmtCarousel/CmtCarouselRow';

import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import * as _ from 'lodash';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles(theme => ({
  cardRoot: {
    minHeight: '500px',
    display: 'flex',
    padding: '20px',
    flexDirection: 'column',
    '& > div': {
      height: '100%',
    },
    '& .slick-slider': {
      height: '100%',
      '&.bottom .slick-dots': {
        bottom: 10,
        width: 'auto',
        marginLeft: 35,
      },
    },
    '& .slick-list, & .slick-track, & .slick-slide > div': {
      height: '100%',
    },
    '& .slick-dots': {
      bottom: 0,
    },
    '& .slick-dots li': {
      marginLeft: 2,
      marginRight: 2,
      '& button, & button:before': {
        padding: 0,
      },
    },
  },
  sliderContent: {
    width: '100%',
    padding: 20,
    [theme.breakpoints.up('xl')]: {
      padding: 40,
    },
  },
  titleRoot: {
    [theme.breakpoints.up('xl')]: {
      fontSize: 22,
    },
  },
  disclaimerSection: {
    '& > p': {
      marginBottom: '5px',
    },
  },
}));

export default function EveningBiteDetail() {
  const [data, setData] = React.useState(null);
  const classes = useStyles();

  useEffect(() => {
    fetch('https://us-central1-sanguine-sign-305717.cloudfunctions.net/Evening_Bite', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => res.json())
      .then(json => {
        setData(json);
      });

    return () => {};
  }, []);

  if (data == null) {
    return 'Loading... Please wait.';
  }

  const renderDisclaimer = () => {
    return renderMainSlide(() => {
      return (
        <div className={classes.disclaimerSection}>
          <h1 className="text-center" style={{ marginBottom: '20px' }}>
            <strong>Disclaimer</strong>
          </h1>
          <p>
            This presentation is for educational information purposes only. It has been complied or arrived at from sources
            believed to be reliable, but no representation of warranty, expressed or implied, is made as to its accuracy or
            completeness.
          </p>
          <p>
            Our services and educational products provide information from the Market Action team on trading options and
            securities.
          </p>
          <p>
            All investments involve risk and past performance of a security, industry, sector, market, financial product,
            trading strategy or individuals trading does not guarantee future results or returns
          </p>
          <p>
            Investors are fully responsible for any investment decisions they make. Such decisions should be based solely on
            an evaluation of their financial circumstance’s, investment objectives, risk tolerance and liquidity needs.
          </p>
          <p>
            Market Action LLC. (Market Action) is not giving investment advice, tax advice, legal advice or other
            professional advice.
          </p>
          <p>
            Market Actions referenced specific securities should not be construed as a recommendation to buy, sell or hold
            the security. specific securities are mentioned for educational and informational purposes only.
          </p>

          <br />
          <h2>
            <strong>Intellectual Property Rights Notice.</strong>
          </h2>
          <p>
            Market Action LLC. Retains all right, title & interest in and to all intellectual property contained in the
            material provided and contained herein.
          </p>
        </div>
      );
    }, 'renderDisclaimer');
  };

  const renderUnusualTickers = dataToRender => {
    if (dataToRender) {
      return renderMainSlide(() => {
        return (
          <>
            <h1 className="text-center">Unusual options tickers </h1>
            <MaterialTable
              icons={tableIcons}
              columns={[
                {
                  title: 'Symbol',
                  field: 'baseSymbol',
                  render: rowData => <strong>{rowData.baseSymbol}</strong>,
                },
                {
                  title: 'Company Name',
                  field: 'symbolName',
                },
                {
                  title: 'Call/Put',
                  field: 'symbolType',
                  render: rowData => (
                    <span className={rowData.symbolType.toLowerCase() == 'call' ? 'colorGreen' : 'colorRed'}>
                      {rowData.symbolType}
                    </span>
                  ),
                },
              ]}
              data={dataToRender}
              options={{
                search: false,
                paging: false,
                showTitle: false,
              }}
            />
          </>
        );
      }, 'renderUnusualTickers');
    }

    return renderMainSlide(() => {
      return '-';
    }, 'renderUnusualTickers');
  };

  const renderMainPremarketMovers = () => {
    return renderMainSlide(() => {
      return (
        <GridContainer>
          <Grid item xs={4}>
            <h1 className="text-center">Top 5 After Hours Gainers </h1>
            <div>{renderPremarketMovers(data.GainersAndLosers?.Top5Gainers)}</div>
          </Grid>
          <Grid item xs={4}>
            <h1 className="text-center">Top 5 After Hours Losers </h1>
            <div>{renderPremarketMovers(data.GainersAndLosers?.Top5Losers)}</div>
          </Grid>
          <Grid item xs={4}>
            <h1 className="text-center">Top 5 MostActive By Volume </h1>
            <div>{renderPremarketMovers(data.GainersAndLosers?.Top5MostActiveByVolume)}</div>
          </Grid>
        </GridContainer>
      );
    }, 'renderPremarketMovers');
  };

  const renderPremarketMovers = dataToRender => {
    if (dataToRender) {
      return (
        <MaterialTable
          icons={tableIcons}
          columns={[
            {
              title: 'Symbol',
              field: 'symbol',
              render: rowData => (
                <strong
                  className={parseFloat(rowData.change.replace('%', '').replace(',', '')) > 0 ? 'colorGreen' : 'colorRed'}>
                  {rowData.symbol}
                </strong>
              ),
            },
            {
              title: 'Company Name',
              field: 'name',
            },
            {
              title: 'Percentage Change',
              field: 'change',
            },
          ]}
          data={dataToRender}
          options={{
            search: false,
            paging: false,
            showTitle: false,
          }}
        />
      );
    }

    return '-';
  };

  const renderEconomicCalendar = dataToRender => {
    if (dataToRender) {
      return renderMainSlide(() => {
        return (
          <>
            <h1 className="text-center">Economic Calendars </h1>
            <MaterialTable
              icons={tableIcons}
              columns={[
                {
                  title: 'Time',
                  field: 'time',
                  render: rowData => (
                    <strong>
                      {moment(rowData.date, 'DD/MM/YYYY').format('MM/DD/YYYY')} {rowData.time}
                    </strong>
                  ),
                },
                {
                  title: 'Event',
                  field: 'event',
                },
              ]}
              data={dataToRender}
              options={{
                search: false,
                paging: false,
                showTitle: false,
              }}
            />
          </>
        );
      }, 'renderEconomicCalendar');
    }

    return renderMainSlide(() => {
      return '-';
    }, 'renderEconomicCalendar');
  };

  const renderCrypto = dataToRender => {
    if (dataToRender) {
      return renderMainSlide(() => {
        return (
          <>
            <h1 className="text-center">Crypto </h1>
            <MaterialTable
              icons={tableIcons}
              columns={[
                {
                  title: 'Name',
                  field: 'Name',
                },
                {
                  title: 'Symbol',
                  field: 'Symbol',
                  render: rowData => <strong>{rowData.Symbol}</strong>,
                },
                {
                  title: 'Price',
                  field: 'Price (Intraday)',
                  render: rowData => (
                    <span>
                      {rowData['Price (Intraday)'] >= 1000
                        ? staticFunctions.nFormatterNoMulitply(rowData['Price (Intraday)'], 2)
                        : parseFloat(rowData['Price (Intraday)']).toFixed(2)}
                    </span>
                  ),
                },
                {
                  title: 'Percentage Change',
                  field: '% Change',
                  render: rowData => (
                    <span className={parseFloat(rowData['% Change']) > 0 ? 'colorGreen' : 'colorRed'}>
                      {staticFunctions.CommaFormatted(rowData['% Change'])}%
                    </span>
                  ),
                },
              ]}
              data={dataToRender}
              options={{
                search: false,
                paging: false,
                showTitle: false,
              }}
            />
          </>
        );
      }, 'renderCrypto');
    }

    return renderMainSlide(() => {
      return '-';
    }, 'renderCrypto');
  };

  const renderTrendingTickers = dataToRender => {
    if (dataToRender && dataToRender.length > 0) {
      return renderMainSlide(() => {
        return (
          <>
            <h1 className="text-center">Trending tickers on social </h1>
            <MaterialTable
              icons={tableIcons}
              columns={[
                {
                  title: 'Symbol',
                  field: 'symbol',
                  render: rowData => <strong>{rowData.symbol}</strong>,
                },
                {
                  title: 'Company Name',
                  field: 'title',
                },
                {
                  title: 'Percentage Change',
                  field: 'percentChange',
                  render: rowData => (
                    <span className={parseFloat(rowData.percentChange) > 0 ? 'colorGreen' : 'colorRed'}>
                      {parseFloat(rowData.percentChange).toFixed(2)}%
                    </span>
                  ),
                },
              ]}
              data={dataToRender}
              options={{
                search: false,
                paging: false,
                showTitle: false,
              }}
            />
          </>
        );
      }, 'renderTrendingTickers');
    }

    return renderMainSlide(() => {
      return '-';
    }, 'renderTrendingTickers');
  };

  const renderMainEarnings = () => {
    return renderMainSlide(() => {
      return (
        <GridContainer>
          <Grid item xs={6}>
            <h1 className="text-center">Before Market Open Earnings ☀️</h1>
            <div>{renderEarnings(data.Earnings.filter(f => f.startdatetimetype.toUpperCase() != 'AMC'))}</div>
          </Grid>
          <Grid item xs={6}>
            <h1 className="text-center">After Market Close Earnings 🌙</h1>
            <div>{renderEarnings(data.Earnings.filter(f => f.startdatetimetype.toUpperCase() == 'AMC'))}</div>
          </Grid>
        </GridContainer>
      );
    }, 'renderMainEarnings');
  };

  const renderEarnings = dataToRender => {
    if (dataToRender && dataToRender.length > 0) {
      return renderMainSlide(() => {
        return (
          <MaterialTable
            icons={tableIcons}
            columns={[
              {
                title: 'Ticker',
                field: 'ticker',
                render: rowData => <strong>{rowData.ticker}</strong>,
              },
              {
                title: 'Company Name',
                field: 'companyshortname',
              },
              {
                title: 'Call Time',
                field: 'startdatetimetype',
                render: rowData => <span>{getEarningsCallTime(rowData)}</span>,
              },
            ]}
            data={dataToRender}
            options={{
              search: false,
              paging: false,
              showTitle: false,
            }}
          />
        );
      }, 'renderEarnings');
    }

    function getEarningsCallTime(rowData) {
      switch (rowData.startdatetimetype.toUpperCase()) {
        case 'BMO':
          return 'Before Market Open ☀️';
          break;
        case 'AMC':
          return 'After Market Close 🌙';
          break;
        case 'TNS':
          return 'Time Not Supplied';
          break;
        case 'TAS':
          return 'Transfer Agent System';
          break;
        default:
          return rowData.startdatetimetype;
          break;
      }
    }

    return renderMainSlide(() => {
      return '-';
    }, 'renderEarnings');
  };

  const renderFutureTickers = dataToRender => {
    if (dataToRender) {
      return renderMainSlide(() => {
        return (
          <>
            <h1 className="text-center">Future Markets</h1>
            <MaterialTable
              icons={tableIcons}
              columns={[
                { title: 'Symbol', field: 'symbol' },
                { title: 'Contract Name', field: 'contractName' },
                {
                  title: 'Price Change',
                  field: 'priceChange',
                  render: rowData => (
                    <span className={parseFloat(rowData.priceChange) > 0 ? 'colorGreen' : 'colorRed'}>
                      {rowData.priceChange}
                    </span>
                  ),
                },
                {
                  title: 'Percentage Change',
                  field: 'percentChange',
                  render: rowData => (
                    <span className={parseFloat(rowData.priceChange) > 0 ? 'colorGreen' : 'colorRed'}>
                      {staticFunctions.CommaFormatted(rowData.percentChange)}%
                    </span>
                  ),
                },
              ]}
              data={dataToRender}
              options={{
                search: false,
                paging: false,
                showTitle: false,
              }}
            />
          </>
        );
      }, 'renderFutureTickers');
    }

    return renderMainSlide(() => {
      return '-';
    }, 'renderFutureTickers');
  };

  const renderThankYou = () => {
    return renderMainSlide(() => {
      return (
        <>
          <h1 className="text-center">Thank you for watching.</h1>
          <h2 className="text-center">
            Please do not forget to subscribe, comment below any feedback and Visit MarketAction.Live to sign up for your
            free trial.
          </h2>
        </>
      );
    }, 'renderThankYou');
  };

  const renderMainSlide = (renderChild, key) => {
    return (
      <Box key={key} display="flex" flexDirection={{ xs: 'column', sm: 'row' }} height={1}>
        <Box className={classes.sliderContent}>{renderChild()}</Box>
      </Box>
    );
  };

  return (
    <PageContainer heading="Evening Bite">
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard className={classes.cardRoot}>
            <CmtCarouselRow
              outline
              dotPosition="bottom"
              dotSize={5}
              settings={{
                slidesToShow: 1,
                slidesToScroll: 1,
              }}
              renderRow={() => {
                return [
                  renderDisclaimer(),
                  renderFutureTickers(data.Top15Futures),
                  renderUnusualTickers(data.Top15Unusual),
                  renderMainPremarketMovers(),
                  renderEconomicCalendar(data.EconomicCalendar),
                  renderCrypto(data.Top10Crypto),
                  renderTrendingTickers(data.Top10Trending),
                  renderMainEarnings(),
                  renderThankYou(),
                ];
              }}
            />
          </CmtCard>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
}
