import React, { useContext, useEffect, useState } from 'react';
import * as FirestoreService from '../auth/firebase/FirebaseRealTimeDb';
import {
  SetUserWatchlists,
  SetUserSubscription,
  UpdateLoadSubscription,
  SetOption,
  SetOptions,
  SetSubscriptionPlans,
  UpdateLoadSubscriptionPlans,
  SetDiscordTokens,
} from '../../redux/actions/Firebase';
import * as staticFunctions from '../../routes/Pages/Dashboard/dashboardFunctions';
import { fetchError, fetchSuccess, fetchStart } from 'redux/actions';
import moment from 'moment-timezone';
import { batchActions } from 'redux-batched-actions';

const OptionActivityService = {
  SyncOptionsActivity: ({ querySnapshot, optionsData }) => {
    return async dispatch => {
      var data = [];
      querySnapshot.forEach(function(childSnapshot) {
        var childData = childSnapshot.val();
        childData.docId = childSnapshot.key;
        var row = staticFunctions.createOptionsRowData(childData);
        if (optionsData.some(el => el.docId === row.docId) === false) {
          data.unshift(row);
        }
      });
      dispatch(fetchSuccess());
      dispatch(SetOptions(data));
    };
  },

  SyncOptionsActivityChildAdded: ({ querySnapshot, optionsData, queuedActions, lastTime, currentTimeout }) => {
    return async dispatch => {
      var objValue = querySnapshot.val();
      if (objValue && objValue !== '') {
        var row = staticFunctions.createOptionsRowData(objValue);
        if (optionsData.some(el => el.id === row.id) === false) {
          queuedActions.push(SetOption(row));

          var currentTime = moment(new Date());
          var diff = currentTime.diff(lastTime, 'seconds');

          if (currentTimeout) {
            clearTimeout(currentTimeout);
          }
          currentTimeout = setTimeout(function() {
            lastTime = moment(new Date());
            dispatch(batchActions(queuedActions));
            queuedActions = [];
          }, 15000);

          if (diff > 15) {
            if (currentTimeout) {
              clearTimeout(currentTimeout);
            }

            lastTime = moment(new Date());
            dispatch(batchActions(queuedActions));
            queuedActions = [];
          }
        }
      }
    };
  },
};

export default OptionActivityService;
