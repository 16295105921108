import React, { useEffect } from 'react';
import GridContainer from '@jumbo/components/GridContainer';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { Grid } from '@material-ui/core';
import * as firebaseFunctions from '../../../services/firebase/functions';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtAdvCardContent from '@coremat/CmtAdvCard/CmtAdvCardContent';
import CmtAdvCard from '@coremat/CmtAdvCard';
import * as staticFunctions from '../Dashboard/dashboardFunctions';

export default function MorningBite() {
  const [data, setData] = React.useState(null);

  useEffect(() => {
    fetch("https://us-central1-sanguine-sign-305717.cloudfunctions.net/Morning_Bite", {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then((res) => res.json())
      .then((json) => {
        setData(json);
      });

    return () => { };
  }, []);

  if(data == null){
    return ("Loading... Please wait.")
  }

  const renderUpgradesDowngrades = (downgradesOrUpgrades) => {
    if (downgradesOrUpgrades) {
      return downgradesOrUpgrades.map((item) => {
        return (
          <p key={item.message}><span className={(parseFloat(item.percentageChange) > 0 ? 'colorGreen' : 'colorRed')}><strong>{item.ticker}</strong> {parseFloat(item.percentageChange).toFixed(1)}%</span> {item.message}</p>
        )
      });
    }

    return "-";
  }

  const renderUnusualTickers = (dataToRender) => {
    if (dataToRender) {
      return (
        <p key={"renderPremarketMovers"}><strong>{dataToRender.map(m => m.baseSymbol).join(", ")}</strong></p>
      )
    }

    return "-";
  }

  const renderPremarketMovers = (dataToRender) => {
    if (dataToRender) {
      return dataToRender.map((item, i) => {
        return (
          <span key={item.symbol + "_" + item.change} className={(parseFloat(item.change.replace('%', '').replace(',', '')) > 0 ? 'colorGreen' : 'colorRed')}><strong>{item.symbol}</strong> {item.change}
          {(dataToRender.length - 1 == i ? "" : ", ")}
          </span> 
        )
      });
    }

    return "-";
  }
  
  const renderEconomicCalendar = (dataToRender) => {
    if (dataToRender) {
      return dataToRender.map((item, i) => {
        return (
          <p key={item.time + "_" + item.event}><strong>{moment(item.date, 'DD/MM/YYYY').format('MM/DD/YYYY')} {item.time}</strong> {item.event}</p> 
        )
      });
    }

    return "-";
  }

  const renderCrypto = (dataToRender) => {
    if (dataToRender) {
      return dataToRender.map((item, i) => {
        return (
          <p key={item.Symbol + "_" + item["Price (Intraday)"]}><strong>{item.Symbol} {staticFunctions.nFormatterNoMulitply(item["Price (Intraday)"], 2)}</strong> <span className={(parseFloat(item["% Change"]) > 0 ? 'colorGreen' : 'colorRed')}>{item["% Change"]}</span></p> 
        )
      });
    }

    return "-";
  }

  const renderTrendingTickers = (dataToRender) => {
    if (dataToRender && dataToRender.length > 0) {
      return dataToRender.map((item, i) => {
        return (
          <p key={"renderTrendingTickers_" + item.symbol}><strong>{item.symbol}</strong> <span className={(parseFloat(item.percentChange) > 0 ? 'colorGreen' : 'colorRed')}>{parseFloat(item.percentChange).toFixed(2)}%</span></p> 
        )
      });
    }

    return "-";
  }

  const renderFutureTickers = (dataToRender) => {
    if (dataToRender) {
      return dataToRender.map((item, i) => {
        return (
          <p key={item.symbol + "_" + item.contractName}><strong>{item.contractName}</strong> <span className={(parseFloat(item.priceChange) > 0 ? 'colorGreen' : 'colorRed')}>{item.priceChange}</span></p> 
        )
      });
    }

    return "-";
  }

  return (
    <PageContainer heading="Morning Bite">
      <GridContainer>
        <Grid item xs={12}>
          <CmtAdvCard>
            <CmtAdvCardContent>
              <GridContainer>
                <Grid item xs={12}>
                  <h2>Future Markets</h2>
                  {renderFutureTickers(data.Top15Futures)}
                </Grid>
                <Grid item xs={12}>
                  <h2>Top Downgrades </h2>
                  {renderUpgradesDowngrades(data.TopDownOrUpGrades?.Downgrades)}
                  <br/>
                  <h2>Top Upgrades</h2>
                  {renderUpgradesDowngrades(data.TopDownOrUpGrades?.Upgrades)}
                  <br/>
                  <h2>Initiated Coverage</h2>
                  {renderUpgradesDowngrades(data.TopDownOrUpGrades?.Initiates)}
                </Grid>
                <Grid item xs={12}>
                  <h2>Unusual options tickers </h2>
                  {renderUnusualTickers(data.Top15Unusual)}
                </Grid>
                <Grid item xs={12}>
                  <h2>Top 5 PreMarket Gainers </h2>
                  <div>{renderPremarketMovers(data.GainersAndLosers?.Top5Gainers)}</div>
                  <br/>
                  <h2>Top 5 PreMarket Losers </h2>
                  <div>{renderPremarketMovers(data.GainersAndLosers?.Top5Losers)}</div>
                  <br/>
                  <h2>Top 5 MostActive By Volume </h2>
                  <div>{renderPremarketMovers(data.GainersAndLosers?.Top5MostActiveByVolume)}</div>
                </Grid>
                <Grid item xs={12}>
                  <h2>Economic Calendars </h2>
                  {renderEconomicCalendar(data.EconomicCalendar)}
                </Grid>
                <Grid item xs={12}>
                  <h2>Crypto </h2>
                  {renderCrypto(data.Top10Crypto)}
                </Grid>
                <Grid item xs={12}>
                  <h2>Trending tickers on social </h2>
                  {renderTrendingTickers(data.Top10Trending)}
                </Grid>
              </GridContainer>
            </CmtAdvCardContent>
          </CmtAdvCard>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
}
