import React, {useEffect} from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtAdvCardContent from '@coremat/CmtAdvCard/CmtAdvCardContent';
import CmtAdvCard from '@coremat/CmtAdvCard';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import BullishBearishSummarySummaryWidgetRow from './BullishBearishSummarySummaryWidgetRow';

const useStyles = makeStyles(theme => ({
  cardContentRoot: {
    '& .MuiGrid-container': {
      alignItems: 'center',
    },
  },
  cardContentTitle: {
    marginBottom: 4,
  },
  subTitleRoot: {
    fontSize: 12,
    marginBottom: 0,
    color: theme.palette.text.secondary,
  },
  tooltip: {
    position: 'relative',
    borderRadius: 6,
    padding: '4px 8px',
    backgroundColor: '#8d46ef',
    color: theme.palette.common.white,
  },
  tableRowCellRoot: {
    fontSize: 12,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 16,
    letterSpacing: 0.4,
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      paddingRight: 24,
    },
  },
}));

export default function BullishBearishSummaryWidget(props) {
  var currentSentiment = props.CurrentSentiment;
  const classes = useStyles();
  const [bullishBearishSummaryHidden, setBullishBearishSummaryHidden] = React.useState(false);
  const [bullishBearishSummaryPageSize, setBullishBearishSummaryPageSize] = React.useState(10);
  const [symbolsBullishBearishSummary, setSymbolsBullishBearishSummary] = React.useState([]);

  useEffect(() => {
    if (!bullishBearishSummaryHidden) {
      const summaryWorker = new Worker('/WebWorkers/summary.worker.js');
    
      summaryWorker.postMessage({optionsData: props.optionsData, CurrentSentiment: props.CurrentSentiment, pageSize: bullishBearishSummaryPageSize, type: props.CurrentSentiment.toLowerCase()});

      summaryWorker.onmessage = event => {
        setSymbolsBullishBearishSummary(event.data);
      }
      return () => {
        summaryWorker.terminate();
      }
    }
  }, [props.optionsData, props.CurrentSentiment, bullishBearishSummaryPageSize, bullishBearishSummaryHidden]);


  if (!bullishBearishSummaryHidden) {
    const onBullishBearishSummaryPageSizeChange = event => {
      setBullishBearishSummaryPageSize(event.target.value);
    };

    const bullishBearishSummaryActionHandler = event => {
      switch (event.value) {
        case 'close': {
          return setBullishBearishSummaryHidden(true);
        }
        default:
          return true;
      }
    };

    return (
      <Grid item  xs={12} sm={12} md={6}>
        <CmtAdvCard>
          <CmtCardHeader
            className="pt-2 pb-2"
            actionsPos="top-corner"
            actions={[{ label: 'Close', value: 'close' }]}
            actionHandler={bullishBearishSummaryActionHandler}
            title={
              <Typography
                {...{
                  variant: 'h4',
                  component: 'div',
                }}>
                {currentSentiment} Summary (Top &nbsp;
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={bullishBearishSummaryPageSize}
                  onChange={onBullishBearishSummaryPageSizeChange}>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
                )
              </Typography>
            }
          />
          <CmtAdvCardContent className={classes.cardContentRoot}>
            <Box className="Cmt-table-responsive">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableRowCellRoot}>Symbol</TableCell>
                    <TableCell className={classes.tableRowCellRoot}>Orders</TableCell>
                    <TableCell className={classes.tableRowCellRoot}>Premium</TableCell>
                    <TableCell className={classes.tableRowCellRoot}>{currentSentiment}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {symbolsBullishBearishSummary.map((row, index) => (
                    <BullishBearishSummarySummaryWidgetRow currentSentiment={currentSentiment} row={row} key={index} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </CmtAdvCardContent>
        </CmtAdvCard>
      </Grid>
    );
  } else {
    return '';
  }
}
