import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';
import { useSelector } from 'react-redux';

const Logo = ({ color, ...props }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  const logoUrl = color === 'white' ? '/images/logo.png' : '/images/logo.png';
  //const logoSymbolUrl = color === 'white' ? '/images/logo-symbol.png' : '/images/logo-symbol.png';
  const logoSymbolUrl = color === 'white' ? '/images/logo.png' : '/images/logo.png';

  return (
    <Box className="pointer" {...props}>
      <Hidden xsDown>
        <a href={process.env.REACT_APP_WEB_APP_URL}>
          <CmtImage src={logoUrl} alt="logo" />
        </a>
      </Hidden>
      <Hidden smUp>
        <a href={process.env.REACT_APP_WEB_APP_URL}>
          <CmtImage src={logoSymbolUrl} alt="logo" />
        </a>
      </Hidden>
    </Box>
  );
};

export default Logo;
