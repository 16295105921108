import React from 'react';
import { Button, fade } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtAdvCardContent from '@coremat/CmtAdvCard/CmtAdvCardContent';
import CmtImage from '@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import InviteForm from './InviteForm';
import CmtRevealCard from '@coremat/CmtRevealCard';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  revealCard: {
    backgroundColor: fade(theme.palette.primary.main, 0.1),
    height: '100%',
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    '& .Cmt-content-head': {
      paddingBottom: 18,
    },
  },
  titleRoot: {
    marginBottom: 16,
  },
  subTitileRoot: {
    fontSize: 14,
    marginBottom: 16,
    letterSpacing: 0.25,
  },
}));

 const ReferCard = ({authUser, isAffiliate}) => {
  const classes = useStyles();
  const [revealed, setRevealed] = React.useState(false);

  const revelCard = () => {
    setRevealed(true);
  };

  const handleOnClose = () => {
    setRevealed(false);
  };

  return (
    <CmtRevealCard
                className={classes.root}
                revealComponentTitle="Invite your friend"
                revealComponent={<InviteForm auth={authUser} />}
                titleProps={{ component: 'div', variant: 'h4' }}
                revealed={revealed}
                onClose={handleOnClose}>
                <CmtAdvCardContent
                  className={classes.revealCard}
                  avatar={<CmtImage src={'/images/dashboard/Friend-icon.svg'} />}
                  title={'Total Refers: ' + (authUser.userDetails.ReferralCount === undefined ? 0 : authUser.userDetails.ReferralCount)}
                  titleProps={{
                    variant: 'h3',
                    component: 'div',
                    className: classes.titleRoot,
                  }}
                  subTitle={isAffiliate === true ? (<div>
                            <Typography component="p" style={{marginBottom: "10px",color:"white"}}>Earn commission by referring your friends and followers.</Typography>
                            <Typography component="p" style={{color:"white"}}>Current commissions rate: $10.00 for paid annual subscriptions & $5.00 for paid monthly subscriptions.</Typography>
                          </div>)
                          : (<div>
                      <Typography component="p" style={{marginBottom: "10px",color:"white"}}>Refer us to your friends and earn credits towards your subscription.</Typography>
                      <Typography component="p" style={{color:"white"}}>For every 10 friends that signup for the annual subscription you will earn 1 year of free subscription credits.</Typography>
                    </div>)}
                  subTitleProps={{
                    component: 'p',
                    variant: 'subtitle1',
                    className: classes.subTitileRoot,
                  }}
                  extraContent={
                    <div>
                      <Button variant="contained" color="primary" onClick={revelCard}>
                        {isAffiliate === true ? "Click to get your affiliate tracking link" : "Invite A Friend" }
                    </Button>
                    {isAffiliate === true ? <Typography component="p" style={{ marginTop:"20px",fontSize: "12px" }}>To receive referral commission for referring someone who orders a paid plan on Market Action, your referee must be a new Market Action user and can’t have more than one account. Any non US paid transactions will be voided and be ineligible to receive commissions. In case your referee files a charge back or claims a refund for their paid subscription, the commission that received from Market Action will be canceled. Market Action may cancel a reward at any time at its sole discretion if a violation of any referral rules occurs.</Typography>
                    : <Typography component="p" style={{ marginTop:"20px",fontSize: "12px" }}>*referral terms and conditions subject to change without notice.</Typography> }
                    </div>
                  }
                  alignCenter
                />
              </CmtRevealCard>
  );
}


export default ReferCard;