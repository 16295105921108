import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../contextProvider/AppContextProvider/AppContext';
import globalStyles from '../../../theme/GlobalCss';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { AuhMethods } from '../../../services/auth';
import { CurrentAuthMethod } from '../../constants/AppConstants';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import VerticalDefault from './VerticalLayouts/VerticalDefault';
import { LAYOUT_STYLES, LAYOUT_TYPES } from '../../constants/ThemeOptions';
import VerticalMinimal from './VerticalLayouts/VerticalMinimal';
import MinimalNoHeader from './VerticalLayouts/MinimalNoHeader';
import ModernSideBar from './VerticalLayouts/ModernSidebar';
import HorizontalDefault from './HorizontalLayouts/HorizontalDefault';
import HorizontalDark from './HorizontalLayouts/HorizontalDark';
import HorizontalMinimal from './HorizontalLayouts/HorizontalMinimal';
import HorizontalTopMenu from './HorizontalLayouts/HorizontalTopMenu';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import { batchActions } from 'redux-batched-actions';
import { firebase, analytics, auth, messaging } from '../../../services/configs/config';
import Cookies from 'universal-cookie';
import { loadStripe } from '@stripe/stripe-js';
import * as appConstants from '../../../Constants/AppConstants';
import * as FirestoreService from '../../../services/auth/firebase/FirebaseRealTimeDb';
import PlansService from '../../../services/firebase/PlansService';
import OptionActivityService from '../../../services/firebase/OptionActivityService';
import { useHistory } from 'react-router-dom';
import {
  UpdateLoadSubscription,
  SetOption,
  SetOptions,
  SetDiscordTokens,
} from '../../../redux/actions/Firebase';
import * as staticFunctions from '../../../routes/Pages/Dashboard/dashboardFunctions';
import { fetchError, fetchSuccess, fetchStart } from 'redux/actions';
import { setUserProperties } from 'firebase/analytics';
import { getIdToken } from 'firebase/auth';
import { off } from 'firebase/database';
import { getToken, isSupported } from 'firebase/messaging';

const useStyles = makeStyles(theme => ({
  circularProgressRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const AppLayout = ({ children }) => {
  const [isTemplateLoaded, setTemplateLoading] = useState(false);
  const { layout, layoutStyle, themeType, updateThemeType } = useContext(AppContext);
  const { authUser, loadUser } = useSelector(({ auth }) => auth);
  const { loadSubscription, userSubscription, optionsData, selectedDate, loadSubscriptionPlans } = useSelector(
    ({ firebaseStore }) => firebaseStore,
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  globalStyles();
  const cookies = new Cookies();

  useEffect(() => {
    dispatch(AuhMethods[CurrentAuthMethod].getAuthUser(true));
    updateThemeType(themeType);
    setTemplateLoading(true);
  }, []);

  async function subscribeToPlanPrice(planPriceId) {
    dispatch(fetchStart());
    await FirestoreService.subscripeToPlan(planPriceId, async snap => {
      cookies.set('subscriptionPlanId', '', { path: '/' });
      const { error, sessionId } = snap.data();
      if (error) {
        dispatch(fetchError(`An error occured: ${error.message}`));
        console.log(`An error occured: ${error.message}`);
      }
      if (sessionId) {
        dispatch(fetchSuccess());
        const stripe = await loadStripe(FirestoreService.STRIPE_PUBLISHABLE_KEY);
        stripe.redirectToCheckout({ sessionId });
      }
    });
  }

  useEffect(() => {
    //clearing all the reducers after logout So, reload plans.
    dispatch(PlansService.SyncPlans());

    if (authUser !== null) {
      dispatch(
        SetDiscordTokens({
          access_token: authUser.userDetails.DiscordAccessToken,
          refresh_token: authUser.userDetails.DiscordRefreshToken,
        }),
      );


      // getIdToken(auth.currentUser, true).then(function(idToken) {
      //   // Send token to your backend via HTTPS
      //   // ...
      //   console.log(idToken);
      // }).catch(function(error) {
      //   // Handle error
      // });

      setUserProperties(analytics, { "UserName": authUser.email + "|" + authUser.displayName});

      const subscriptionPlanId = cookies.get('subscriptionPlanId');
      if (subscriptionPlanId && subscriptionPlanId != '') {
        if (subscriptionPlanId.startsWith('free_plan')) {
          history.push('/dashboard');
        }
        subscribeToPlanPrice(subscriptionPlanId);
      } else {
        try {
          const customerSubscriptionRef = FirestoreService.getCustomerSubscription(async querySnapshot => {
            dispatch(PlansService.SyncUserSubscription({ querySnapshot }));
          });

          return () => {
            if (customerSubscriptionRef !== null) {
              customerSubscriptionRef();
            }
          };
        } catch (error) {
          dispatch(UpdateLoadSubscription(true));
          dispatch(fetchError(`An error occured: ${error.message}`));
        }
      }
    }
  }, [authUser]);

  useEffect(() => {
    dispatch(SetOptions([]));

    var onceRef = '';
    var childAddedRef = '';
    var queuedActions = [];
    var lastTime = moment(new Date());
    var currentTimeout = undefined;

    if (
      loadSubscription === true &&
      authUser != null &&
      (userSubscription === null || userSubscription.role !== appConstants.Role_Customer)
    ) {
      try {
        FirestoreService.getUserExtraData(async userDataRef => {
          if (userDataRef.exists()) {
            var userData = userDataRef.data();
            if (userData.RefreshFirebaseToken === true) {
              await getIdToken(auth.currentUser, true);
              await FirestoreService.updateUserRefreshTokenStatus();
            }
          }

          if (
            window.location.pathname.toLowerCase() === '/dashboard' ||
            window.location.pathname.toLowerCase() === '/mobile-dashboard' ||
            window.location.pathname.toLowerCase() === '/analysis' ||
            window.location.pathname.toLowerCase() === '/summary'
          ) {
            dispatch(fetchStart());
          }

          var startDate = staticFunctions.formatDate(selectedDate.StartDate);
          var endDate = staticFunctions.formatDate(selectedDate.EndDate);
          onceRef = FirestoreService.getOptionActivityDataByDateFree(startDate, endDate, querySnapshot => {
            dispatch(
              OptionActivityService.SyncOptionsActivity({
                querySnapshot,
                optionsData,
              }),
            );
          });

          childAddedRef = FirestoreService.OptionActivityChildAddedByDateFree(startDate, endDate, querySnapshot => {
            //dispatch(OptionActivityService.SyncOptionsActivityChildAdded({ querySnapshot, optionsData, queuedActions, lastTime, currentTimeout }));
            var objValue = querySnapshot.val();
            if (objValue && objValue !== '') {
              var row = staticFunctions.createOptionsRowData(objValue);
              if (optionsData.some(el => el.id === row.id) === false) {
                queuedActions.push(SetOption(row));

                var currentTime = moment(new Date());
                var diff = currentTime.diff(lastTime, 'seconds');

                if (currentTimeout) {
                  clearTimeout(currentTimeout);
                }
                currentTimeout = setTimeout(function() {
                  lastTime = moment(new Date());
                  dispatch(batchActions(queuedActions));
                  queuedActions = [];
                }, 15000);

                if (diff > 15) {
                  if (currentTimeout) {
                    clearTimeout(currentTimeout);
                  }

                  lastTime = moment(new Date());
                  dispatch(batchActions(queuedActions));
                  queuedActions = [];
                }
              }
            }
          });
        });
      } catch (error) {
        dispatch(fetchError(`An error occured: ${error.message}`));
      }

      return () => {
        if (onceRef != '') {
          off(onceRef);
        }
        if (childAddedRef != '') {
          off(childAddedRef);
        }

        queuedActions = [];
        clearTimeout(currentTimeout);
      };
    }
  }, [authUser, selectedDate, userSubscription, loadSubscription]);

  useEffect(() => {
    dispatch(SetOptions([]));

    var onceRef;
    var childAddedRef;
    var queuedActions = [];
    var lastTime = moment(new Date());
    var currentTimeout = undefined;

    if (userSubscription !== null && userSubscription.role === appConstants.Role_Customer) {
      try {
        if (isSupported()) {
          Notification.requestPermission()
            .then(function(permission) {
              if (permission === 'granted') {
                let tokenMessaging = process.env.REACT_APP_MESSAGING_TOKEN;
                
                getToken(messaging, {
                  vapidKey: tokenMessaging,
                }).then(function (currentToken) {
                  if (currentToken) {
                    FirestoreService.addUdateNotificationToken(currentToken);
                  }
                }).catch(function (err) {
                  console.log('Error in token.', err);
                });
              }
            })
            .catch(function(err) {
              console.log('Unable to get permission to notify.', err);
            });
          navigator.serviceWorker.addEventListener('message', message => console.log(message));
        }
      } catch (error) {
        console.log(error);
      }

      try {
        FirestoreService.getUserExtraData(async userDataRef => {
          if (userDataRef.exists()) {
            var userData = userDataRef.data();
            if (userData.RefreshFirebaseToken === true) {
              await getIdToken(auth.currentUser, true);
              await FirestoreService.updateUserRefreshTokenStatus();
            }
          }

          if (
            window.location.pathname.toLowerCase() === '/dashboard' ||
            window.location.pathname.toLowerCase() === '/mobile-dashboard' ||
            window.location.pathname.toLowerCase() === '/analysis' ||
            window.location.pathname.toLowerCase() === '/summary'
          ) {
            dispatch(fetchStart());
          }

          var startDate = staticFunctions.formatDate(selectedDate.StartDate);
          var endDate = staticFunctions.formatDate(selectedDate.EndDate);
          onceRef = FirestoreService.getOptionActivityDataByDate(startDate, endDate, querySnapshot => {
            dispatch(
              OptionActivityService.SyncOptionsActivity({
                querySnapshot,
                optionsData,
              }),
            );
          });

          childAddedRef = FirestoreService.OptionActivityChildAddedByDate(startDate, endDate, querySnapshot => {
            var objValue = querySnapshot.val();
            if (objValue && objValue !== '') {
              objValue.docId = objValue.key;

              var row = staticFunctions.createOptionsRowData(objValue);
              if (optionsData.some(el => el.docId === row.docId) === false) {
                queuedActions.push(SetOption(row));

                var currentTime = moment(new Date());
                var diff = currentTime.diff(lastTime, 'seconds');

                if (currentTimeout) {
                  clearTimeout(currentTimeout);
                }
                currentTimeout = setTimeout(function() {
                  lastTime = moment(new Date());
                  dispatch(batchActions(queuedActions));
                  queuedActions = [];
                }, 15000);

                if (diff > 15) {
                  if (currentTimeout) {
                    clearTimeout(currentTimeout);
                  }

                  lastTime = moment(new Date());
                  dispatch(batchActions(queuedActions));
                  queuedActions = [];
                }
              }
            }
          });
        });
      } catch (error) {
        dispatch(fetchError(`An error occured: ${error.message}`));
      }

      return () => {
        if (onceRef != '') {
          off(onceRef);
        }
        if (childAddedRef != '') {
          off(childAddedRef);
        }

        queuedActions = [];
        clearTimeout(currentTimeout);
      };
    }
  }, [selectedDate, userSubscription]);

  const setLayoutType = () => {
    if (layoutStyle === LAYOUT_STYLES.FULL_WIDTH) {
      document.body.classList.remove('layout-type-boxed');
      document.body.classList.remove('layout-type-framed');
      document.body.classList.add('layout-type-fullwidth');
    } else if (layoutStyle === LAYOUT_STYLES.BOXED) {
      document.body.classList.remove('layout-type-fullwidth');
      document.body.classList.remove('layout-type-framed');
      document.body.classList.add('layout-type-boxed');
    } else if (layoutStyle === LAYOUT_STYLES.FRAMED) {
      document.body.classList.remove('layout-type-boxed');
      document.body.classList.remove('layout-type-fullwidth');
      document.body.classList.add('layout-type-framed');
    }
  };

  if (!isTemplateLoaded || !loadUser || !loadSubscription || !loadSubscriptionPlans) {
    return (
      <Box className={classes.circularProgressRoot}>
        <CircularProgress />
      </Box>
    );
  }

  if (
    location.pathname === '/' ||
    location.pathname === '/signin' ||
    location.pathname === '/signup' ||
    location.pathname === '/forgot-password' ||
    location.pathname === '/privacy-policy' ||
    location.pathname === '/faq' ||
    location.pathname === '/discord-callback' ||
    location.pathname === '/verify-phone' ||
    location.pathname === '/agreement' ||
    location.pathname === '/impersonate' ||
    location.pathname === '/callback'
  ) {
    return <HorizontalMinimal children={children} />;
  }

  if (authUser === null && (location.pathname === '/plans' || location.pathname === '/discord-plans')) {
    return <HorizontalMinimal children={children} />;
  }

  switch (layout) {
    case LAYOUT_TYPES.VERTICAL_DEFAULT: {
      return <VerticalDefault children={children} />;
    }
    case LAYOUT_TYPES.VERTICAL_MINIMAL: {
      return <VerticalMinimal children={children} />;
    }
    case LAYOUT_TYPES.VERTICAL_MINIMAL_NO_HEADER: {
      return <MinimalNoHeader children={children} />;
    }
    case LAYOUT_TYPES.VERTICAL_MODERN_SIDEBAR: {
      return <ModernSideBar children={children} />;
    }
    case LAYOUT_TYPES.HORIZONTAL_DEFAULT: {
      return <HorizontalDefault children={children} />;
    }
    case LAYOUT_TYPES.HORIZONTAL_DARK: {
      return <HorizontalDark children={children} />;
    }
    case LAYOUT_TYPES.HORIZONTAL_MINIMAL: {
      return <HorizontalMinimal children={children} />;
    }
    case LAYOUT_TYPES.HORIZONTAL_TOP_MENU: {
      return <HorizontalTopMenu children={children} />;
    }
    default:
      return <VerticalDefault />;
  }
};

export default AppLayout;
