import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import tawkTo from "tawkto-react";

const tawkToPropertyId = '60537380067c2605c0b9e613';
const tawkToKey = '1f12vmbd4';

export default function TawkToChatPop() {
  const { authUser } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (authUser === null) {
        tawkTo(tawkToPropertyId, tawkToKey);
    }
  }, [authUser]);

  return ('');
}
