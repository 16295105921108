import { httpsCallable } from 'firebase/functions';
import { getIdToken } from 'firebase/auth';
import { firebase, functions } from '../configs/config';

//const BaseApiUrl = process.env.REACT_APP_TRADE_IDEAS_API_URL + '/api/';
const BaseApiUrl = 'https://api.marketaction.live/api/';

export async function GetAsync(url, token) {
  return await CallApi(url, 'GET', {}, token);
}

export async function PostAsync(url, data, token) {
  return await CallApi(url, 'POST', data, token);
}

export async function PutAsync(url, data, token) {
  return await CallApi(url, 'PUT', data, token);
}

export async function DeleteAsync(url, data, token) {
  return await CallApi(url, 'DELETE', data, token);
}

async function CallApi(url, type, data, token) {
  try {
    if (data && Object.keys(data).length > 0) {
      return await fetch(BaseApiUrl + url, {
        method: type,
        body: JSON.stringify(data),
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-type': 'application/json; charset=UTF-8',
        },
      }).then(response => response.json());
    } else {
      return await fetch(BaseApiUrl + url, {
        method: type,
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-type': 'application/json; charset=UTF-8',
        },
      }).then(response => response.json());
    }
  } catch (err) {
    return null;
  }
}
