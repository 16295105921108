import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { CurrentAuthMethod } from '../../../@jumbo/constants/AppConstants';
import Steppers1 from './Steppers/Steppers1';
import { AuhMethods } from '../../../services/auth';
const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function UpdateDialog({ method = CurrentAuthMethod }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [updateEnteries, setUpdateEntries] = React.useState({
    experienceLevel: '',
    traderType: {
      dayTrader: false,
      swingTrader: false,
      optionsTrader: false,
      stocksTraders: false,
      etfTrader: false,
      spyTrader: false,
    },
    imageData: { result: null, filename: null, filetype: null, src: null, error: null },
  });

  const handleSubmit = () => {
    dispatch(AuhMethods[method].updateUserDataOnSignUp({ ...updateEnteries }));
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
        fullWidth
        maxWidth="sm">
        <DialogContent dividers className="dialog-2">
          <Steppers1 handleSubmit={handleSubmit} updateEnteries={updateEnteries} setUpdateEntries={setUpdateEntries} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
