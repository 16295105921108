import * as FirestoreService from '../auth/firebase/FirebaseRealTimeDb';
import {
  SetUserWatchlists,
  SetUserSubscription,
  UpdateLoadSubscription,
  SetSubscriptionPlans,
  UpdateLoadSubscriptionPlans,
} from '../../redux/actions/Firebase';
import * as staticFunctions from '../../routes/Pages/Dashboard/dashboardFunctions';
import { getDoc, getDocs, collection } from "firebase/firestore";

const PlansService = {
  SyncPlans: () => {
    return async dispatch => {
      const querySnapshot = await FirestoreService.getPlansAsync();
      var subscriptionPlans = [];
      var index = 0;
      var promiseCall = new Promise((resolve, reject) => {
        querySnapshot.docs.forEach(async planDoc => {
          var plan = planDoc.data();
          plan.id = planDoc.id;
          plan.prices = [];
          const priceSnap = await getDocs(collection(planDoc.ref, 'prices'));
          priceSnap.docs.forEach(doc => {
            var planPrice = doc.data();
            if (planPrice.active === true) {
              planPrice.id = doc.id;
              planPrice.unit_amount = planPrice.unit_amount / 100;
              planPrice.total_amount = planPrice.unit_amount;

              if (
                plan.stripe_metadata_discount_percent &&
                plan.stripe_metadata_discount_percent != '' &&
                isNaN(parseFloat(plan.stripe_metadata_discount_percent)) === false
              ) {
                var discount = parseFloat(plan.stripe_metadata_discount_percent);
                planPrice.total_amount = planPrice.unit_amount - (planPrice.unit_amount * discount) / 100;
                planPrice.strike_out_amount = planPrice.unit_amount;
              }

              plan.prices.push(planPrice);
            }
          });

          plan.features = [];
          if (plan.stripe_metadata_features) {
            const splitFeatures = plan.stripe_metadata_features.split(';');
            splitFeatures.forEach(feature => {
              plan.features.push(feature);
            });
          }

          subscriptionPlans.push(plan);
          if (index === querySnapshot.docs.length - 1) resolve();
          index++;
        });

        if (querySnapshot.docs.length === 0) resolve();
      });

      // subscriptionPlans.unshift({
      //   name: 'Free Plan',
      //   stripe_metadata_plan: 'standard',
      //   stripe_metadata_features:
      //     'Delayed Options Order Flow;Limited Order Flow;Market Action Flow Summary;Stock Flow Analyzer;Watchlists;Mobile Friendly UI;Desktop Notification;Discord Community Chat Room;Intermittent Ads',
      //   description: 'Completely free to uses with limited data to check out our app features.',
      //   role: 'customer',
      //   active: true,
      //   stripe_metadata_discount_percent: '0',
      //   images: [],
      //   id: 'free_plan',
      //   prices: [
      //     {
      //       tiers_mode: null,
      //       tiers: null,
      //       type: 'recurring',
      //       interval_count: 1,
      //       description: null,
      //       currency: 'usd',
      //       billing_scheme: 'per_unit',
      //       transform_quantity: null,
      //       trial_period_days: null,
      //       interval: 'month',
      //       active: true,
      //       unit_amount: 0.0,
      //       recurring: {
      //         interval_count: 1,
      //         interval: 'month',
      //         usage_type: 'licensed',
      //         aggregate_usage: null,
      //         trial_period_days: null,
      //       },
      //       id: 'free_plan_price',
      //       total_amount: 0.0,
      //       strike_out_amount: 0.0,
      //     },
      //   ],
      //   features: [
      //     'Delayed Options Order Flow',
      //     'Limited Order Flow',
      //     'Market Action Flow Summary',
      //     'Stock Flow Analyzer',
      //     'Watchlists',
      //     'Mobile Friendly UI',
      //     'Desktop Notification',
      //     'Discord Community Chat Room',
      //     'Intermittent Ads',
      //   ],
      // });

      promiseCall.then(() => {
        subscriptionPlans = subscriptionPlans.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        dispatch(SetSubscriptionPlans(subscriptionPlans));
        dispatch(UpdateLoadSubscriptionPlans(true));
      });
    };
  },

  SyncUserSubscription: ({ querySnapshot }) => {
    return async dispatch => {
      if (querySnapshot.docs.length > 0) {
        const doc = querySnapshot.docs[0];
        var userPlan = doc.data();
        userPlan.id = doc.id;
        var planPrice = await getDoc(userPlan.price);
        userPlan.planPrice = planPrice.data();
        userPlan.planPrice.id = planPrice.id;

        var planProduct = await getDoc(userPlan.product);
        userPlan.planProduct = planProduct.data();

        userPlan.planProduct.id = planProduct.id;
        userPlan.planPrice.unit_amount = userPlan.planPrice.unit_amount / 100;
        userPlan.planPrice.total_amount = userPlan.planPrice.unit_amount;

        if (
          userPlan.planProduct.stripe_metadata_discount_percent &&
          userPlan.planProduct.stripe_metadata_discount_percent != '' &&
          isNaN(parseFloat(userPlan.planProduct.stripe_metadata_discount_percent)) === false
        ) {
          var discount = parseFloat(userPlan.planProduct.stripe_metadata_discount_percent);
          userPlan.planPrice.total_amount =
            userPlan.planPrice.unit_amount - (userPlan.planPrice.unit_amount * discount) / 100;
          userPlan.planPrice.strike_out_amount = userPlan.planPrice.unit_amount;
        }
        var planStatus = userPlan.status;

        switch (userPlan.status) {
          case 'trialing': {
            planStatus = 'Trial';
            break;
          }
          case 'active': {
            planStatus = 'Active';
            break;
          }
        }
        userPlan.planStatus = planStatus;
        if (userPlan.cancel_at !== null) {
          userPlan.PlanWillCancelDate = staticFunctions.getEpochFormattedDate(userPlan.cancel_at, 'DD MMMM YYYY');
        }

        if (userPlan.current_period_end !== null) {
          userPlan.PlanEndDate = staticFunctions.getEpochFormattedDate(userPlan.current_period_end, 'DD MMMM YYYY');
        }
        dispatch(SetUserSubscription(userPlan));

        var watchlists = await FirestoreService.getWatchlistsAsync();
        var data = [];
        if (watchlists != null) {
          watchlists.forEach(function(childSnapshot) {
            var childData = childSnapshot.data();
            childData.id = childSnapshot.id;
            data.push(childData);
          });
        }
        dispatch(SetUserWatchlists(data));
      } else {
        dispatch(SetUserSubscription(null));
      }

      dispatch(UpdateLoadSubscription(true));
    };
  },
};

export default PlansService;
