import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Checkbox, CircularProgress, fade, FormControlLabel, InputAdornment } from '@material-ui/core';
import IntlMessages from '../../../utils/IntlMessages';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import { NavLink, useLocation } from 'react-router-dom';
import * as firebaseFunctions from '../../../../services/firebase/functions';
import { fetchError } from '../../../../redux/actions';
import Cookies from 'universal-cookie';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { auth, firebase } from '../../../../services/configs/config';
import { updatePhoneNo } from 'redux/actions/Auth';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { updateUserPhoneNo } from 'services/auth/firebase/FirebaseRealTimeDb';
import MuiPhoneNumber from 'material-ui-phone-number';
import { RecaptchaVerifier, linkWithPhoneNumber } from 'firebase/auth';
const phone = require('phone');

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 30,
    paddingRight: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '100%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));

// Phone auth

//variant = 'default', 'standard', 'bgColor'
const PhoneAuth = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = '' }) => {
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const [confirm, setConfirm] = React.useState('');

  const [phoneNo, setPhoneNo] = React.useState({
    phone: '',
    isValid: false,
  });
  const [otp, setOtp] = React.useState('');
  const [timer, setTimer] = React.useState(30);
  const [loading, setLoading] = React.useState(false);
  const [isResend, setIsResend] = React.useState(false);
  const captchaRef = React.useRef(null);

  // useEffect(() => {
  //   var phoneReg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  //   if (phoneNo.phone && phoneReg.test(phoneNo.phone)) {
  //     setPhoneNo(val => ({
  //       phone: val.phone,
  //       isValid: true,
  //     }));
  //   }
  // }, [phoneNo.phone]);

  useEffect(() => {
    if (confirm && timer > 0) {
      const tim = setTimeout(() => {
        setTimer(val => val - 1);
      }, 1000);
      return () => {
        clearTimeout(tim);
      };
    } else if (timer === 0) {
      setOtp('');
    }
  }, [timer, confirm]);

  function verifyPhone() {
    //for testing
    const testPhn = phoneNo.phone;

    const reCaptchaVerifier = window.recaptchaVerifier;

    linkWithPhoneNumber(auth.currentUser, testPhn, reCaptchaVerifier)
      .then(result => {
        if (timer === 0) {
          setTimer(30);
          // reCaptchaVerifier.clear();
        }
        setConfirm(result);
        console.log('result ', result);
        dispatch(fetchError('Verification code sent to phone ' + phoneNo.phone));
      })
      .catch(error => {
        // reCaptchaVerifier.clear();
        console.error('err ', error);
        if (error.code === 'auth/invalid-phone-number') {
          // alert('Invalid phone number.');
          dispatch(fetchError(`Invalid phone number.`));
        }
        setLoading(false);
        setPhoneNo({
          phone: '',
          isValid: false,
        });
      });
  }

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(captchaRef.current, {
      size: 'invisible',
      callback: function(response) {
        console.log('resp ', response);
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
      'expired-callback': function() {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
      },
    }, auth);
    window.recaptchaVerifier.render().then(function(widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
  }, []);

  const onSubmit = () => {};

  const handleVerifyPhone = () => {
    setOtp('');
    verifyPhone();
  };

  const handleVerifyOtp = () => {
    confirm
      .confirm(otp)
      .then(ree => {
        const {
          user: { phoneNumber },
        } = ree || {};
        if (phoneNumber) {
          dispatch(fetchError(`Phone number saved successfully.`));
          updateUserPhoneNo(phoneNumber)
            .then(res => {
              dispatch(updatePhoneNo(phoneNumber));
            })
            .catch(error => {
              dispatch(fetchError('Error ' + error.message));
            });

          // alert('Phone number saved successfully.');
        }
      })
      .catch(error => {
        console.log('err ', error);
        if (error.code === 'auth/invalid-verification-code') {
          dispatch(fetchError(`Oops ! Invalid OTP.Try again.`));
        } else if ('auth/credential-already-in-use') {
          dispatch(fetchError(`This Phone number is already in use.`));
        }
      });
  };

  const handleBack = () => {
    setConfirm(null);
    setPhoneNo({
      phone: '',
      isValid: false,
    });
    setLoading(false);
    setTimer(30);
  };
  const handlePhoneNo = val => {
    if (phone(val).length > 0) {
      setPhoneNo({
        phone: val,
        isValid: true,
      });
    } else {
      setPhoneNo({
        phone: val,
        isValid: false,
      });
    }
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={'/images/logo.png'} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Verify phone number {confirm && phoneNo.phone ? ' - ' + phoneNo.phone + '' : ''}
        </Typography>
        <ValidatorForm onSubmit={onSubmit} onError={errors => console.log(errors)}>
          <Box>
            {confirm ? (
              <Box md={2}>
                <TextField
                  autoFocus
                  margin="normal"
                  id="otp"
                  label="Enter verification code"
                  type="text"
                  variant="standard"
                  fullWidth
                  onChange={e => setOtp(e.target.value)}
                  value={otp}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {timer > 0 ? (
                          <h4> {timer} s</h4>
                        ) : (
                          <Button id="verify-phone" onClick={handleVerifyPhone}>
                            Resend
                          </Button>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            ) : (
              <Box md={2}>
                {/* <TextField
                  autoFocus
                  margin="normal"
                  id="phoneNo"
                  label="Please enter mobile number"
                  type="text"
                  fullWidth
                  variant="outlined"
                  onChange={e =>
                    setPhoneNo({
                      phone: e.target.value,
                    })
                  }
                  value={phoneNo.phone}
                /> */}
                <MuiPhoneNumber
                  margin="normal"
                  autoFocus
                  disableAreaCodes={true}
                  label="Please enter mobile number"
                  fullWidth
                  defaultCountry={'us'}
                  onChange={handlePhoneNo}
                  className={classes.textFieldRoot}
                />
              </Box>
            )}
          </Box>

          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ sm: 'center' }}
            justifyContent={{ sm: 'space-between' }}
            mb={3}>
            <Box mb={{ xs: 2, sm: 0 }}>
              {!confirm ? (
                <Fragment>
                  {!loading ? (
                    <Button
                      onClick={handleVerifyPhone}
                      id="verify-phone"
                      variant="contained"
                      color="primary"
                      disabled={!phoneNo.isValid}>
                      Send verification code
                    </Button>
                  ) : (
                    <CircularProgress size={24} />
                  )}
                </Fragment>
              ) : (
                <div>
                  <Button onClick={handleVerifyOtp} color="primary" variant="contained" disabled={otp === ''}>
                    Confirm
                  </Button>
                  <Button
                    style={{ marginLeft: 8 }}
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<KeyboardBackspaceIcon />}
                    onClick={handleBack}>
                    Change phone no
                  </Button>
                </div>
              )}
            </Box>
          </Box>
        </ValidatorForm>

        <ContentLoader />
      </Box>
      <div id="recaptcha-container" ref={ref => (captchaRef.current = ref)} />
    </AuthWrapper>
  );
};

export default PhoneAuth;
