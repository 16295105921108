import React, { Fragment } from 'react';
import { Box, Button, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AuhMethods } from '../../../../../../services/auth';
import { CurrentAuthMethod } from '../../../../../constants/AppConstants';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import clsx from 'clsx';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import CmtAvatar from '@coremat/CmtAvatar';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { PostAdd } from '@material-ui/icons';
import SettingsIcon from '@material-ui/icons/Settings';
import { isElementAccessExpression } from 'typescript';
import * as appConstants from '../../../../../../Constants/AppConstants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  loginSection: {
    display: 'flex',
    alignItems: 'center',
    '& > .MuiDivider-root': {
      height: 14,
      marginLeft: 8,
      marginRight: 8,
      backgroundColor: theme.palette.text.secondary,
    },
  },
}));

const HeaderLogin = () => {
  const history = useHistory();
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const { userSubscription } = useSelector(({ firebaseStore }) => firebaseStore);
  const dispatch = useDispatch();

  const onItemClick = item => {
    if (item.label === 'Logout') {
      dispatch(AuhMethods[CurrentAuthMethod].onLogout());
    } else if (item.label === 'Account') {
      history.push('/my-account');
    } else if (item.label === 'Plans') {
      history.push('/plans');
    } else if (item.label === 'Dashboard') {
      history.push('/dashboard');
    } else if (item.label === 'Discord') {
      history.push('/discord-servers');
    }
  };

  var actionsList = [
    {
      icon: <PersonIcon />,
      label: 'Account',
    },
    {
      icon: <ExitToAppIcon />,
      label: 'Logout',
    },
  ];

  actionsList.unshift({
    icon: <ShoppingBasketIcon />,
    label: 'Plans',
  });

  actionsList.unshift({
    icon: <PostAdd />,
    label: 'Dashboard',
  });
  if (userSubscription !== null && userSubscription.id && userSubscription.role === appConstants.Role_Discord) {
    actionsList.unshift({
      icon: <SettingsIcon />,
      label: 'Discord',
    });
  }

  return (
    <Box className={classes.root}>
      <Box display="flex" alignItems="center" color="warning.main" />
      {authUser ? (
        <Fragment>
          {authUser.phoneNumber !== null ? (
            <Box className={clsx(classes.profileRoot, 'Cmt-profile-pic')}>
              <CmtDropdownMenu
                onItemClick={onItemClick}
                TriggerComponent={<CmtAvatar size="small" alt={authUser.displayName} src={authUser.photoURL} />}
                items={actionsList}
              />
            </Box>
          ) : (
            <Box className={clsx(classes.profileRoot, 'Cmt-profile-pic')}>
              <CmtDropdownMenu
                onItemClick={onItemClick}
                TriggerComponent={<CmtAvatar size="small" alt={authUser.displayName} src={authUser.photoURL} />}
                items={[
                  {
                    icon: <ExitToAppIcon />,
                    label: 'Logout',
                  },
                ]}
              />
            </Box>
          )}
        </Fragment>
      ) : (
        <Box className={classes.loginSection}>
          {/* <Button onClick={() => history.push('/plans')} className="Cmt-btn" size="small">
            Plans
          </Button> 
          <Divider className="Cmt-divider" orientation="vertical" />*/}
          <Button onClick={() => history.push('/signin')} className="Cmt-btn" size="small">
            Login
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default HeaderLogin;
