import React from 'react';
import Box from '@material-ui/core/Box';
import { Button, Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import CmtAdvCard from '@coremat/CmtAdvCard';
import CmtAdvCardContent from '@coremat/CmtAdvCard/CmtAdvCardContent';
import CmtImage from '@coremat/CmtImage';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import * as FirestoreService from '../../../services/auth/firebase/FirebaseRealTimeDb';
import { useSelector, useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import * as appConstants from "../../../Constants/AppConstants";
import { findLastIndex } from 'lodash-es';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    height: 'auto',
  },
  subTitleContent: {
    fontSize: 14,
    color: theme.palette.common.dark,
    marginBottom: 18,
  },
  planList: {
    padding: 0,
    '& li': {
      padding: 0,
      color: theme.palette.text.secondary,
      '& .MuiTypography-body1': {
        fontSize: 14,
      },
      '& .MuiListItemIcon-root': {
        minWidth: 10,
        marginRight: 10,
      },
      '& .MuiSvgIcon-root': {
        fontSize: 18,
      },
    },
  },
  heroContent: {
    padding: theme.spacing(0, 0, 6),
  },
}));

const DiscordPlans = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const cookies = new Cookies();

  const { userSubscription, subscriptionPlans } = useSelector(({ firebaseStore }) => firebaseStore);
  var plans = subscriptionPlans.filter(f => f.role === appConstants.Role_Discord);

  plans.forEach(plan => {
    if (userSubscription != null) {
      if (userSubscription.planProduct.id === plan.id) {
        plan.isCurrentPlan = true;
      }
    }
  });

  var isHomePage = window.location.pathname === '/';

  async function subscribePlan(planPriceId) {
    if (authUser != null && (userSubscription == null || (userSubscription != null && !userSubscription.id))) {
      dispatch(fetchStart());
      await FirestoreService.subscripeToPlan(planPriceId, async snap => {
        const { error, sessionId } = snap.data();
        if (error) {
          dispatch(fetchError(`An error occured: ${error.message}`));
          console.log(`An error occured: ${error.message}`);
        }
        if (sessionId) {
          dispatch(fetchSuccess());
          const stripe = await loadStripe(FirestoreService.STRIPE_PUBLISHABLE_KEY);
          stripe.redirectToCheckout({ sessionId });
        }
      });
    } else if (authUser != null) {
      dispatch(fetchStart());
      FirestoreService.goToStripeBillingDashboard(data => {
        dispatch(fetchSuccess());
        window.location.assign(data.url);
      });
    } else {
      cookies.set('subscriptionPlanId', planPriceId, { path: '/' });
      history.push('/signup');
    }
  }

  const getContentTitle = (planPrice, plan) => (
    <Box component={'div'} mb={5}>
      {planPrice.trial_period_days > 0 && (
        <Box
          component="span"
          fontSize={{ xs: 30, md: 35, xl: 40 }}
          fontWeight="fontWeightBold"
          color="common.dark"
          lineHeight="1">
          {planPrice.trial_period_days} days free
        </Box>
      )}
      <Box display="flex" alignItems="flex-end">
        <Box
          component="span"
          fontSize={planPrice.trial_period_days > 0 ? {} : { xs: 30, md: 35, xl: 40 }}
          fontWeight="fontWeightBold"
          color="common.dark"
          lineHeight="1">
          {planPrice.trial_period_days > 0 && (
            <Typography component="span" variant="subtitle1">
              Then
            </Typography>
          )}{' '}
          {planPrice.strike_out_amount > 0 && <Typography component="del" style={{color:'grey'}}>${planPrice.strike_out_amount}</Typography> }
          {' '}
          <Typography component="span">${planPrice.total_amount.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}</Typography>
        </Box>
        <Typography component="span" variant="subtitle1">
          /{planPrice.interval}
        </Typography>
      </Box>
      {plan.description !== null && plan.description !== '' && (
        <Box component="span" lineHeight="1.75">
           <div dangerouslySetInnerHTML={createMarkup(plan.description)} />
        </Box>
      )}  
      <Button
      style={{width:"100%"}}
      onClick={async () => {
        await subscribePlan(planPrice.id);
      }}
      variant="contained"
      color="primary">
      Subscribe
    </Button>
    </Box>
  );

  function createMarkup(text) { return {__html: text}; };

  return (
    <div>
      <Grid className={classes.heroContent}>
        <Typography component="h1" align="center" color="textPrimary" style={{ fontSize: '30px' }}>
          Discord Bot Pricing
        </Typography>
        {isHomePage && (
          <Typography component="h3" align="center" color="textPrimary">
            Signup below to get started with MarketAction.
          </Typography>
        )}
        <Typography variant="h5" align="center" color="textSecondary" component="p">
          Try Market Action without any commitments using our free 7 day trial. Select a plan that fits your needs. Upgrade,
          downgrade or cancel anytime.
        </Typography>
      </Grid>
      <GridContainer spacing={5} style={{display:'flex', justifyContent:'center'}}>
        {plans.map((plan, i) => {
          const planPrice = plan.prices[0];
          return (
            <Grid item key={plan.name + i} xs={12} sm={4}>
              <CmtAdvCard className={classes.cardRoot}>
                <CmtCardHeader
                  title={
                    (planPrice.trial_period_days > 0 ? 'Try ' : '') +
                    plan.name +
                    (plan.isCurrentPlan === true ? ' (Current Plan)' : '')
                  }
                  titleProps={{
                    variant: 'h4',
                    component: 'div',
                  }}
                />
                <CmtAdvCardContent
                  style={{ textTransform: 'capitalize' }}
                  reverseDir>
                    {getContentTitle(planPrice, plan)}
                    {plan.stripe_metadata_plan + ' Plan'}
                    <List component="ul" className={classes.planList}>
                      {plan.features.map((feature, index) => {
                        return (
                          <ListItem key={feature + index}>
                            <ListItemIcon>
                              <FiberManualRecordIcon />
                            </ListItemIcon>
                            <ListItemText primary={feature} />
                          </ListItem>
                        );
                      })}
                    </List>
                </CmtAdvCardContent>
              </CmtAdvCard>
            </Grid>
          );
        })}
      </GridContainer>

      

<Box style={{ margin: '25px auto', display:'flex', justifyContent:'center' }}>
                  <Button
                      onClick={() => history.push('/plans')}
                      variant="contained"
                      color="primary"
                      size="large"
                      htmltype="button">
                      Regular Plans
                      </Button>
              </Box>
    </div>
  );
};

export default DiscordPlans;
