import React from 'react';
import { Box, Typography } from '@material-ui/core';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CmtDropdownMenu from '../../../../@coremat/CmtDropdownMenu';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch } from 'react-redux';
import { AuhMethods } from '../../../../services/auth';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '30px 16px 12px 16px',
    borderBottom: `solid 1px ${theme.palette.sidebar.borderColor}`,
  },
  userInfo: {
    paddingTop: 24,
    transition: 'all 0.1s ease',
    height: 75,
    opacity: 1,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: 'all 0.3s ease',
    },
  },
  userTitle: {
    color: theme.palette.sidebar.textDarkColor,
    marginBottom: 8,
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
  },
}));

const SidebarHeader = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const onItemClick = item => {
    if (item.label === 'Logout') {
      dispatch(AuhMethods[CurrentAuthMethod].onLogout());
    } else if (item.label === 'Account') {
      history.push('/my-account');
    } else if (item.label === 'Plans') {
      history.push('/plans');
    }
  };

  const actionsList = [
    {
      icon: <PersonIcon />,
      label: 'Account',
    },
  ];
  actionsList.push({
    icon: <ShoppingBasketIcon />,
    label: 'Plans',
  });

  actionsList.push({
    icon: <ExitToAppIcon />,
    label: 'Logout',
  });

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <CmtAvatar alt={authUser?.displayName} src={authUser?.photoURL} />
        <Box className={classes.userInfo}>
          <CmtDropdownMenu
            onItemClick={onItemClick}
            TriggerComponent={
              <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                <Box mr={2}>
                  <Typography className={classes.userTitle} component="h3" variant="h6">
                    {authUser?.displayName}
                  </Typography>
                  <Typography className={classes.userSubTitle}>{authUser?.email}</Typography>
                </Box>
                <ArrowDropDownIcon />
              </Box>
            }
            items={actionsList}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default SidebarHeader;
