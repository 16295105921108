import React from 'react';
import { Column } from 'react-data-grid';
import PropTypes from 'prop-types';

const RuleType = {
  Number: 1,
  Range: 2,
  GreaterThen: 3,
  LessThen: 4,
};

const DecimalFilter = props => {
  function filterValues(row, columnFilter, columnKey) {
    if (columnFilter.filterTerm == null) {
      return true;
    }
    let result = false;
    // implement default filter logic
    const value = parseFloat(row[columnKey], 10);
    for (const ruleKey in columnFilter.filterTerm) {
      if (!columnFilter.filterTerm.hasOwnProperty(ruleKey)) {
        continue;
      }

      const rule = columnFilter.filterTerm[ruleKey];

      switch (rule.type) {
        case RuleType.Number:
          if (rule.value === value) {
            result = true;
          }
          break;
        case RuleType.GreaterThen:
          if (rule.value <= value) {
            result = true;
          }
          break;
        case RuleType.LessThen:
          if (rule.value >= value) {
            result = true;
          }
          break;
        case RuleType.Range:
          if (rule.begin <= value && rule.end >= value) {
            result = true;
          }
          break;
        default:
          // do nothing
          break;
      }
    }
    return result;
  }

  function getRules(value) {
    const rules = [];
    if (value === '') {
      return rules;
    }
    // check comma
    const list = value.split(',');
    if (list.length > 0) {
      // handle each value with comma
      for (const key in list) {
        if (!list.hasOwnProperty(key)) {
          continue;
        }

        const obj = list[key];
        if (obj.indexOf('-') > 0) {
          // handle dash
          const begin = parseFloat(obj.split('-')[0], 10);
          const end = parseFloat(obj.split('-')[1], 10);
          rules.push({ type: RuleType.Range, begin: begin, end: end });
        } else if (obj.indexOf('>') > -1) {
          // handle greater then
          const begin = parseFloat(obj.split('>')[1], 10);
          rules.push({ type: RuleType.GreaterThen, value: begin });
        } else if (obj.indexOf('<') > -1) {
          // handle less then
          const end = parseFloat(obj.split('<')[1], 10);
          rules.push({ type: RuleType.LessThen, value: end });
        } else {
          // handle normal values
          const numericValue = parseFloat(obj, 10);
          rules.push({ type: RuleType.Number, value: numericValue });
        }
      }
    }
    return rules;
  }

  function handleKeyPress(e) {
    // Validate the input
    const regex = '>|<|-|,|.|([0-9])';
    const result = RegExp(regex).test(e.key);
    if (result === false) {
      e.preventDefault();
    }
  }

  function handleChange(e) {
    const value = e.target.value;
    const filters = getRules(value);
    props.onChange({
      filterTerm: filters.length > 0 ? filters : null,
      column: props.column,
      rawValue: value,
      filterValues: filterValues,
    });
  }

  const inputKey = 'header-filter-' + props.column.key;
  const columnStyle = {
    float: 'left',
    marginRight: 5,
    maxWidth: '80%',
  };
  const badgeStyle = {
    cursor: 'help',
  };

  const tooltipText = 'Input Methods: Range (x-y), Greater Then (>x), Less Then (<y)';

  return (
    <div>
      <div style={columnStyle}>
        <input
          key={inputKey}
          type="text"
          placeholder="e.g. 3,10-15,>20"
          className="form-control input-sm"
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
      </div>
      <div className="input-sm">
        <span className="badge" style={badgeStyle} title={tooltipText}>
          ?
        </span>
      </div>
    </div>
  );
};

DecimalFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  column: PropTypes.shape(Column),
};
export default DecimalFilter;
