import React, { useState, useEffect } from 'react';
import '../Dashboard/index.css';
import * as staticFunctions from '../Dashboard/dashboardFunctions';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtAdvCardContent from '@coremat/CmtAdvCard/CmtAdvCardContent';
import CmtAdvCard from '@coremat/CmtAdvCard';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Pie, PieChart, ResponsiveContainer, Tooltip, Cell } from 'recharts';
import { Box, Grid, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import * as FirestoreService from '../../../services/auth/firebase/FirebaseRealTimeDb';
import * as firebaseFunctions from '../../../services/firebase/functions';
import { fetchError } from '../../../redux/actions';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(theme => ({
    cardContentRoot: {
        '& .MuiGrid-container': {
            alignItems: 'center',
        },
    },
    cardContentTitle: {
        marginBottom: 4,
    },
    subTitleRoot: {
        fontSize: 12,
        marginBottom: 0,
        color: theme.palette.text.secondary,
    },
    tooltip: {
        position: 'relative',
        borderRadius: 6,
        padding: '4px 8px',
        backgroundColor: '#8d46ef',
        color: theme.palette.common.white,
    }
}));

export default function AnalystRatingsSummary({ selectedSymbol }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { selectedDate } = useSelector(({ firebaseStore }) => firebaseStore);

    const [analystRatingsSummaryWidgetHidden, setAnalystRatingsSummaryWidgetHidden] = useState(false);
    const [analystRatings, setAnalystRatings] = useState([]);
    const [analystRatingsData, setAnalystRatingsData] = useState([]);
    const [latestPrice, setLatestPrice] = React.useState(null);
    const [priceTargets, setPriceTargets] = React.useState([]);
    const [priceTargetsData, setPriceTargetsData] = React.useState(null);
    const [stockRating, setStockRating] = React.useState('');

    const average = arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length;

    useEffect(() => {
        if (selectedSymbol) {
            firebaseFunctions.callfunction(
                'getLatestPrice',
                {
                    symbol: selectedSymbol
                },
                function (res) {
                    if (res.data.status === 1) {
                        setLatestPrice(res.data.price);
                    } else {
                        dispatch(fetchError(res.data.msg));
                        setLatestPrice(0);
                    }
                },
            );

            FirestoreService.getThreeMonthsAnalystSummary(selectedSymbol, selectedDate.StartDate, function (snapshot) {
                var analystData = [];
                var analystRaData = [];
                var priceTargetPrices = [];

                var buy = 0, sell = 0, hold = 0, moderateBuy = 0, moderateSell = 0, strongBuy = 0, strongSell = 0;
                snapshot.docs.forEach((doc) => {
                    var docData = doc.data();
                    priceTargetPrices.push(docData.pt_current);
                    analystRaData.push(docData);

                    switch (docData.rating_current.toUpperCase()) {
                        case 'BUY':
                            buy++;
                            break;
                        case 'SELL':
                            sell++;
                            break;
                        case 'STRONG BUY':
                        case 'CONVICTION BUY':
                            strongBuy++;
                            break;
                        case 'STRONG SELL':
                        case 'CONVICTION SELL':
                            strongSell++;
                            break;
                        case 'HOLD':
                        case 'NEUTRAL':
                        case 'EQUAL-WEIGHT':
                        case 'MARKET PERFORM':
                        case 'PERFORM':
                        case 'IN-LINE':
                        case 'PEER PERFORM':
                            hold++;
                            break;
                        case 'OUTPERFORM':
                        case 'OUTPERFORMER':
                        case 'MARKET OUTPERFORM':
                        case 'ACCUMULATE':
                        case 'OVERWEIGHT':
                        case 'POSITIVE':
                            moderateBuy++;
                            break;
                        case 'UNDERWEIGHT':
                        case 'UNDERPERFORM':
                        case 'UNDERPERFORMER':
                        case 'MARKET UNDERPERFORM':
                        case 'NEGATIVE':
                            moderateSell++;
                            break;
                        default:
                            break;
                    }
                });

                var totalBuy = buy + moderateBuy + strongBuy;
                var totalSell = sell + moderateSell + strongSell;
                
                if(totalBuy > totalSell && totalBuy > hold) {
                    if(strongBuy > moderateBuy && strongBuy > buy) {
                        setStockRating('Strong Buy');
                    }
                    else if(moderateBuy > strongBuy && moderateBuy > buy ){
                        setStockRating('Moderate Buy');
                    }
                    else if(buy > strongBuy && buy > moderateBuy){
                        setStockRating('Buy');
                    }
                    else{
                        setStockRating('Buy');
                    }
                }
                else if(totalSell > totalBuy && totalSell > hold){
                    if(sell > moderateSell && sell > strongSell) {
                        setStockRating('Sell');
                    }
                    else if(moderateSell > sell && moderateSell > strongSell) {
                        setStockRating('Moderate Sell');
                    }
                    else if(strongSell > sell && strongSell > moderateSell) {
                        setStockRating('Strong Sell');
                    }
                    else{
                        setStockRating('Sell');
                    }
                }
                else{
                    setStockRating('Hold/Neutral');
                }

                analystData.push({ name: 'Buy', value: buy + moderateBuy + strongBuy });
                analystData.push({ name: 'Sell', value: sell + moderateSell + strongSell });
                analystData.push({ name: 'Hold', value: hold });

                setAnalystRatings(analystData);
                setAnalystRatingsData(analystRaData);
                setPriceTargets(priceTargetPrices.filter(f => f > 0));
            });
        }
        else {
            setAnalystRatings([]);
            setAnalystRatingsData([]);
            setStockRating('');
        }

        return () => { }
    }, [selectedSymbol, selectedDate]);

    useEffect(() => {
        setPriceTargetsData(null);
        if(latestPrice !== null && priceTargets.length > 0){
            var maxPriceTarget = Math.max.apply(Math, priceTargets);
            var minPriceTarget = Math.min.apply(Math, priceTargets);
            var avgPriceTarget = average(priceTargets);
            var priceChangePercentage = 0;

            if (avgPriceTarget > 0 && latestPrice > 0) {
                priceChangePercentage = ((avgPriceTarget - latestPrice) / latestPrice) * 100;
            }
            setPriceTargetsData({
                MaxPriceTarget:maxPriceTarget.toFixed(2),
                MinPriceTarget:minPriceTarget.toFixed(2),
                AvgPriceTarget:avgPriceTarget.toFixed(2),
                PriceChangePercentage:priceChangePercentage.toFixed(2),
                LastPrice: latestPrice.toFixed(2)
            });
        }
    }, [latestPrice, priceTargets]);

    if (analystRatingsSummaryWidgetHidden) {
        return ('');
    }

    const analystRatingsSummaryWidgetActionHandler = event => {
        switch (event.value) {
            case 'close': {
                return setAnalystRatingsSummaryWidgetHidden(true);
            }
            default:
                return true;
        }
    };

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        var row = analystRatings[index];

        if (row.value > 0) {
            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${row.name} : ${row.value}`}
                </text>
            );
        }
    };

    function LoadSummaryPriceRating(){
        if (analystRatingsData.length > 0 && priceTargetsData !== null) {
            return (<div className={'stock-rating-title-price ' + (priceTargetsData.PriceChangePercentage > 0 ? 'upside-color' : 'downside-color')}>
            <p>${priceTargetsData.AvgPriceTarget}</p>
            <span>({priceTargetsData.PriceChangePercentage > 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} {priceTargetsData.PriceChangePercentage}% {priceTargetsData.PriceChangePercentage > 0 ? 'Upside' : 'Downside'})</span>
        </div>);
        }
        else{
            return '';
        }
    }

    function LoadSummaryContent(){
        if(analystRatingsData.length > 0){
            return (<CmtAdvCardContent className={classes.cardContentRoot} reverseDir>
                <div className="stock-rating-title">
                    <h2>{stockRating}</h2>
                    <LoadSummaryPriceRating />
                </div>
                <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                        <Pie dataKey="value" labelLine={false} isAnimationActive={false} data={analystRatings} cx="50%" cy="50%" outerRadius={120} fill="#6200EE" label={renderCustomizedLabel}>

                            {analystRatings.map((entry, index) => {
                                var hexColor = entry.name === 'Buy' ? '#00B399' : entry.name === 'Sell' ? '#F44336' : '#747474';
                                return (<Cell key={`cell-${index}`} fill={hexColor} />);
                            })}
                        </Pie>

                        <Tooltip labelStyle={{ color: 'black' }} itemStyle={{ color: 'black' }} cursor={false} />
                    </PieChart>
                </ResponsiveContainer>
                <GetAnalystSummaryText />
            </CmtAdvCardContent>);
        }
        else{
            return (<h4 className="text-center">No Data!</h4>);
        }
    }


    function GetAnalystSummaryText(){
        if (analystRatingsData.length > 0 && priceTargetsData !== null) {
            return (<p className="analystSummaryText">
                Based on <strong>{analystRatingsData.length}</strong> Market analysts offering 12 month price targets for <strong>{analystRatingsData[0].name}</strong> in the last 3 months.
                The average price target is <strong>${priceTargetsData.AvgPriceTarget}</strong> with a high forecast of <strong>${priceTargetsData.MaxPriceTarget}</strong> and a low forecast of <strong>${priceTargetsData.MinPriceTarget}</strong>.
                The average price target represents a <strong>{priceTargetsData.PriceChangePercentage}%</strong> change from the last price of <strong>${priceTargetsData.LastPrice}</strong>.
            </p>);
        }
        else{
            return '';
        }
    }
    return (
        <Grid item xs={12} sm={12} md={6}>
            <CmtAdvCard>
                <CmtCardHeader
                    className="pt-2 pb-2"
                    actionsPos="top-corner"
                    actions={[{ label: 'Close', value: 'close' }]}
                    actionHandler={analystRatingsSummaryWidgetActionHandler}
                    title={
                        <Typography
                            {...{
                                variant: 'h4',
                                component: 'div',
                            }}>
                            Analyst Rating for {selectedSymbol} (BETA)
                            </Typography>
                    }
                />
                <LoadSummaryContent />
            </CmtAdvCard>
        </Grid>
    );
}