import React, {useEffect} from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtAdvCardContent from '@coremat/CmtAdvCard/CmtAdvCardContent';
import CmtAdvCard from '@coremat/CmtAdvCard';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import MostActiveSummaryWidgetRow from './MostActiveSummaryWidgetRow';

const useStyles = makeStyles(theme => ({
  cardContentRoot: {
    '& .MuiGrid-container': {
      alignItems: 'center',
    },
  },
  cardContentTitle: {
    marginBottom: 4,
  },
  subTitleRoot: {
    fontSize: 12,
    marginBottom: 0,
    color: theme.palette.text.secondary,
  },
  tooltip: {
    position: 'relative',
    borderRadius: 6,
    padding: '4px 8px',
    backgroundColor: '#8d46ef',
    color: theme.palette.common.white,
  },
  tableRowCellRoot: {
    fontSize: 12,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 16,
    letterSpacing: 0.4,
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      paddingRight: 24,
    },
  },
}));

export default function MostActiveSummaryWidget(props) {
  const classes = useStyles();
  const [mostActiveHidden, setMostActiveHidden] = React.useState(false);
  const [mostActivePageSize, setMostActivePageSize] = React.useState(10);
  const [symbolsMostActive, setSymbolsMostActive] = React.useState([]);

  useEffect(() => {
    if(!mostActiveHidden){
      const summaryWorker = new Worker('/WebWorkers/summary.worker.js');
      
      summaryWorker.postMessage({optionsData: props.optionsData, groupBy: props.groupBy, pageSize: mostActivePageSize, type: 'mostactive'});

      summaryWorker.onmessage = event => {
        setSymbolsMostActive(event.data);
      }

      return () => {
        summaryWorker.terminate();
      }
    }
  }, [props.optionsData, props.groupBy, mostActivePageSize, mostActiveHidden])

  if (!mostActiveHidden) {
  const onMostActivePageSizeChange = event => {
    setMostActivePageSize(event.target.value);
  };

  const mostActiveActionHandler = event => {
    switch (event.value) {
      case 'closePremium': {
        return setMostActiveHidden(true);
      }
      default:
        return true;
    }
  };

    return (
      <Grid item  xs={12} sm={12} md={6}>
        <CmtAdvCard>
          <CmtCardHeader
            className="pt-2 pb-2"
            actionsPos="top-corner"
            actions={[{ label: 'Close', value: 'closePremium' }]}
            actionHandler={mostActiveActionHandler}
            title={
              <Typography
                {...{
                  variant: 'h4',
                  component: 'div',
                }}>
                Most Active (Top &nbsp;
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={mostActivePageSize}
                  onChange={onMostActivePageSizeChange}>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
                )
              </Typography>
            }
          />
          <CmtAdvCardContent className={classes.cardContentRoot}>
            <Box className="Cmt-table-responsive">
              <Table>
                <TableHead>
                  <TableRow>
                    {props.groupBy !== 'StrikePrice' &&  <TableCell className={classes.tableRowCellRoot}>Symbol</TableCell>}
                    {props.groupBy === 'StrikePrice' && <TableCell className={classes.tableRowCellRoot}>Strike</TableCell>}
                    <TableCell className={classes.tableRowCellRoot}>Calls/Puts</TableCell>
                    <TableCell className={classes.tableRowCellRoot}>Orders</TableCell>
                    <TableCell className={classes.tableRowCellRoot}>Calls/Puts</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {symbolsMostActive.map((row, index) => (
                    <MostActiveSummaryWidgetRow row={row} key={"MostActive_Div_" + index} groupBy={props.groupBy} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </CmtAdvCardContent>
        </CmtAdvCard>
      </Grid>
    );
  } else {
    return '';
  }
}
