import React, { forwardRef, useEffect } from 'react';
import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { lighten } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as FirestoreService from '../../../services/auth/firebase/FirebaseRealTimeDb';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import * as firebaseFunctions from '../../../services/firebase/functions';
import { fetchError, fetchSuccess } from '../../../redux/actions';
import { useDispatch } from 'react-redux';
import * as staticFunctions from '../../Pages/Dashboard/dashboardFunctions';
import { getDoc } from 'firebase/firestore';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  MuiFormControlRoot: {
    width: '100%',
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function Watchlist() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [users, setUsers] = React.useState([]);

  useEffect(() => {
    FirestoreService.getAllUsers(async docs => {
      var data = [];
      debugger;

      var index = 0;
      var usersLoop = new Promise((resolve, reject) => {
        docs.forEach(async doc => {
          var userData = doc.data();
          if (userData.UserId) {
            var subscriptions = await FirestoreService.getUserSubscription(userData.UserId);

            if (subscriptions.docs.length > 0) {
              var subscriptionDocData = subscriptions.docs[0].data();
              if (subscriptionDocData.product) {
                var product = (await getDoc(subscriptionDocData.product)).data();

                userData.PlanName = product.name;
                userData.PlanStatus = subscriptionDocData.status;
                userData.PlanTrialEndDate = subscriptionDocData.trial_end;
                userData.PlanEndDate = subscriptionDocData.current_period_end;
              }
            }
          }

          data.push(userData);
          if (index === docs.docs.length - 1) resolve();
          index++;
        });
      });

      usersLoop.then(() => {
        setUsers(data);
      });
    });
  }, []);

  const columns = [
    {
      title: 'Name',
      field: 'DisplayName',
      editable: 'never',
    },
    {
      title: 'Email',
      field: 'email',
      editable: 'never',
    },
    {
      title: 'Created Date',
      field: 'InsertDate',
      editable: 'never',
      defaultSort: 'desc',
      render: rowData => <span>{staticFunctions.getEmptyDateFormatted(rowData.InsertDate)}</span>,
    },
    {
      title: 'Last Activity',
      field: 'lastActivityDateTime',
      editable: 'never',
      defaultSort: 'desc',
      render: rowData => <span>{staticFunctions.getEmptyDateTimeFormatted(rowData.lastActivityDateTime)}</span>,
    },
    {
      title: 'Ref By',
      field: 'ReferralCode',
      editable: 'never',
      render: rowData => {
        var referralUser = users.filter(f => f.UserId === rowData.ReferralCode);
        var userName = '';
        if (referralUser.length > 0) {
          userName = referralUser[0].DisplayName;
        }
        return <span>{userName}</span>;
      },
    },
    {
      title: 'Plan Name',
      field: 'PlanName',
      editable: 'never',
    },
    {
      title: 'Plan Status',
      field: 'PlanStatus',
      editable: 'never',
      render: rowData => <span style={{ textTransform: 'capitalize' }}>{rowData.PlanStatus}</span>,
    },
    {
      title: 'Trial End Date',
      field: 'PlanTrialEndDate',
      editable: 'never',
      render: rowData => <span>{staticFunctions.getEmptyDateFormatted(rowData.PlanTrialEndDate?.toDate())}</span>,
    },
    {
      title: 'Plan End Date',
      field: 'PlanEndDate',
      editable: 'never',
      render: rowData => <span>{staticFunctions.getEmptyDateFormatted(rowData.PlanEndDate?.toDate())}</span>,
    },
    {
      title: 'Approved',
      field: 'IsApproved',
      lookup: { true: 'Yes', false: 'No' },
      render: rowData => (rowData.IsApproved ? <span>Yes</span> : <span>No</span>),
      editComponent: props => (
        <Select value={props.value} onChange={e => props.onChange(e.target.value)} style={{ width: '100%' }}>
          <MenuItem value={true}>Yes</MenuItem>
          <MenuItem value={false}>No</MenuItem>
        </Select>
      ),
    },
  ];

  return (
    <MaterialTable
      className={classes.root}
      icons={tableIcons}
      title="Users"
      columns={columns}
      options={{
        pageSize: 10,
        pageSizeOptions: [10, 20, 50],
        actionsColumnIndex: -1,
      }}
      data={users}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            resolve();
            if (oldData) {
              FirestoreService.updateUserData(newData);
              if (newData.IsApproved === true && newData.IsApproved !== oldData.IsApproved) {
                //FirestoreService.SendEmail(newData.email, "IsApprovedTemplate", {name: newData.DisplayName});
                firebaseFunctions.callfunction(
                  'sendEmail',
                  {
                    toEmail: newData.email,
                    templateId: 'd-0f1d0494d5214a0887099f1f135ab8d2',
                    templateData: {
                      name: newData.DisplayName,
                    },
                  },
                  function(res) {
                    if (res.data.status === 1) {
                      dispatch(fetchSuccess(res.data.msg));
                    } else {
                      dispatch(fetchError(res.data.msg));
                    }
                  },
                );
              }
              const dataUpdate = [...users];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;
              setUsers([...dataUpdate]);
            }
          }),
      }}
    />
  );
}
