import React, { useEffect } from 'react';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as appConstants from "../../../Constants/AppConstants";
import * as d3 from "d3";
import * as _ from 'lodash';
import * as staticFunctions from '../Dashboard/dashboardFunctions';

const useStyles = makeStyles(() => ({
    bioTitle: {
        marginBottom: 16,
    },
}));

export default function Privacy() {
    const classes = useStyles();
    const { userWatchlists, optionsData, selectedDate, userSubscription } = useSelector(({ firebaseStore }) => firebaseStore);
    const [sectorHeatmapData, setSectorHeatmapData] = React.useState({ children: [] });

    useEffect(() => {
        let isMounted = true;
        if (isMounted === true) {
            var filteredRecords = optionsData.filter(f => f.sector != undefined && f.sector != '' && f.premiumOrg > 150000);

            var groupBySector = _.chain(filteredRecords)
                .groupBy(item => item.sector)
                .map((objs, key) => {
                    return {
                        name: key,
                        children: _.chain(objs).groupBy(g => g.symbol)
                            .map((symbolData, key) => {
                                return {
                                    name: key,
                                    value: _.sumBy(symbolData, (obj) => obj.premiumOrg),
                                    premiumBullish: _.sumBy(symbolData.filter(f => f.sentiment.toUpperCase() == 'BULLISH'), (obj) => obj.premiumOrg),
                                    premiumBearish: _.sumBy(symbolData.filter(f => f.sentiment.toUpperCase() == 'BEARISH'), (obj) => obj.premiumOrg),
                                }
                            }).value()
                    };
                })
                .value();

            setSectorHeatmapData({ children: groupBySector });
            console.log(groupBySector);
        }

        return () => {
            isMounted = false;
        };
    }, [optionsData]);

    const dividNoHash = "my_dataviz";
    const divid = "#" + dividNoHash;

    useEffect(() => {
        if (sectorHeatmapData.children.length > 0) {

            document.getElementById(dividNoHash).innerHTML = '';

            var margin = { top: 10, right: 10, bottom: 10, left: 10 },
                width = document.getElementById(dividNoHash).offsetWidth - margin.left - margin.right,
                height = 900 - margin.top - margin.bottom;

            width = document.getElementById(dividNoHash).offsetWidth / 1.01;
            var svg = d3
                .select(divid)
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            var root = d3.hierarchy(sectorHeatmapData).sum(function (d) {
                return d.value;
            });

            d3
                .treemap()
                .size([width, height])
                .paddingTop(28)
                .paddingRight(7)
                .paddingInner(3)(
                    root
                );


            // And a opacity scale
            var opacity = d3.scaleLinear().domain([10, 30]).range([0.7, 1]);

            svg.selectAll("rect")
                .data(root.leaves())
                .enter()
                .append("rect")
                .attr("x", function (d) {
                    return d.x0;
                })
                .attr("y", function (d) {
                    return d.y0;
                })
                .attr("width", function (d) {
                    return d.x1 - d.x0;
                })
                .attr("height", function (d) {
                    return d.y1 - d.y0;
                })
                .attr("text-anchor", "middle")
                .style("fill", function (d) {
                    var data = d.data;
                    if (data.premiumBullish > data.premiumBearish) {
                        return "rgb(0, 179, 153)";
                    }
                    else {
                        return "rgb(244, 67, 54)";
                    }
                })
                .attr("opacity", d => {
                    var data = d.data;
                    if (data.premiumBullish > data.premiumBearish) {
                        let min = d3.min(root.leaves().map(leaf => leaf.data.premiumBullish))
                        let max = d3.max(root.leaves().map(leaf => leaf.data.premiumBullish))
                        return Math.max(0.4, (d.data.premiumBullish - min) / (max - min));
                    }
                    else {
                        let min = d3.min(root.leaves().map(leaf => leaf.data.premiumBearish))
                        let max = d3.max(root.leaves().map(leaf => leaf.data.premiumBearish))
                        return Math.max(0.4, (d.data.premiumBearish - min) / (max - min))
                    }
                });

            svg.selectAll("text")
                .data(root.leaves())
                .enter()
                .append("text")
                .attr("x", function (d) {
                    return d.x0 + getXOffset(d);
                })
                .attr("y", function (d) {
                    return d.y0 + getYOffset(d);
                })
                .text(function (d) {
                    return d.data.name;
                })
                .style("font-weight", 800)
                .attr("font-size", d => { return ((d.x1 - d.x0) + (d.y1 - d.y0)) / 16; })
                .attr("fill", "white");

            svg.selectAll("vals")
                .data(root.leaves())
                .enter()
                .append("text")
                .attr("x", function (d) {
                    return d.x0 + getXOffsetEnd(d);
                })
                .attr("y", function (d) {
                    return d.y0 + (getYOffset(d) * 1.7);
                })
                .text(function (d) {
                    return staticFunctions.nFormatterNoMulitply(d.data.value, 1);
                })
                .style("font-weight", 400)
                .attr("font-size", d => { return calculate_font_size_static(d); })
                .attr("fill", "white");

            svg.selectAll("titles")
                .data(
                    root.descendants().filter(function (d) {
                        return d.depth == 1;
                    })
                )
                .enter()
                .append("text")
                .attr("x", function (d) {
                    return d.x0;
                })
                .attr("y", function (d) {
                    return d.y0 + 21;
                })
                .text(function (d) {
                    return d.data.name;
                })
                .style("font-weight", 600)
                .attr("font-size", "16px")
                .attr("fill", function (d) {
                    return "#ffffff";
                });

            function getYOffset(d) {
                var scale = (d.y1 - d.y0) + (d.x1 - d.x0);
                return scale / 10;
            }

            function getXOffset(d) {
                var scale = (d.y1 - d.y0) + (d.x1 - d.x0);
                return scale / 50;
            }

            function getXOffsetEnd(d) {
                var scale = (d.y1 - d.y0) + (d.x1 - d.x0);
                return scale / 50;
            }

            function calculate_font_size_static(d) {
                let default_size = ((d.x1 - d.x0) + (d.y1 - d.y0)) / 20;
                if (default_size < 8) {
                    return 0;
                }
                else {
                    return Math.min(15, default_size);
                }
            }
        }
    }, [sectorHeatmapData]);


    return (
        <CmtCard>
            <CmtCardHeader title="Sector Heatmap" />
            <CmtCardContent>
                <div id="my_dataviz"></div>
            </CmtCardContent>
        </CmtCard>
    );
}
