import React from 'react';

import { PostAdd } from '@material-ui/icons';
import CmtHorizontal from '../../../../../@coremat/CmtNavigation/Horizontal';
import IntlMessages from '../../../../utils/IntlMessages';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

const HeaderMenu = () => {
  const navigationMenus = [
    // {
    //   name: 'Pricing',
    //   type: 'collapse',
    //   children: [
    //     {
    //       name: 'Plans',
    //       icon: <ShoppingBasketIcon />,
    //       type: 'item',
    //       link: '/plans',
    //     },
    //   ],
    // },
  ];

  return <CmtHorizontal menuItems={navigationMenus} />;
};

export default HeaderMenu;
