import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../utils/IntlMessages';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import {
  Box,
  Divider,
  Grid,
  fade,
  List,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  CircularProgress,
  GridListTileBar,
} from '@material-ui/core';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import { auth, firebase } from '../../../../services/configs/config';
import SendIcon from '@material-ui/icons/Send';
import { PhoneAndroid } from '@material-ui/icons';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { fetchError } from 'redux/actions';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import MuiPhoneNumber from 'material-ui-phone-number';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
const phone = require('phone');

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    // '& .MuiOutlinedInput-notchedOutline': {
    //   borderColor: fade(theme.palette.common.dark, 0.12),
    // },
    width: '100%',
  },
  divider: {
    height: 2,
    backgroundColor: '#fff',
    width: '100%',
    marginTop: 8,
    marginBottom: 8,
  },
}));
//variant = 'default', 'standard', 'bgColor'
const ForgotPassword = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [email, setEmail] = useState('');
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const dispatch = useDispatch();
  let history = useHistory();

  const classes = useStyles({ variant });
  const [confirm, setConfirm] = React.useState('');

  const [otp, setOtp] = React.useState('');
  const [timer, setTimer] = React.useState(30);
  const [phoneNo, setPhoneNo] = React.useState({
    phone: '',
    isValid: false,
  });
  const [loading, setLoading] = React.useState(false);
  const captchaRef = React.useRef(null);

  const { authUser } = useSelector(({ auth }) => auth);
  const { userDetails } = authUser || {};

  function verifyPhone() {
    //for testing
    const testPhn = phoneNo.phone;

    console.log('test ', testPhn);

    const reCaptchaVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, testPhn, reCaptchaVerifier)
      .then(result => {
        if (timer === 0) {
          setTimer(30);
          // reCaptchaVerifier.clear();
        }
        setConfirm(result);
        console.log('result ', result);
        dispatch(fetchError('Verification code sent to phone ' + phoneNo.phone));
      })
      .catch(error => {
        // reCaptchaVerifier.clear();
        console.error('err ', error);
        if (error.code === 'auth/invalid-phone-number') {
          // alert('Invalid phone number.');
          dispatch(fetchError(`Invalid phone number.`));
        }
        setLoading(false);
        setPhoneNo({
          phone: '',
          isValid: false,
        });
      });
  }

  const onSubmit = () => {
    dispatch(AuhMethods[method].onForgotPassword({ email }));
  };
  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(captchaRef.current, {
      size: 'invisible',
      callback: function (response) {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
      'expired-callback': function () {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
      },
    }, auth);
    window.recaptchaVerifier.render().then(function(widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
  }, []);

  // useEffect(() => {
  // var phoneReg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  // if (phoneNo.phone && phoneReg.test(phoneNo.phone)) {
  //   setPhoneNo(val => ({
  //     phone: val.phone,
  //     isValid: true,
  //   }));
  // }
  // console.log('Is valid ', phoneNo.phone, phone(phoneNo.phone));
  // }, [phoneNo.phone]);

  useEffect(() => {
    if (confirm && timer > 0) {
      const tim = setTimeout(() => {
        setTimer(val => val - 1);
      }, 1000);
      return () => {
        clearTimeout(tim);
      };
    } else if (timer === 0) {
      setOtp('');
    }
  }, [timer, confirm]);

  // useEffect(() => {
  //   if () {

  //   }
  // }, []);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const handleVerifyOtp = () => {
    confirm
      .confirm(otp)
      .then(ree => {
        if (ree && ree.user) {
          console.log('res', ree);
          dispatch(fetchError(`Logged in.`));
          dispatch(AuhMethods[CurrentAuthMethod].getAuthUser(true));
          history.push('/dashboard');
        }
      })
      .catch(error => {
        console.log('err ', error);
        if (error.code === 'auth/invalid-verification-code') {
          dispatch(fetchError(`Oops ! Invalid OTP.Try again.`));
        } else if ('auth/credential-already-in-use') {
          dispatch(fetchError(`This Phone number is already in use.`));
        }
      });
  };

  const handleBack = () => {
    setConfirm(null);
    setPhoneNo({
      phone: '',
      isValid: false,
    });
    setTimer(30);
  };

  const handleVerifyPhone = () => {
    setOtp('');
    verifyPhone();
  };
  const handlePhoneNo = val => {
    if (phone(val).length > 0) {
      setPhoneNo({
        phone: val,
        isValid: true,
      });
    } else {
      setPhoneNo({
        phone: val,
        isValid: false,
      });
    }
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={'/images/logo.png'} />
        </Box>

        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Forgot Password
        </Typography>

        <Grid container>
          <Grid item xs={12}>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem button onClick={event => handleListItemClick(event, 0)} selected={selectedIndex === 0}>
                <ListItemIcon>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText primary="Reset by email" />
              </ListItem>
              <Divider />
              <ListItem button onClick={event => handleListItemClick(event, 1)} selected={selectedIndex === 1}>
                <ListItemIcon>
                  <PhoneAndroid />
                </ListItemIcon>
                <ListItemText primary="Reset by phone" />
              </ListItem>
            </List>
          </Grid>
          {/* <hr className={classes.divider} /> */}
          <Grid container spacing={2} style={{ border: '1px solid #333131', borderRadius: 8, padding: 8 }}>
            <Grid item xs={12}>
              {selectedIndex === 0 ? (
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label={'Please enter email here'}
                        fullWidth
                        autoFocus
                        onChange={event => setEmail(event.target.value)}
                        defaultValue={email}
                        name="email"
                        margin="normal"
                        variant="standard"
                        className={classes.textFieldRoot}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button onClick={onSubmit} variant="contained" color="primary">
                        <IntlMessages id="appModule.resetPassword" />
                      </Button>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography>
                        Don't remember your email?
                        <Box component="span" ml={2}>
                          <Link href="#">
                            <IntlMessages id="appModule.contactSupport" />
                          </Link>
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              ) : (
                <Grid item xs={12}>
                  <ValidatorForm onSubmit={onSubmit} onError={errors => console.log(errors)}>
                    {confirm ? (
                      <Grid item>
                        <TextField
                          className={classes.textFieldRoot}
                          autoFocus
                          margin="normal"
                          id="otp"
                          label="Enter verification code"
                          type="text"
                          variant="standard"
                          fullWidth
                          onChange={e => setOtp(e.target.value)}
                          value={otp}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                {timer > 0 ? (
                                  <h4> {timer} s</h4>
                                ) : (
                                  <Button id="verify-phone" onClick={handleVerifyPhone}>
                                    Resend
                                  </Button>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item>
                        <MuiPhoneNumber
                          margin="normal"
                          autoFocus
                          disableAreaCodes={true}
                          label="Please enter mobile number"
                          fullWidth
                          defaultCountry={'us'}
                          onChange={handlePhoneNo}
                          className={classes.textFieldRoot}
                        />
                      </Grid>
                    )}

                    <Box
                      display="flex"
                      flexDirection={{ xs: 'column', sm: 'row' }}
                      alignItems={{ sm: 'center' }}
                      justifyContent={{ sm: 'space-between' }}
                      mb={3}
                      style={{ marginTop: 16 }}>
                      <Box mb={{ xs: 2, sm: 0 }}>
                        {!confirm ? (
                          <Fragment>
                            {!loading ? (
                              <Button
                                onClick={handleVerifyPhone}
                                id="verify-phone"
                                variant="contained"
                                color="primary"
                                disabled={!phoneNo.isValid}>
                                Send verification code
                              </Button>
                            ) : (
                              <CircularProgress size={24} />
                            )}
                          </Fragment>
                        ) : (
                          <div>
                            <Button onClick={handleVerifyOtp} color="primary" variant="contained" disabled={otp === ''}>
                              Confirm
                            </Button>
                            <Button
                              style={{ marginLeft: 8 }}
                              variant="contained"
                              color="primary"
                              size="medium"
                              startIcon={<KeyboardBackspaceIcon />}
                              onClick={handleBack}>
                              Change phone no
                            </Button>
                          </div>
                        )}
                      </Box>
                    </Box>
                  </ValidatorForm>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <ContentLoader />
        <div id="recaptcha-container" ref={ref => (captchaRef.current = ref)} />
      </Box>
    </AuthWrapper>
  );
};

export default ForgotPassword;
