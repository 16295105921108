import React, { forwardRef, useEffect } from 'react';
import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { lighten } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import * as FirestoreService from '../../../services/auth/firebase/FirebaseRealTimeDb';
import * as staticFunctions from '../../Pages/Dashboard/dashboardFunctions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function StockTwitFilters() {
  const classes = useStyles();
  const [StockTwitFilters, setStockTwitFilters] = React.useState([]);

  useEffect(() => {
    const ref = FirestoreService.getStockTwitFilters(querySnapshot => {
      var data = [];
      querySnapshot.forEach(function(childSnapshot) {
        var childData = childSnapshot.data();
        childData.id = childSnapshot.id;
        data.push(childData);
      });
      setStockTwitFilters(data);
    });

    if(ref != null){
      return () => {
        ref();
      }
    }
  }, []);

  const columns = [
    {
      title: 'Title',
      field: 'Title',
      validate: rowData => {
        if (rowData.Title === undefined) return;

        var validation = rowData.Title !== '' && rowData.Title.length <= 50;
        if (validation === true) {
          const isRecordFound = StockTwitFilters.some(
            el => el.id !== rowData.id && el.Title.toLowerCase() === rowData.Title.toLowerCase(),
          );
          if (isRecordFound === true) {
            return { isValid: false, helperText: 'There is already a StockTwit Update Filter with same name!' };
          }
          return true;
        } else {
          return { isValid: false, helperText: 'Title must be greater than 1 character and less than 50 characters!' };
        }
      },
    },
    {
      title: 'Notify Symbols',
      field: 'NotifySymbols',
      validate: rowData => {
        if (rowData.NotifySymbols === undefined) return;

        var validation = rowData.NotifySymbols !== '';
        if (validation === true) {
          var regex = new RegExp(/^[a-zA-Z0-9,*]+$/);
          if (regex.test(rowData.NotifySymbols) === false) {
            return {
              isValid: false,
              helperText:
                'Special Characters and spaces not allowed in Symbols! Use star(*) for all symbols and use comma(",") to separate multiple symbols.',
            };
          }
        }

        return validation === false ? { isValid: false, helperText: 'Symbols Field is required! Use star(*) for all symbols and use comma(",") to separate multiple symbols.' } : true;
      },
    },
    {
      title: 'Filters',
      field: 'Filters',
      render: rowData => {
        var filterString = [];
        if (rowData.Filters) {
          var filters = rowData.Filters.split(',');
          if (filters.length > 0) {
            var filtersAll = staticFunctions.filtersAlertDdlData;
            filters.forEach(filterData => {
              if(filtersAll.filter(f => f.value === filterData)[0]){
                filterString.push(filtersAll.filter(f => f.value === filterData)[0].name);
              }
            });
          }
        }
        return filterString.join(',');
      },
      editComponent: props => {
        var selected = props.value;
        if(selected === undefined || selected === ""){
          selected = [];
        }
        else if (typeof(props.value) === "object"){
          selected = props.value;
        }
        else {
          selected = props.value.split(',').map(m => {
            var selectedData = staticFunctions.filtersAlertDdlData.filter(f => f.value === m);
            if(selectedData.length > 0){
              return selectedData[0];
            }
          });
        }

        selected = selected.filter(f => f != undefined);

        return (<Autocomplete
          multiple
          size="small"
          value={selected}
          onChange={(event, newValue) => {
            props.onChange(newValue);
          }}
          options={staticFunctions.filtersAlertDdlData}
          getOptionLabel={option => option.name}
          filterSelectedOptions
          renderInput={params => <TextField {...params} label="Filters" />}
        />);
      }
    },
    {
      title: 'Premium',
      field: 'Premium',
      editComponent: props => (
        <div>
          <input
            value={props.value ?? ""}
            type="text"
            placeholder="Premium"
            className="form-control input-sm"
            onChange={e => props.onChange(e.target.value)}
          />
          <span>Range (x-y), Greater Than ({'>'}x), Less Than ({'<'}y) (In Thousands)</span>
        </div>
      )
    },
    {
      title: 'Strike Price',
      field: 'StrikePrice',
      editComponent: props => (
        <div>
          <input
            value={props.value ?? ""}
            type="text"
            placeholder="Strike Price"
            className="form-control input-sm"
            onChange={e => props.onChange(e.target.value)}
          />
          <span>Range (x-y), Greater Than ({'>'}x), Less Than ({'<'}y)</span>
        </div>
      )
    },
    {
      title: 'DTE',
      field: 'Dte',
      editComponent: props => (
        <div>
          <input
            value={props.value ?? ""}
            type="text"
            placeholder="Days To Expiration"
            className="form-control input-sm"
            onChange={e => props.onChange(e.target.value)}
          />
            <span>Range (x-y), Greater Than ({'>'}x), Less Than ({'<'}y)</span>
        </div>
      )
    },
    {
      title: 'Template',
      field: 'Template',
      editComponent: props => (
        <div>
          <textarea
            value={props.value ?? staticFunctions.getDefaultTemplateText()}
            style={{ height: '200px' }}
            placeholder="Template Text"
            className="form-control input-sm"
            onChange={e => props.onChange(e.target.value)}
          />
          <span>Add Template Text, if empty, then default template will be used.</span>
        </div>
      )
    }
  ];

  return (
    <MaterialTable
      className={classes.root}
      icons={tableIcons}
      title="StockTwit Updates Filters"
      columns={columns}
      options={{
        pageSize: 10,
        pageSizeOptions: [10, 20, 50],
        actionsColumnIndex: -1,
      }}
      data={StockTwitFilters}
      editable={{
        onRowAdd: newData =>
          new Promise(resolve => {
            resolve();
            var data = newData;
            if (data.Filters && typeof(data.Filters) === "object") {
              data.Filters = data.Filters.map(m => m.value).join(',');
            }
            else if(data.Filters === undefined){
              data.Filters = "";
            }

            if(data.Template === '' || data.Template === undefined || data.Template === null){
              data.Template = staticFunctions.getDefaultTemplateText();
            }

            FirestoreService.addUpdateStockTwitFilters(data);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            resolve();
            if (oldData) {
              var data = newData;
              if (data.Filters && typeof(data.Filters) === "object") {
                data.Filters = data.Filters.map(m => m.value).join(',');
              }
              else if(data.Filters === undefined){
                data.Filters = "";
              }

              if(data.Template === '' || data.Template === undefined || data.Template === null){
                data.Template = staticFunctions.getDefaultTemplateText();
              }

              FirestoreService.addUpdateStockTwitFilters(data);
            }
          }),
        onRowDelete: oldData =>
          new Promise(resolve => {
            resolve();
            FirestoreService.deleteStockTwitFilter(oldData);
          }),
      }}
    />
  );
}
