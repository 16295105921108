import { httpsCallable } from 'firebase/functions';
import { firebase, functions } from '../configs/config';

export const FreePlanListId = "0e3121f1-7c14-4ea6-9fe8-d8f4b71a9d18";
export const SignupPopupListId = "5c5a8d1b-ae46-4664-b12a-50827f526c95";

export const callfunction = (functionName, data, observer) => {

  const callableReturnMessage = httpsCallable(functions, functionName);
  callableReturnMessage(data).then(observer)
    .catch((error) => {
      console.log(`error: ${JSON.stringify(error)}`);
    });
}

export async function callfunctionAsync(functionName, data) {
  const callableReturnMessage = httpsCallable(functions, functionName);
  await callableReturnMessage(data);
}