import React from 'react';
import { Box, Button } from '@material-ui/core';
import Header from './Header';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Contact from './Contact';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSelector, useDispatch } from 'react-redux';

import CmtAdvCard from '@coremat/CmtAdvCard';
import CmtAdvCardContent from '@coremat/CmtAdvCard/CmtAdvCardContent';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtImage from '@coremat/CmtImage';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import Typography from '@material-ui/core/Typography';
import * as FirestoreService from '../../../services/auth/firebase/FirebaseRealTimeDb';
import { fetchStart, fetchSuccess } from '../../../redux/actions';
import { useHistory } from 'react-router-dom';

import './MyAccount.css';
import ReferCard from './ReferCard';

const useStyles = makeStyles(theme => ({
  pageFull: {
    width: '100%',
  },
  profileSidebar: {
    '@media screen and (min-width: 1280px) and (max-width: 1499px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
  profileMainContent: {
    '@media screen and (min-width: 1280px) and (max-width: 1499px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
}));

export default function MyAccount() {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const { userSubscription } = useSelector(({ firebaseStore }) => firebaseStore);
  const plan = userSubscription;

  const getContentTitle = planPrice => (
    <Box display="flex" alignItems="flex-end" mb={5}>
      <Box
        component="span"
        fontSize={{ xs: 36, md: 40, xl: 48 }}
        fontWeight="fontWeightBold"
        color="common.dark"
        lineHeight="1">
        ${planPrice.unit_amount}
      </Box>
      <Typography component="span" variant="subtitle1">
        /{planPrice.interval}
      </Typography>
    </Box>
  );

  function goToStripeBillingDashboard() {
    dispatch(fetchStart());
    FirestoreService.goToStripeBillingDashboard(data => {
      dispatch(fetchSuccess());
      window.location.assign(data.url);
    });
  }

  return (
    <React.Fragment>
      {authUser && (
        <Box className={classes.pageFull}>
          <Header classes={classes} userDetail={authUser} />
          <GridContainer>
            <Grid item xs={12} lg={6} className={classes.profileSidebar}>
              <Box mb={6}>
                <Contact userDetail={authUser} userSubscription={userSubscription} />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6} className={classes.profileMainContent}>
              <Box mb={6}>
                {plan !== null ? (
                  <CmtAdvCard className={classes.cardRoot}>
                    <CmtCardHeader
                      title={plan.planProduct.name}
                      titleProps={{
                        variant: 'h4',
                        component: 'div',
                      }}
                      subTitle={plan.planStatus}
                    />
                    <CmtAdvCardContent
                      style={{ textTransform: 'capitalize' }}
                      title={getContentTitle(plan.planPrice)}
                      subTitle={plan.planProduct.stripe_metadata_plan + ' Plan'}
                      subTitleProps={{
                        className: classes.subTitleContent,
                      }}
                      reverseDir>
                      <Box display="flex" flexDirection="column" alignItems="center" mt={{ sm: -7 }}>
                        <Box mb={{ xs: 3, md: 4 }}>
                          <CmtImage src={'/images/dashboard/current_plan.svg'} alt="plan" />
                        </Box>
                        <Button onClick={goToStripeBillingDashboard} variant="contained" color="primary">
                          Billing Dashboard
                        </Button>
                      </Box>
                    </CmtAdvCardContent>
                    {plan.PlanWillCancelDate && (
                      <CmtCardContent>
                        <Box component={'p'}>Your plan will be cancelled on {plan.PlanWillCancelDate}.</Box>
                      </CmtCardContent>
                    )}

                    {!plan.PlanWillCancelDate && plan.PlanEndDate && (
                      <CmtCardContent>
                        <Box component={'p'}>Your card will be charged on {plan.PlanEndDate}.</Box>
                      </CmtCardContent>
                    )}
                  </CmtAdvCard>
                ) : (
                  <CmtAdvCard className={classes.cardRoot}>
                    <CmtCardHeader
                      title={'Subscriptions'}
                      titleProps={{
                        variant: 'h4',
                        component: 'div',
                      }}
                    />
                    <CmtAdvCardContent
                      title={'No active subscription found. Please subscribe by clicking on the subscribe button.'}
                      reverseDir>
                      <Box display="flex" flexDirection="column" alignItems="center" mt={{ sm: -7 }}>
                        <Box mb={{ xs: 3, md: 4 }}>
                          <CmtImage src={'/images/dashboard/current_plan.svg'} alt="plan" />
                        </Box>
                        <Button onClick={() => history.push('/plans')} variant="contained" color="primary">
                          Subscribe Now
                        </Button>
                      </Box>
                    </CmtAdvCardContent>
                  </CmtAdvCard>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <ReferCard authUser={authUser} />
            </Grid>
          </GridContainer>
        </Box>
      )}
    </React.Fragment>
  );
}
