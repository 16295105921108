import React, { forwardRef, useEffect } from 'react';
import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { lighten } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import * as FirestoreService from '../../../services/auth/firebase/FirebaseRealTimeDb';
import * as staticFunctions from '../../Pages/Dashboard/dashboardFunctions';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function ManualNotifications() {
  const classes = useStyles();
  const [Notifications, setNotifications] = React.useState([]);

  useEffect(() => {
    const ref = FirestoreService.getManualNotifications(querySnapshot => {
      var data = [];
      querySnapshot.forEach(function(childSnapshot) {
        var childData = childSnapshot.data();
        childData.id = childSnapshot.id;
        data.push(childData);
      });
      setNotifications(data);
    });

    if(ref != null){
      return () => {
        ref();
      }
    }
  }, []);

  const columns = [
    {
      title: 'Title',
      field: 'Title',
      validate: rowData => {
        if (rowData.Title === undefined) return;

        var validation = rowData.Title !== '' && rowData.Title.length <= 50;
        if (validation === true) {
          return true;
        } else {
          return { isValid: false, helperText: 'Title field is required!' };
        }
      },
    },
    {
      title: 'Body',
      field: 'Body',
      editComponent: props => (
        <div>
          <textarea
            value={props.value ?? ''}
            placeholder="Body"
            className="form-control input-sm"
            onChange={e => props.onChange(e.target.value)}
          />
        </div>
      ),
      validate: rowData => {
        if (rowData.Body === undefined) return;

        var validation = rowData.Body !== '';
        if (validation === true) {
          return true;
        } else {
          return { isValid: false, helperText: 'Body field is required!' };
        }
      },
    },
    {
      title: 'Action Link',
      field: 'ActionLink'
    },
    {
      title: 'Sent To',
      field: 'SendTo',
      lookup: { 'all': 'All', 'paid': 'Paid', 'free': 'Free' },
      render: rowData => (<span style= {{ textTransform:"capitalize"}}>{rowData.SendTo}</span>),
      editComponent: props => (
        <Select value={props.value ?? 'all'} onChange={e => props.onChange(e.target.value)} style={{ width: '100%' }}>
          <MenuItem value={'all'}>All</MenuItem>
          <MenuItem value={'paid'}>Paid</MenuItem>
          <MenuItem value={'free'}>Free</MenuItem>
        </Select>
      ),
    },
    {
      title: 'Sent By',
      field: 'InsertUser',
      editable: 'never',
    },
    {
      title: 'Sent Date',
      field: 'InsertDate',
      editable: 'never',
      defaultSort: 'desc',
      render: rowData => <span>{staticFunctions.getEmptyDateTimeFormatted(rowData.InsertDate)}</span>,
    },
  ];

  return (
    <MaterialTable
      className={classes.root}
      icons={tableIcons}
      title="Manual Notifications"
      columns={columns}
      options={{
        pageSize: 10,
        pageSizeOptions: [10, 20, 50],
        actionsColumnIndex: -1,
      }}
      data={Notifications}
      editable={{
        onRowAdd: newData =>
          new Promise(resolve => {
            resolve();
            FirestoreService.addManualNotifications(newData);
          })
      }}
    />
  );
}
