import React, { useState, useEffect } from 'react';
import GridContainer from '@jumbo/components/GridContainer';
import PremiumSummaryWidget from '../Summary/PremiumSummaryWidget';
import MostActiveSummaryWidget from '../Summary/MostActiveSummaryWidget';
import RepeaterSummaryWidget from '../Summary/RepeaterSummaryWidget';
import UnusualSummaryWidget from '../Summary/UnusualSummaryWidget';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import CmtAdvCardContent from '@coremat/CmtAdvCard/CmtAdvCardContent';
import CmtAdvCard from '@coremat/CmtAdvCard';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import VolOverOISummaryWidget from '../Summary/VolOverOISummaryWidget';
import GoldenSweepSummaryWidget from '../Summary/GoldenSweepSummaryWidget';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import PriceLevelSummaryWidget from '../Summary/PriceLevelSummaryWidget';
import CallPutGraphWidget from '../Dashboard/CallPutGraphWidget';
import SentimentScoreWidget from '../Dashboard/SentimentScoreWidget';
import TadingViewTickerPriceWidget from './TadingViewTickerPriceWidget';
import SentimentWeeklySummary from './SentimentWeeklySummary';
import WhyIsItMovingSummary from './WhyIsItMovingSummary';
import * as FirestoreService from '../../../services/auth/firebase/FirebaseRealTimeDb';
import moment from 'moment-timezone';
import CallPutWeeklySummary from './CallPutWeeklySummary';
import AnalystRatingsSummary from './AnalystRatingsSummary';
import * as appConstants from '../../../Constants/AppConstants';

export default function Analysis() {
  const { optionsData, selectedDate, userSubscription } = useSelector(({ firebaseStore }) => firebaseStore);
  const [selectedSymbol, setSelectedSymbol] = useState('');
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [sentimentWeeklySummaryData, setSentimentWeeklySummaryData] = React.useState([]);

  useEffect(() => {
    if (selectedSymbol && userSubscription !== null && userSubscription.role === appConstants.Role_Customer) {
      FirestoreService.getWeeklySentimentSummary(selectedSymbol, selectedDate, function(snapshot) {
        var weeklyData = [];
        snapshot.docs.forEach(doc => {
          var docData = doc.data();
          weeklyData.push({
            Date: docData.RecordDate,
            Record: moment(docData.RecordDate).format('MM/DD/YY'),
            Bullish: docData.BullishPremium,
            Bearish: docData.BearishPremium,
            Calls: docData.CallsCount,
            Puts: docData.PutsCount,
          });
        });

        weeklyData = weeklyData.sort((a, b) => (a.Date > b.Date ? 1 : -1));
        setSentimentWeeklySummaryData(weeklyData);
      });
    } else {
      setSentimentWeeklySummaryData([]);
    }
    return () => {};
  }, [selectedSymbol, selectedDate]);

  useEffect(() => {
    setFilteredRows(optionsData.filter(f => f.symbol === selectedSymbol));
  }, [selectedSymbol, optionsData]);

  return (
    <GridContainer>
      <Grid item xs={12} sm={12}>
        <CmtAdvCard>
          <CmtAdvCardContent>
            <GridContainer>
              <Grid item xs={4} sm={6}>
                <Typography component="h1">Analysis</Typography>
              </Grid>
              <Grid item xs={8} sm={6}>
                <Autocomplete
                  options={[
                    ...new Set(
                      optionsData
                        .sort((a, b) => (a.symbol > b.symbol ? 1 : b.symbol > a.symbol ? -1 : 0))
                        .map(item => item.symbol),
                    ),
                  ]}
                  getOptionLabel={option => option}
                  style={{ width: '100%' }}
                  renderInput={params => <TextField {...params} label="Select a symbol to analyze" />}
                  onChange={(event, newValue) => {
                    setSelectedSymbol(newValue);
                  }}
                />
              </Grid>
            </GridContainer>
          </CmtAdvCardContent>
        </CmtAdvCard>
      </Grid>

      {selectedSymbol && <Grid item xs={12} sm={12} md={6}>
        <TadingViewTickerPriceWidget selectedSymbol={selectedSymbol} />
      </Grid>}

      <WhyIsItMovingSummary selectedSymbol={selectedSymbol} />

      <CallPutGraphWidget optionsData={filteredRows} />
      <SentimentScoreWidget optionsData={filteredRows} />
      <SentimentWeeklySummary sentimentWeeklySummaryData={sentimentWeeklySummaryData} userSubscription={userSubscription} />
      <CallPutWeeklySummary sentimentWeeklySummaryData={sentimentWeeklySummaryData} userSubscription={userSubscription} />
      <PremiumSummaryWidget optionsData={filteredRows} groupBy={'StrikePrice'} />
      <MostActiveSummaryWidget optionsData={filteredRows} groupBy={'StrikePrice'} />
      <RepeaterSummaryWidget optionsData={filteredRows} />
      <UnusualSummaryWidget optionsData={filteredRows} />
      <VolOverOISummaryWidget optionsData={filteredRows} />
      <GoldenSweepSummaryWidget optionsData={filteredRows} />

      {selectedSymbol && (
        <Grid item xs={12} md={6} style={{ height: '500px' }}>
          <TradingViewWidget
            symbol={selectedSymbol}
            theme={Themes.DARK}
            locale="en"
            autosize
            timezone="America/New_York"
            toolbar_bg="#f1f3f6"
            enable_publishing={false}
            hide_side_toolbar={true}
            hide_top_toolbar={true}
            allow_symbol_change={false}
            range="5d"
            interval={5}
            save_image={false}
          />
        </Grid>
      )}
      <AnalystRatingsSummary selectedSymbol={selectedSymbol} />
      <PriceLevelSummaryWidget selectedSymbol={selectedSymbol} selectedDate={selectedDate.StartDate} />
    </GridContainer>
  );
}
