import React, { forwardRef, useEffect } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { lighten, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as FirestoreService from '../../../services/auth/firebase/FirebaseRealTimeDb';
import { useSelector, useDispatch } from 'react-redux';
import GridContainer from '@jumbo/components/GridContainer';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Switch from '@material-ui/core/Switch';
import { useLocation } from 'react-router-dom';
import { SetDiscordTokens } from '../../../redux/actions/Firebase';
import AddIcon from '@material-ui/icons/Add';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { fetchError } from 'redux/actions';
import * as firebaseFunctions from '../../../services/firebase/functions';

const crypto = require('crypto');
const DiscordOauth2 = require("discord-oauth2");

const oauth = new DiscordOauth2({
    version: 'v9',
    clientId: process.env.REACT_APP_DISCORD_CLIENT_ID,
    redirectUri: window.location.origin + "/discord-callback"
});

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: lighten(theme.palette.background.paper, 0.1),
    },
}));

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const MySwal = withReactContent(Swal);

export default function DiscordServers() {
    const classes = useStyles();
    const dispatch = useDispatch();
    let query = useQuery();
    const { discordTokens } = useSelector(({ firebaseStore }) => firebaseStore);

    const [discordServers, setDiscordServers] = React.useState([]);
    const [activatedServersCount, setActivatedServersCount] = React.useState(0);
    const [activeServersLimit, setActiveServersLimit] = React.useState(0);
    const [refreshTokenExpired, setRefreshTokenExpired] = React.useState(false);

    function LinkDiscord() {
        const url = oauth.generateAuthUrl({
            state: crypto.randomBytes(16).toString("hex"),
            scope: ["identify", "email", "guilds"]
        });

        window.open(url,'targetWindow','toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=900');
    }

    function LinkDiscordWithConfirm(){
        MySwal.fire({
            title: <p>Are you sure, you want to switch your Discord login. Switching will disable the Bot from all active servers.</p>,
            showCancelButton: true,
            confirmButtonText: `Yes, Sure`,
            didOpen: () => {
              MySwal.clickConfirm()
            }
          }).then((res) => {
              if (res.isConfirmed === true) {
                  LinkDiscord();
              }
          })
    }

    function LinkDiscordBot(row) {
        const url = oauth.generateAuthUrl({
            state: crypto.randomBytes(16).toString("hex"),
            scope: ["bot", "applications.commands"],
            permissions: 294205451360,
            guildId: row.id
        });

        window.open(url,'targetWindow','toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=900');
    }

    function refreshDiscordToken() {
        try {
            firebaseFunctions.callfunction(
                'discord-refreshToken',
                {
                    refresh_token: discordTokens.refresh_token
                },
                function (res) {
                    if (res.data.status === 1) {
                        res.data.discordData.DiscordId = res.data.userData.id;

                        FirestoreService.addUpdateDiscordTokenData(res.data.discordData, function () {
                            dispatch(SetDiscordTokens(res.data.discordData));
                        });
                    } else {
                        setRefreshTokenExpired(true);
                    }
                },
            );
        }
        catch (err) {
            console.log(err);
            setRefreshTokenExpired(true);
        }
    }

    async function getActiveServerLimitCount(){
        setActiveServersLimit(await FirestoreService.getActiveServerLimit());
    }

    useEffect(() => {
        getActiveServerLimitCount();

        if (discordTokens.access_token) {
            oauth.getUserGuilds(discordTokens.access_token).then(async (guilds) => {
                var discordServers = await FirestoreService.getDiscordServers();

                //filtering if User has Admin Permission to Discord
                guilds = guilds.filter(f => (f.permissions & 0x8) == 0x8);
                var totalActivatedCount = 0;

                var forLoopPromise = new Promise((resolve, reject) => {
                    guilds.forEach(function (discordData, index) {
                        var allData = discordServers.docs.map(f => f.data());
                        var docData = allData.filter(f => f.ServerId === discordData.id)[0];
                        if (docData) {
                            discordData.IsActive = docData.IsActive ?? false;
                            if(discordData.IsActive === true){
                                totalActivatedCount++;
                            }
                        }
                        else {
                            discordData.IsActive = false;
                        }

                        if (index === guilds.length - 1) resolve();
                    });
                });

                forLoopPromise.then(() => {
                    setDiscordServers(guilds);
                    setActivatedServersCount(totalActivatedCount);
                });

                setRefreshTokenExpired(false);
            }).catch((err) => {
                console.log(err);
                refreshDiscordToken();
            });
        }

        return () => {}
    }, [discordTokens]);

    const columns = [
        {
            title: 'Id',
            field: 'id',
            editable: 'never'
        },
        {
            title: 'Name',
            field: 'name',
            editable: 'never'
        },
        {
            title: 'Active?',
            field: 'IsActive',
            render: rowData => {
                return <span>{rowData.IsActive === true || rowData.IsActive === 'true' ? <CheckIcon style={{color:'green'}} /> : <ClearIcon style={{color:'red'}}/> }</span>
            },
            editComponent: props => (
                <Switch
                    checked={props.value}
                    onChange={e => {
                        props.onChange(e.target.checked)
                    }}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              )
        }
    ];

    function LinkDiscordButton(props) {
        if (refreshTokenExpired || discordTokens === null || discordTokens.access_token === '' || discordTokens.access_token === undefined) {
            return ( <Button variant="contained" color="primary" style={{float:"right"}} onClick={() => LinkDiscord()}> Link Discord</Button>);
        }
        return (<Button variant="contained" color="primary" style={{float:"right"}} onClick={() => LinkDiscordWithConfirm()}>Switch Discord Account</Button>);
      }

    return (
        <GridContainer>
            <Grid item xs={6} sm={6} md={6}>
              License Status:- {activatedServersCount} Active out of {activeServersLimit} License
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
            <LinkDiscordButton />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
                <MaterialTable
                    className={classes.root}
                    icons={tableIcons}
                    title="Discord Servers"
                    columns={columns}
                    options={{
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],
                        actionsColumnIndex: -1,
                    }}
                    data={discordServers}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise(async (resolve) => {
                                if (oldData) {
                                    var serverCountExceeded = await FirestoreService.checkActiveServerCount(newData);
                                    if (serverCountExceeded) {
                                        dispatch(fetchError(`You have exceeded limit of enabling Servers for Market Action Commands. Please disable a server or contact Administrator to increase Server Limit!`));
                                        //sweetAlerts('error', "");

                                        resolve();
                                    }
                                    else {
                                        FirestoreService.updateDiscordServer(newData).then(function () {
                                            var totalActivatedCount = 0;
                                            discordServers.forEach(function (discordData) {
                                                if (discordData.id === newData.id) {
                                                    discordData.IsActive = newData.IsActive;
                                                }

                                                if(discordData.IsActive === true){
                                                    totalActivatedCount++;
                                                }
                                            });

                                            setDiscordServers(discordServers);
                                            setActivatedServersCount(totalActivatedCount);
                                            resolve();
                                        });
                                    }
                                }
                            })
                    }}
                    actions={[
                        {
                            icon: () => <AddIcon />,
                            tooltip: 'Add Bot',
                            onClick: (event, rowData) => {
                                LinkDiscordBot(rowData);
                            }
                        }
                    ]}
                />
            </Grid>
        </GridContainer>
    );
}
