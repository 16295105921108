import React from 'react';
import { fade, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles(theme => ({
  root: {
    backgroundColor: fade(theme.palette.common.dark, 0.05),
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
}))(MuiAccordionDetails);

export default function LearningCenterAccordionChild({rowChildren, index, childIndex}) {

    const [expanded, setExpanded] = React.useState('');

    const handleChange = panel => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    if(rowChildren.isParagraph === true) {
        if (rowChildren.childrens && rowChildren.childrens.length > 0) {
            return (
                <Box style={{marginBottom:"15px"}}>
                    {rowChildren.childrens.map(function (child, i) {
                        return (<Typography key={index + "_" + childIndex + "_" + i}>
                            {child}
                        </Typography>)
                })}
                </Box>
            )
        }
        return '';
    }

    return (
    <Accordion square expanded={expanded === 'panel_' + rowChildren.title + index + "_" + childIndex} onChange={handleChange('panel_' + rowChildren.title +  index + "_" + childIndex)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content">
            <Typography>{rowChildren.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography component="div">
                {rowChildren.detail}
            
            {rowChildren.childrens && rowChildren.childrens.length > 0 &&  rowChildren.childrens.map(function(child, i) {
                    return (<LearningCenterAccordionChild key={index + "_" + childIndex + "_" + i} rowChildren={child} index={index} childIndex={childIndex + "_" + i}></LearningCenterAccordionChild>)
                })}
            </Typography>
        </AccordionDetails>
    </Accordion>);
}