import React from 'react';
import { Box, fade, IconButton, makeStyles, Popover, Tooltip } from '@material-ui/core';
import MessageIcon from '@material-ui/icons/Message';
import clsx from 'clsx';
import WidgetBot from '@widgetbot/react-embed';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: 4,
      paddingBottom: 4,
    },
    '& .Cmt-card-content': {
      padding: '0 0 16px !important',
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
  iconRoot: {
    color: fade(theme.palette.common.white, 0.38),
    '&:hover, &:focus': {
      color: theme.palette.common.white,
    },
  },
  scrollbarRoot: {
    height: 300,
    padding: 16,
  },
  popoverRoot: {
    '& .MuiPopover-paper': {
      width: 375,
    },
  },
}));

function HeaderMessages() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onOpenPopOver = event => {
    setAnchorEl(event.currentTarget);
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <Tooltip title="Messages">
        <IconButton onClick={onOpenPopOver} className={clsx(classes.iconRoot, 'Cmt-appIcon')}>
          <MessageIcon />
        </IconButton>
      </Tooltip>
      <Popover
        className={classes.popoverRoot}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <iframe
          src="https://titanembeds.com/embed/799504384463077426?defaultchannel=799504384807272498&theme=DiscordDark"
          height="600"
          width="100%"
          frameBorder="0"></iframe>
        {/* <WidgetBot server="799504384463077426" channel="799504384807272498" height={600} style={{ width: '100%' }} /> */}
      </Popover>
    </Box>
  );
}

export default React.memo(HeaderMessages, () => true);
