import { database, auth, firestore, firebase, functions, functionsUsWest2 } from '../../configs/config';
import { ref, onValue, onChildAdded, query, orderByChild, startAt, endAt, limitToLast, off } from "firebase/database";
import { collection, where, onSnapshot, query as firestoreQuery, getDocs, getDoc, addDoc, orderBy, updateDoc, deleteDoc, writeBatch, setDoc, doc } from "firebase/firestore";
import { httpsCallable } from 'firebase/functions';

import moment from 'moment-timezone';

export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

//const optionActivityRef = ref(database, 'optionsActivityPayload');
const optionActivityRef = ref(database, 'optionsActivityPayload');
const optionsActivityFreeRef = ref(database, 'optionsActivityFree');

const MarketActionDbRef = collection(firestore, 'MarketActionDb');
const MarketActionUserConfigDbRef = collection(firestore, 'MarketActionUserConfigDb');

export const getPlans = observer => {
  return onSnapshot(firestoreQuery(collection(firestore, 'products'), where('active', '==', true)), observer);
};

export async function getPlansAsync() {
  return await getDocs(firestoreQuery(collection(firestore, 'products'), where('active', '==', true)));
}

export const getCustomerSubscription = observer => {
  if (auth.currentUser) {
    let customersRef = collection(firestore, 'customers');
    let loggedCustomerRef = doc(customersRef, auth.currentUser.uid);
    let loggedCustomerSubscriptionsRef = collection(loggedCustomerRef, 'subscriptions');
    return onSnapshot(firestoreQuery(loggedCustomerSubscriptionsRef, where('status', 'in', ['trialing', 'active'])), observer);
  } else {
    return null;
  }
};

export async function subscripeToPlan(pricePlanId, observer) {
  if (auth.currentUser) {
    let customersRef = collection(firestore, 'customers');
    let loggedCustomerRef = doc(customersRef, auth.currentUser.uid);
    let loggedCustomerSessionsRef = collection(loggedCustomerRef, 'checkout_sessions');

    const docRef = await addDoc(loggedCustomerSessionsRef, {
      price: pricePlanId,
      success_url: window.location.origin + '/discord-instructions',
      cancel_url: window.location.origin + '/my-account',
      allow_promotion_codes: true,
    });
    // Wait for the CheckoutSession to get attached by the extension
    onSnapshot(docRef, observer);
  }
}

export async function goToStripeBillingDashboard(callback) {

  const functionRef = httpsCallable(functionsUsWest2, 'ext-firestore-stripe-subscriptions-createPortalLink');
  const { data } = await functionRef({
    returnUrl: window.location.origin + '/my-account',
  });
  callback(data);
}

export const getOptionActivityDataByDate = (startDate, endDate, observer) => {
  onValue(query(optionActivityRef, orderByChild('InsertDate'), startAt(startDate), endAt(endDate)), observer);
  return optionActivityRef;
};

export const OptionActivityChildAddedByDate = (startDate, endDate, observer) => {
  onChildAdded(query(optionActivityRef, orderByChild('InsertDate'), startAt(startDate), endAt(endDate), limitToLast(1)), observer);
  return optionActivityRef;
};

export const getOptionActivityDataByDateFree = (startDate, endDate, observer) => {
  onValue(query(optionsActivityFreeRef, orderByChild('InsertDate'), startAt(startDate), endAt(endDate)), observer);
  return optionsActivityFreeRef;
};

export const OptionActivityChildAddedByDateFree = (startDate, endDate, observer) => {
  onChildAdded(query(optionsActivityFreeRef, orderByChild('InsertDate'), startAt(startDate), endAt(endDate), limitToLast(1)), observer);
  return optionsActivityFreeRef;

};

export const removeOptionActivityListeners = () => {
  off(optionActivityRef);
};

export const removeOptionActivityFreeListeners = () => {
  off(optionsActivityFreeRef);
};

export const getWatchlists = observer => {
  if (auth.currentUser) {
    const watchlistsRef = firestoreQuery(collection(doc(MarketActionDbRef, auth.currentUser.uid), 'watchlists'), orderBy('InsertDate', 'desc'));
    return onSnapshot(watchlistsRef, observer);
  }
  return null;
};

export async function getWatchlistsAsync() {
  if (auth.currentUser) {
    return await getDocs(firestoreQuery(collection(doc(MarketActionDbRef, auth.currentUser.uid), 'watchlists'), orderBy('InsertDate', 'desc')));
  }
  return null;
}

export const addUpdateWatchlist = watchlistData => {
  if (auth.currentUser) {
    const watchlistsRef = collection(doc(MarketActionDbRef, auth.currentUser.uid), 'watchlists');
    var userId = auth.currentUser.uid;
    if (watchlistData.id && watchlistData.id !== '') {
      updateDoc(doc(watchlistsRef, watchlistData.id), {
        UserId: userId,
        Title: watchlistData.Title,
        Symbols: watchlistData.Symbols,
        ModifiedDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
      });
    } else {
      addDoc(watchlistsRef, {
        UserId: userId,
        Title: watchlistData.Title,
        Symbols: watchlistData.Symbols,
        InsertDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
        ModifiedDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
      });
    }
  }
};

export const deleteWatchlist = watchlistData => {
  if (auth.currentUser) {
    const watchlistsRef = collection(doc(MarketActionDbRef, auth.currentUser.uid), 'watchlists');
    if (watchlistData.id !== '') {
      deleteDoc(doc(watchlistsRef, watchlistData.id));
    }
  }
};

export const removeWatchlistsListeners = () => {
  //watchlistsRef.off("value");
};

export const getAlerts = observer => {
  if (auth.currentUser) {
    return onSnapshot(firestoreQuery(collection(doc(MarketActionDbRef, auth.currentUser.uid), 'notifications'), orderBy('InsertDate', 'desc')), observer);
  }
  return null;
};

export const addUpdateAlert = alertData => {
  if (auth.currentUser) {
    const alertRef = collection(doc(MarketActionDbRef, auth.currentUser.uid), 'notifications');
    var userId = auth.currentUser.uid;
    if (alertData.id && alertData.id !== '') {
      updateDoc(doc(alertRef, alertData.id), {
        UserId: userId,
        AlertName: alertData.AlertName,
        NotifySymbols: alertData.NotifySymbols,
        Filters: alertData.Filters ?? '',
        Premium: alertData.Premium ?? '',
        StrikePrice: alertData.StrikePrice ?? '',
        Dte: alertData.Dte ?? '',
        ModifiedDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
      });
    } else {
      addDoc(alertRef, {
        UserId: userId,
        AlertName: alertData.AlertName,
        NotifySymbols: alertData.NotifySymbols,
        Filters: alertData.Filters ?? '',
        Premium: alertData.Premium ?? '',
        StrikePrice: alertData.StrikePrice ?? '',
        Dte: alertData.Dte ?? '',
        ModifiedDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
        InsertDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
      });
    }
  }
};

export const deleteAlert = alertData => {
  if (auth.currentUser) {
    const alertRef = collection(doc(MarketActionDbRef, auth.currentUser.uid), 'notifications');
    if (alertData.id !== '') {
      deleteDoc(doc(alertRef, alertData.id));
    }
  }
};

export const addUdateNotificationToken = token => {
  if (auth.currentUser) {
    setDoc(doc(collection(doc(MarketActionDbRef, auth.currentUser.uid), 'notificationTokens'), token), {
      UserId: auth.currentUser.uid,
    },
      { merge: true },
    );
  }
};

export const deleteNotificationToken = token => {
  if (auth.currentUser) {
    deleteDoc(doc(collection(doc(MarketActionDbRef, auth.currentUser.uid), 'notificationTokens'), token));
  }
};

export const addUserExtraData = (authData, userData, observer) => {
  if (authData) {
    (doc(MarketActionDbRef, authData.uid), {
      UserId: authData.uid,
      IsApproved: true,
      InsertDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
      ModifiedDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
      PhoneNumber: userData && userData.PhoneNumber ? userData.PhoneNumber : '',
      DisplayName: authData.displayName,
      email: authData.email,
      ReferralCode: userData && userData.ReferralCode ? userData.ReferralCode : '',
      ReferralCount: 0,
      IsReferralEarned: false,
      IsSubscribedToNewsletter:
        userData && userData.IsSubscribedToNewsletter !== undefined ? userData.IsSubscribedToNewsletter : false,
    }).then(observer);
  }
};

export const addUpdateUserExtraData = (authData, userData, observer) => {
  if (authData) {
    getDoc(doc(MarketActionDbRef, authData.uid)).then(docData => {
      if (docData.exists()) {
        var currentDoc = docData.data();
        updateDoc(doc(MarketActionDbRef, authData.uid), {
          UserId: authData.uid,
          DisplayName: authData.displayName,
          email: authData.email,
          PhoneNumber: userData && userData.PhoneNumber ? userData.PhoneNumber : currentDoc.PhoneNumber ?? '',
          ReferralCode: userData && userData.ReferralCode ? userData.ReferralCode : currentDoc.ReferralCode ?? '',
          IsSubscribedToNewsletter:
            userData && userData.IsSubscribedToNewsletter !== undefined
              ? userData.IsSubscribedToNewsletter
              : currentDoc.IsSubscribedToNewsletter ?? false,
        })
          .then(observer);
      } else {
        setDoc(doc(MarketActionDbRef, authData.uid), {
          UserId: authData.uid,
          IsApproved: true,
          InsertDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
          ModifiedDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
          PhoneNumber: userData && userData.PhoneNumber ? userData.PhoneNumber : '',
          DisplayName: authData.displayName,
          email: authData.email,
          ReferralCode: userData && userData.ReferralCode ? userData.ReferralCode : '',
          ReferralCount: 0,
          IsReferralEarned: false,
          IsSubscribedToNewsletter: userData && userData.IsSubscribedToNewsletter !== undefined ? userData.IsSubscribedToNewsletter : false,
          DiscordAccessToken: '',
          DiscordRefreshToken: '',
          DiscordId: '',
        })
          .then(observer);
      }
    });
  }
};

export async function addUpdateDiscordTokenData(tokenData, observer) {
  if (auth.currentUser) {
    var authData = auth.currentUser;
    var doc = await getDoc(doc(MarketActionDbRef, authData.uid));
    if (doc.exists()) {
      var currentData = doc.data();

      if (currentData.DiscordId !== tokenData.DiscordId) {
        var snapshot = await getDocs(collection(doc(MarketActionDbRef, auth.currentUser.uid), 'discordServers'));

        const batch = writeBatch(firestore);
        snapshot.docs.forEach(docData => {
          batch.delete(docData.ref);
        });
        await batch.commit();
      }

      var data = await updateDoc(doc(MarketActionDbRef, authData.uid), {
          ModifiedDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
          DiscordAccessToken: tokenData.access_token ?? '',
          DiscordRefreshToken: tokenData.refresh_token ?? '',
          DiscordId: tokenData.DiscordId ? tokenData.DiscordId : currentData.DiscordId ?? '',
        });
    } else {
      var data = await setDoc(doc(MarketActionDbRef, authData.uid), {
          UserId: authData.uid,
          IsApproved: true,
          InsertDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
          ModifiedDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
          PhoneNumber: '',
          DisplayName: authData.displayName,
          email: authData.email,
          ReferralCode: '',
          ReferralCount: 0,
          IsReferralEarned: false,
          IsSubscribedToNewsletter: false,
          DiscordAccessToken: tokenData.access_token ?? '',
          DiscordRefreshToken: tokenData.refresh_token ?? '',
          DiscordId: tokenData.DiscordId ?? '',
        });
    }

    observer();
  }
}

export const updateNewsletterStatus = (authData, IsSubscribedToNewsletter, observer) => {
  if (authData) {
    getDoc(doc(MarketActionDbRef, authData.uid))
      .then(docData => {
        if (docData.exists()) {
          updateDoc(doc(MarketActionDbRef, authData.uid), {
            IsSubscribedToNewsletter: IsSubscribedToNewsletter,
          })
            .then(observer);
        }
      });
  }
};

export const getUserExtraData = observer => {
  if (auth.currentUser) {
    return getDoc(doc(MarketActionDbRef, auth.currentUser.uid))
      .then(observer);
  }
  return null;
};

export async function updateUserRefreshTokenStatus() {
  if (auth.currentUser) {
    return await updateDoc(doc(MarketActionDbRef, auth.currentUser.uid), { RefreshFirebaseToken: false });
  }
  return null;
}

export const getUserReferrals = observer => {
  if (auth.currentUser) {
    return getDocs(collection(doc(MarketActionDbRef, auth.currentUser.uid), 'referrals'))
      .then(observer);
  }
  return null;
};

export const IsEmailSubscribed = (email, observer) => {
  getDocs(firestoreQuery(collection(firestore, 'NewsletterUsers'), where('email', 'in', [email])))
    .then(observer);
};

export const SubscribeUserEmail = (email, observer) => {
  setDoc(collection(firestore, 'NewsletterUsers'), {
    email: email,
    insertDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
  })
  .then(observer);
};

export const SendEmail = (to, templateName, data) => {
  setDoc(collection(firestore, 'mail'), {
      to: to,
      template: {
        name: templateName,
        data: data,
      },
    });
};

export async function getDiscordSetting(discordSetting) {
  if (auth.currentUser) {
    return getDoc(doc(collection(doc(MarketActionDbRef, auth.currentUser.uid), 'discords'), discordSetting.id));
  }
}

export function getDiscordChannels(alertId, observer) {
  if (auth.currentUser) {
    return onSnapshot(collection(doc(collection(doc(MarketActionDbRef, auth.currentUser.uid), 'discords'), alertId), 'channels'), observer);
  }
  return null;
}

export const deleteDiscordChannel = data => {
  if (auth.currentUser) {
    const discordChannelRef = collection(doc(collection(doc(MarketActionDbRef, auth.currentUser.uid), 'discords'), data.AlertId), 'channels');
    if (data.id !== '') {
      deleteDoc(doc(discordChannelRef, data.id));
    }
  }
};

export async function updateDiscordSetting(discordSetting) {
  if (auth.currentUser) {
    var discordFilterRef = doc(collection(doc(MarketActionDbRef, auth.currentUser.uid), 'discords'), discordSetting.id);
    var checkExists = (await getDoc(discordFilterRef)).exists();
    var isActive = discordSetting.IsActive;

    if (checkExists) {
      await updateDoc(discordFilterRef, {
        IsActive: isActive ? isActive : false,
        IsOneLine: discordSetting.IsOneLine ? discordSetting.IsOneLine : false,
        UserId: auth.currentUser.uid,
      });
    } else {
      await setDoc(discordFilterRef, {
        IsActive: isActive ? isActive : false,
        IsOneLine: discordSetting.IsOneLine ? discordSetting.IsOneLine : false,
        UserId: auth.currentUser.uid,
      });
    }
  }
}

export async function getDiscordServers() {
  if (auth.currentUser) {
    return await getDocs(collection(doc(MarketActionDbRef, auth.currentUser.uid), 'discordServers'));  }
}

export async function getDiscordServer(serverId) {
  if (auth.currentUser) {
    return await getDoc(doc(collection(doc(MarketActionDbRef, auth.currentUser.uid), 'discordServers'), serverId));
  }
}

export async function getActiveServerLimit() {
  var defaultServerCountLimit = 1;
  if (auth.currentUser) {
    var userConfigs = await getDoc(doc(MarketActionUserConfigDbRef, auth.currentUser.uid));
    if (userConfigs.exists()) {
      defaultServerCountLimit = userConfigs.data().ServerCountLimit;
    }
  }
  return defaultServerCountLimit;
}

export async function checkActiveServerCount(discord) {
  if (auth.currentUser) {
    if (discord.IsActive === false) {
      return false;
    }

    var defaultServerCountLimit = 1;

    var userConfigs = await getDoc(doc(MarketActionUserConfigDbRef, auth.currentUser.uid));
    if (userConfigs.exists()) {
      defaultServerCountLimit = userConfigs.data().ServerCountLimit;
    }

    var discordServers = await getDocs(firestoreQuery(collection(doc(MarketActionDbRef, auth.currentUser.uid), 'discordServers'), where('IsActive', '==', true), where('ServerId', '!=', discord.id)));

    if (discordServers.docs.length + 1 <= defaultServerCountLimit) {
      return false;
    } else {
      return true;
    }
  }
}

export async function updateDiscordServer(discord) {
  if (auth.currentUser) {
    var discordRef = doc(collection(doc(MarketActionDbRef, auth.currentUser.uid), 'discordServers'), discord.id);
    var checkExists = (await getDoc(discordRef)).exists();
    var isActive = discord.IsActive;

    if (checkExists) {
      await updateDoc(discordRef, {
        IsActive: isActive,
        ServerId: discord.id,
        ServerName: discord.name,
        UserId: auth.currentUser.uid,
      });
    } else {
      await setDoc(discordRef, {
        IsActive: isActive,
        ServerId: discord.id,
        ServerName: discord.name,
        UserId: auth.currentUser.uid,
      });
    }
  }
}

export const getWeeklySentimentSummary = (symbol, selectedDate, observer) => {
  if (auth.currentUser) {
    var dates = Last7Days(selectedDate.StartDate);
    getDocs(firestoreQuery(collection(firestore, 'OptionsSummary'), where('Ticker', '==', symbol), where('RecordDate', 'in', dates))).then(observer);
  }
};

function Last7Days(selectedDate) {
  var result = [];
  for (var i = 1; i <= 11; i++) {
    var d = new Date(selectedDate);
    d.setDate(d.getDate() - i);
    if (d.getDay() != 6 && d.getDay() != 0) {
      result.push(moment(d).format('YYYY-MM-DD'));
      if (result.length == 7) {
        break;
      }
    }
  }

  return result;
}

export const getAnalystSummary = (selectedDates, observer) => {
  if (auth.currentUser) {
    var fromDate = moment(selectedDates[0]).format('YYYY-MM-DD');
    var toDate = moment(selectedDates[1]).format('YYYY-MM-DD');
    getDocs(firestoreQuery(collection(firestore, 'AnalystRatings'), where('date', '>=', fromDate), where('date', '<=', toDate))).then(observer);
  }
};

export const getThreeMonthsAnalystSummary = (selectedSymbol, selectedDate, observer) => {
  if (auth.currentUser) {
    var fromDate = moment(selectedDate)
      .subtract(3, 'months')
      .format('YYYY-MM-DD');
    var toDate = moment(selectedDate).format('YYYY-MM-DD');

    getDocs(firestoreQuery(collection(firestore, 'AnalystRatings'), where('ticker', '==', selectedSymbol), where('date', '>=', fromDate), where('date', '<=', toDate))).then(observer);
  }
};

//admin functions
export const getAllUsers = observer => {
  return getDocs(MarketActionDbRef).then(observer);
};

export const getAllSubscriptions = observer => {
  return getDocs(collection(firestore, 'customers')).then(observer);
};

export async function getUserSubscription(userId) {
  return await getDocs(firestoreQuery(collection(doc(collection(firestore, 'customers'), userId), 'subscriptions'), where('status', 'in', ['trialing', 'active'])));
}

export const updateUserData = data => {
  if (auth.currentUser) {
    const userDataRef = doc(MarketActionDbRef, data.UserId);
    updateDoc(userDataRef, {
      IsApproved: data.IsApproved,
    });
  }
};
export const updateUserPhoneNo = phoneNo => {
  if (auth.currentUser) {
    const userDataRef = doc(MarketActionDbRef, auth.currentUser.uid);
    return updateDoc(userDataRef, {
      PhoneNumber: phoneNo,
    });
  }
};

export const getIsAdmin = observer => {
  if (auth.currentUser) {
    return getDoc(doc(collection(firestore, 'Admins'), auth.currentUser.uid))
      .then(observer);
  }
};

export const getSharingFilters = observer => {
  if (auth.currentUser) {
    return onSnapshot(firestoreQuery(collection(doc(collection(firestore, 'AdminData'), 'SharingFilters'), 'detail'), orderBy('InsertDate', 'desc')), observer);
  }
  return null;
};

export const addUpdateSharingFilters = sharingFiltersData => {
  if (auth.currentUser) {
    const alertRef = collection(doc(collection(firestore, 'AdminData'), 'SharingFilters'), 'detail');
    var userId = auth.currentUser.uid;
    if (sharingFiltersData.id && sharingFiltersData.id !== '') {
      updateDoc(doc(alertRef, sharingFiltersData.id), {
        UserId: userId,
        Title: sharingFiltersData.Title,
        NotifySymbols: sharingFiltersData.NotifySymbols,
        Filters: sharingFiltersData.Filters ?? '',
        Premium: sharingFiltersData.Premium ?? '',
        StrikePrice: sharingFiltersData.StrikePrice ?? '',
        Dte: sharingFiltersData.Dte ?? '',
        ModifiedDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
        Template: sharingFiltersData.Template ?? '',
      });
    } else {
      addDoc(alertRef, {
        UserId: userId,
        NotifySymbols: sharingFiltersData.NotifySymbols,
        Title: sharingFiltersData.Title,
        Filters: sharingFiltersData.Filters ?? '',
        Premium: sharingFiltersData.Premium ?? '',
        StrikePrice: sharingFiltersData.StrikePrice ?? '',
        Dte: sharingFiltersData.Dte ?? '',
        ModifiedDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
        InsertDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
        Template: sharingFiltersData.Template ?? '',
      });
    }
  }
};

export const deleteSharingFilter = sharingFilterData => {
  if (auth.currentUser) {
    const sharingFilterRef = collection(doc(collection(firestore, 'AdminData'), 'SharingFilters'), 'detail');
    if (sharingFilterData.id !== '') {
      deleteDoc(doc(sharingFilterRef, sharingFilterData.id));
    }
  }
};

export const getStockTwitFilters = observer => {
  if (auth.currentUser) {
    return onSnapshot(firestoreQuery(collection(doc(collection(firestore, 'AdminData'), 'StockTwitFilters'), 'detail'), orderBy('InsertDate', 'desc')), observer);
  }
  return null;
};

export const getManualNotifications = observer => {
  if (auth.currentUser) {
    return onSnapshot(firestoreQuery(collection(doc(collection(firestore, 'AdminData'), 'ManualNotifications'), 'detail'), orderBy('InsertDate', 'desc')), observer);
  }
  return null;
};

export const addManualNotifications = data => {
  if (auth.currentUser) {
    const docRef = collection(doc(collection(firestore, 'AdminData'), 'ManualNotifications'), 'detail');
    var userId = auth.currentUser.uid;
    addDoc(docRef, {
      InsertUser: userId,
      Title: data.Title,
      Body: data.Body ?? '',
      SendTo: data.SendTo ?? 'all',
      ActionLink: data.ActionLink ?? '',
      InsertDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss')
    });
  }
};

export const addUpdateStockTwitFilters = filtersData => {
  if (auth.currentUser) {
    const alertRef = collection(doc(collection(firestore, 'AdminData'), 'StockTwitFilters'), 'detail');
    var userId = auth.currentUser.uid;
    if (filtersData.id && filtersData.id !== '') {
      updateDoc(doc(alertRef, filtersData.id), {
        UserId: userId,
        Title: filtersData.Title,
        NotifySymbols: filtersData.NotifySymbols,
        Filters: filtersData.Filters ?? '',
        Premium: filtersData.Premium ?? '',
        StrikePrice: filtersData.StrikePrice ?? '',
        Dte: filtersData.Dte ?? '',
        ModifiedDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
        Template: filtersData.Template ?? '',
      });
    } else {
      addDoc(alertRef, {
        UserId: userId,
        NotifySymbols: filtersData.NotifySymbols,
        Title: filtersData.Title,
        Filters: filtersData.Filters ?? '',
        Premium: filtersData.Premium ?? '',
        StrikePrice: filtersData.StrikePrice ?? '',
        Dte: filtersData.Dte ?? '',
        ModifiedDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
        InsertDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
        Template: filtersData.Template ?? '',
      });
    }
  }
};

export const deleteStockTwitFilter = filterData => {
  if (auth.currentUser) {
    const filterRef = collection(doc(collection(firestore, 'AdminData'), 'StockTwitFilters'), 'detail');
    if (filterData.id !== '') {
      deleteDoc(doc(filterRef, filterData.id));
    }
  }
};

export const getDiscordFilters = observer => {
  if (auth.currentUser) {
    return onSnapshot(firestoreQuery(collection(doc(collection(firestore, 'AdminData'), 'DiscordFilters'), 'detail'), orderBy('InsertDate', 'desc')), observer);
  }
  return null;
};

export const addUpdateDiscordFilters = sharingFiltersData => {
  if (auth.currentUser) {
    const alertRef = collection(doc(collection(firestore, 'AdminData'), 'DiscordFilters'), 'detail');
    var userId = auth.currentUser.uid;
    if (sharingFiltersData.id && sharingFiltersData.id !== '') {
      updateDoc(doc(alertRef, sharingFiltersData.id), {
        UserId: userId,
        Title: sharingFiltersData.Title,
        NotifySymbols: sharingFiltersData.NotifySymbols,
        Filters: sharingFiltersData.Filters ?? '',
        Premium: sharingFiltersData.Premium ?? '',
        StrikePrice: sharingFiltersData.StrikePrice ?? '',
        Dte: sharingFiltersData.Dte ?? '',
        ModifiedDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
      });
    } else {
      addDoc(alertRef, {
        UserId: userId,
        NotifySymbols: sharingFiltersData.NotifySymbols,
        Title: sharingFiltersData.Title,
        Filters: sharingFiltersData.Filters ?? '',
        Premium: sharingFiltersData.Premium ?? '',
        StrikePrice: sharingFiltersData.StrikePrice ?? '',
        Dte: sharingFiltersData.Dte ?? '',
        ModifiedDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
        InsertDate: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
      });
    }
  }
};

export const deleteDiscordFilter = sharingFilterData => {
  if (auth.currentUser) {
    const sharingFilterRef = collection(doc(collection(firestore, 'AdminData'), 'DiscordFilters'), 'detail');
    if (sharingFilterData.id !== '') {
      deleteDoc(doc(sharingFilterRef, sharingFilterData.id));
    }
  }
};

export const updateUserAgreementData = async data => {
  if (auth.currentUser) {
    const userDataRef = doc(MarketActionDbRef, auth.currentUser.uid);
    try {
      const res = await updateDoc(userDataRef, {
        agreement: {
          isAgreementDone: data.isAgreementDone,
          userDetails: data.userDetails,
        },
      });
      return Promise.resolve({
        success: true,
        res,
      });
    } catch (error) {
      return Promise.resolve({
        success: false,
      });
    }
  }
};

export const updateLastActivityDateTime = async () => {
  if (auth.currentUser) {
    const userDataRef = doc(MarketActionDbRef, auth.currentUser.uid);
    try {
      const res = await updateDoc(userDataRef, {
        lastActivityDateTime: moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      });
      return Promise.resolve({
        success: true,
        res,
      });
    } catch (error) {
      console.log(error);
      return Promise.resolve({
        success: false,
        error: error,
      });
    }
  }
};
