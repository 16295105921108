import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { Step1, Step2, Step3, Step4 } from './Steps';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  backBtn: {},
  btnBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  stepTitlte: {
    margin: '10px 0 30px 0',
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  labelStyle: {
    fontSize: '12px',
  },
}));

function getSteps() {
  return ['Profile Picture', 'Trader type', 'Join Discord', 'Video Walk Thru'];
}

function getStepContent(step, updateEnteries, setUpdateEntries) {
  switch (step) {
    case 0:
      return <Step1 updateEnteries={updateEnteries} setUpdateEntries={setUpdateEntries} />;
    case 1:
      return <Step2 updateEnteries={updateEnteries} setUpdateEntries={setUpdateEntries} />;
    case 2:
      return <Step3 updateEnteries={updateEnteries} setUpdateEntries={setUpdateEntries} />;
    case 3:
      return <Step4 updateEnteries={updateEnteries} setUpdateEntries={setUpdateEntries} />;
    default:
      return 'Unknown step';
  }
}

export default function HorizontalLinearStepper({ handleSubmit, updateEnteries, setUpdateEntries, image, setImage }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const isStepOptional = step => {
    return step !== getSteps().length - 1;
  };

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box className={classes.root}>
      <Box className={`${classes.stepTitlte}`}>Setup Your Profile</Box>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>
                <span className={classes.labelStyle}>{label}</span>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box style={{ marginBottom: '30px' }}>
        {activeStep === steps.length ? (
          <Box>
            <Typography className={classes.instructions}>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </Box>
        ) : (
          <Box>
            <Box className={classes.instructions}>
              {getStepContent(activeStep, updateEnteries, setUpdateEntries, image, setImage)}
            </Box>
            <Box className={`${classes.btnBox}`}>
              <Button
                disabled={activeStep === 0}
                variant="contained"
                color="primary"
                onClick={handleBack}
                className={`${classes.button} ${classes.backBtn}`}>
                Back
              </Button>

              <Box>
                {isStepOptional(activeStep) && (
                  <Button variant="contained" color="primary" onClick={handleSkip} className={classes.button}>
                    Skip
                  </Button>
                )}
                {activeStep === steps.length - 1 ? (
                  <Button variant="contained" color="primary" onClick={handleSubmit} className={classes.button}>
                    Finish
                  </Button>
                ) : (
                  <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>
                    Next
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
