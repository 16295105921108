import React, { useState, useEffect } from 'react';
import './index.css';
import * as staticFunctions from './dashboardFunctions';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtAdvCardContent from '@coremat/CmtAdvCard/CmtAdvCardContent';
import CmtAdvCard from '@coremat/CmtAdvCard';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { Box, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    cardContentRoot: {
      '& .MuiGrid-container': {
        alignItems: 'center',
      },
    },
    cardContentTitle: {
      marginBottom: 4,
    },
    subTitleRoot: {
      fontSize: 12,
      marginBottom: 0,
      color: theme.palette.text.secondary,
    },
    tooltip: {
      position: 'relative',
      borderRadius: 6,
      padding: '4px 8px',
      backgroundColor: '#8d46ef',
      color: theme.palette.common.white,
    }
  }));

export default function CallPutGraphWidget(props) {
  const classes = useStyles();
  const [callOrPutWidgetHidden, setCallOrPutWidgetHidden] = useState(false);
  const [callsOrPutsHourlyData, setCallsOrPutsHourlyData] = React.useState([]);
  const filteredData = props.optionsData;

  useEffect(() => {
    if(!callOrPutWidgetHidden){
      const summaryWorker = new Worker('/WebWorkers/summary.worker.js');
      
      summaryWorker.postMessage({optionsData: props.optionsData, type: 'callorputhourssummary'});

      summaryWorker.onmessage = event => {
        setCallsOrPutsHourlyData(event.data);
      }

      return () => {
        summaryWorker.terminate();
      }
    }
  }, [props.optionsData, callOrPutWidgetHidden]);

  if (!callOrPutWidgetHidden) {
    const callPutRationWidgetActionHandler = event => {
        switch (event.value) {
          case 'closeCallOrPut': {
            return setCallOrPutWidgetHidden(true);
          }
          default:
            return true;
        }
      };

    return (
      <Grid item xs={12} sm={12} md={6}>
        <CmtAdvCard>
            <CmtCardHeader
              className="pt-2 pb-2"
              actionsPos="top-corner"
              actions={[{ label: 'Close', value: 'closeCallOrPut' }]}
              actionHandler={callPutRationWidgetActionHandler}
              title={
                <Typography
                  {...{
                    variant: 'h4',
                    component: 'div',
                  }}>
                  Total Puts/Calls
                </Typography>
              }
            />
            <CmtAdvCardContent
              className={classes.cardContentRoot}
              title={
                <Typography
                  {...{
                    variant: 'h1',
                    component: 'div',
                    className: classes.cardContentTitle,
                  }}
                  style={{ fontSize: '32px' }}>
                  <span style={{ color: '#f44336' }}>{staticFunctions.getTotal(filteredData, 'PUTS')}</span>
                  <span>/</span>
                  <span style={{ color: '#00b399' }}>{staticFunctions.getTotal(filteredData, 'CALLS')}</span>
                </Typography>
              }
              subTitle={
                <Typography
                  {...{
                    variant: 'inherit',
                    component: 'div',
                    className: classes.subTitleRoot,
                  }}>
                  <span>Total Put To Call Ratio : </span>
                  <span>{staticFunctions.getPutToCallRatio(filteredData)}</span>
                </Typography>
              }
              reverseDir>
              <ResponsiveContainer width="100%" height={125}>
                <BarChart textAlign="right" data={callsOrPutsHourlyData} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                  <Tooltip
                    labelStyle={{ color: 'black' }}
                    cursor={false}
                    content={data => {
                      return data.payload && data.payload[0] ? (
                        <Box className={classes.tooltip}>
                          <Box>Hour : {data.payload[0].payload.hour + ':00'}</Box>
                          <Box>Puts : {data.payload[0].payload.puts}</Box>
                          <Box>Calls : {data.payload[0].payload.calls}</Box>
                        </Box>
                      ) : null;
                    }}
                  />
                  <XAxis dataKey="hour" />
                  <Bar dataKey="puts" stackId="a" fill="#f44336" barSize={15} />
                  <Bar dataKey="calls" stackId="a" fill="#00b399" barSize={15} />
                </BarChart>
              </ResponsiveContainer>
            </CmtAdvCardContent>
          </CmtAdvCard>
      </Grid>
    );
  } else {
    return '';
  }
}
