import React from 'react';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as appConstants from "../../../Constants/AppConstants";

const useStyles = makeStyles(() => ({
  bioTitle: {
    marginBottom: 16,
  },
}));

export default function Privacy() {
  const classes = useStyles();
  return (
    <CmtCard>
      <CmtCardHeader title="Terms of Use & Privacy Policy" />
      <CmtCardContent>
        <Box mb={3} component="p" color="text.secondary">
        Legal Disclaimer
        </Box>
        <Typography component="div" variant="h2" className={classes.bioTitle}>
        REGISTRATION
        </Typography>
        <Box component="p" mb={{ xs: 5, md: 6 }}>
        This User Agreement between Authorized User (“You”) and Market Action, LLC ( {appConstants.AppUrl} ) (“the Site”) and any of our Site affiliates, agencies, sponsors, licensees or content partners (hereafter, collectively referred to as “Market Action”, “We”, or “Us”) governs your use of these information services and web sites (collectively referred to as “the Tools”), including any document, content, services, functions or features accessible on or through the Site (the “Products”). Your use of the Site and the Tools is subject to the terms and conditions hereof. By using the Site or any other website owned, operated, licensed, or controlled by Us, you agree to be bound by the terms of the User Agreement. Other provisions that govern your use of the Site may be set forth in online notices appearing in connection with certain Products (collectively, the “Additional Terms”), all of which are incorporated by reference into this User Agreement. Your use of any Products that are subject to Additional Terms constitutes your acceptance of the respective Additional Terms.
        </Box>
        <Box component="p" mb={{ xs: 5, md: 6 }}>
        BY REGISTERING FOR OR USING THIS SITE , THE USER ACCEPTS ALL OF THESE TERMS (the “TERMS OF SERVICE”). USE OF THIS SITE WILL CONSTITUTE THE USER’S ACCEPTANCE OF ALL OF SUCH TERMS OF SERVICE.
        </Box>
        <Box component="p" mb={{ xs: 5, md: 6 }}>
        If you do NOT agree with any such Additional Terms, do NOT use the Product associated with them. You may review the User Agreement at any time by clicking on the “User Agreement” link that appears on the Site. The Terms of Service for your continued use of this Site will be available at {appConstants.AppUrl}
        </Box>
        <Box component="p" mb={{ xs: 5, md: 6 }}>
          1. YOU AGREE TO PAY THE PREDETERMINED SUBSCRIPTION FEE FOR YOUR USE TO THE SITE.
        </Box>
        <Box component="p" mb={{ xs: 5, md: 6 }}>
        2. YOU AGREE NOT TO SHARE ACCESS TO YOUR ACCOUNT AND NOT TO REDISTRIBUTE ANY DATA YOU RECEIVE THROUGH YOUR USE OF THE SITE OR PRODUCTS.
         <br />
         You may not copy, modify, reproduce, republish, distribute, transmit or use the site or the products for commercial or other purposes, except to the extent required in order for you to use the Site and the Products in the manner expressly provided herein.
          <br />
          You may NOT share your password with anyone.
          <br />
          Our security system checks for simultaneous logins from multiple locations. If it is found that you are sharing your account, the system will auto-lock the account for a 24-hour period as required by our data provider agreements.
          <br />
          You may not scrape or extract data from the site for use in any external applications including but not limited to Discord, Slack, and Twitter. Your account will be banned without notice and no refunds will be issued.
        </Box>
        <Box component="p" mb={{ xs: 5, md: 6 }}>
        3.THE USER VERIFIES THAT THE INFORMATION PROVIDED BY THE USER IS ACCURATE AND CORRECT TO THE BEST OF THE USER’S KNOWLEDGE.
        </Box>
        <Box component="p" mb={{ xs: 5, md: 6 }}>
        4. THIS SITE AND ALL OF ITS CONTENTS ARE COPYRIGHTED. THE VIEWER OF THIS SITE IS GIVEN A LIMITED LICENSE TO USE ITS CONTENTS FOR PERSONAL OR INTERNAL COMPANY USE ONLY.
        </Box>
        <Box component="p" mb={{ xs: 5, md: 6 }}>
        5. DIGITAL GOODS (NO REFUND POLICY) 
        Since services are non­tangible, irrevocable, digital goods; we do not issue refunds, which you are solely responsible for understanding. During checkout, upon clicking the final submit button, your purchase is charged to the credit/debit card that you supplied. All sales are final. You cannot cancel a purchase or receive a refund for a purchase. You can cancel future recurring subscription payments at any time by cancellation through your account settings page. You may cancel at any time from your Account Settings page.
          <br />
          If you are under a free trial period, you will be charged at the end of your trial unless cancelled. We do not provide refunds. All sales are final. It is your responsibility to manage your subscription accordingly.
        </Box>
        <Box component="p" mb={{ xs: 5, md: 6 }}>
        6. YOU AGREE TO NOT ABUSE THE FREE TRIAL OR PAYMENT SYSTEM(S).
          <br />
          You are entitled to ONE free trial period. Our system automatically checks if you have already signed up for a free trial. If it is found that you are abusing the free trial system, your free trial period is immediately removed from future accounts and is transitioned to a normal non-trial account if it is found that you are severely breaching our terms of service.
          <br />
          Your account will be banned without notice if it is found you are opening multiple accounts.
        </Box>
        <Box component="p" mb={{ xs: 5, md: 6 }}>
        7. PRIVACY <br />
          We respect your privacy rights! We will not sell or distribute your email address or other information without your express consent. Only Market Action, our Agencies, Attorneys, and Partners are entitled to your information.
        </Box>
        <Box component="p" mb={{ xs: 5, md: 6 }}>
        8. COPYRIGHT; PROPRIETARY DATA; USE PROVISIONS <br />
        Neither Market Action nor any individual or organization that may be a source of content distributed through the Site makes any warranty as to the timeliness and accuracy of the information. The Products are provided for informational purposes only and do not constitute investment advice. Access to, transmission or receipt of, or reliance upon, Content from the Site does not create and is not intended to create an investor­ broker relationship between you or any other person or any Content Partner.
         <br />
         Except for the license granted in the User Agreement, all right, title, and interest in the Site and the Tools (including all trademarks, copyrights, and other intellectual property rights), in all languages, formats and media throughout the world, are and will continue to be the exclusive property of Market Action and/or the Content Partners. You may not decompile, reverse engineer, disassemble, rent, lease, loan, sublicense, or create derivative works from the Site.
        </Box>
        <Box component="p" mb={{ xs: 5, md: 6 }}>
        9. NO REPRESENTATIONS OR WARRANTIES
          <br />
          Neither Market Action, its agencies, attorneys, members, affiliates, nor any content partner makes any warranty or representation that the information is accurate and timely. You understand that use of the site and of the products is undertaken at your own risk. Any information or data provided by Market Action is not exhaustive or complete. Neither Market Action nor any content partner assumes any liability for the use or interpretation of any product. The Site and the products are provided “as is” without warranty of any kind, either expressed or implied, including, but not limited to, implied warranties with respect to performance, merchantability, fitness for a particular purpose, non­infringement of third party rights, accuracy, omissions, completeness, currentness, or timeliness. Your exclusive remedy under this agreement, if any, for any claim for damages relating to your use of the site or the products, whether on the basis of contract, negligence, or any other basis, will be limited to the aggregate amount of charges paid by you to Market Action during the 12­month period preceding the event giving rise to such claim. You acknowledge that provision of the Site and content entails the likelihood of some human and machine errors, delays, interruptions and losses, including the inadvertent loss of data.
        </Box>
        <Box component="p" mb={{ xs: 5, md: 6 }}>
        10. LIMITATION OF LIABILITY
          <br />
          In no event will the content creator, its affiliates, partners, members, managers, directors, officers, employees or agents be liable to you or any third person for any indirect, consequential, exemplary, incidental, special or punitive damages, including for any lost profits or lost data arising from your use of the website, or any of the content or other materials on, accessed through or downloaded from the website, even if the content creator or its affiliates, partners, members, managers, directors, officers, employees or agents is aware or has been advised of the possibility of such damages. Notwithstanding anything to the contrary contained herein, the liability of the content creator and its affiliates, partners, members, managers, directors, officers, employees and agents to you for any cause whatsoever, and regardless of the form of the action, will at all times be limited to the amount paid, if any, by you to the content creator for the website in the immediately preceding twelve months, but in no case will such liability to you exceed $1000. you acknowledge that if no fees are paid to the content creator for the website, you shall be limited to injunctive relief only, unless otherwise permitted by law, and shall not be entitled to damages of any kind from the content creator, regardless of the cause of action.
          <br />
          Certain state laws do not allow limitations on implied warranties or the exclusion or limitation of certain damages. If these laws apply to you, some or all of the above disclaimers, exclusions or limitations may not apply to you, and you may have additional rights.
        </Box>
        <Box component="p" mb={{ xs: 5, md: 6 }}>
        11. DISCLAIMER
          <br />
          There is a high degree of risk involved in trading stocks and options. Past results are not indicative of future returns. Market Action Live LLC (Market Action) is not a registered investment advisor nor is licensed as such with any federal or state regulatory agency. Market Action does not manage client assets in any way. Information provided and opinions expressed on the website do not constitute investment advice. Any and all ideas expressed, research, tutorials, and instructional resources are for educational purposes only. The ideas expressed are solely the opinions of Market Action. We advise everyone to know the risks involved with trading stocks and options. We encourage every visitor to the website to do his/her own research and to consult with his/her own financial advisor(s) prior to engaging in any investing activities, and to make sure he/she knows all the potential risks involved. Any investment decision that results in losses or gains made based on any information on this site, or related services is not the responsibility of Market Action.
          <br />
          Your use of ideas, systems, and/or data provided by Market Action are at your own risk and it is your sole responsibility to evaluate the accuracy, completeness, and usefulness of the information.
        </Box>
        <Box component="p" mb={{ xs: 5, md: 6 }}>
        12. YOU AGREE TO NOT SOLICIT, PROMOTE, OR SHARE EXTERNAL PREMIUM DATA WITHIN OUR MESSAGE BOARDS.
          <br />
          You may not promote, share, discuss, or suggest the use of competing services within the chat room. You Agree to not solicit members of the Site for any reason. You Agree to adhere to the Chat Code of Conduct and promote a positive, professional, and team-oriented atmosphere. We reserve the right to ban any user from participating in our chat room that violates any of the message board rules.
        </Box>

        <Box component="p" mb={{ xs: 5, md: 6 }}>
        13. REFERRAL & AFFILIATE PROGRAM RULES
          <br />
          Any Market Action account holder can become a referrer. To receive referral rewards or credits for referring someone who orders a paid plan on Market Action, your referee must be a new Market Action user and can’t have more than one account. As long as you and your referee follow these Rules, as well as Market Action’s posted policies, you should receive your rewards or credits after your referee uses your link to sign up with Market Action and makes their first payment. Referral rewards in the form of Market Action credits are not transferable, have no cash value, and may expire. Market Action may cancel a reward at any time at its sole discretion. In case your referee files a chargeback or claims a refund for their paid subscription, the reward that you and your referee both received from Market Action will be canceled.
          <br /><br />
          Market Action, wants you to share your referral link and earn rewards or credits, but you agree that you will not:
          <br />

          <Box component="ol" ml={5}>
  <li>Try to get referees by spamming, bulk emailing, or sending large numbers of unsolicited emails. The only people you should be emailing are people you know personally;</li>
  <li>Use, display, or manipulate Market Action intellectual property (such as Market Action logos, trademarks, and copyright-protected works) in any way, except as to identify yourself as a Market Action user or a Market Action referrer;</li>
  <li>Create or register any (i) businesses, (ii) URLs, (iii) domain names, (iv) software application names or titles, or (v) social media handles or profiles that include the word "MarketActionLive" or any of Market Action's other brand phrases or any words that are confusingly similar to Market Action Live.</li>
  <li>Purchase keywords (including, but not limited to Google AdWords) that contain any of Market Action Live;</li>
  <li>Use automated systems or bots through any channel to distribute, post, or otherwise share your referral link;</li>
  <li>Use scripts or programmed or automatic dialers to send invites or otherwise share your referral link;</li>
  <li>Make misleading claims about Market Action, use offensive/abusive content, create fake websites/webpages/social media profiles/apps, misrepresent your connection to Market Action, or otherwise make any false or misleading statements to get a referee to use your link; or</li>
  <li>Use your referral link in any manner that violates the law or Market Action’s House Rules.</li>
</Box> 

<br/>
Market Action reserves the right to change, end, or pause, in whole or in part, any referral program, as well as any referrer’s or referee's ability to participate in any referral program or receive Market Action rewards at any time for any reason, including suspected fraud (including by either the referrer and/or referee), abuse, or any violation of these Rules.

<br/>
<br/>
Market Action may update these rules at any time.

        </Box>

        <Box component="p" mb={{ xs: 5, md: 6 }}>
        SEC DISCLAIMER <br />
          The U.S. Securities and Exchange Commission (SEC) requires the following legal notices: All material presented on Market Action Live LLC is not to be regarded as investment advice, but for general informational purposes only. There is a high degree of risk involved in trading stocks and options, so caution must always be utilized. We cannot guarantee profits or freedom from loss. You assume the entire cost and risk of any trading you choose to undertake. You are solely responsible for making your own investment decisions. Market Action LLC, its representatives, its principals, its moderators, and its members, are NOT registered as securities broker-dealers or investment advisors either with the U.S. Securities and Exchange Commission or with any state securities regulatory authority. Market Action LLC does not manage client assets in any way. All information/ideas provided on Market Action LLC products and services are for educational purposes only. We encourage every visitor to the website to do his/her own research and to consult with his/her own financial advisor(s) prior to engaging in any investing activities, and to make sure he/she knows all the potential risks involved. Any investment decision that results in losses or gains made based on any information on this site, or related services is not the responsibility of Market Action.
        </Box>
        <Box component="p" mb={{ xs: 5, md: 6 }}>
        Market Action LLC requires that you are a nonprofessional subscriber to receive data through our service. If you are not, please let us know.
        </Box>
      </CmtCardContent>
    </CmtCard>
  );
}
