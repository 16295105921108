import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import * as staticFunctions from '../Dashboard/dashboardFunctions';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

export default function FilterPopup(props) {
  const [open, setOpen] = React.useState(false);
  const [currentFilter, setCurrentFilter] = React.useState(props.currentFilter);

  const handleClickOpen = () => {
    setCurrentFilter(props.currentFilter);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onFilterListChanged = (event, newValue) => {
    setCurrentFilter(newValue);
  };

  const handleFilter = () => {
    props.callback(currentFilter);
    handleClose();
  };
  return (
    <Box>
      <Button className="openFilterBtn" variant="outlined" color="primary" onClick={handleClickOpen} />
      <Dialog fullWidth={true} maxWidth={'sm'} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Filters
        </DialogTitle>
        <DialogContent dividers>
          <Autocomplete
            multiple
            id="filterDDL"
            size="small"
            value={currentFilter}
            onChange={onFilterListChanged}
            options={staticFunctions.filtersDdlData}
            getOptionLabel={option => option.name}
            filterSelectedOptions
            renderInput={params => <TextField {...params} label="Filters" />}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant="contained" size="small" onClick={() => handleFilter()} color="primary">
            Filter
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
