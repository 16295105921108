import { UPDATE_AUTH_USER, UPDATE_LOAD_USER, UPDATE_PHONE } from '../../@jumbo/constants/ActionTypes';

export const setAuthUser = user => {
  return dispatch => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: user,
    });
  };
};

export const updateLoadUser = loading => {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAD_USER,
      payload: loading,
    });
  };
};

export const updatePhoneNo = phoneNo => {
  return dispatch => {
    dispatch({
      type: UPDATE_PHONE,
      phoneNo,
    });
  };
};
