import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function TawkToChatPop() {
    const { authUser } = useSelector(({ auth }) => auth);

    useEffect(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, []);

    return (
        <ins className='adsbygoogle'
            style={{ display: 'block' }}
            data-ad-client="ca-pub-8178385556037072"
            data-ad-slot="6965950488"
            data-ad-format="auto"
            data-full-width-responsive="true"
            data-adtest="on" />
    );
}