import React, { useEffect } from 'react';
import { fade, Grid, Typography, Box } from '@material-ui/core';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtAdvCardContent from '@coremat/CmtAdvCard/CmtAdvCardContent';
import CmtAdvCard from '@coremat/CmtAdvCard';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import * as staticFunctions from '../Dashboard/dashboardFunctions';
import EnhancedTableHead from './EnhancedTableHead';

const useStyles = makeStyles(theme => ({
  cardContentRoot: {
    '& .MuiGrid-container': {
      alignItems: 'center',
    },
  },
  cardContentTitle: {
    marginBottom: 4,
  },
  subTitleRoot: {
    fontSize: 12,
    marginBottom: 0,
    color: theme.palette.text.secondary,
  },
  tooltip: {
    position: 'relative',
    borderRadius: 6,
    padding: '4px 8px',
    backgroundColor: '#8d46ef',
    color: theme.palette.common.white,
  },
  tableRowCellRoot: {
    fontSize: 12,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 16,
    letterSpacing: 0.4,
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      paddingRight: 24,
    },
  },
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${fade(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      paddingRight: 24,
    },
    '& .Cmt-avatar-more': {
      fontSize: 10,
      color: theme.palette.primary.main,
    },
  },
}));

export default function GoldenSweepSummaryWidget(props) {
  const classes = useStyles();
  const [goldenSweepSummaryHidden, setGoldenSweepSummaryHidden] = React.useState(false);
  const [goldenSweepSummaryPageSize, setGoldenSweepSummaryPageSize] = React.useState(10);
  const [symbolsGoldenSweepSummary, setSymbolsGoldenSweepSummary] = React.useState([]);
  const [orderBy, setOrderBy] = React.useState('premiumOrg');
  const [order, setOrder] = React.useState('desc');

  useEffect(() => {
    if (!goldenSweepSummaryHidden) {
      const summaryWorker = new Worker('/WebWorkers/summary.worker.js');
      
      summaryWorker.postMessage({
        optionsData: props.optionsData,
        orderBy: orderBy,
        order: order,
        pageSize: goldenSweepSummaryPageSize,
        type: 'goldensweep',
      });

      summaryWorker.onmessage = event => {
        setSymbolsGoldenSweepSummary(event.data);
      };
      return () => {
        summaryWorker.terminate();
      };
    }
    return () => {};
  }, [props.optionsData, orderBy, order, goldenSweepSummaryPageSize, goldenSweepSummaryHidden]);

  if (!goldenSweepSummaryHidden) {
    const onGoldenSweepSummaryPageSizeChange = event => {
      setGoldenSweepSummaryPageSize(event.target.value);
    };

    const goldenSweepSummaryActionHandler = event => {
      switch (event.value) {
        case 'close': {
          return setGoldenSweepSummaryHidden(true);
        }
        default:
          return true;
      }
    };

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const headCells = [
      { id: 'symbol', numeric: false, disablePadding: false, label: 'Symbol' },
      { id: 'StrikePrice', numeric: true, disablePadding: false, label: 'Strike' },
      {
        id: 'CallOrPut',
        numeric: false,
        disablePadding: false,
        label: 'Calls/Puts',
      },
      {
        id: 'expiration',
        numeric: false,
        disablePadding: false,
        label: 'Expiration',
      },
      { id: 'premiumOrg', numeric: true, disablePadding: false, label: 'Premium' },
      {
        id: 'optionSymbol',
        numeric: true,
        disablePadding: false,
        label: 'Option Symbol',
      },
    ];

    return (
      <Grid item xs={12} sm={12} md={6}>
        <CmtAdvCard>
          <CmtCardHeader
            className="pt-2 pb-2"
            actionsPos="top-corner"
            actions={[{ label: 'Close', value: 'close' }]}
            actionHandler={goldenSweepSummaryActionHandler}
            title={
              <Typography
                {...{
                  variant: 'h4',
                  component: 'div',
                }}>
                Golden Sweeps Flow (Top &nbsp;
                <Select value={goldenSweepSummaryPageSize} onChange={onGoldenSweepSummaryPageSizeChange}>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
                )
              </Typography>
            }
          />
          <CmtAdvCardContent className={classes.cardContentRoot}>
            <Box className="Cmt-table-responsive">
              <Table>
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={symbolsGoldenSweepSummary.length}
                  headCells={headCells}
                />
                <TableBody>
                  {symbolsGoldenSweepSummary.map((row, index) => {
                    return (
                      <TableRow hover key={index} className={clsx(classes.tableRowRoot)}>
                        <TableCell className={classes.tableCellRoot}>
                          <Box>
                            {staticFunctions.CallPutFormatterNoToolTip({
                              value: row.symbol,
                              row: row,
                            })}
                          </Box>
                        </TableCell>
                        <TableCell className={classes.tableCellRoot}>
                          <Box>{staticFunctions.CommaFormatted(row.StrikePrice)}</Box>
                        </TableCell>
                        <TableCell className={classes.tableCellRoot}>
                          <Box>
                            {staticFunctions.CallPutTextFormatterNoToolTip({
                              value: row.CallOrPut,
                              row: row,
                            })}
                          </Box>
                        </TableCell>
                        <TableCell className={classes.tableCellRoot}>
                          <Box>{row.expiration}</Box>
                        </TableCell>
                        <TableCell className={classes.tableCellRoot}>
                          <Box>
                            {staticFunctions.CallPutTextFormatterNoToolTip({
                              value: '$' + staticFunctions.nFormatter(row.premium, 1),
                              row: row,
                            })}
                          </Box>
                        </TableCell>
                        <TableCell className={classes.tableCellRoot}>
                          <Box>{row.optionSymbol}</Box>
                        </TableCell>
                        <TableCell className={classes.tableCellRoot}>
                          <Box>{row.orders}</Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </CmtAdvCardContent>
        </CmtAdvCard>
      </Grid>
    );
  } else {
    return '';
  }
}
