import React, { useState, useEffect } from 'react';
import ReactDataGrid from 'react-data-grid';
import { Toolbar, Data, Filters } from 'react-data-grid-addons';
import * as staticFunctions from '../Dashboard/dashboardFunctions';
import GridContainer from '@jumbo/components/GridContainer';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import ExactFilter from '../../../Custom/Filters/ExactFilter';
import DecimalFilter from '../../../Custom/Filters/DecimalFilter';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import * as FirestoreService from '../../../services/auth/firebase/FirebaseRealTimeDb';
import '../Dashboard/index.css';
import './index.css';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import moment from 'moment-timezone';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '100%',
    marginTop: '0px',
    paddingRight: '25px',
  },
  reactRangeInput:{

  },
  reactCalendar:{
    color:"black !important"
  }
}));

const defaultColumnProperties = {
  filterable: true,
  sortable: true,
  resizable: true,
};

const selectors = Data.Selectors;
const { SingleSelectFilter } = Filters;
const columns = [
  {
    name: 'Date',
    key: 'RecordDateTime',
    width: 200
  },
  {
    name: 'Symbol',
    key: 'ticker',
    width: 100,
    filterRenderer: ExactFilter,
  },
  {
    name: 'Name',
    key: 'name',
    width: 250,
  },
  {
    name: 'Analyst Firm',
    key: 'analyst',
    width: 180,
  },
  {
    name: 'Analyst Name',
    key: 'analyst_name',
    width: 130,
  },
  {
    name: 'Action On Security',
    key: 'action_company',
    width: 200,
    filterRenderer: SingleSelectFilter,
  },
  {
    name: 'Prior Rating',
    key: 'rating_prior',
    width: 130,
    filterRenderer: SingleSelectFilter,
  },
  {
    name: 'Rating',
    key: 'rating_current',
    width: 130,
    filterRenderer: SingleSelectFilter,
  },
  {
    name: 'Action Price Target',
    key: 'action_pt',
    width: 130,
    filterRenderer: SingleSelectFilter,
  },
  {
    name: 'Prior Price Target',
    key: 'pt_prior',
    width: 130,
    filterRenderer: DecimalFilter,
  },
  {
    name: 'Price Target',
    key: 'pt_current',
    width: 130,
    filterRenderer: DecimalFilter,
  }
].map(c => ({ ...c, ...defaultColumnProperties }));

export default function AnalystRatings() {
  const classes = useStyles();

  const [filters, setFilters] = useState({});
  const [currentSort, setCurrentSort] = useState({});
  const [analystRatings, setAnalystRatings] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [selectDates, setSelectDates] = useState([new Date(), new Date()]);
  
  useEffect(() => {
    if (selectDates[0] != null && selectDates[1] != null) {
      FirestoreService.getAnalystSummary(selectDates, (snapshots) => {
        var allData = [];
        snapshots.docs.forEach((doc) => {
          if (doc.exists()) {
            var analystData = doc.data();
            analystData.RecordDateTime = moment(analystData.date + ' ' + analystData.time, ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DDTHH:mm:ss.SSSZ']).format('MM/DD/YYYY hh:mm A');
            allData.unshift(analystData);
          }
        });

        setAnalystRatings(allData);
      });
    }
    else {
      setAnalystRatings([]);
    }

    return () => { }
  }, [selectDates]);

  useEffect(() => {
      if(analystRatings.length > 0){
          setFilteredRows(getRows(analystRatings, filters));
      }
      else{
        setFilteredRows([]);
      }

      return () => {}
  }, [analystRatings, filters])

  useEffect(() => {
    console.log(selectDates);

    return () => {}
}, [selectDates])

  function getRows(rows, filters) {
    return selectors.getRows({ rows, filters });
  }

  const sortRows = (initialRows, sortColumn, sortDirection) => rows => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
      else if(sortDirection === "NONE"){
        return a["id"] < b["id"] ? 1 : -1;
      }
    };
    return [...rows].sort(comparer);
  };

  const handleFilterChange = filter => filters => {
    const newFilters = { ...filters };
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
      delete newFilters[filter.column.key];
    }
    return newFilters;
  };

  function getValidFilterValues(rows, columnId) {
    return rows
      .map(r => r[columnId])
      .filter((item, i, a) => {
        return i === a.indexOf(item);
      });
  }

  const EmptyRowsView = () => {
    const message = 'No data to show';
    return (
      <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px' }}>
        <h3>{message}</h3>
      </div>
    );
  };

  return (
    <PageContainer>
      <GridContainer>
        <Grid item xs={12} sm={4} md={6}>
        <h1>Analyst Ratings</h1>
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <div style={{ float: "right" }}>
            <DateRangePicker
              onChange={setSelectDates}
              value={selectDates}
              clearIcon={null}
              format={'MM/dd/yyyy'}
              calendarClassName={classes.reactCalendar}
              className={classes.reactRangeInput}
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className="dark-table-react-grid" style={{ width: '100%' }}>
            <ReactDataGrid
              columns={columns}
              rowGetter={i => filteredRows[i]}
              rowsCount={filteredRows.length}
              minHeight={600}
              rowHeight={40}
              toolbar={<Toolbar enableFilter={true} />}
              onAddFilter={filter => setFilters(handleFilterChange(filter))}
              onClearFilters={() => setFilters({})}
              onGridSort={(sortColumn, sortDirection) => {
                setCurrentSort({
                  sortColumn: sortColumn,
                  sortDirection: sortDirection,
                });
                setAnalystRatings(sortRows(analystRatings, sortColumn, sortDirection));
              }}
              getValidFilterValues={columnKey => getValidFilterValues(analystRatings, columnKey)}
              emptyRowsView={EmptyRowsView}
            />
          </div>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
}
