import React, { useEffect }  from 'react';

export default function TadingViewTickerPriceWidget({selectedSymbol}) {

  useEffect(() => {
    document.getElementById("tickerPricingChart").innerHTML = '';
    if (selectedSymbol && document.getElementById("tickerPricingChart")) {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-single-quote.js'
        script.async = true;
        script.innerHTML = JSON.stringify({
            "symbol": selectedSymbol,
            "width": "100%",
            "colorTheme": "dark",
            "isTransparent": false,
            "locale": "en"
          });

        document.getElementById("tickerPricingChart").appendChild(script);
      }

      return () => {}
  }, [selectedSymbol]);

  return (
    <div id="tickerPricingChart"></div>
  );
}
