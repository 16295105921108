import React, { useEffect } from 'react';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';

export default function TadingViewTickerWidget({ selectedSymbol }) {
  // useEffect(() => {
  //   document.getElementById("tickerChart_" + selectedSymbol).innerHTML = '';
  //   if (selectedSymbol && document.getElementById("tickerChart_" + selectedSymbol)) {
  //     const script = document.createElement('script');
  //     script.innerHTML = "new TradingView.MediumWidget(" + JSON.stringify({
  //       "symbols": [
  //         [
  //           selectedSymbol
  //         ]
  //       ],
  //       "width": "100%",
  //       "chartOnly": false,
  //       "locale": "en",
  //       "colorTheme": "dark",
  //       "gridLineColor": "rgba(240, 243, 250, 0)",
  //       "trendLineColor": "#2962ff",
  //       "fontColor": "#787b86",
  //       "underLineColor": "rgba(41, 98, 255, 0.3)",
  //       "underLineBottomColor": "rgba(41, 98, 255, 0)",
  //       "isTransparent": false,
  //       "autosize": false,
  //       "container_id": "tickerChart_" + selectedSymbol
  //     }) + ")";

  //     document.getElementById("tickerChart_" + selectedSymbol).appendChild(script);
  //   }

  //   return () => { }
  // }, [selectedSymbol]);

  return (
    <div style={{ width: '100%', height: '400px' }}>
      <TradingViewWidget
        symbol={selectedSymbol}
        theme={Themes.DARK}
        locale="en"
        autosize
        interval={3}
        height={500}
        timezone="America/New_York"
        toolbar_bg="#f1f3f6"
        enable_publishing={false}
        withdateranges={false}
        save_image={false}
        allow_symbol_change={false}
        details={false}
        calendar={false}
      />
    </div>
    // <div id={"tickerChart_" + selectedSymbol}></div>
  );
}
