import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import CmtAvatar from '@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import { fade, Grid, Button } from '@material-ui/core';
import CmtImage from '@coremat/CmtImage';

import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import { auth, storage } from '../../../services/configs/config';
import { useDispatch, useSelector } from 'react-redux';
import { AuhMethods } from '../../../services/auth';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import DropNCrop from '@synapsestudios/react-drop-n-crop';
import '@synapsestudios/react-drop-n-crop/lib/react-drop-n-crop.min.css';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { updateProfile } from 'firebase/auth';
import { StringFormat, getDownloadURL, ref as storageRef, uploadString } from 'firebase/storage';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  headerRoot: {
    position: 'relative',
    margin: '-30px -15px 0 -15px',
    paddingRight: 15,
    paddingLeft: 15,
    paddingTop: 30,
    paddingBottom: 20,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 56,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: -50,
      marginRight: -50,
      paddingLeft: 50,
      paddingRight: 50,
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: -65,
      marginRight: -65,
      paddingLeft: 65,
      paddingRight: 65,
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: -88,
      marginRight: -88,
      paddingLeft: 88,
      paddingRight: 88,
    },
  },
  headerBgImg: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    minHeight: 370,
    zIndex: 0,
    [theme.breakpoints.up('sm')]: {
      minHeight: 270,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: fade(theme.palette.primary.main, 0.5),
    },
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  headerContent: {
    position: 'relative',
    zIndex: 3,
  },
  titleRoot: {
    color: theme.palette.common.white,
    marginBottom: 4,
  },
  subTitleRoot: {
    color: fade(theme.palette.common.white, 0.74),
  },
  followerList: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -15,
    marginRight: -15,
    [theme.breakpoints.up('md')]: {
      marginLeft: -24,
      marginRight: -24,
    },
  },
  followerListItem: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: 'center',
    cursor: 'pointer',
    color: fade(theme.palette.common.white, 0.74),
    fontSize: 12,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    '&:not(:first-child)': {
      borderLeft: `solid 1px ${fade(theme.palette.common.white, 0.38)}`,
    },
  },
  followerListTitle: {
    color: theme.palette.common.white,
    marginBottom: 3,
  },
  tabsList: {
    position: 'relative',
    minHeight: 10,
    '& .MuiTabs-indicator': {
      backgroundColor: fade(theme.palette.common.white, 0.4),
    },
  },
  tabItem: {
    maxWidth: 'none',
    minWidth: 10,
    minHeight: 10,
    padding: '5px 10px',
    textTransform: 'capitalize',
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Header = ({ userDetail }) => {
  const MySwal = withReactContent(Swal);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { displayName } = userDetail;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const [imageData, setImageData] = useState({
    result: null,
    filename: null,
    filetype: null,
    src: null,
    error: null,
  });

  const onChange = value => {
    setImageData(value);
  };

  const handleClose = () => {
    setImageData({
      result: null,
      filename: null,
      filetype: null,
      src: null,
      error: null,
    });
    setOpen(false);
  };

  function handleUploadProfilePicture() {
    if (imageData.result == null) {
      MySwal.fire({
        icon: 'error',
        title: 'error',
        text: 'Please Select a image to upload.',
      });
      return;
    }

    var profilePictureStorageRef = storageRef(storage, '/' + authUser.uid + '/profilePictures/' + authUser.uid);

    uploadString(profilePictureStorageRef, imageData.result, StringFormat.DATA_URL).then(() => {
      getDownloadURL(profilePictureStorageRef).then(function(url) {
          updateProfile(auth.currentUser, { photoURL: url })
            .then(function() {
              setOpen(false);
              dispatch(AuhMethods[CurrentAuthMethod].getAuthUser());
            })
            .catch(function(error) {
              console.log(error);
            });
        })
        .catch(function(error) {
          console.log(error);
        });
    });
  }

  return (
    <Box className={classes.headerRoot}>
      <Box className={classes.headerBgImg}>
        <CmtImage src={'/images/profile-bg-img.png'} />
      </Box>
      <Box className={classes.headerContent}>
        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center" mb={4}>
          <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center">
            <Box mr={{ sm: 4, md: 5, lg: 6 }} mb={{ xs: 3, sm: 0 }}>
              <Badge
                onClick={() => setOpen(true)}
                overlap="circle"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                badgeContent={<CreateIcon cursor={'pointer'} />}>
                <CmtAvatar size={80} src={authUser.photoURL} alt={authUser.displayName} />
              </Badge>
              <div>
                <Dialog
                  fullWidth
                  maxWidth={'sm'}
                  open={open}
                  fullScreen={fullScreen}
                  onClose={handleClose}
                  aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title">Change Profile Picture</DialogTitle>
                  <DialogContent>
                    <Grid item xs={12}>
                      <Box>
                        <DropNCrop
                          onChange={onChange}
                          maxFileSize={5000000}
                          cropperOptions={{ initialAspectRatio: 1 / 1, aspectRatio: 1 }}
                          value={imageData}
                        />
                      </Box>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={handleUploadProfilePicture} color="primary">
                      Upload
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Box>
            <Box>
              <Typography className={classes.titleRoot} component="div" variant="h1">
                {displayName}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
