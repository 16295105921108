import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Auth from './Auth';
import FirebaseReducer from './FirebaseReducer';
import WallApp from './WallApp';

const appReducer = (history) => combineReducers({
  router: connectRouter(history),
  common: Common,
  auth: Auth,
  firebaseStore: FirebaseReducer,
  wallApp: WallApp,
});

const initialState = {
  common: {},
  auth: null,
  firebaseStore: {},
  wallApp: {}
}

const rootReducer = history => (state, action) => {
  if (action.type === 'LOG_OUT') {
    state = undefined;
  }
  return appReducer(history)(state, action);
};

export default rootReducer;