import React, { useEffect } from 'react';
import { lighten } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {Button, Dialog, DialogActions, DialogContent, Grid, DialogTitle, Box, Divider} from "@material-ui/core";
import GridContainer from '@jumbo/components/GridContainer';

import {FormControl, TextField, InputLabel, Select} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: lighten(theme.palette.background.paper, 0.1),
    },
}));

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="$ "
      />
    );
  }
  
  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

export default function AddTradeIdea(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [tradeType, setTradeType] = React.useState('stock');

    useEffect(() => {
    }, []);
    
  const handleTradeTypeChange = (event, newTradeType) => {
    setTradeType(newTradeType);
  };

    const handleCloseAddTradeIdea = () => {
        props.handleCloseAddTradeIdea();
    };

    const handlePriceChange = event => {
        // setValues({
        //   ...values,
        //   [event.target.name]: event.target.value,
        // });
      };

      const [expirationDate, setExpirationDate] = React.useState(getNextFriday());

      const handleExpirationDateChange = date => {
        setExpirationDate(date);
      };

      function getNextFriday(){
        var weeksFriday = moment().startOf('isoWeek').add('4', 'days');
        if(moment().day() > 5){
            weeksFriday = weeksFriday.add(1, 'week');
        }

        return weeksFriday;
      }

    const renderStockForm = () => {
        return (<GridContainer style={{ margin: '10px 0px 10px 0px' }}>
            <Grid item xs={12}>
                <Box className="tradeIdeaForm">
                    <FormControl variant='outlined' style={{width:'120px'}} className={classes.formControl}>
                        <InputLabel id="position-label">Position</InputLabel>
                        <Select labelId="position-label" id="position" name="position" label="Position">
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={'BTO'}>Buy</MenuItem>
                            <MenuItem value={'STC'}>Sell</MenuItem>
                            <MenuItem value={'STO'}>Short</MenuItem>
                            <MenuItem value={'BTC'}>Cover</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField id="ticker" name='ticker' style={{width:'100px'}} placeholder='SPY/TSLA/META etc.' label="Ticker" variant='outlined' />
                    <Box style={{ fontSize: '1.5rem' }}>@</Box>
                    <TextField id="price" name="price" style={{width:'120px'}} placeholder='$ 0.00' onChange={handlePriceChange} label="Price" variant='outlined'
                        InputProps={{ inputComponent: NumberFormatCustom }} />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Box className="tradeIdeaForm">
                    <TextField id="stopLoss" name="stopLoss" fullWidth={true} placeholder='$ 0.00' onChange={handlePriceChange} label="Stop Loss" variant='outlined'
                        InputProps={{ inputComponent: NumberFormatCustom }} />

                    <TextField id="profitTarget" name="profitTarget" fullWidth={true} placeholder='$ 0.00' onChange={handlePriceChange} label="Profit Target" variant='outlined'
                        InputProps={{ inputComponent: NumberFormatCustom }} />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth={true}
                    id="outlined-multiline-static"
                    label="Comment"
                    multiline
                    variant='outlined'
                    rows={4}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth={true}
                    id="outlined-multiline-static"
                    label="Type Entry"
                    variant='outlined'
                    placeholder='BTO SPY 6/30 335C @ $3.6'
                />
            </Grid>
        </GridContainer>);
    }

    const renderOptionForm = () => {
        return (<GridContainer style={{ margin: '10px 0px 10px 0px' }}>
            <Grid item xs={12}>
                <Box className="tradeIdeaForm">
                <FormControl variant='outlined' style={{width:'120px'}} className={classes.formControl}>
                        <InputLabel id="position-label">Position</InputLabel>
                        <Select labelId="position-label" id="position" name="position" label="Position">
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={'BTO'}>Buy</MenuItem>
                            <MenuItem value={'STC'}>Sell</MenuItem>
                            <MenuItem value={'STO'}>Short</MenuItem>
                            <MenuItem value={'BTC'}>Cover</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField id="ticker" name='ticker' style={{width:'100px'}} placeholder='SPY/TSLA/META etc.' label="Ticker" variant='outlined' />

                    <FormControl style={{width:'130px'}} variant='outlined' className={classes.formControl}>
                        <KeyboardDatePicker id="expirationDate" label="Expiration" format="MM/DD/YY" value={expirationDate}
                            onChange={handleExpirationDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box className="tradeIdeaForm">
                    
                <TextField id="strikePrice" name="strikePrice" style={{width:'120px'}} placeholder='$ 0.00' onChange={handlePriceChange} label="Strike Price" variant='outlined'
                        InputProps={{ inputComponent: NumberFormatCustom }} />

                    <FormControl variant='outlined' style={{width:'90px'}} className={classes.formControl}>
                        <InputLabel id="callPut-label">Call/Put</InputLabel>
                        <Select labelId="callPut-label" id="callPut" name="callPut" label="Call/Put">
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={'C'}>Call</MenuItem>
                            <MenuItem value={'P'}>Put</MenuItem>
                        </Select>
                    </FormControl>

                    <Box style={{ fontSize: '1.5rem' }}>@</Box>
                    <TextField id="price" name="price" style={{width:'120px'}} placeholder='$ 0.00' onChange={handlePriceChange} label="Price" variant='outlined'
                        InputProps={{ inputComponent: NumberFormatCustom }} />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Box className="tradeIdeaForm">
                    <TextField id="stopLoss" name="stopLoss" fullWidth={true} placeholder='$ 0.00' onChange={handlePriceChange} label="Stop Loss" variant='outlined'
                        InputProps={{ inputComponent: NumberFormatCustom }} />

                    <TextField id="profitTarget" name="profitTarget" fullWidth={true} placeholder='$ 0.00' onChange={handlePriceChange} label="Profit Target" variant='outlined'
                        InputProps={{ inputComponent: NumberFormatCustom }} />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth={true}
                    id="outlined-multiline-static"
                    label="Comment"
                    multiline
                    variant='outlined'
                    rows={4}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth={true}
                    id="outlined-multiline-static"
                    label="Type Entry"
                    variant='outlined'
                    placeholder='BTO SPY 6/30 335C @ $3.6'
                />
            </Grid>
        </GridContainer>);
    }

    return (
        <Dialog open={props.openAddTradeIdea} maxWidth='xs' fullWidth={true} onClose={handleCloseAddTradeIdea}>
            <DialogTitle>New Trade</DialogTitle>
            <DialogContent>
                <ToggleButtonGroup value={tradeType} exclusive onChange={handleTradeTypeChange} aria-label="text alignment">
                    <ToggleButton value="stock" aria-label="left aligned">
                        Stock
                    </ToggleButton>
                    <ToggleButton value="option" aria-label="centered">
                        Option
                    </ToggleButton>
                </ToggleButtonGroup>

                <Box>
                    {tradeType == 'stock' ? (
                        renderStockForm()
                    ) : (
                        renderOptionForm()
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseAddTradeIdea}>Cancel</Button>
                <Button onClick={handleCloseAddTradeIdea}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}
