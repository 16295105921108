import React, { useState, useEffect } from 'react';
import GridContainer from '@jumbo/components/GridContainer';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box, fade, Grid, Link } from '@material-ui/core';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AutoSizer, List } from 'react-virtualized';
import * as staticFunctions from '../Dashboard/dashboardFunctions';
import { red } from '@material-ui/core/colors';
import { useSelector, useDispatch } from 'react-redux';
import FilterPopup from './FilterPopup';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Popover from '@material-ui/core/Popover';
import TwitterIcon from '@material-ui/icons/Twitter';
import TadingViewTickerWidget from './TadingViewTickerWidget';
import MobileFilterPopup from './MobileFilterPopup';
import * as firebaseFunctions from '../../../services/firebase/functions';
import { fetchError } from '../../../redux/actions';

import './index.css';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    position: 'relative',
    '& .Cmt-card-content': {
      padding: 0,
      paddingBottom: 24,
    },
    width: '100%',
  },
  cardHeaderRoot: {
    padding: '15px',
  },
  badgeRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    backgroundColor: fade(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
    padding: '2px 10px',
    borderRadius: 30,
  },
  camCellItem: {
    transition: 'all .2s',
    borderTop: `1px solid ${fade(theme.palette.common.dark, 0.04)}`,
    '&:last-child': {
      borderBottom: `1px solid ${fade(theme.palette.common.dark, 0.04)}`,
    },
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.1),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${fade(theme.palette.common.dark, 0.2)}`,
    },
    padding: '8px 15px !important',
  },
  IconSm: {
    fontSize: 16,
    marginLeft: 4,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(4),
    flex: 1,
  },
  detailTitleLabel: {
    fontWeight: 'bolder',
    color: 'grey',
    fontSize: '13px',
  },
  detailLabel: {
    fontSize: '12px',
  },
}));

export default function MobileDashboard() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const optionsData = useSelector(state => state.firebaseStore.optionsData);
  const [rowDetail, setRowDetail] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [latestPrice, setLatestPrice] = React.useState(null);
  const [filteredRows, setFilteredRows] = React.useState([]);
  
  //Filters States
  const [selectedSymbol, setSelectedSymbol] = useState('');
  const [currentFilter, setCurrentFilter] = React.useState([]);
  const [dte, setDte] = React.useState([0, 365]);
  const [premiumPrice, setPremiumPrice] = React.useState([0, 1500000]);
  const [cpc, setCpc] = React.useState([0, 100]);

  useEffect(() => {
    setLatestPrice(null);
    if (rowDetail != null) {
      firebaseFunctions.callfunction(
        'getLatestPrice',
        {
          symbol: rowDetail.optionSymbol,
        },
        function(res) {
          if (res.data.status === 1) {
            setLatestPrice(res.data.price);
          } else {
            dispatch(fetchError(res.data.msg));
            setLatestPrice(0);
          }
        },
      );

      return () => {};
    }
  }, [rowDetail]);

  useEffect(() => {
    setFilteredRows(filterOptionsRows());
  }, [dte, premiumPrice, cpc, optionsData, currentFilter, selectedSymbol]);

  const [copy, setCopy] = React.useState({
    value: '',
    copied: false,
  });

  const handleClickOpen = index => {
    setRowDetail(filteredRows[index]);
    setOpen(true);
  };

  const handleClose = () => {
    setRowDetail(null);
    setOpen(false);
  };

  function getQuery(values, maxValue) {
    var query = values[0];
    if(values[1] >= maxValue) {
      query = '>' + query;
    }
    else{
      query = query + '-' + values[1];
    }
    return query;
  }

  function filterOptionsRows() {
    var rows = optionsData.filter(f => f.symbol !== '');
    if (selectedSymbol !== '' && selectedSymbol !== null) {
      rows = rows.filter(f => f.symbol.toUpperCase() === selectedSymbol.toUpperCase());
    }
    rows = staticFunctions.filterOptionsData(currentFilter, rows);

    var dteQuery = getQuery(dte, 365);
    var premiumQuery = getQuery(premiumPrice, 1500000); //1.5M
    var cpcQuery = getQuery(cpc, 100); //100

    rows = rows.filter(row => {
      var dteCheck = staticFunctions.filterValues(dteQuery, row.dte);
      var premiumCheck = staticFunctions.filterValues(premiumQuery, row.premiumOrg);
      var cpcCheck = staticFunctions.filterValues(cpcQuery, row.price);
      return (dteCheck && premiumCheck && cpcCheck);
    });
    return rows;
  }

  function onFilterChanged(filters) {
    setCurrentFilter(filters);
  }
  function OnReset() {
    setCurrentFilter([]);
    setDte([0, 365]);
    setPremiumPrice([0, 1500000]);
    setCpc([0, 100]);
  }

  const handleCopyClick = event => {
    setAnchorEl(event.currentTarget);

    setTimeout(function() {
      handleCopyClose();
    }, 700);
  };

  const handleCopyClose = () => {
    setCopy({ copied: false });
    setAnchorEl(null);
  };
  
  function rowRenderer({ key, index, style }) {
    const optionRowData = filteredRows[index];
    const statusColor = optionRowData.CallOrPut === 'CALLS' ? '#00B399' : red[500];

    return (
      <Box
        style={style}
        key={key}
        className={classes.camCellItem}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={6}
        py={2}
        onClick={() => handleClickOpen(index)}>
        <Box display="flex" alignItems="center" flexDirection="row">
          <Box>
            <Box component="h3" mb={1} color={statusColor}>
              {optionRowData.symbol}
            </Box>
            <Box component="p" mb={1}>
              ${optionRowData.StrikePrice} {optionRowData.money} {optionRowData.CallOrPut}
            </Box>
            <Box component="span" color="text.disabled" fontSize={12}>
              {optionRowData.expiration} ({optionRowData.dte} DTE)
            </Box>
          </Box>
        </Box>
        <Box>
          <Box component="p" color="text.hint" mb={1} fontSize={12}>
            {optionRowData.processedDateTime}
          </Box>
          <Box component="p" mb={1}>
            {optionRowData.details}
          </Box>
          <Box component="span" color="text.hint" fontSize={12}>
            Vol: {optionRowData.volume} vs Ol: {optionRowData.openInterest}
          </Box>
        </Box>
        <Box color={statusColor}>
          <Box display="flex" alignItems="center" fontSize={25}>
            ${staticFunctions.nFormatter(optionRowData.premium, 1)}
            {optionRowData.sentiment === 'BULLISH' ? (
              <TrendingUpIcon className={classes.IconSm} style={{ color: '#00b399' }} />
            ) : optionRowData.sentiment === 'BEARISH' ? (
              <TrendingDownIcon className={classes.IconSm} style={{ color: red[500] }} />
            ) : (
              <ShowChartIcon className={classes.IconSm} color="primary" />
            )}
          </Box>
          <Box color="text.hint" fontSize={12}>
            {optionRowData.sentiment} {optionRowData.optionActivityType}
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <GridContainer style={{ height: '100%' }}>
      <div id="tickerChartMainScript" style={{ display: 'none' }}></div>
      <CmtCard className={classes.cardRoot}>
        <CmtCardHeader
          className={classes.cardHeaderRoot}
          title="Mobile Dashboard"
          subTitle={staticFunctions.getLastUpdateTime(filteredRows)}
          actionHandleIcon={<ExpandMoreIcon />}>
          <Autocomplete
            id="combo-box-demo"
            options={[...new Set(optionsData.map(item => item.symbol))]}
            getOptionLabel={option => option}
            style={{ width: 100 }}
            renderInput={params => <TextField {...params} label="Symbols" />}
            onChange={(event, newValue) => {
              setSelectedSymbol(newValue);
            }}
          />
          {/* <FilterPopup currentFilter={currentFilter} callback={onFilterChanged} /> */}
          <MobileFilterPopup
            currentFilter={currentFilter}
            callback={onFilterChanged}
            reset={OnReset}
            dte={dte}
            cpc={cpc}
            premiumPrice={premiumPrice}
            setDte={setDte}
            setCpc={setCpc}
            setPremiumPrice={setPremiumPrice}
          />
        </CmtCardHeader>
        <CmtCardContent style={{ height: '90%' }}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                width={width}
                rowCount={filteredRows.length}
                rowHeight={121}
                rowRenderer={rowRenderer}
                className={'virtualized-list-market'}
              />
            )}
          </AutoSizer>
          {filteredRows.length === 0 ? (
            <Box textAlign={'center'}>
              <Grid item={true} xs={12} />
              <Grid item={true} xs={12}>
                <Box component="span">No Data to Show</Box>
              </Grid>
            </Box>
          ) : (
            ''
          )}

          <Dialog
            fullScreen
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title">
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                  <ArrowBack />
                </IconButton>
                {rowDetail !== null && (
                  <Typography variant="h6" className={classes.title}>
                    {rowDetail.symbol}
                  </Typography>
                )}
              </Toolbar>
            </AppBar>

            {rowDetail !== null && (
              <Box mt={1} padding={3} className="detailcontainer">
                <GridContainer>
                  <Grid item xs={6}>
                    <GridContainer>
                      <Grid item xs={5}>
                        <Typography component="label" className={classes.detailTitleLabel}>
                          Time
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography component="label" className={classes.detailLabel}>
                          {rowDetail.time}
                        </Typography>
                      </Grid>
                    </GridContainer>
                  </Grid>
                  <Grid item xs={6}>
                    <GridContainer>
                      <Grid item xs={5}>
                        <Typography component="label" className={classes.detailTitleLabel}>
                          Symbol
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography component="label" className={classes.detailLabel}>
                          {staticFunctions.CallPutFormatterWithChart({
                            value: rowDetail.symbol,
                            row: rowDetail,
                          })}
                        </Typography>
                      </Grid>
                    </GridContainer>
                  </Grid>
                  <Grid item xs={6}>
                    <GridContainer>
                      <Grid item xs={5}>
                        <Typography component="label" className={classes.detailTitleLabel}>
                          Strike
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography component="label" className={classes.detailLabel}>
                          {staticFunctions.PriceFormatter({
                            value: rowDetail.StrikePrice,
                            row: rowDetail,
                          })}
                        </Typography>
                      </Grid>
                    </GridContainer>
                  </Grid>
                  <Grid item xs={6}>
                    <GridContainer>
                      <Grid item xs={5}>
                        <Typography component="label" className={classes.detailTitleLabel}>
                          Money
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography component="label" className={classes.detailLabel}>
                          {rowDetail.money}
                        </Typography>
                      </Grid>
                    </GridContainer>
                  </Grid>

                  <Grid item xs={6}>
                    <GridContainer>
                      <Grid item xs={5}>
                        <Typography component="label" className={classes.detailTitleLabel}>
                          DTE
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography component="label" className={classes.detailLabel}>
                          {staticFunctions.DaysFormatter({
                            value: rowDetail.dte,
                            row: rowDetail,
                          })}
                        </Typography>
                      </Grid>
                    </GridContainer>
                  </Grid>
                  <Grid item xs={6}>
                    <GridContainer>
                      <Grid item xs={5}>
                        <Typography component="label" className={classes.detailTitleLabel}>
                          Call/Put
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography component="label" className={classes.detailLabel}>
                          {staticFunctions.CallPutTextFormatter({
                            value: rowDetail.CallOrPut,
                            row: rowDetail,
                          })}
                        </Typography>
                      </Grid>
                    </GridContainer>
                  </Grid>

                  <Grid item xs={6}>
                    <GridContainer>
                      <Grid item xs={5}>
                        <Typography component="label" className={classes.detailTitleLabel}>
                          Type
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography component="label" className={classes.detailLabel}>
                          {rowDetail.optionActivityType}
                        </Typography>
                      </Grid>
                    </GridContainer>
                  </Grid>
                  <Grid item xs={6}>
                    <GridContainer>
                      <Grid item xs={5}>
                        <Typography component="label" className={classes.detailTitleLabel}>
                          Sentiment
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography component="label" className={classes.detailLabel}>
                          {staticFunctions.SentimentFormatter({
                            value: rowDetail.sentiment,
                            row: rowDetail,
                          })}
                        </Typography>
                      </Grid>
                    </GridContainer>
                  </Grid>

                  <Grid item xs={6}>
                    <GridContainer>
                      <Grid item xs={5}>
                        <Typography component="label" className={classes.detailTitleLabel}>
                          Spot
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography component="label" className={classes.detailLabel}>
                          {staticFunctions.PriceFormatter({
                            value: rowDetail.underlyingPrice,
                            row: rowDetail,
                          })}
                        </Typography>
                      </Grid>
                    </GridContainer>
                  </Grid>
                  <Grid item xs={6}>
                    <GridContainer>
                      <Grid item xs={5}>
                        <Typography component="label" className={classes.detailTitleLabel}>
                          Expiration
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography component="label" className={classes.detailLabel}>
                          {rowDetail.expiration}
                        </Typography>
                      </Grid>
                    </GridContainer>
                  </Grid>

                  <Grid item xs={6}>
                    <GridContainer>
                      <Grid item xs={5}>
                        <Typography component="label" className={classes.detailTitleLabel}>
                          Details
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography component="label" className={classes.detailLabel}>
                          {rowDetail.details}
                        </Typography>
                      </Grid>
                    </GridContainer>
                  </Grid>
                  <Grid item xs={6}>
                    <GridContainer>
                      <Grid item xs={5}>
                        <Typography component="label" className={classes.detailTitleLabel}>
                          Volume
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography component="label" className={classes.detailLabel}>
                          {staticFunctions.PriceFormatter({
                            value: rowDetail.volume,
                            row: rowDetail,
                          })}
                        </Typography>
                      </Grid>
                    </GridContainer>
                  </Grid>

                  <Grid item xs={6}>
                    <GridContainer>
                      <Grid item xs={5}>
                        <Typography component="label" className={classes.detailTitleLabel}>
                          OI
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography component="label" className={classes.detailLabel}>
                          {staticFunctions.PriceFormatter({
                            value: rowDetail.openInterest,
                            row: rowDetail,
                          })}
                        </Typography>
                      </Grid>
                    </GridContainer>
                  </Grid>
                  <Grid item xs={6}>
                    <GridContainer>
                      <Grid item xs={5}>
                        <Typography component="label" className={classes.detailTitleLabel}>
                          Premium
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography component="label" className={classes.detailLabel}>
                          {staticFunctions.PremiumFormatter({
                            value: rowDetail.premium,
                            row: rowDetail,
                          })}
                        </Typography>
                      </Grid>
                    </GridContainer>
                  </Grid>

                  <Grid item xs={6}>
                    <GridContainer>
                      <Grid item xs={5}>
                        <Typography component="label" className={classes.detailTitleLabel}>
                          Unusual
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography component="label" className={classes.detailLabel}>
                          {rowDetail.unusual}
                        </Typography>
                      </Grid>
                    </GridContainer>
                  </Grid>
                  <Grid item xs={6} />

                  <Grid item xs={6}>
                    <GridContainer>
                      <Grid item xs={5}>
                        <Typography component="label" className={classes.detailTitleLabel}>
                          Option Symbol
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography component="label" className={classes.detailLabel}>
                          {rowDetail.optionSymbol}
                          <CopyToClipboard text={rowDetail.optionSymbol} onCopy={event => setCopy({ copied: true })}>
                            <FileCopyIcon onClick={handleCopyClick} />
                          </CopyToClipboard>
                          <Popover
                            anchorEl={anchorEl}
                            onClose={handleCopyClose}
                            open={copy.copied}
                            anchorOrigin={{
                              vertical: 'center',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'center',
                              horizontal: 'left',
                            }}>
                            Copied
                          </Popover>
                        </Typography>
                      </Grid>
                    </GridContainer>
                  </Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <GridContainer>
                      <Grid item xs={5}>
                        <Typography component="label" className={classes.detailTitleLabel}>
                          Share on Twitter
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Link
                          href={staticFunctions.getTweetText(rowDetail)}
                          title="Share on twitter"
                          target="_blank"
                          color="inherit">
                          <TwitterIcon />
                        </Link>
                      </Grid>
                    </GridContainer>
                  </Grid>

                  <Grid item xs={6}>
                    <GridContainer>
                      <Grid item xs={5}>
                        <Typography component="label" className={classes.detailTitleLabel}>
                          Current Price:
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography component="label" className={classes.detailLabel}>
                          {latestPrice === null && <span>Loading...</span>}
                          {latestPrice !== null &&
                            staticFunctions.PriceFormatter({
                              value: latestPrice,
                              row: rowDetail,
                            })}
                        </Typography>
                      </Grid>
                    </GridContainer>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <TadingViewTickerWidget selectedSymbol={rowDetail.symbol} />
                  </Grid>
                </GridContainer>
              </Box>
            )}
          </Dialog>
        </CmtCardContent>
      </CmtCard>
    </GridContainer>
  );
}
