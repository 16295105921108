import React, { forwardRef, useEffect } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { lighten, Tooltip, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as FirestoreService from '../../../services/auth/firebase/FirebaseRealTimeDb';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import copy from 'copy-to-clipboard';
import { useSelector } from 'react-redux';
import GridContainer from '@jumbo/components/GridContainer';
import DiscordChannels from './DiscordChannels';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: lighten(theme.palette.background.paper, 0.1),
    },
}));

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function DiscordSettings() {
    const classes = useStyles();
    const [DiscordFilters, setDiscordFilters] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedDiscordFilterRow, setSelectedDiscordFilterRow] = React.useState(null);
    const { authUser } = useSelector(({ auth }) => auth);
    const RangeText = "Range (x-y), Greater Than ({'>'}x), Less Than ({'<'}y) (In Thousands)";

    useEffect(() => {
        const ref = FirestoreService.getDiscordFilters(querySnapshot => {
            var data = [];
            querySnapshot.forEach(function (childSnapshot) {
                var childData = childSnapshot.data();
                childData.id = childSnapshot.id;
                data.push(childData);
            });

            if (data.length > 0) {
                var forLoopPromise = new Promise((resolve, reject) => {
                    data.forEach(async function (discordData, index) {
                        var docData = await FirestoreService.getDiscordSetting(discordData);
                        if (docData.exists()) {
                            var rowData = docData.data();
                            discordData.IsActive = rowData.IsActive;
                            discordData.IsOneLine = rowData.IsOneLine;
                        }

                        if (index === data.length - 1) resolve();
                    });
                });

                forLoopPromise.then(() => {
                    setDiscordFilters(data);
                });
            }
            else {
                setDiscordFilters(data);
            }
        });

        return () => {
            if (ref != null) {
                ref();
            }
        }
    }, []);

    const columns = [
        {
            title: 'Alert Id',
            field: 'id',
            editable: 'never'
        },
        {
            title: 'Title',
            field: 'Title',
            editable: 'never'
        },
        {
            title: 'Notify Symbols',
            field: 'NotifySymbols',
            editable: 'never'
        },
        {
            title: 'Filters',
            field: 'Filters',
            editable: 'never'
        },
        {
            title: 'Premium',
            field: 'Premium',
            editable: 'never',
            render: rowData => {
                return <Tooltip title={RangeText}><span>{rowData.Premium}</span></Tooltip>
            }
        },
        {
            title: 'Strike Price',
            field: 'StrikePrice',
            editable: 'never',
            render: rowData => {
                return <Tooltip title={RangeText}><span>{rowData.StrikePrice}</span></Tooltip>
            }
        },
        {
            title: 'DTE',
            field: 'Dte',
            editable: 'never',
            render: rowData => {
                return <Tooltip title={'Days To Expiration'}><span>{rowData.Dte}</span></Tooltip>
            }
        },
        {
            title: 'Active?',
            field: 'IsActive',
            render: rowData => {
                return <span>{rowData.IsActive === true || rowData.IsActive === 'true' ? <CheckIcon style={{color:'green'}} /> : <ClearIcon style={{color:'red'}}/> }</span>
            },
            editComponent: props => (
                <Switch
                    checked={props.value ?? false}
                    onChange={e => {
                        props.onChange(e.target.checked)
                    }}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              )
        },
        {
            title: 'Template (One Line)',
            field: 'IsOneLine',
            render: rowData => {
                return <span>{rowData.IsOneLine === true || rowData.IsOneLine === 'true' ? "Yes" : "No" }</span>
            },
            editComponent: props => (
                <Switch
                    checked={props.value ?? false}
                    onChange={e => {
                        props.onChange(e.target.checked)
                    }}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              )
        }
    ];

    const updateDetails = (alertData) => {
        setOpenDialog(true);
        setSelectedDiscordFilterRow(alertData);
      };

    const handleOpenDialog = () => {
        setOpenDialog(true);
      };
    
      const handleCloseDialog = () => {
        setOpenDialog(false);
      };

    return (
        <GridContainer>

            <DiscordChannels 
            open={openDialog}
        handleOpenDialog={handleOpenDialog}
        handleCloseDialog={handleCloseDialog} 
        discordFilterData={selectedDiscordFilterRow}/>

            <Grid item xs={12} sm={12} md={12}>
                <MaterialTable
                    className={classes.root}
                    icons={tableIcons}
                    title="Discord Filters"
                    columns={columns}
                    actions={[
                        rowData => ({
                            icon: () => <FileCopyIcon />,
                            tooltip: 'Copy Discord Command',
                            onClick: (event, rowData) => {
                                var commandTemplate = `!reg-ma-bot -UserID=${authUser.uid} -AlertId=${rowData.id}`;
                                copy(commandTemplate);
                            }
                        }),
                        rowData => ({
                            icon: () => <MergeTypeIcon />,
                            tooltip: 'Discord Subscribed Channels',
                            onClick: (event, rowData) => {
                                updateDetails(rowData);
                            }
                        })
                    ]}
                    options={{
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],
                        actionsColumnIndex: -1,
                    }}
                    data={DiscordFilters}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve) => {
                                if (oldData) {
                                    FirestoreService.updateDiscordSetting(newData).then(function () {
                                        DiscordFilters.forEach(function (discordData) {
                                            if (discordData.id === newData.id) {
                                                discordData.IsActive = newData.IsActive;
                                                discordData.IsOneLine = newData.IsOneLine;
                                            }
                                        });

                                        setDiscordFilters(DiscordFilters);
                                        resolve();
                                    });
                                }
                            })
                    }}
                />
            </Grid>
        </GridContainer>
    );
}
