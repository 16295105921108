import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(4),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
}))(MuiDialogActions);



export const TimeSessionModal = (props) => {
    const { visibility, onTimeSelect, selectedValue, onRequestClose, } = props;

    return (
        <Dialog onClose={onRequestClose} aria-labelledby="customized-dialog-title" open={visibility}>
            <DialogTitle id="customized-dialog-title" onClose={onRequestClose}>
                Time Period
            </DialogTitle>
            <DialogContent dividers>
                <p style={{marginBottom:"16px", color: "#b5b5b5"}}>Please choose the time to get the last minutes/hour data!</p>
                <RadioGroup aria-label="gender" name="gender1" value={selectedValue} onChange={(elem, type) => onTimeSelect(type)}>
                    <FormControlLabel value="session" control={<Radio />} label="Session (default)" />
                    <FormControlLabel value="5m" control={<Radio />} label="5 Minutes" />
                    <FormControlLabel value="15m" control={<Radio />} label="15 Minutes" />
                    <FormControlLabel value="30m" control={<Radio />} label="30 Minutes" />
                    <FormControlLabel value="1h" control={<Radio />} label="1 Hour" />
                    <FormControlLabel value="2h" control={<Radio />} label="2 Hours" />
                    <FormControlLabel value="4h" control={<Radio />} label="4 Hours" />
                </RadioGroup>
            </DialogContent>
        </Dialog>
    )
}
export default TimeSessionModal;