import React, { useEffect } from 'react';
import { Grid, Typography, LinearProgress } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtAdvCardContent from '@coremat/CmtAdvCard/CmtAdvCardContent';
import CmtAdvCard from '@coremat/CmtAdvCard';
import * as staticFunctions from '../Dashboard/dashboardFunctions';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import jss from 'jss';

const useStyles = makeStyles(theme => ({
  cardContentRoot: {
    '& .MuiGrid-container': {
      alignItems: 'center',
    },
  },
  cardContentTitle: {
    marginBottom: 4,
  },
  subTitleRoot: {
    fontSize: 12,
    marginBottom: 0,
    color: theme.palette.text.secondary,
  },
  tooltip: {
    position: 'relative',
    borderRadius: 6,
    padding: '4px 8px',
    backgroundColor: '#8d46ef',
    color: theme.palette.common.white,
  },
  speedometerRoot: {
    '& text': {
      fill: theme.palette.text.primary + '!important',
    },
    '& .current-value': {
      paddingTop: 10,
    },
    '& g.pointer': {
      fill: theme.palette.text.primary + '!important',
    },
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '100%',
    marginTop: '0px',
    paddingRight: '25px',
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  progressLabel: {
    position: 'absolute',
    height: '100%',
    zIndex: 1,
    maxHeight: '20px', // borderlinearprogress root.height
    display: 'flex',
    padding: '10px',
    alignItems: 'center',
    '& span': {
      width: '100%',
    },
  },
  redColor: {
    backgroundColor: '',
  },
  greenColor: {},
}));

const BorderLinearProgress = withStyles(() => {
  return {
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: 'transparent',
    },
    barColorPrimary: {},
    bar: {
      borderRadius: 5,
    },
  };
})(LinearProgress);

export default function PremiumSummaryWidget(props) {
  const classes = useStyles();
  const [premiumWidgetHidden, setPremiumWidgetHidden] = React.useState(false);
  const [premiumWidgetPageSize, setPremiumWidgetPageSize] = React.useState(10);
  const [symbolsPremium, setSymbolsPremium] = React.useState([]);

  useEffect(() => {
    if (!premiumWidgetHidden) {
      const summaryWorker = new Worker('/WebWorkers/summary.worker.js');

     
      summaryWorker.postMessage({
        optionsData: props.optionsData,
        groupBy: props.groupBy,
        pageSize: premiumWidgetPageSize,
        type: 'premiumsummary',
      });

      summaryWorker.onmessage = (event) => {
        if (event && event.data) {
          setSymbolsPremium(event.data);
        }
    };

      return () => {
        summaryWorker.terminate();
      };
    }
  }, [props.optionsData, props.groupBy, premiumWidgetPageSize, premiumWidgetHidden]);

  if (!premiumWidgetHidden) {
    const MIN = 0;
    const MAX = 100;
    const normalise = value => {
      return ((value - MIN) * 100) / (MAX - MIN);
    };

    const onPremiumWidgetPageSizeChange = event => {
      setPremiumWidgetPageSize(event.target.value);
    };

    const premiumWidgetActionHandler = event => {
      switch (event.value) {
        case 'closePremium': {
          return setPremiumWidgetHidden(true);
        }
        default:
          return true;
      }
    };

    return (
      <Grid item xs={12} sm={12} md={6}>
        <CmtAdvCard>
          <CmtCardHeader
            className="pt-2 pb-2"
            actionsPos="top-corner"
            actions={[{ label: 'Close', value: 'closePremium' }]}
            actionHandler={premiumWidgetActionHandler}
            title={
              <Typography
                {...{
                  variant: 'h4',
                  component: 'div',
                }}>
                Premium Summary (Top &nbsp;
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={premiumWidgetPageSize}
                  onChange={onPremiumWidgetPageSizeChange}>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
                )
              </Typography>
            }
          />
          <CmtAdvCardContent className={classes.cardContentRoot}>
            <div className={classes.root}>
              {symbolsPremium.map((symbolData, index) => {
                const barColorStyle = jss
                  .createStyleSheet({
                    bar: {
                      'background-color': symbolData.color,
                    },
                  })
                  .attach();

                return (
                  <Grid
                    key={symbolData.symbol + '_' + index}
                    container
                    spacing={1}
                    justify="space-between"
                    style={{ marginBottom: '15px' }}>
                    <Grid item xs={12}>
                      <div className={classes.progressLabel}>
                        <span>
                          {props.groupBy !== 'StrikePrice' && symbolData.symbol + ' - '} $
                          {staticFunctions.nFormatter(symbolData.premium, 1)}{' '}
                          {props.groupBy === 'StrikePrice' &&
                            ' - $' + staticFunctions.CommaFormatted(symbolData.StrikePrice)}
                        </span>
                      </div>{' '}
                      <BorderLinearProgress
                        variant="determinate"
                        value={normalise(symbolData.percentage)}
                        classes={{
                          barColorPrimary: barColorStyle.classes.bar,
                        }}
                        style={{ height: '20px' }}
                      />
                    </Grid>
                  </Grid>
                );
              })}

              {symbolsPremium.length === 0 && <h3 style={{ textAlign: 'center', marginTop: '10%' }}>No data to show</h3>}
            </div>
          </CmtAdvCardContent>
        </CmtAdvCard>
      </Grid>
    );
  } else {
    return '';
  }
}
