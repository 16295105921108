import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade } from '@material-ui/core';
import CmtProgressBar from '@coremat/CmtProgressBar';
import clsx from 'clsx';
import * as staticFunctions from '../Dashboard/dashboardFunctions';

const useStyles = makeStyles(theme => ({
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${fade(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&:last-child': {
          color: theme.palette.error.main,
        },
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      paddingRight: 24,
    },
    '& .Cmt-avatar-more': {
      fontSize: 10,
      color: theme.palette.primary.main,
    },
  },
  tableCellSecondaryColor: {
    color: theme.palette.text.secondary,
  },
  blockRoot: {
    display: 'block',
    fontSize: 14,
  },
}));

const MostActiveWidgetRow = ({ row, groupBy }) => {
  const classes = useStyles();

  return (
    <TableRow className={clsx(classes.tableRowRoot)}>
      <TableCell className={classes.tableCellRoot}>
        <Box>   {staticFunctions.CallPutFormatterMostActive({
          value: row.symbol,
          callOrPut: row.callsPercentage >= 50 ? 'CALLS' : 'PUTS',
        })}</Box>
      </TableCell>

      <TableCell className={classes.tableCellRoot}>
        <Box>
          {row.calls}/{row.puts}
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Box>{row.total}</Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ width: 200 }}>
        <CmtProgressBar hideValue value={row.callsPercentage} containedColor={'#3BD27C'} emptyColor={'#E73145'} />
      </TableCell>
    </TableRow>
  );
};

export default MostActiveWidgetRow;
