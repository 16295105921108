import React, { useState } from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';

import Grid from '@material-ui/core/Grid';
import Dialog from './UpdateDialog';
import { useSelector } from 'react-redux';
const UpdateProfile = () => {
  return (
    <PageContainer>
      <GridContainer>
        <Grid item xs={12}>
          <Dialog />
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default UpdateProfile;
