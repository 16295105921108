import React, { useEffect, useMemo } from 'react';
import GridContainer from '@jumbo/components/GridContainer';
import MostActiveWidget from './MostActiveWidget';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { Box, Grid } from '@material-ui/core';
import * as staticFunctions from '../../../routes/Pages/Dashboard/dashboardFunctions';
import { UpdateSelectedDate } from '../../../redux/actions/Firebase';

export default function MostActive() {
  const dispatch = useDispatch();
  const { optionsData, selectedDate } = useSelector(({ firebaseStore }) => firebaseStore);
  var [todayDate, setTodayDate] = React.useState(moment.tz('America/New_York'));

  useEffect(() => {
    var interval = setInterval(function() {
      var currentDateTime = moment.tz('America/New_York');
      setTodayDate(currentDateTime);
      console.log(currentDateTime.format('MM/DD/YYYY'));
      if (selectedDate.StartDate.format('MM/DD/YYYY') !== currentDateTime.format('MM/DD/YYYY')) {
        dispatch(UpdateSelectedDate({
          StartDate: currentDateTime,
          EndDate: currentDateTime
        }));
      }
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, [selectedDate]);

  const MostActiveSummaryWidgetLeftMemo = useMemo(
    () => <MostActiveWidget optionsData={optionsData} pageStart={0} pageSize={20} />,
    [optionsData],
  );
  const MostActiveSummaryWidgetRightMemo = useMemo(
    () => <MostActiveWidget optionsData={optionsData} pageStart={20} pageSize={40} />,
    [optionsData],
  );

  return (
    <GridContainer>
      {staticFunctions.MarketOpensInText(todayDate)}

      {MostActiveSummaryWidgetLeftMemo}
      {MostActiveSummaryWidgetRightMemo}

      {optionsData.length === 0 && (
        <Grid xs={12} item style={{ textAlign: 'center' }}>
          <h2>Waiting For Data...</h2>
        </Grid>
      )}
    </GridContainer>
  );
}
