import React from 'react';
import { shapes } from 'react-data-grid';
import PropTypes from 'prop-types';
const { ExcelColumn } = shapes;

class ExactFilter extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  filterValues(row, columnFilter, columnKey) {
    if (columnFilter.filterTerm === null) {
      return true;
    }

    // implement default filter logic
    if (row[columnKey].toUpperCase() === columnFilter.filterTerm.toUpperCase()) {
      return true;
    }
    return false;
  }

  handleChange(e) {
    let value = e.target.value;
    this.props.onChange({
      filterTerm: value,
      column: this.props.column,
      rawValue: value,
      filterValues: this.filterValues,
    });
  }

  render() {
    let inputKey = 'header-filter-' + this.props.column.key;
    let columnStyle = {
      float: 'left',
      marginRight: 0,
      maxWidth: '100%',
    };

    return (
      <div style={columnStyle}>
        <input
          key={inputKey}
          type="text"
          placeholder="Search"
          className="form-control input-sm"
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

ExactFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  column: PropTypes.shape(ExcelColumn),
};

export default ExactFilter;
