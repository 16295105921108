import { initializeApp }  from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, GithubAuthProvider, TwitterAuthProvider, OAuthProvider } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFirestore, collection } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getMessaging } from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';
import * as FirebaseConfigs from './firebaseConfigs';

// Initialize Firebase
var config = FirebaseConfigs.FirebaseConfigs;
if (process.env.NODE_ENV === 'production') {
  config = FirebaseConfigs.FirebaseConfigs;
}

const firebase = initializeApp(config);

// Initialize Analytics and get a reference to the service
const auth = getAuth(firebase);
const analytics = getAnalytics(firebase);
const storage = getStorage(firebase);
const firestore = getFirestore(firebase);
const functions = getFunctions(firebase);
const functionsUsWest2 = getFunctions(firebase, 'us-west2');
const database = getDatabase(firebase);
const messaging = getMessaging(firebase);

const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();
const githubAuthProvider = new GithubAuthProvider();
const twitterAuthProvider = new TwitterAuthProvider();
const appleAuthProvider = new OAuthProvider('apple.com');
const discordAuthProvider = new OAuthProvider('discord.com');
appleAuthProvider.addScope('email');
appleAuthProvider.addScope('name');

export {
  firebase,
  firestore,
  functions,
  functionsUsWest2,
  storage,
  auth,
  database,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  appleAuthProvider,
  analytics,
  discordAuthProvider,
  messaging
};
