import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { fetchError } from '../../../redux/actions';
import PageLoader from '../PageComponents/PageLoader';
import { useHistory } from 'react-router-dom';

const ContentLoader = () => {
  const { error, loading, message, redirectTo } = useSelector(({ common }) => common);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      if (redirectTo != null && redirectTo != '') {
        history.push(redirectTo);
      }
      dispatch(fetchError(''));
    }, 6000);
  }, [dispatch, error, message]);

  return (
    <React.Fragment>
      {loading && <PageLoader />}
      {error && <Snackbar open={Boolean(error)} message={error} />}
      {message && <Snackbar open={Boolean(message)} message={message} />}
    </React.Fragment>
  );
};

export default ContentLoader;
