import React from 'react';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  height100: {
    height: '100%',
  },
}));

export default function Chart(props) {
  const classes = useStyles();
  const symbol = props.symbol ?? "AAPL";

  return (
    <PageContainer heading="Chart" className={classes.height100}>
      <TradingViewWidget
        symbol={symbol}
        theme={Themes.DARK}
        locale="en"
        autosize
        height={600}
        timezone="America/New_York"
        toolbar_bg="#f1f3f6"
        enable_publishing={false}
        withdateranges={true}
        hide_side_toolbar={false}
        allow_symbol_change={true}
        details={true}
        calendar={true}
      />
    </PageContainer>
  );
}
