import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment-timezone';
import { red } from '@material-ui/core/colors';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { Box, Grid } from '@material-ui/core';
import * as appConstants from '../../../Constants/AppConstants';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import ReactTimeAgo from 'react-time-ago';

export const GreenChip = withStyles(() => ({
  root: {
    backgroundColor: '#00b399',
  },
}))(Chip);

export const RedChip = withStyles(() => ({
  root: {
    backgroundColor: red[500],
  },
}))(Chip);

export const CallPutFormatter = ({ value, row }) => {
  if (row.CallOrPut === 'PUTS') {
    return (
      <Tooltip title={row.description} enterDelay={500}>
        <RedChip label={value} size="small" />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={row.description}>
        <GreenChip label={value} size="small" />
      </Tooltip>
    );
  }
};

export const CallPutTextFormatter = ({ value, row }) => {
  if (row.CallOrPut === 'PUTS') {
    return (
      <Tooltip title={row.description} enterDelay={500}>
        <span style={{ color: '#f44336' }}>{value}</span>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={row.description} enterDelay={500}>
        <span style={{ color: '#00b399' }}>{value}</span>
      </Tooltip>
    );
  }
};

export const CallPutFormatterNoToolTip = ({ value, row }) => {
  if (row.CallOrPut === 'PUTS') {
    return <RedChip label={value} size="small" />;
  } else {
    return <GreenChip label={value} size="small" />;
  }
};

export const CallPutFormatterMostActive = ({ value, callOrPut }) => {
  if (callOrPut === 'PUTS') {
    return <RedChip label={value} size="small" />;
  } else {
    return <GreenChip label={value} size="small" />;
  }
};

export const CallPutTextFormatterNoToolTip = ({ value, row }) => {
  if (row.CallOrPut === 'PUTS') {
    return <span style={{ color: '#f44336' }}>{value}</span>;
  } else {
    return <span style={{ color: '#00b399' }}>{value}</span>;
  }
};

export const PriceFormatterNoToolTip = ({ value, row }) => {
  return <span>{CommaFormatted(value)}</span>;
};

export const DefaultTextFormatter = ({ value, row }) => {
  return (
    <Tooltip title={row.description} enterDelay={500}>
      <div>{value}</div>
    </Tooltip>
  );
};

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export const CallPutFormatterWithChart = ({ value, row }) => {
  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <p style={{ marginBottom: '7px' }} color="inherit">
            {row.description}
          </p>
          <TradingViewWidget
            symbol={row.symbol}
            theme={Themes.DARK}
            locale="en"
            interval={3}
            height={300}
            width={300}
            timezone="America/New_York"
            toolbar_bg="#f1f3f6"
            enable_publishing={false}
            withdateranges={false}
            save_image={false}
            allow_symbol_change={false}
            details={false}
            calendar={false}
          />
        </React.Fragment>
      }>
      <div>
        {row.CallOrPut === 'PUTS' && <RedChip label={value} size="small" />}
        {row.CallOrPut !== 'PUTS' && <GreenChip label={value} size="small" />}
      </div>
    </HtmlTooltip>
  );
};

export const DaysFormatter = ({ value, row }) => {
  return (
    <Tooltip title={row.description} enterDelay={500}>
      <div>
        {value} {value > 1 ? 'DAYS' : 'DAY'}
      </div>
    </Tooltip>
  );
};

export const PriceFormatter = ({ value, row }) => {
  return (
    <Tooltip title={row.description} enterDelay={500}>
      <div>{CommaFormatted(value)}</div>
    </Tooltip>
  );
};

export const PremiumFormatter = ({ value, row }) => {
  if (row.CallOrPut === 'PUTS') {
    return (
      <Tooltip title={row.description} enterDelay={500}>
        <div style={{ color: '#ef5350' }}>${nFormatter(value, 1)}</div>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={row.description} enterDelay={500}>
        <div style={{ color: '#00b399' }}>${nFormatter(value, 1)}</div>
      </Tooltip>
    );
  }
};

export const SentimentFormatter = ({ value, row }) => {
  return (
    <Tooltip title={row.description} enterDelay={500}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {value === 'BULLISH' ? (
          <TrendingUpIcon style={{ color: '#00b399' }} />
        ) : value === 'BEARISH' ? (
          <TrendingDownIcon style={{ color: red[500] }} />
        ) : (
          <ShowChartIcon color="primary" />
        )}
        {' ' + value}
      </div>
    </Tooltip>
  );
};

export function formatDate(date) {
  return moment(date).format('YYYY-MM-DD');
}

export function getEpochDate(epochDate) {
  return moment.unix(epochDate).utc();
}

export function getEpochFormattedDate(epochDate, toFormat) {
  var date = getEpochDate(epochDate.seconds);
  return moment(date).format(toFormat);
}

export function getDaysFromDate(date) {
  var a = moment(date, ['YYYY-MM-DD H:mm:ss', 'YYYY-MM-DDTHH:mm:ss.SSSZ']).startOf('day');
  var b = moment()
    .utc()
    .startOf('day');
  return a.diff(b, 'DAYS');
}

export const getLastUpdateTime = dataList => {
  dataList.sort((a, b) => new Date(b.processedDate) - new Date(a.processedDate));
  if (dataList.length > 0) {
    var lastDate = dataList.filter(f => f.processedDate)[0].processedDate;
    if (lastDate) {
      return (
        <Box component="span">
          Last update <ReactTimeAgo date={new Date(lastDate)} locale="en-US" timeStyle="round" />{' '}
        </Box>
      );
    } else {
      return '';
    }
  }

  return 'Last update --';
};

export const MarketOpensInText = todayDate => {
  var newDate = moment(todayDate);
  var text = 'Market Opens ';
  if (newDate.isoWeekday() === 6 || newDate.isoWeekday() === 7 || newDate.hour() > 16) {
    text = 'Market Closed';
    newDate = null;
  } else {
    if ((newDate.hour() >= 9 && newDate.minute() >= 30) || newDate.hour() > 9) {
      text = 'Market Closes ';
      newDate.set({ h: 16, m: 0, s: 0 });
    } else {
      newDate.set({ h: 9, m: 30, s: 0 });
    }
  }

  return newDate !== null ? (
    <Grid item xs={12} style={{ fontSize: '20px' }}>
      {text} <ReactTimeAgo future date={newDate.toDate()} locale="en-US" timeStyle="round" />
    </Grid>
  ) : (
    <Grid item xs={12} style={{ fontSize: '20px' }}>
      {text}
    </Grid>
  );
};

export function getDateFormatted(date) {
  var a = moment(date, ['YYYY-MM-DD H:mm:ss', 'YYYY-MM-DDTHH:mm:ss.SSSZ']);
  return a.format('MM/DD/YYYY');
}

export function getEmptyDateFormatted(date) {
  if (date === '' || date === null || date === undefined) {
    return '';
  }
  var a = moment(date, ['YYYY-MM-DD H:mm:ss', 'YYYY-MM-DDTHH:mm:ss.SSSZ']);
  return a.format('MM/DD/YYYY');
}

export function getEmptyDateTimeFormatted(date) {
  if (date === '' || date === null || date === undefined) {
    return '';
  }
  var a = moment(date, ['YYYY-MM-DDTHH:mm:ss.SSSZ']);
  return a.format('MM/DD/YYYY hh:mm A');
}

export function getTimeFromDate(date) {
  return moment(date, ['YYYY-MM-DD H:mm:ss', 'YYYY-MM-DDTHH:mm:ss.SSSZ'])
    .tz('America/New_York')
    .format('hh:mm A');
}

export function getDateTimeFromDate(date) {
  return moment(date, ['YYYY-MM-DD H:mm:ss', 'YYYY-MM-DDTHH:mm:ss.SSSZ'])
    .tz('America/New_York')
    .format('MM/DD/YYYY hh:mm A');
}

export function getLocalDateTimeFromDate(date) {
  return moment(date, ['YYYY-MM-DD H:mm:ss', 'YYYY-MM-DDTHH:mm:ss.SSSZ']).format('MM/DD/YYYY hh:mm A');
}

export function getHourFromDate(date) {
  return moment(date, ['YYYY-MM-DD H:mm:ss', 'YYYY-MM-DDTHH:mm:ss.SSSZ'])
    .tz('America/New_York')
    .format('H');
}

export function CurrencyFormatted(amount) {
  var i = parseFloat(amount);
  if (isNaN(i)) {
    i = 0.0;
  }
  var minus = '';
  if (i < 0) {
    minus = '-';
  }
  i = Math.abs(i);
  i = parseInt((i + 0.005) * 100);
  i = i / 100;
  var s = i.toString();
  if (s.indexOf('.') < 0) {
    s += '.00';
  }
  if (s.indexOf('.') === s.length - 2) {
    s += '0';
  }
  s = minus + s;
  return s;
}

export function CommaFormatted(amount) {
  amount = CurrencyFormatted(amount);
  var delimiter = ','; // replace comma if desired
  var a = amount.split('.', 2);
  var d = a[1];
  var i = parseInt(a[0]);
  if (isNaN(i)) {
    return '';
  }
  var minus = '';
  if (i < 0) {
    minus = '-';
  }
  i = Math.abs(i);
  var n = i.toString();
  a = [];
  while (n.length > 3) {
    var nn = n.substr(n.length - 3);
    a.unshift(nn);
    n = n.substr(0, n.length - 3);
  }
  if (n.length > 0) {
    a.unshift(n);
  }
  n = a.join(delimiter);
  if (d.length < 1) {
    amount = n;
  } else {
    amount = n + '.' + d;
  }
  amount = minus + amount;
  return amount.replace(/\.00$/, '');
}

export function nFormatter(num, digits) {
  num = num * 1000;
  var si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  if (si[i].symbol === '' || si[i].symbol === 'K') {
    return (num / si[i].value).toFixed(0).replace(rx, '$1') + si[i].symbol;
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
}

export function nFormatterNoMulitply(num, digits) {
  if (num.indexOf(',') >= 0) {
    num = num.replace(',', '');
  }

  var si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;

  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  if (si[i].symbol === '' || si[i].symbol === 'K') {
    return (num / si[i].value).toFixed(0).replace(rx, '$1') + si[i].symbol;
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
}

export function nFormatterNoMulitplyWithSeparator(num, digits) {
  if (isNaN(num)) {
    if (num.indexOf(',') >= 0) {
      num = num.replace(',', '');
    }
  }

  if(isNaN(num)){
    return { outputNum: 0, unit: ''};
  }
  var si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;

  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  if (si[i].symbol === '' || si[i].symbol === 'K') {
    return { outputNum: (num / si[i].value).toFixed(0).replace(rx, '$1'), unit: si[i].symbol };
  }
  return { outputNum: (num / si[i].value).toFixed(digits).replace(rx, '$1'), unit: si[i].symbol };
}

export function getMoneyDate(rowData) {
  if (rowData.CallOrPut === 'C') {
    if (rowData.properties.underlyingPrice > rowData.StrikePrice) {
      return 'ITM';
    } else if (rowData.properties.underlyingPrice < rowData.StrikePrice) {
      return 'OTM';
    } else {
      return 'ATM';
    }
  } else {
    if (rowData.properties.underlyingPrice < rowData.StrikePrice) {
      return 'ITM';
    } else if (rowData.properties.underlyingPrice > rowData.StrikePrice) {
      return 'OTM';
    } else {
      return 'ATM';
    }
  }
}

export function createOptionsRowData(rowData) {
  if (rowData.properties) {
    return {
      id: rowData.id > 0 ? rowData.id : rowData.recordId,
      docId: rowData.docId,
      recordId: rowData.recordId,
      description: rowData.description,
      dte: getDaysFromDate(getEpochDate(rowData.ExpirationDate)),
      hour: getHourFromDate(rowData.processedDate),
      expiration: getDateFormatted(getEpochDate(rowData.ExpirationDate)),
      expirationDate: getEpochDate(rowData.ExpirationDate).toDate(),
      expirationDayOfWeek: moment(getEpochDate(rowData.ExpirationDate)).format('dddd'),
      expirationSameWeek: moment(getEpochDate(rowData.ExpirationDate)).isSame(moment().utc(), 'week'),
      symbol: rowData.symbol,
      CallOrPut: rowData.CallOrPut === 'P' ? 'PUTS' : 'CALLS',
      StrikePrice: rowData.StrikePrice,
      optionActivityType: rowData.properties.optionActivityType,
      sentiment: rowData.properties.sentiment,
      size: rowData.properties.size,
      premium: (rowData.properties.size * rowData.properties.price * 100) / 1000,
      premiumOrg: rowData.properties.size * rowData.properties.price * 100,
      underlyingPrice: rowData.properties.underlyingPrice,
      time: getTimeFromDate(rowData.processedDate),
      processedDateTime: getDateTimeFromDate(rowData.processedDate),
      processedDate: rowData.processedDate,
      details: CommaFormatted(rowData.properties.size) + ' @ $' + CommaFormatted(rowData.properties.price),
      money: getMoneyDate(rowData),
      volume: rowData.properties.volume,
      openInterest: rowData.properties.openInterest,
      underlyingType: rowData.properties.underlyingType,
      optionSymbol: rowData.properties.optionSymbol,
      unusual: rowData.properties.size > rowData.properties.dayVolume * 2 || rowData.properties.tradeCount >= 35 ? 'Yes' : 'No',
      price: CommaFormatted(rowData.properties.price),
      sector: rowData.Sector,
      industry: rowData.Industry
    };
  } else {
    return {
      id: Math.random(),
      docId: Math.random(),
      recordId: Math.random(),
      description: '',
      dte: '',
      hour: '',
      expiration: '',
      expirationDate: '',
      expirationDayOfWeek: '',
      expirationSameWeek: '',
      symbol: '',
      CallOrPut: '',
      StrikePrice: '',
      optionActivityType: '',
      sentiment: '',
      size: '',
      premium: '',
      premiumOrg: '',
      underlyingPrice: '',
      time: '',
      processedDateTime: '',
      processedDate: '',
      details: '',
      money: '',
      volume: '',
      openInterest: '',
      underlyingType: '',
      optionSymbol: '',
      unusual: '',
      price: '',
      sector: '',
      industry: ''
    };
  }
}

export function getTweetText(rowData) {
  var templateParams = {
    symbol: rowData.symbol,
    strike_price: '$' + rowData.StrikePrice,
    call_put: rowData.CallOrPut,
    size: CommaFormatted(rowData.size),
    price: '$' + rowData.price,
    expiration: rowData.expiration,
    premium: '$' + nFormatter(rowData.premium, 1),
    sentiment: rowData.sentiment,
    volume: CommaFormatted(rowData.volume),
    open_interest: CommaFormatted(rowData.openInterest),
  };

  var text =
    'MARKET ACTION OPTION ALERT:\n${symbol} {strike_price} {call_put} ({size} @ {price}) {expiration}; Premium: {premium}; {sentiment}; Vol/OI: {volume}/{open_interest} \n\r ' +
    appConstants.AppUrl;

  var templateData = text.replace(/\{(.*?)\}/g, function(match, property) {
    return templateParams[property];
  });

  return 'https://twitter.com/intent/tweet?text=' + escape(templateData);
}

export function getDefaultTemplateText() {
  return 'OPTION ALERT: ${symbol} {strike_price} {call_put} ({size} @ {price}) {expiration}; Premium: {premium}; {sentiment}; Vol/OI: {volume}/{open_interest}';
}

export const filtersDdlData = [
  { value: 'calls', name: 'Calls' },
  { value: 'puts', name: 'Puts' },
  { value: 'weekly', name: 'Weeklys' },
  { value: 'vooi', name: 'Vol Over OI' },
  { value: 'stock', name: 'Stock' },
  { value: 'etf', name: 'ETF' },
  { value: 'sweep', name: 'SWEEP' },
  { value: 'golden_sweep', name: 'Golden Sweeps' },
  { value: 'unusual', name: 'Unusual' },
  { value: 'directional', name: 'Directional' },
  { value : 'otm', name : "OTM"},
  {value : 'atm', name : 'ATM'},
  {value : 'itm', name : 'ITM'}
];

export const filtersAlertDdlData = [
  { value: 'calls', name: 'Calls' },
  { value: 'puts', name: 'Puts' },
  { value: 'weekly', name: 'Weeklys' },
  { value: 'vooi', name: 'Vol Over OI' },
  { value: 'stock', name: 'Stock' },
  { value: 'etf', name: 'ETF' },
  { value: 'sweep', name: 'SWEEP' },
  { value: 'golden_sweep', name: 'Golden Sweeps' },
  { value: 'unusual', name: 'Unusual' },
  { value: 'directional', name: 'Directional' },
  { value: 'bullish', name: 'Bullish' },
  { value: 'bearish', name: 'Bearish' },
  { value: 'otm', name: 'OTM' },
  { value: 'itm', name: 'ITM' },
];

export function filterOptionsData(selectedFilters, rows) {
  for (var i = 0; i < selectedFilters.length; i++) {
    var currentFilter = selectedFilters[i].value;
    if (currentFilter !== '') {
      switch (currentFilter) {
        case 'calls':
        case 'puts': {
          rows = rows.filter(row => {
            if (row.CallOrPut.toLowerCase() === currentFilter) {
              return row;
            }
          });
          break;
        }
        case 'weekly': {
          rows = rows.filter(row => {
            if (row.expirationSameWeek === true && row.expirationDayOfWeek.toLowerCase() === 'friday') {
              return row;
            }
          });
          break;
        }
        case 'vooi': {
          rows = rows.filter(row => {
            if (row.volume > row.openInterest) {
              return row;
            }
          });
          break;
        }
        case 'stock':
        case 'etf': {
          rows = rows.filter(row => {
            if (row.underlyingType.toLowerCase() === currentFilter) {
              return row;
            }
          });
          break;
        }
        case 'otm':
        case 'itm':
        case 'atm': {
          rows = rows.filter(row => {
            if (row.money.toLowerCase() === currentFilter) {
              return row;
            }
          });
          break;
        }
        case 'sweep': {
          rows = rows.filter(row => {
            if (row.optionActivityType.toLowerCase() === currentFilter) {
              return row;
            }
          });
          break;
        }
        case 'golden_sweep': {
          rows = rows.filter(row => {
            if (row.optionActivityType.toLowerCase() === 'sweep' && row.premium >= 1000) {
              return row;
            }
          });
          break;
        }
        case 'unusual': {
          rows = rows.filter(row => {
            if (row.unusual.toLowerCase() === 'yes') {
              return row;
            }
          });
          break;
        }
        case 'directional': {
          rows = rows.filter(row => {
            if ((row.CallOrPut.toLowerCase() === 'calls' && row.sentiment.toLowerCase() === 'bullish') || (row.CallOrPut.toLowerCase() === 'puts' && row.sentiment.toLowerCase() === 'bearish')) {
              return row;
            }
          });
          break;
        }
        default:
          break;
      }
    }
  }
  return rows;
}

export function filterByDate(selectedDate, rows) {
  rows.filter(row => {
    if (moment(selectedDate.StartDate).isSameOrAfter(moment(row.processedDate), 'date') && moment(selectedDate.EndDate).isSameOrBefore(moment(row.processedDate), 'date')) {
      return row;
    }
  });
  return rows;
}

export function getTotal(filteredRows, callOrPut) {
  return filteredRows.filter(st => st.CallOrPut === callOrPut).length;
}

export function getTotalSentiment(filteredRows, sentiment) {
  return filteredRows.filter(st => st.sentiment.toLowerCase() === sentiment).length;
}

export function getTotalSentimentPremium(filteredRows, sentiment) {
  var total = 0;
  filteredRows.forEach(st => {
    if (st.sentiment.toLowerCase() === sentiment) {
      total += st.premiumOrg;
    }
  });

  if (total > 0) {
    return nFormatter(total / 1000, 1);
  }
  return 0;
}

export function getSentimentScore(filteredRows) {
  var bullish = filteredRows.filter(st => st.sentiment.toLowerCase() === 'bullish').reduce((a, b) => a + b.premiumOrg, 0);
  var bearish = filteredRows.filter(st => st.sentiment.toLowerCase() === 'bearish').reduce((a, b) => a + b.premiumOrg, 0);
  var neutral = filteredRows.filter(st => st.sentiment.toLowerCase() === 'neutral').reduce((a, b) => a + b.premiumOrg, 0);
  var total = filteredRows.reduce((a, b) => a + b.premiumOrg, 0) - neutral;
  if (bullish > 0) {
    return Math.round((bullish / total) * 100);
  } else if (bearish > 0) {
    return 100 - Math.round((bearish / total) * 100);
  } else if (neutral > 0) {
    return 50;
  }
  return 0;
}

export function getSentimentScoreText(filteredRows) {
  var score = getSentimentScore(filteredRows);
  if (score > 50) {
    if (score > 75) {
      return 'Very Bullish - ' + score + '%';
    } else {
      return 'Slightly Bullish - ' + score + '%';
    }
  } else if (score < 50) {
    if (score < 25) {
      return 'Very Bearish - ' + score + '%';
    } else {
      return 'Slightly Bearish - ' + score + '%';
    }
  } else {
    return 'NEUTRAL - ' + score + '%';
  }
}

export function getPutToCallRatio(filteredRows) {
  var totalCalls = getTotal(filteredRows, 'CALLS');
  var totalPuts = getTotal(filteredRows, 'PUTS');
  if (totalCalls > 0) {
    return (totalPuts / totalCalls).toFixed(2);
  }
  return 0;
}
export function priceLevelSymbolFormatter({ value, difference, style }) {
  if (!value) {
    return;
  }
  if (difference < 0) {
    return <RedChip label={value} size="small" style={{ ...style }} />;
  } else {
    return <GreenChip label={value} size="small" style={{ ...style }} />;
  }
}
export const priceLevelTextFormatter = ({ value, difference, style }) => {
  if (difference < 0) {
    return <span style={{ color: '#f44336', ...style }}>{value}</span>;
  } else {
    return <span style={{ color: '#00b399', ...style }}>{value}</span>;
  }
};

const RuleType = {
  Number: 1,
  Range: 2,
  GreaterThen: 3,
  LessThen: 4,
};

export function filterValues(filterValue, value) {
  var filterTerm = getRules(filterValue);
  filterTerm = filterTerm.length > 0 ? filterTerm : null;

  if (filterTerm == null) {
    return true;
  }

  let result = false;
  // implement default filter logic
  for (const ruleKey in filterTerm) {
    if (!filterTerm.hasOwnProperty(ruleKey)) {
      continue;
    }

    const rule = filterTerm[ruleKey];
    switch (rule.type) {
      case RuleType.Number:
        if (rule.value === value) {
          result = true;
        }
        break;
      case RuleType.GreaterThen:
        if (rule.value <= value) {
          result = true;
        }
        break;
      case RuleType.LessThen:
        if (rule.value >= value) {
          result = true;
        }
        break;
      case RuleType.Range:
        if (rule.begin <= value && rule.end >= value) {
          result = true;
        }
        break;
      default:
        // do nothing
        break;
    }
  }

  return result;
}

function getRules(value) {
  const rules = [];
  if (value === '') {
    return rules;
  }
  // check comma
  const list = value.split(',');
  if (list.length > 0) {
    // handle each value with comma
    for (const key in list) {
      if (!list.hasOwnProperty(key)) {
        continue;
      }

      const obj = list[key];
      if (obj.indexOf('-') > 0) {
        // handle dash
        const begin = parseFloat(obj.split('-')[0], 10);
        const end = parseFloat(obj.split('-')[1], 10);
        rules.push({ type: RuleType.Range, begin: begin, end: end });
      } else if (obj.indexOf('>') > -1) {
        // handle greater then
        const begin = parseFloat(obj.split('>')[1], 10);
        rules.push({ type: RuleType.GreaterThen, value: begin });
      } else if (obj.indexOf('<') > -1) {
        // handle less then
        const end = parseFloat(obj.split('<')[1], 10);
        rules.push({ type: RuleType.LessThen, value: end });
      } else {
        // handle normal values
        const numericValue = parseFloat(obj, 10);
        rules.push({ type: RuleType.Number, value: numericValue });
      }
    }
  }
  return rules;
}


export function getSubtractDate(date, value, type) {
  return moment(date, ['YYYY-MM-DD H:mm:ss', 'YYYY-MM-DDTHH:mm:ss.SSSZ']).subtract(value, type)
      .tz('America/New_York')
      .format('YYYY-MM-DDTHH:mm:ss.SSSZ');
}