import AuthWrapper from '@jumbo/components/Common/authComponents/AuthWrapper';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import {
  Box,
  Button,
  CircularProgress,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch } from 'react-redux';
import { fetchError } from 'redux/actions';
import { AuhMethods } from 'services/auth';
import { updateUserAgreementData, updateUserData } from 'services/auth/firebase/FirebaseRealTimeDb';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fafafa',
    padding: 8,
    borderRadius: 8,
    // border: 'solid 2px yellow',
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    padding: 24,
    // overflowY: 'scroll',
    // maxHeight: 600,
    textAlign: 'left',
    // border: 'solid 2px yellow',
  },
  divider: {
    height: 2,
    backgroundColor: '#fff',
    width: '100%',
    marginTop: 8,
    marginBottom: 8,
  },
  list: {
    paddingLeft: 16,
    '& li': {
      marginTop: 8,
    },
  },
}));

export default function Agreement({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [agreeTerms, setAgreeTerms] = useState({
    agree1: false,
    agree2: false,
    agree3: false,
    agree4: false,
    agree5: false,
    agree6: false,
  });
  const [userData, setUserDate] = useState({
    firstName: '',
    lastName: '',
    street: '',
    country: '',
    zipCode: '',
  });
  const [isSubmitTrue, setIsSubmitTrue] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const formRef = useRef();

  const handleChange = event => {
    setUserDate({ ...userData, [event.target.name]: event.target.value });
  };
  const handleChangeSwitch = event => {
    setAgreeTerms({ ...agreeTerms, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    if (error) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [error]);

  const onSubmit = () => {
    if (isSubmitTrue) {
      setLoading(true);
      updateUserAgreementData({
        isAgreementDone: true,
        userDetails: userData,
      })
        .then(res => {
          if (res.success) {
            dispatch(fetchError('Submitted.Thank you'));
            setLoading(false);
            setTimeout(() => {
              dispatch(AuhMethods[CurrentAuthMethod].getAuthUser(true));
            }, 1000);
          }
        })
        .catch(error => {
          setLoading(false);
          dispatch(fetchError('Error ' + error.message));
        });
    }
  };

  useEffect(() => {
    if (
      agreeTerms.agree1 &&
      agreeTerms.agree2 &&
      agreeTerms.agree3 &&
      agreeTerms.agree4 &&
      agreeTerms.agree5 &&
      agreeTerms.agree6
    ) {
      setIsSubmitTrue(true);
    } else {
      setIsSubmitTrue(false);
    }
  }, [agreeTerms]);

  return (
    <div className={classes.root}>
      {/* <AuthWrapper variant={wrapperVariant}> */}
      <Grid container className={classes.container} spacing={2}>
        <ValidatorForm onSubmit={onSubmit} onError={errors => setError(errors)}>
          <Grid xs={12} item style={{ padding: 18, textAlign: 'center' }}>
            <Typography variant="h3"> Final Step</Typography>
          </Grid>
          <Grid item>
            <Paper style={{ backgroundColor: '#eee', padding: 8, color: '#000', marginTop: 18 }}>
              <Typography variant="subtitle2">
                In order for Market Action to be able to provide you access to realtime data, you must agree to the terms
                below. Once you fill this out, you’ll get immediate access.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} className={classes.block} style={{ padding: 25, textAlign: 'center', borderRadius: 4 }}>
            <Typography variant="subtitle1">OPTIONS PRICE REPORTING AUTHORITY</Typography>
            <Typography variant="subtitle2">Electronic subscriber agreement</Typography>
          </Grid>
          <Grid
            container
            style={{ height: 400, overflowY: 'scroll', border: '1px solid #eee', padding: 8, borderRadius: 4 }}>
            <Grid item>
              <Typography variant="caption">
                IMPORTANT NOTICE: THIS SUBSCRIBER AGREEMENT (THIS “AGREEMENT”) IS AN AGREEMENT BETWEEN YOU AND MARKET ACTION
                LLC FOR YOU TO RECEIVE INFORMATION PUBLISHED BY THE OPTIONS PRICE REPORTING AUTHORITY, LLC (“OPRA”). PLEASE
                READ THIS AGREEMENT CAREFULLY. AFTER YOU HAVE READ THIS AGREEMENT, PLEASE INDICATE YOUR AGREEMENT TO BE BOUND
                BY ITS TERMS AND CONDITIONS BY CLICKING ON THE “I AGREE” BUTTON AT THE END.IF YOU DO NOT AGREE TO THESE TERMS
                AND CONDITIONS, YOU WILL BE UNABLE TO RECEIVE THE INFORMATION.
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item>
                <Typography variant="body2">
                  By completing and submitting this Agreement, you are applying to receive from Market Action LLC, Chino
                  hills, CA 91709 (“Vendor”) a market data service (the “Service”) providing access to current options last
                  sale and quotation information and related information (“OPRA Data”) published by OPRA pursuant to a Plan
                  declared effective by the Securities and Exchange Commission. The parties to this Plan (each, an “OPRA
                  Participant”) are those national securities exchanges that are from time to time approved by the Securities
                  and Exchange Commission for the trading of securities options. In reviewing and approving this Agreement,
                  Vendor is authorized to act on behalf of OPRA. The person who acts from time to time as data processor on
                  behalf of OPRA is referred to herein as “OPRA’s Processor.”
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  By completing and submitting this Agreement and clicking on the “I agree” button at the end of this
                  Agreement, you are consenting to enter into this Agreement in electronic form. You have the right to
                  withdraw your consent by terminating this Agreement and your receipt of the OPRA Data. Your right to
                  terminate this Agreement and your receipt of the OPRA Data, and the procedure you must follow to do so, are
                  described in paragraph 6 below. If any information needed to contact you electronically changes, the
                  procedure for notifying Vendor is described in paragraph 11 below. If you wish to have a copy of this
                  Agreement in paper form and you are unable to print a copy on your own computer system, Vendor will provide
                  you with a paper copy at no charge upon its receipt of your request transmitted as described in paragraph
                  11. You may access a copy of this Agreement electronically at no charge)
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  This Agreement includes an “Addendum for Nonprofessionals.” The term “Nonprofessional” is defined in the
                  Addendum. The purpose of the Addendum is to determine whether you are a Nonprofessional under this
                  definition. If you are a Nonprofessional under this definition, OPRA’s charges to Vendor for your use of
                  the OPRA Data are subject to a cap, and you may be entitled to pay lower fees to Vendor. You do not need to
                  complete the Addendum, but if you do not do so, or if you cannot agree with all of the statements in the
                  Addendum, OPRA will not consider you to be a Nonprofessional.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: 16, marginBottom: 16 }}>
            <Typography variant="subtitle2">You hereby represent and agree as follows:</Typography>
          </Grid>

          <hr className={classes.divider} />

          <Grid item ref={formRef} style={{ marginBottom: 16 }}>
            <Typography variant="body2">1. Your full name and address are:</Typography>

            <TextValidator
              name="firstName"
              margin="normal"
              id="fname"
              label="First Name"
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              value={userData.firstName}
              validators={['required']}
              errorMessages={['This field is required!']}
            />
            <TextValidator
              name="lastName"
              margin="normal"
              id="lname"
              label="Last Name"
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              value={userData.lastName}
              validators={['required']}
              errorMessages={['This field is required!']}
            />
            <TextValidator
              name="street"
              margin="normal"
              id="street"
              label="Full Street address"
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              value={userData.street}
              validators={['required']}
              errorMessages={['This field is required!']}
            />
            <TextValidator
              name="country"
              margin="normal"
              id="country"
              label="Country"
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              value={userData.country}
              validators={['required']}
              errorMessages={['This field is required!']}
            />
            <TextValidator
              name="state"
              margin="normal"
              id="state"
              label="State"
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              value={userData.state}
              validators={['required']}
              errorMessages={['This field is required!']}
            />
            <TextValidator
              name="zipCode"
              margin="normal"
              id="zip"
              label="Zip code"
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              value={userData.zipCode}
              validators={['required']}
              errorMessages={['This field is required!']}
            />
          </Grid>
          <Grid
            item
            style={{
              height: 400,
              overflowY: 'scroll',
              border: '1px solid #eee',
              padding: 8,
              borderRadius: 4,
              marginBottom: 8,
            }}>
            <ol start="2" className={classes.list}>
              <li>
                <Typography variant="body2">
                  You shall receive the Service and the OPRA Data included therein solely for your own business or personal
                  use, and you shall not retransmit or otherwise furnish the OPRA Data to any person, other than your own
                  employees on devices that are subject to the control of Vendor. If you are a Nonprofessional and have
                  completed the Addendum for Nonprofessionals, you are only permitted under this Agreement to use the OPRA
                  Data for the investment activities described in the Addendum for Nonprofessionals.
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  You acknowledge that OPRA Data is and shall remain the property of the OPRA Participant on which a reported
                  transaction took place or a reported quotation was entered.
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  DISCLAIMER OF LIABILITY -- NEITHER VENDOR, OPRA, OPRA’S PROCESSOR NOR ANY OPRA PARTICIPANT GUARANTEES THE
                  TIMELINESS, SEQUENCE, ACCURACY OR COMPLETENESS OF ANY OF THE OPRA DATA SUPPLIED TO YOU HEREUNDER AND
                  NEITHER VENDOR, OPRA, OPRA’S PROCESSOR NOR ANY OPRA PARTICIPANT SHALL BE LIABLE IN ANY WAY, TO YOU OR TO
                  ANY OTHER PERSON, FOR ANY LOSS, DAMAGES, COST OR EXPENSE WHICH MAY ARISE FROM ANY FAILURE OF PERFORMANCE BY
                  VENDOR, OPRA, OPRA’S PROCESSOR OR ANY OPRA PARTICIPANT, OR FROM ANY DELAYS, INACCURACIES, ERRORS IN OR
                  OMISSIONS OF, ANY OF THE OPRA DATA OR IN THE TRANSMISSION OR DELIVERY THEREOF, WHETHER OR NOT DUE TO ANY
                  NEGLIGENT ACT OR OMISSION ON THE PART OF VENDOR, OPRA, OPRA’S PROCESSOR OR ANY OPRA PARTICIPANT. IN NO
                  EVENT SHALL VENDOR, OPRA, OPRA’S PROCESSOR OR ANY PARTICIPANT BE LIABLE FOR ANY INCIDENTAL, SPECIAL,
                  INDIRECT OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOST PROFITS, TRADING LOSSES, OR DAMAGES
                  RESULTING FROM INCONVENIENCE OR LOSS OF USE OF THE SERVICE.
                </Typography>
              </li>
              <li>
                {' '}
                <Typography variant="body2">
                  The terms of this Agreement may be modified at any time upon notice to you. If you do not assent to this
                  Agreement as modified at or prior to the time you next attempt to access the Service, this Agreement shall
                  automatically be terminated. This Agreement as modified shall apply to your use of the Service from and
                  after the date of the modification.
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Your receipt of the OPRA Data hereunder may be terminated at any time by you or by Vendor upon 30 days
                  notice from the terminating party to the other party, and may be terminated immediately upon a
                  determination by Vendor or OPRA that you are not in compliance with this Agreement.
                </Typography>
              </li>
              <li>
                {' '}
                <Typography variant="body2">
                  Your receipt of the OPRA Data hereunder may be terminated at any time by you or by Vendor upon 30 days
                  notice from the terminating party to the other party, and may be terminated immediately upon a
                  determination by Vendor or OPRA that you are not in compliance with this Agreement.
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Nothing herein shall be deemed to prevent or restrict OPRA, OPRA’s Processor or any OPRA Participant from
                  discontinuing to furnish OPRA Data for dissemination or from making such changes in the speed of
                  transmission, the characteristics of the electrical signals representing the OPRA Data or the manner of
                  disseminating the same, as OPRA shall from time to time determine to be appropriate, with or without notice
                  to you. You shall not hold OPRA, OPRA’s Processor, or any OPRA Participant liable for any resulting
                  liability, loss or damage that may arise therefrom.
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  You agree to notify Vendor promptly of any changes in the information provided herein and to furnish Vendor
                  any additional information requested by it in connection with your receipt of the OPRA Data.
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  The parties acknowledge and agree that this Agreement is for the express benefit of OPRA, OPRA’s Processor
                  and each OPRA Participant.
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  The provisions of Sections 3, 4 and 9 will survive any termination of this Agreement and will remain in
                  full force and effect.
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  All notices under this Agreement may be provided either in writing or electronically. All written notices
                  to Vendor shall be sent to the Vendor’s street address set forth above and all such notices to you shall be
                  sent to the street address that you provide in paragraph 1. All electronic notices to Vendor shall be sent
                  to Vendor’s email address set forth above and all such notices to you shall be provided to you where you
                  access the OPRA Data electronically.
                </Typography>
              </li>
            </ol>
          </Grid>

          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="caption">
                IF YOU AGREE TO THE TERMS AND CONDITIONS SET FORTH ABOVE, PLEASE TYPE IN YOUR NAME AND ADDRESS IN THE SPACES
                PROVIDED ABOVE AND CLICK ON THE “I AGREE” BUTTON BELOW. By clicking on the “I AGREE” button below and typing
                in your name as indicated above, you agree that:
              </Typography>
            </Grid>
            <Grid item>
              <ol className={classes.list}>
                <li>
                  <Typography variant="subtitle2">
                    You have read and you understand all of the terms and conditions set forth above; and
                  </Typography>
                </li>
                <li>
                  <Typography variant="subtitle2">
                    You intend to form a legally binding and valid contract under which you will bebound by all of the terms
                    and conditions set forth above.
                  </Typography>
                </li>
              </ol>
              <FormControlLabel
                control={<Switch checked={agreeTerms.agree1} onChange={handleChangeSwitch} name="agree1" color="primary" />}
                label="I AGREE"
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">ADDENDUM FOR NONPROFESSIONALS</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">
              The purpose of this Addendum is to determine whether you are a “Nonprofessional” for OPRA’s purposes. OPRA
              defines a “Nonprofessional” as a legal person for whom the statements set out in Section 1 of this Addendum are
              true.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">
              (1) You represent and agree that the following statements are and will continue to be true for so long as you
              receive OPRA Data as a Nonprofessional:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">
              (a) You are either a “natural person” (an individual human being) or a “qualifying trust.”* You are not a
              corporation, partnership, limited liability company, or other form of entity (including any form of trust that
              does not qualify as a qualifying trust). If you agree, click on “I AGREE”:
            </Typography>
            <FormControlLabel
              control={<Switch checked={agreeTerms.agree2} onChange={handleChangeSwitch} name="agree2" color="primary" />}
              label="I AGREE"
            />
          </Grid>
          <Grid item>
            <Typography variant="body2">
              (b) If you are a natural person, you shall use the OPRA Data solely in connection with your personal investment
              activities and the personal investment activities of your immediate family members** and qualifying trusts of
              which you are the trustee or custodian. If you are a qualifying trust, you shall use the OPRA Data solely in
              connection with your personal investment activities. In any case, you shall not use the OPRA Data in connection
              with any trade, business, professional or other commercial activities. If you agree, click on “I AGREE”:
            </Typography>
            <FormControlLabel
              control={<Switch checked={agreeTerms.agree3} onChange={handleChangeSwitch} name="agree3" color="primary" />}
              label="I AGREE"
            />
          </Grid>
          <Grid item>
            <Typography variant="body2">
              (c) You are not a “Professional.” For a natural person who works in the United States, a “Professional” is a
              natural person who is: (i) registered or qualified with the Securities and Exchange Commission, the Commodities
              Futures Trading Commission, any state securities agency, any securities exchange/association, or any
              commodities/futures contract market/association, (ii) engaged as an “investment adviser,” as that term is
              defined in the Investment Advisers Act of 1940 (whether or not registered or qualified under that Act); or
              (iii) employed by a bank or other organization exempt from registration under Federal and/or state securities
              laws to perform functions that would require you to be so registered or qualified if you were to perform such
              functions for an organization not so exempt. For a natural person who works outside of the United States, a
              “Professional” is a natural person who performs the same functions as someone who would be considered a
              “Professional” in the United States. If you agree that you are not a “Professional”, click on “I AGREE”:
            </Typography>
            <FormControlLabel
              control={<Switch checked={agreeTerms.agree4} onChange={handleChangeSwitch} name="agree4" color="primary" />}
              label="I AGREE"
            />
          </Grid>
          <Grid item>
            <Typography variant="body2">
              (2) You agree to notify Vendor promptly if your circumstances change such that any of the statements in Section
              1 of this Addendum would no longer be true for you.
            </Typography>
            <FormControlLabel
              control={<Switch checked={agreeTerms.agree5} onChange={handleChangeSwitch} name="agree5" color="primary" />}
              label="I AGREE"
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="body2" style={{ fontStyle: 'italic' }}>
                *The term “qualifying trust” means (a) any irrevocable or revocable trust (1) which has only one trustee, who
                is a natural person and is not receiving any compensation for acting as trustee and (2) of which the only
                current beneficiaries are any one or more of the trustee and the immediate family members of the trustee, and
                (b) any custodial account established under a Uniform Transfers to Minors Act or similar state statute (1)
                which has only one custodian, who is a natural person and is not receiving any compensation for acting as
                custodian, and (2) of which the beneficiary is a lineal descendant (a child, grandchild, etc.) of the
                custodian. A “current beneficiary” is a beneficiary to whom the current income or principal of the trust may
                or must then be distributed, ignoring the possible exercise of any then unexercised power of appointment. The
                term “immediate family members” is defined in the footnote to paragraph 1(b) of this Addendum.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" style={{ fontStyle: 'italic' }}>
                **The term “immediate family members” means, with reference to a particular natural person, the spouse of
                that person, that person’s lineal ancestors (that is, parents, grandparents, etc.) and lineal descendants
                (that is, children, grandchildren, etc.), and the spouses (including surviving spouses) of that person’s
                lineal ancestors and lineal descendants. The term includes step and adoptive relationships. 12/2014
              </Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={<Switch checked={agreeTerms.agree6} onChange={handleChangeSwitch} name="agree6" color="primary" />}
                label="I AGREE"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 45 }}>
            <Grid container spacing={2} justify="center">
              <Grid item>
                <Button variant="contained" onClick={() => setOpenDialog(true)}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                {loading ? (
                  <CircularProgress size={24} style={{ marginLeft: 8, marginTop: 8 }} />
                ) : (
                  <Button variant="contained" color="primary" type="submit" disabled={!isSubmitTrue}>
                    Submit Agreement
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
        <Box>
          <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title"> Are you sure ? </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This action will log you out.Do you want to proceed ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)} color="primary" variant="contained">
                No
              </Button>
              <Button
                onClick={() => dispatch(AuhMethods[CurrentAuthMethod].onLogout())}
                color="primary"
                autoFocus
                variant="contained">
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Grid>
      {/* </AuthWrapper> */}
    </div>
  );
}
