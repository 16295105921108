import React, { useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import { Box, Button, Badge, MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DropNCrop from '@synapsestudios/react-drop-n-crop';
import '@synapsestudios/react-drop-n-crop/lib/react-drop-n-crop.min.css';
import CmtAvatar from '@coremat/CmtAvatar';
import './Steps.css';
import DiscordIcon from './icons8-discord.svg';
import { auth } from '../../../../services/configs/config';
import { useDispatch, useSelector } from 'react-redux';
import { fetchError } from 'redux/actions';

export function Step1({ updateEnteries, setUpdateEntries }) {
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const [imageData, setImageData] = React.useState(null);
  const [isClicked, setIsClicked] = React.useState(false);
  const [showUploader, setShowUploader] = React.useState(false);

  useEffect(() => {
    if(auth.currentUser.photoURL === null && updateEnteries.imageData.src === null){
      setShowUploader(true);
    }
    else {
      setShowUploader(false);
    }
  }, []);

  const onChange = value => {
    // setUpdateEntries({ ...updateEnteries, imageData: { ...value } });
    setImageData(value);
  };

  const handleClickSetImage = () => {
    setIsClicked(false);
    setShowUploader(true);
  };
  const handleClick = () => {
    if(imageData === null){
      dispatch(fetchError(`Please select an image to upload!`));
      return;
    }
    setUpdateEntries({ ...updateEnteries, imageData: imageData });
    setIsClicked(true);
    setShowUploader(false);
  };
  const handleClear = () => {
    setUpdateEntries({
      ...updateEnteries,
      imageData: { result: null, filename: null, filetype: null, src: null, error: null },
    });
    setImageData(null);
    setShowUploader(true);
  };

  let URL = '';
  return (
    <div className="step1">
      <p className="step_header">Please upload a profile picture</p>
      <div>
        {showUploader ? (
          <React.Fragment>
            <DropNCrop
              onChange={onChange}
              maxFileSize={5000000}
              cropperOptions={{ initialAspectRatio: 1 / 1, aspectRatio: 1 }}
              value={imageData}
              canvasHeight="270px"
            />
            <div className="dropncrop-btn">
              <Button onClick={handleClear} color="primary" variant="contained" className="btn">
                Cancel
              </Button>
              <Button onClick={handleClick} color="primary" variant="contained" className="btn">
                Upload
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <Badge
            onClick={handleClickSetImage}
            overlap="circle"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            badgeContent={<CreateIcon cursor={'pointer'} />}>
            {updateEnteries.imageData.result !== null ? (
              <CmtAvatar size={80} src={updateEnteries.imageData.result} alt={authUser.displayName} />
            ) : (
              <CmtAvatar size={80} src={authUser.photoURL} alt={authUser.displayName} />
            )}
          </Badge>
        )}
      </div>
    </div>
  );
}

export function Step2({ updateEnteries, setUpdateEntries }) {
  const [state, setState] = React.useState({
    dayTrader: false,
    swingTrader: false,
    optionsTrader: false,
    stocksTraders: false,
    etfTrader: false,
    spyTrader: false,
  });

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
    setUpdateEntries({
      ...updateEnteries,
      traderType: { ...updateEnteries.traderType, [event.target.name]: event.target.checked },
    });
  };
  function ExperienceLevel() {
    const [level, setLevel] = React.useState('');

    const handleExperienceLevel = event => {
      setUpdateEntries({
        ...updateEnteries,
        experienceLevel: event.target.value,
      });
    };
    return (
      <Box className="experience__level">
        <p className="step_header">Please select your experience level</p>
        <FormControl className="form_control">
          <InputLabel id="demo-simple-select-label">Experience Level</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={updateEnteries.experienceLevel}
            onChange={handleExperienceLevel}>
            <MenuItem value="Beginner">Beginner</MenuItem>
            <MenuItem value="Intermediate">Intermediate</MenuItem>
            <MenuItem value="Expert">Expert</MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
  }
  return (
    <React.Fragment>
      <ExperienceLevel />

      <p className="step_header_2">What kind of trader are you ?</p>
      <FormGroup row className="form-group">
        <FormControlLabel
          control={<Checkbox checked={state.dayTrader} onChange={handleChange} name="dayTrader" />}
          label="Day trader"
        />
        <FormControlLabel
          control={<Checkbox checked={state.swingTrader} onChange={handleChange} name="swingTrader" />}
          label="Swing trader"
        />
        <FormControlLabel
          control={<Checkbox checked={state.optionsTrader} onChange={handleChange} name="optionsTrader" />}
          label="Options trader"
        />
        <FormControlLabel
          control={<Checkbox checked={state.stocksTraders} onChange={handleChange} name="stocksTraders" />}
          label="Stocks trader"
        />
        <FormControlLabel
          control={<Checkbox checked={state.etfTrader} onChange={handleChange} name="etfTrader" />}
          label="ETF trader"
        />
        <FormControlLabel
          control={<Checkbox checked={state.spyTrader} onChange={handleChange} name="spyTrader" />}
          label="SPY trader"
        />
      </FormGroup>
    </React.Fragment>
  );
}
export function Step4() {
  const embedId = '9HKpR0Fn37E';
  return (
    <div className="video-responsive">
      <p className="step_header">Watch our walk through video</p>
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
}
export function Step3() {
  return (
    <div className="step4">
      <p className="step_header">Click the discord logo below to join our discord server</p>
      <a href="https://discord.gg/XBkuVMQ7pC" target="_blank" rel="noopener noreferrer">
        <img src={DiscordIcon} alt="Join our discord" width="90px" style={{ cursor: 'pointer' }} />
      </a>
    </div>
  );
}
