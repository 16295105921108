import React, { useEffect } from 'react';
import CmtList from '@coremat/CmtList';
import PostItem from './PostItem';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import { InfiniteLoader, List, AutoSizer } from 'react-virtualized';
import * as tradeIdeasApiService from '../../../../../../services/rest/TradeIdeasApiService';
import { io } from 'socket.io-client';
import InfiniteScroll from 'react-infinite-scroll-component';

import 'react-virtualized/styles.css'; // only needs to be imported once

const PostsList = () => {
  // const { feedPosts } = useSelector(({ wallApp }) => wallApp);
  const { authUser } = useSelector(({ auth }) => auth);

  const [remoteRowCount, setRemoteRowCount] = React.useState(0);
  const [posts, setPosts] = React.useState([]);

  useEffect(() => {
    loadPosts(0, 20);
  }, []);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_TRADE_IDEAS_WS_URL);
    // const socket = io('ws://api.marketaction.live');

    socket.on('new-post', (newFeedPost) => {
      if(!newFeedPost.likesCount){
        newFeedPost.likesCount = 0;
      }
      setPosts(prevState => [newFeedPost, ...prevState]);
      console.log(newFeedPost);
    })

    socket.on('new-post-comment', (newPostComment) => {
      setPosts((prevState) => {
        const newState = prevState.map(post => {
          if (post._id == newPostComment.wallPost) {
            post.comments = [newPostComment, ...post.comments];
            return post;
          }
          return post;
        });

        return newState;
      });
    })

    socket.on('post-liked', (postLiked) => {
      setPosts((prevState) => {
        const newState = prevState.map(post => {
          if (post._id == postLiked.wallPost) {
            post.likesCount = post.likesCount + 1;
            if(postLiked.likedBy.firebaseUserId == authUser.uid){
              //logged in user. add to his likes
              if(!post.likes){
                post.likes = [];
              }
              post.likes.push(postLiked);
            }
            return post;
          }
          return post;
        });

        return newState;
      });
    })

    socket.on('post-unliked', (postUnliked) => {
      setPosts((prevState) => {
        const newState = prevState.map(post => {
          if (post._id == postUnliked.wallPost) {
            post.likesCount = post.likesCount - 1;
            if(!post.likes){
              post.likes = [];
            }

            if (post.likes.filter(f => f._id == postUnliked._id)[0]) {
              post.likes = [];
            }
            return post;
          }
          return post;
        });

        return newState;
      });
    })

  }, []);

  function loadPosts(startIndex, stopIndex) {
    let newStartIndex = posts.length;

    let pageSize = 20;
    let currentPage = Math.ceil(newStartIndex / pageSize) + 1;

    return tradeIdeasApiService.GetAsync(`wallposts?page=${currentPage}&limit=${pageSize}`, authUser.tradeIdeasToken).then(resp => {
      setPosts(prevState => [...prevState, ...resp.data.results ]);

      // setPosts(resp.data.results);
      setRemoteRowCount(resp.data.totalRecords);
    });
  }

  function refresh(){
    loadPosts(0, 20);
  }

  function isRowLoaded({ index }) {
    return !!posts[index];
  }

  function loadMoreRows({ startIndex, stopIndex }) {
    // return new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve();
    //   }, 500);
    // });
    return loadPosts(startIndex, stopIndex);
  }

  function rowRenderer({ key, index, style }) {
    return (
      <Box mb={6} key={key}>
        <PostItem item={posts[index]} />
      </Box>
    )
  }

  return (
    <InfiniteScroll
    dataLength={remoteRowCount} //This is important field to render the next data
    next={loadPosts}
    hasMore={remoteRowCount > posts.length}
    loader={<h4>Loading...</h4>}
    endMessage={
      <p style={{ textAlign: 'center' }}>
        <b>Yay! You have seen it all</b>
      </p>
    }
    // below props only if you need pull down functionality
    refreshFunction={refresh}
    pullDownToRefresh
    pullDownToRefreshThreshold={50}
    pullDownToRefreshContent={
      <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
    }
    releaseToRefreshContent={
      <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
    }>
      {posts.map((i, index) => (
        <Box mb={6} key={'key_' + index}>
          <PostItem item={posts[index]} />
        </Box>
      ))}
     
    </InfiniteScroll>
  );

  return (<AutoSizer disableWidth>
    {({ height, width }) => (
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={remoteRowCount}
      >
        {({ onRowsRendered, registerChild }) => (
          <List
            height={height}
            onRowsRendered={onRowsRendered}
            ref={registerChild}
            rowCount={remoteRowCount}
            rowHeight={height}
            rowRenderer={rowRenderer}
            width={1}
            containerStyle={{ width: "100%", maxWidth: "100%" }}
            style={{ width: "100%" }}
          />
        )}
      </InfiniteLoader>
    )}
  </AutoSizer>

    // <CmtList
    //   data={feedPosts}
    //   renderRow={(item, index) => (
    //     <Box mb={6} key={index}>
    //       <PostItem item={item} />
    //     </Box>
    //   )}
    // />
  );
};

export default PostsList;
