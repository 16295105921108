import React, { useState, useEffect } from 'react';
import '../Dashboard/index.css';
import * as staticFunctions from '../Dashboard/dashboardFunctions';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtAdvCardContent from '@coremat/CmtAdvCard/CmtAdvCardContent';
import CmtAdvCard from '@coremat/CmtAdvCard';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Box, Grid, Typography } from '@material-ui/core';
import * as appConstants from "../../../Constants/AppConstants";

const useStyles = makeStyles(theme => ({
    cardContentRoot: {
        '& .MuiGrid-container': {
            alignItems: 'center',
        },
    },
    cardContentTitle: {
        marginBottom: 4,
    },
    subTitleRoot: {
        fontSize: 12,
        marginBottom: 0,
        color: theme.palette.text.secondary,
    },
    tooltip: {
        position: 'relative',
        borderRadius: 6,
        padding: '4px 8px',
        backgroundColor: '#8d46ef',
        color: theme.palette.common.white,
    }
}));

export default function CallPutWeeklySummary({ sentimentWeeklySummaryData, userSubscription }) {
    const classes = useStyles();
    const [sentimentWeeklySummaryWidgetHidden, setSentimentWeeklySummaryWidgetHidden] = useState(false);

    if (!sentimentWeeklySummaryWidgetHidden) {
        const callPutRationWidgetActionHandler = event => {
            switch (event.value) {
                case 'close': {
                    return setSentimentWeeklySummaryWidgetHidden(true);
                }
                default:
                    return true;
            }
        };


        function getWeeklySummaryChart() {
            if (userSubscription !== null && userSubscription.role === appConstants.Role_Customer) {
                return (<ResponsiveContainer width="100%" height={300}>
                    <BarChart data={sentimentWeeklySummaryData} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                        <XAxis dataKey="Record" />
                        <YAxis tickFormatter={(value) => {
                            if (value > 0 && value != Infinity && value != -Infinity) {
                                return staticFunctions.CommaFormatted(value);
                            }
                            return value;
                        }} />
                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                        <Tooltip labelStyle={{ color: 'black' }} itemStyle={{ color: 'black' }} cursor={false}
                            //content={<CustomTooltip />}
                            formatter={(value) => {
                                if (value > 0 && value != Infinity && value != -Infinity) {
                                    return staticFunctions.CommaFormatted(value);
                                }
                                return value;
                            }}
                        />
                        <Legend />
                        <defs>
                            <linearGradient id="bearishColor" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#ff1100" stopOpacity={1} />
                                <stop offset="95%" stopColor="#f44336" stopOpacity={1} />
                            </linearGradient>
                        </defs>
                        <defs>
                            <linearGradient id="bullishColor" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#1ABBDE" stopOpacity={1} />
                                <stop offset="95%" stopColor="#00B399" stopOpacity={1} />
                            </linearGradient>
                        </defs>
                        <Bar dataKey="Calls" fill="url(#bullishColor)" />
                        <Bar dataKey="Puts" fill="url(#bearishColor)" />
                    </BarChart>
                </ResponsiveContainer>);
            }
            else {
                return <Typography component="h5">Weekly Summary is only available in Paid plans</Typography>
            }
        }

        return (
            <Grid item xs={12} sm={12} md={6}>
                <CmtAdvCard>
                    <CmtCardHeader
                        className="pt-2 pb-2"
                        actionsPos="top-corner"
                        actions={[{ label: 'Close', value: 'close' }]}
                        actionHandler={callPutRationWidgetActionHandler}
                        title={
                            <Typography
                                {...{
                                    variant: 'h4',
                                    component: 'div',
                                }}>
                                7 Days Call/Put Summary
                </Typography>
                        }
                    />
                    <CmtAdvCardContent className={classes.cardContentRoot} reverseDir>
                        {getWeeklySummaryChart()}
                    </CmtAdvCardContent>
                </CmtAdvCard>
            </Grid>
        );
    } else {
        return '';
    }
}