import React, { useState, useEffect } from 'react';
import '../Dashboard/index.css';
import GridContainer from '@jumbo/components/GridContainer';
import * as staticFunctions from '../Dashboard/dashboardFunctions';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtAdvCardContent from '@coremat/CmtAdvCard/CmtAdvCardContent';
import CmtAdvCard from '@coremat/CmtAdvCard';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Box, Grid, Typography } from '@material-ui/core';
import * as appConstants from '../../../Constants/AppConstants';
import * as firebaseFunctions from '../../../services/firebase/functions';
import { fetchError } from '../../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';

const useStyles = makeStyles(theme => ({
  cardContentRoot: {
    '& .MuiGrid-container': {
      alignItems: 'center',
    },
  },
  cardContentTitle: {
    marginBottom: 4,
  },
  subTitleRoot: {
    fontSize: 12,
    marginBottom: 0,
    color: theme.palette.text.secondary,
  },
  tooltip: {
    position: 'relative',
    borderRadius: 6,
    padding: '4px 8px',
    backgroundColor: '#8d46ef',
    color: theme.palette.common.white,
  },
  wiimMain:{
    padding: '2px 10px'
  },
  wiimDetail:{
    backgroundColor: 'black',
    padding: '5px'
  }
}));

export default function WhyIsItMovingSummary({ selectedSymbol }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [wiimSummaryWidgetHidden, setWiimSummaryWidgetHidden] = useState(false);
  const [wiimData, setWiimData] = React.useState(null);
  const [prices, setPrices] = React.useState({});

  useEffect(() => {
    if (selectedSymbol) {
      firebaseFunctions.callfunction(
        'getWIIM',
        {
          symbol: selectedSymbol
        },
        function (res) {
          if (res.data.status === 1) {
            setWiimData(res.data.data);
          } else {
            dispatch(fetchError(res.data.msg));
            setWiimData(null);
          }
        },
      );

      firebaseFunctions.callfunction(
        'getPrices',
        {
          symbol: selectedSymbol
        },
        function (res) {
          if (res.data.status === 1) {

            setPrices(res.data.prices);
          } else {
            dispatch(fetchError(res.data.msg));
            setPrices({});
          }
        },
      );
    }
    else {
      setPrices({});
      setWiimData(null);
    }

    return () => { }
  }, [selectedSymbol]);

  if (!wiimSummaryWidgetHidden && wiimData != null) {
    const wiimWidgetActionHandler = event => {
      switch (event.value) {
        case 'close': {
          return setWiimSummaryWidgetHidden(true);
        }
        default:
          return true;
      }
    };

    return (
      <Grid item xs={12} sm={12} md={6}>
        <CmtAdvCard>
          <CmtCardHeader
            className="pt-2 pb-2"
            actionsPos="top-corner"
            actions={[{ label: 'Close', value: 'close' }]}
            actionHandler={wiimWidgetActionHandler}
            title={
              <Typography
                {...{
                  variant: 'h4',
                  component: 'div',
                }}>
                Market Action Comment (BETA)
              </Typography>
            }
          />
          <CmtAdvCardContent className={classes.cardContentRoot} reverseDir>
            <Box className={classes.wiimMain} bgcolor={(prices.latestPrice > prices.previousClose ? '#00b388' : '#b22925')} display="flex">
              <Box className={classes.wiimDetail}>{wiimData.title} - {moment(wiimData.updated, "ddd, DD MMM YYYY HH:mm:ss ZZ").format('MMMM Do YYYY')}</Box>
            </Box>
          </CmtAdvCardContent>
        </CmtAdvCard>
      </Grid>
    );
  } else {
    return '';
  }
}
