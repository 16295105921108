import React, { useState, useEffect, useMemo } from 'react';
import ReactDataGrid from 'react-data-grid';
import { Toolbar, Data, Filters } from 'react-data-grid-addons';
import './index.css';
import * as staticFunctions from './dashboardFunctions';
import GridContainer from '@jumbo/components/GridContainer';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box, Grid, Button } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ExactFilter from '../../../Custom/Filters/ExactFilter';
import DecimalFilter from '../../../Custom/Filters/DecimalFilter';
import CallPutGraphWidget from './CallPutGraphWidget';
import SentimentScoreWidget from './SentimentScoreWidget';
import OptionsWorker from '../../../services/WebWorkers/options.worker';
import WebWorker from '../../../services/WebWorkers/workerSetup';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { NavLink } from 'react-router-dom';
import * as appConstants from '../../../Constants/AppConstants';
import UpdateDialog from './../UpdateProfileImage/UpdateDialog';
import * as FirestoreService from '../../../services/auth/firebase/FirebaseRealTimeDb';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '100%',
    marginTop: '0px',
    paddingRight: '25px',
  },
  UpgradeRowBtn: props => ({
    textAlign: 'center',
    borderBottom: '1px solid',
    height: '40px',
    '& > a': {
      verticalAlign: 'middle',
      verticalAlign: '-webkit-baseline-middle',
      color: 'yellow',
    },
  }),
}));

const defaultColumnProperties = {
  filterable: true,
  sortable: true,
  resizable: true,
};

const selectors = Data.Selectors;
const { SingleSelectFilter } = Filters;
const columns = [
  {
    name: 'Processed At',
    key: 'processedDateTime',
    width: 170,
    formatter: staticFunctions.DefaultTextFormatter,
  },
  {
    name: 'Symbol',
    key: 'symbol',
    width: 100,
    formatter: staticFunctions.CallPutFormatterWithChart,
    filterRenderer: ExactFilter,
  },
  {
    name: 'Strike',
    key: 'StrikePrice',
    filterRenderer: DecimalFilter,
    width: 80,
    formatter: staticFunctions.PriceFormatter,
  },
  {
    name: 'Money',
    key: 'money',
    width: 80,
    filterRenderer: SingleSelectFilter,
    formatter: staticFunctions.DefaultTextFormatter,
  },
  {
    name: 'DTE',
    key: 'dte',
    width: 100,
    formatter: staticFunctions.DaysFormatter,
    filterRenderer: DecimalFilter,
  },
  {
    name: 'Call/Put',
    key: 'CallOrPut',
    width: 80,
    filterRenderer: SingleSelectFilter,
    formatter: staticFunctions.CallPutTextFormatter,
  },
  {
    name: 'Type',
    key: 'optionActivityType',
    width: 80,
    filterRenderer: SingleSelectFilter,
    formatter: staticFunctions.DefaultTextFormatter,
  },
  {
    name: 'Sentiment',
    key: 'sentiment',
    width: 120,
    filterRenderer: SingleSelectFilter,
    formatter: staticFunctions.SentimentFormatter,
  },
  {
    name: 'Spot',
    key: 'underlyingPrice',
    filterRenderer: DecimalFilter,
    width: 80,
    formatter: staticFunctions.PriceFormatter,
  },
  {
    name: 'Expiration',
    key: 'expiration',
    width: 100,
    formatter: staticFunctions.DefaultTextFormatter,
  },
  {
    name: 'Details',
    key: 'details',
    width: 130,
    formatter: staticFunctions.DefaultTextFormatter,
  },
  {
    name: 'Premium',
    key: 'premium',
    width: 100,
    filterRenderer: DecimalFilter,
    formatter: staticFunctions.PremiumFormatter,
  },
  {
    name: 'Vol',
    key: 'volume',
    filterRenderer: DecimalFilter,
    width: 80,
    formatter: staticFunctions.PriceFormatter,
  },
  {
    name: 'OI',
    key: 'openInterest',
    filterRenderer: DecimalFilter,
    width: 80,
    formatter: staticFunctions.PriceFormatter,
  },
  {
    name: 'Unusual',
    key: 'unusual',
    width: 80,
    filterRenderer: SingleSelectFilter,
    formatter: staticFunctions.DefaultTextFormatter,
  },
].map(c => ({ ...c, ...defaultColumnProperties }));

export default function Dashboard() {
  const classes = useStyles();
  const [filters, setFilters] = useState({});
  const [currentSort, setCurrentSort] = useState({});
  const [currentWatchlist, setCurrentWatchlist] = React.useState('');
  const [currentFilter, setCurrentFilter] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([]);

  const { userWatchlists, optionsData, selectedDate, userSubscription } = useSelector(({ firebaseStore }) => firebaseStore);

  // handle update profile modal to show only once
  const [signUpComplete, setSignUpComplete] = useState(true);

  useEffect(() => {
    FirestoreService.getUserExtraData(doc => {
      if (doc.data().isSignUpComplete) {
        setSignUpComplete(doc.data().isSignUpComplete);
      } else {
        setSignUpComplete(false);
      }
    });
  }, []);

  useEffect(() => {
    if (document.querySelectorAll('.react-grid-HeaderRow').length === 1) {
      document.querySelector('.react-grid-Toolbar button').click();
    }
    else{

    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted === true) {
      //var optionsWorker = new Worker('/WebWorkers/options.worker.js');
      var optionsWorker = new WebWorker(OptionsWorker);
      var filtered = staticFunctions.filterByDate(selectedDate, optionsData);

      optionsWorker.postMessage({
        optionsData: filtered,
        currentWatchlist: currentWatchlist,
        currentSort: currentSort,
        userWatchlists: userWatchlists,
        currentFilter: currentFilter,
        type: 'dashboard',
      });

      optionsWorker.onmessage = event => {
        var rows = selectors.getRows({ rows: event.data, filters });

        if (userSubscription !== null && userSubscription.role === appConstants.Role_Customer) {
          setFilteredRows(rows);
        } else {
          var rowsEmpty = [];
          rows.forEach((row, index) => {
            rowsEmpty.push(row);
            var rndNum = Math.floor(Math.random() * 10) + 1;
            if (index % rndNum == 0 && index != 0) {
              rowsEmpty.push(staticFunctions.createOptionsRowData({}));
            }
          });

          setFilteredRows(rowsEmpty);
        }
      };
    }

    return () => {
      isMounted = false;
      optionsWorker.terminate();
    };
  }, [optionsData, filters, currentWatchlist, currentSort, userWatchlists, currentFilter, selectedDate]);

  const onWatchListChanged = event => {
    setCurrentWatchlist(event.target.value);
  };

  const onFilterListChanged = (event, newValue) => {
    // console.log({newValue});
    setCurrentFilter(newValue);
  };

  const handleFilterChange = filter => filters => {
    const newFilters = { ...filters };
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
      delete newFilters[filter.column.key];
    }
    return newFilters;
  };

  function getValidFilterValues(rows, columnId) {
    switch(columnId) {
      case 'money':
        return [ 'OTM', 'ITM', 'ATM' ];
        break;
      case 'CallOrPut':
        return ['PUTS', 'CALLS'];
        break;
      case 'optionActivityType':
        return ['TRADE', 'SWEEP'];
        break;
      case 'sentiment':
        return ['BEARISH', 'BULLISH', 'NEUTRAL'];
        break;
      case 'unusual':
        return ['Yes', 'No'];
        break;
      default:
        // code block
    }
    
    return rows
      .map(r => r[columnId])
      .filter((item, i, a) => {
        return i === a.indexOf(item);
      });
  }

  const EmptyRowsView = () => {
    const message = 'No data to show';
    return (
      <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px' }}>
        <h3>{message}</h3>
      </div>
    );
  };

  const CallPutGraphWidgetMemo = useMemo(() => <CallPutGraphWidget optionsData={filteredRows} />, [filteredRows]);
  const SentimentScoreWidgetMemo = useMemo(() => <SentimentScoreWidget optionsData={filteredRows} />, [filteredRows]);

  return (
    <PageContainer heading="Dashboard">
      {!signUpComplete && <UpdateDialog />}
      <GridContainer>
        {CallPutGraphWidgetMemo}
        {SentimentScoreWidgetMemo}
        <Grid item xs={12}>
          <div className="dark-table-react-grid" style={{ width: '100%' }}>
            <Box style={{ position: 'absolute', width: '75%' }}>
              <Grid container>
                <Grid item xs={6} md={4}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Watchlists</InputLabel>
                    <Select value={currentWatchlist} onChange={onWatchListChanged}>
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {userWatchlists.map((watchlist, index) => (
                        <MenuItem key={index} value={watchlist.id}>
                          {watchlist.Title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Autocomplete
                    multiple
                    id="filterDDL"
                    size="small"
                    value={currentFilter}
                    onChange={onFilterListChanged}
                    options={staticFunctions.filtersDdlData}
                    getOptionLabel={option => option.name}
                    filterSelectedOptions
                    renderInput={params => <TextField {...params} label="Filters" />}
                  />
                </Grid>
              </Grid>
            </Box>

            <ReactDataGrid
              columns={columns}
              rowGetter={i => filteredRows[i]}
              rowsCount={filteredRows.length}
              minHeight={600}
              rowHeight={40}
              rowRenderer={({ renderBaseRow, ...props }) => {
                if (props.row.description != '') {
                  return renderBaseRow(props);
                } else {
                  return (
                    <Box className={classes.UpgradeRowBtn}>
                      <NavLink to="/plans">Upgrade to unlock Row!</NavLink>
                    </Box>
                  );
                }
              }}
              toolbar={<Toolbar enableFilter={true} />}
              onAddFilter={filter => setFilters(handleFilterChange(filter))}
              onClearFilters={() => setFilters({})}
              onGridSort={(sortColumn, sortDirection) => {
                setCurrentSort({
                  sortColumn: sortColumn,
                  sortDirection: sortDirection,
                });
              }}
              getValidFilterValues={columnKey => getValidFilterValues(optionsData, columnKey)}
              emptyRowsView={EmptyRowsView}
            />
          </div>
        </Grid>
        {(userSubscription === null || userSubscription.role !== appConstants.Role_Customer) && (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <NavLink to="/plans">
              <Button variant="contained" color="primary">
                Subscribe To See All Data
              </Button>
            </NavLink>
          </Grid>
        )}
      </GridContainer>
    </PageContainer>
  );
}
