import {
  Set_Options,
  Set_Option,
  Set_UserWatchlists,
  Set_UserSubscription,
  Update_LoadSubscription,
  Update_SelectedDate,
  Set_SubscriptionPlans,
  Update_LoadSubscriptionPlans,
  Set_DiscordTokens
} from '@jumbo/constants/ActionTypes';
import moment from 'moment-timezone';

const INIT_STATE = {
  optionsData: [],
  userWatchlists: [],
  userSubscription: null,
  loadSubscription: false,
  selectedDate: {
    StartDate: moment().tz('America/New_York'),
    EndDate: moment().tz('America/New_York')
  },
  subscriptionPlans: [],
  loadSubscriptionPlans: false,
  discordTokens: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Set_Options: {
      return {
        // returning a copy of orignal state
        ...state, //spreading the original state
        optionsData: action.payload,
      };
    }
    case Set_Option: {
      return {
        // returning a copy of orignal state
        ...state, //spreading the original state
        optionsData: [action.payload, ...state.optionsData], // new optionsData array
      };
    }
    case Set_UserWatchlists: {
      return {
        ...state,
        userWatchlists: action.payload,
      };
    }
    case Set_UserSubscription: {
      return {
        ...state,
        userSubscription: action.payload,
        loadSubscription: true,
      };
    }
    case Update_LoadSubscription: {
      return {
        ...state,
        loadSubscription: action.payload,
      };
    }
    case Update_SelectedDate: {
      return {
        ...state,
        selectedDate: action.payload,
      };
    }
    case Set_SubscriptionPlans: {
      return {
        ...state,
        subscriptionPlans: action.payload,
        loadSubscriptionPlans: true
      };
    }
    case Update_LoadSubscriptionPlans: {
      return {
        ...state,
        loadSubscriptionPlans: action.payload,
      };
    }
    case Set_DiscordTokens: {
      return {
        ...state,
        discordTokens: action.payload,
      };
    }
    default:
      return state;
  }
};
