import React, {useEffect} from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtAdvCardContent from '@coremat/CmtAdvCard/CmtAdvCardContent';
import CmtAdvCard from '@coremat/CmtAdvCard';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import MostActiveWidgetRow from './MostActiveWidgetRow';

const useStyles = makeStyles(theme => ({
  cardContentRoot: {
    '& .MuiGrid-container': {
      alignItems: 'center',
    },
  },
  cardContentTitle: {
    marginBottom: 4,
  },
  subTitleRoot: {
    fontSize: 12,
    marginBottom: 0,
    color: theme.palette.text.secondary,
  },
  tooltip: {
    position: 'relative',
    borderRadius: 6,
    padding: '4px 8px',
    backgroundColor: '#8d46ef',
    color: theme.palette.common.white,
  },
  tableRowCellRoot: {
    fontSize: 12,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 16,
    letterSpacing: 0.4,
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      paddingRight: 24,
    },
  },
}));

export default function MostActiveWidget(props) {
  const classes = useStyles();
  const [mostActiveHidden, setMostActiveHidden] = React.useState(false);
  const [symbolsMostActive, setSymbolsMostActive] = React.useState([]);

  useEffect(() => {
    if(!mostActiveHidden){
        const symbolsGroupedMostActive = props.optionsData.reduce((symbolSoFar, { symbol, CallOrPut, sentiment, StrikePrice }) => {
            if (!symbolSoFar[symbol]) symbolSoFar[symbol] = [];
            var model = {
              CallOrPut: CallOrPut,
              sentiment: sentiment,
              symbol: symbol,
              StrikePrice: StrikePrice,
            };
            symbolSoFar[symbol].push(model);
            return symbolSoFar;
          }, {});

        var symbolsMostActiveData = [];
        for (var key in symbolsGroupedMostActive) {
          var model = {
            symbol: symbolsGroupedMostActive[key][0].symbol,
            StrikePrice: symbolsGroupedMostActive[key][0].StrikePrice,
            calls: symbolsGroupedMostActive[key].filter(st => st.CallOrPut === 'CALLS').length,
            puts: symbolsGroupedMostActive[key].filter(st => st.CallOrPut === 'PUTS').length,
            bullish: symbolsGroupedMostActive[key].filter(st => st.sentiment.toUpperCase() === 'BULLISH').length,
            bearish: symbolsGroupedMostActive[key].filter(st => st.sentiment.toUpperCase() === 'BEARISH').length,
            total: symbolsGroupedMostActive[key].length,
          };
          model.callsPercentage = (model.calls / model.total) * 100;
          model.bullishPercentage = (model.bullish / model.total) * 100;
          symbolsMostActiveData.push(model);
        }

        setSymbolsMostActive(symbolsMostActiveData.sort((a, b) => b.total - a.total).slice(props.pageStart, props.pageSize));
      return () => {
      }
    }
  }, [props.optionsData, props.pageStart, props.pageSize, mostActiveHidden])

  if (!mostActiveHidden) {
  const mostActiveActionHandler = event => {
    switch (event.value) {
      case 'closePremium': {
        return setMostActiveHidden(true);
      }
      default:
        return true;
    }
  };

    return (
      <Grid item  xs={12} sm={12} md={6}>
        <CmtAdvCard>
          <CmtCardHeader
            className="pt-2 pb-2"
            actionsPos="top-corner"
            actions={[{ label: 'Close', value: 'closePremium' }]}
            actionHandler={mostActiveActionHandler}
            title={
              <Typography
                {...{
                  variant: 'h4',
                  component: 'div',
                }}>
                {props.pageStart === 0 ? "Most Active (Top 40)" : ""}
              </Typography>
            }
          />
          <CmtAdvCardContent className={classes.cardContentRoot}>
            <Box className="Cmt-table-responsive">
              <Table>
                <TableHead>
                  <TableRow> 
                  <TableCell className={classes.tableRowCellRoot}>Symbol</TableCell>
                    <TableCell className={classes.tableRowCellRoot}>Calls/Puts</TableCell>
                    <TableCell className={classes.tableRowCellRoot}>Orders</TableCell>
                    <TableCell className={classes.tableRowCellRoot}>Calls/Puts</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {symbolsMostActive.map((row, index) => (
                    <MostActiveWidgetRow row={row} key={"MostActive_Div_" + index} groupBy={props.groupBy} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </CmtAdvCardContent>
        </CmtAdvCard>
      </Grid>
    );
  } else {
    return '';
  }
}
