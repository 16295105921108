import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { lighten } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import * as FirestoreService from '../../../services/auth/firebase/FirebaseRealTimeDb';
import { SetUserWatchlists } from '../../../redux/actions/Firebase';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function Watchlist() {
  const classes = useStyles();
  const { userWatchlists } = useSelector(({ firebaseStore }) => firebaseStore);
  const watchlists = { data: userWatchlists };

  const dispatch = useDispatch();

  const columns = [
    {
      title: 'Name',
      field: 'Title',
      validate: rowData => {
        if (rowData.Title === undefined) return;

        var validation = rowData.Title !== '' && rowData.Title.length <= 50;
        if (validation === true) {
          const isRecordFound = watchlists.data.some(
            el => el.id !== rowData.id && el.Title.toLowerCase() === rowData.Title.toLowerCase(),
          );
          if (isRecordFound === true) {
            return { isValid: false, helperText: 'There is already a watchlist with same name!' };
          }
          return true;
        } else {
          return { isValid: false, helperText: 'Title must be greater than 1 character and less than 50 characters!' };
        }
      },
    },
    {
      title: 'Symbols',
      field: 'Symbols',
      validate: rowData => {
        if (rowData.Symbols === undefined) return;

        var validation = rowData.Symbols !== '';
        if (validation === true) {
          var regex = new RegExp(/^[a-zA-Z0-9,]+$/);
          if (regex.test(rowData.Symbols) === false) {
            return {
              isValid: false,
              helperText:
                'Special Characters and spaces not allowed in Symbols! Only use comma(",") to separate multiple symbols.',
            };
          }
        }

        return validation === false ? { isValid: false, helperText: 'Symbols Field is required!' } : true;
      },
    },
  ];

  async function refreshWatchlists() {
    var watchlists = await FirestoreService.getWatchlistsAsync();
    var data = [];
    if (watchlists != null) {
      watchlists.forEach(function (childSnapshot) {
        var childData = childSnapshot.data();
        childData.id = childSnapshot.id;
        data.push(childData);
      });
    }
    dispatch(SetUserWatchlists(data));
  }

  return (
    <MaterialTable
      className={classes.root}
      icons={tableIcons}
      title="Watchlists"
      columns={columns}
      options={{
        pageSize: 10,
        pageSizeOptions: [10, 20, 50],
        actionsColumnIndex: -1,
      }}
      data={watchlists.data}
      editable={{
        onRowAdd: newData =>
          new Promise(resolve => {
            resolve();
            FirestoreService.addUpdateWatchlist(newData);
            refreshWatchlists();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            resolve();
            if (oldData) {
              FirestoreService.addUpdateWatchlist(newData);
              refreshWatchlists();
            }
          }),
        onRowDelete: oldData =>
          new Promise(resolve => {
            resolve();
            FirestoreService.deleteWatchlist(oldData);
            refreshWatchlists();
          }),
      }}
    />
  );
}
