import React, {useEffect} from 'react';
import AffiliateDetails from './AffiliateDetails';
import {Box,Grid} from '@material-ui/core';
import CmtAdvCard from '@coremat/CmtAdvCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtAdvCardContent from '@coremat/CmtAdvCard/CmtAdvCardContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import * as FirestoreService from '../../../services/auth/firebase/FirebaseRealTimeDb';
import GridContainer from '@jumbo/components/GridContainer';
import ReferCard from '../User/ReferCard';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  textError: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.error.main,
    marginLeft: 8,
    marginTop: 4,
    fontWeight: theme.typography.fontWeightRegular,
  },
  subTitle: {
    color: theme.palette.text.secondary,
  },
  linkBtn: {
    marginLeft: -6,
  },
}));

const Affiliate = () => {
    const [userReferrals, setUserReferrals] = React.useState([]);
    const [totalCount, setTotalCount] = React.useState(0);
    const { authUser } = useSelector(({ auth }) => auth);

    useEffect(() => {
        FirestoreService.getUserReferrals((querySnapshot) => {
            var userRefferalsList = { 
                totalSignups:0,
                totalTrialSignups:0,
                totalMonthlySignups:0,
                totalYearlySignups:0,
                totalCount:0
            };
            querySnapshot.forEach(async userReferralDoc => {
                var userReferral = userReferralDoc.data();
                
                if(userReferral.YearlyPlanSignedUp === true){
                    userRefferalsList.totalYearlySignups = userRefferalsList.totalYearlySignups + 1;
                    userRefferalsList.totalCount = userRefferalsList.totalCount + 1;
                }
                else if(userReferral.MonthlyPlanSignedUp === true){
                    userRefferalsList.totalMonthlySignups = userRefferalsList.totalMonthlySignups + 1;
                    userRefferalsList.totalCount = userRefferalsList.totalCount + 1;
                }
                else if(userReferral.TrialSignedUp === true){
                    userRefferalsList.totalTrialSignups = userRefferalsList.totalTrialSignups + 1;
                }
                
                if(userReferral.SignedUp === true){
                    userRefferalsList.totalSignups = userRefferalsList.totalSignups + 1;
                }
            });
            
            var referralsGrouped = [];
            var totalSignups = userRefferalsList.totalSignups;
            referralsGrouped.push({
                title:'Total Signups',
                count: totalSignups,
                percentage: 100
            });

            referralsGrouped.push({
                title:'Total Trial Signups',
                count: userRefferalsList.totalTrialSignups,
                percentage: userRefferalsList.totalSignups === 0 || userRefferalsList.totalTrialSignups === 0 ? 0 : (userRefferalsList.totalTrialSignups/userRefferalsList.totalSignups) * 100
            });

            referralsGrouped.push({
                title:'Total Paid Monthly Signups',
                count: userRefferalsList.totalMonthlySignups,
                percentage: userRefferalsList.totalSignups === 0 || userRefferalsList.totalMonthlySignups === 0 ? 0 : (userRefferalsList.totalMonthlySignups/userRefferalsList.totalSignups) * 100
            });

            referralsGrouped.push({
                title:'Total Paid Annual Signups',
                count: userRefferalsList.totalYearlySignups,
                percentage: userRefferalsList.totalSignups === 0 || userRefferalsList.totalYearlySignups === 0 ? 0 : (userRefferalsList.totalYearlySignups/userRefferalsList.totalSignups) * 100
            });

            setUserReferrals(referralsGrouped);
            setTotalCount(userRefferalsList.totalCount);
        });
    }, []);

  const classes = useStyles();
  const getCount = () => (
    <Box mb={2} display="flex" flexDirection="row" alignItems="center">
      <Typography component="div" variant="h1">
        {totalCount}
      </Typography>
    </Box>
  );

  return (
      <GridContainer>
          <Grid item xs={12} md={6}>
    <CmtAdvCard>
      <CmtCardHeader
        titleProps={{
          variant: 'h4',
          component: 'div',
        }}
        title={'Your Referral Count'}
      />
      <CmtAdvCardContent
        title={getCount()}
        subTitle={'Overall Count'}
        subTitleProps={{
          variant: 'body2',
          component: 'p',
          className: classes.subTitle,
        }}
        reverseDir>
        <AffiliateDetails userReferrals={userReferrals} />
      </CmtAdvCardContent>
    </CmtAdvCard>
    </Grid>
    <Grid item xs={12} md={6}>
              <ReferCard authUser={authUser} isAffiliate={true} />
            </Grid>
    </GridContainer>
  );
};

export default Affiliate;
