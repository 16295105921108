import React from 'react';
import Box from '@material-ui/core/Box';
import CmtList from '@coremat/CmtList';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtProgressBar from '@coremat/CmtProgressBar';

const useStyles = makeStyles(() => ({
  titleRoot: {
    letterSpacing: 0.5,
    marginBottom: 20,
  },
  listItemRoot: {
    padding: 0,
    marginBottom: 4,
  },
}));

const AffiliateDetails  = ({userReferrals}) => {
  const classes = useStyles();
  return (
    <Box width={1}>
      <Typography component="div" variant="h6" className={classes.titleRoot}>
        Referral Distribution
      </Typography>
      <CmtList
        data={userReferrals}
        renderRow={(item, index) => {
            return (
              <Box key={'affiliateRow_' + index} width={1} className={classes.listItemRoot}>
                <CmtProgressBar
                  label={
                    <Box display="flex" alignItems="center">
                      {`${item.title}`} | <Box pl={1} component="span" color="text.secondary" fontSize={12}>{`${item.count}`}</Box>
                    </Box>
                  }
                  labelPos="top-left"
                  value={item.percentage}
                  renderValue={value => {
                    return `${item.count}`;
                  }}
                  //containedColor={item.color}
                  onlyContained
                />
              </Box>
            );
          }
        }
      />
    </Box>
  );
};

export default AffiliateDetails;
