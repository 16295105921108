import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box, Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtAdvCardContent from '@coremat/CmtAdvCard/CmtAdvCardContent';
import CmtAdvCard from '@coremat/CmtAdvCard';
import LearningCenterAccordionChild from './LearningCenterAccordionChild';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';

const useStyles = makeStyles(theme => ({
    cardContentRoot: {
      '& .MuiGrid-container': {
        alignItems: 'center',
      },
    },
    cardContentTitle: {
      marginBottom: 4,
    },
    subTitleRoot: {
      fontSize: 12,
      marginBottom: 0,
      color: theme.palette.text.secondary,
    },
    tooltip: {
      position: 'relative',
      borderRadius: 6,
      padding: '4px 8px',
      backgroundColor: '#8d46ef',
      color: theme.palette.common.white,
    }
  }));

export default function LearningCenter() {
    const classes = useStyles();

    const learningCenterData = [
        {
            id: 1,
            title: "Options Glossary",
            childrens: [
                {
                    title: "Strike Price",
                    detail:"The price specified in a contract at which the holder of the contract can exercise their option. The strike price of a call is the price at which the holder can buy the underlying security and the strike price of a put is the price at which the holder can sell the underlying security.",
                },
                {
                    title: "Money or Moneyness",
                    detail:"A method used to measure the relationship of the strike price of an option to the current price of the underlying security.",
                },
                {
                    title: "DTE (Days to Expiration)",
                    detail:"The number of days after which a contract expires and effectively ceases to exist. Options must be exercised on or before this date, or they will expire worthless.",
                },
                {
                    title: "Call or Put",
                    detail:"The options contract a Call Option or Put Options. See Call Option. Call is often used instead of the full term. See Put Option. Put is often used instead of the full term.",
                    childrens: [
                        {
                            title: "Call Option",
                            detail: "A type of option which grants the holder the right, but not the obligation, to buy the relevant underlying security at an agreed strike price.",
                        },
                        {
                            title: "Put Option",
                            detail: "A type of option which grants the holder the right, but not the obligation, to sell the relevant underlying security at an agreed strike price.",
                        }
                    ]
                },
                {
                    title: "Option Purchase Type (Sweep Or Trade)",
                    childrens: [
                        {
                            title: "Sweeps",
                            detail: "Orders that are fulfilled across multiple options exchanges simultaneously to fill a larger order are known as sweep trades. The individual orders are small orders that fill within seconds of each other. These orders stay hidden from the radar of the overall market because of their individual size. Sweeps can also be market orders which are the most aggressive signifying the entity wants to get into the option immediately before a larger underlying move in the stock price.",
                        },
                        {
                            title: "Trade or Block Trades",
                            detail:"Privately negotiated trades between two parties made in one block or a single chunk on the public market or single exchange are called Trades or Trades Blocks.",
                        }
                    ]
                },
                {
                    title: "Sentiments (Bullish or Bearish)",
                    childrens: [
                        {
                            title: "Bullish",
                            detail: "Options trades are considered having a “bullish sentiment” when a “call option” is purchased at/near ask price or a “put option” is sold at/near bid price. The buyer or seller of the options is expecting the option, or any financial instrument, will increase in price.",
                        },
                        {
                            title: "Bearish",
                            detail:"Options are “bearish sentiment” when a call option is sold at/near bid price or a put option is bought at/near ask price. The buyer or seller of the options is expecting the option, or any financial instrument, will decrease in price.",
                        }
                    ]
                },
                {
                    title: "Spot",
                    detail:"The market price of the financial instrument (stock) when the options order was executed/traded.",
                },
                {
                    title: "Expiration Date",
                    detail:"The date on which a contract expires and effectively ceases to exist. Options must be exercised on or before this date, or they will expire worthless.",
                },
                {
                    title: "Options Details",
                    detail:"The number of options traded, and the price paid for each option. Example 1000 x $0.50 (1000 option contracts for $0.50 each)",
                },
                {
                    title: "Volume",
                    detail:"The amount of transactions that took place a particular option. One with a high volume means it has been heavily traded.",
                },
                {
                    title: "Open Interest",
                    detail:"A count of the total number of open positions relating to a particular option.",
                },
                {
                    title: "Premium Value",
                    detail:"An option premium is the price paid by the buyer to the seller for an option contract. Premiums are quoted on a per-share basis because most option contracts represent 100 shares of the underlying stock. A premium that is quoted as $1.50 per contract means that the (one) option contract will cost $150. To calculate the sweep or block trades premium value we will multiply the “total options traded by the price per options contract” and multiple it by 100. Example: (5000 @$2.25) x 100 = $1,125,000 or ($1M)",
                }
            ]
        },
        {
            id: 2,
            title: "Trade Sentiment",
            childrens: [
                {
                    title: "Bullish",
                    detail:"Options trades are considered having a “bullish sentiment” when a “call option” is purchased at/near ask price or a “put option” is sold at/near bid price. The buyer or seller of the options is expecting the option, or any financial instrument, will increase in price.",
                },
                {
                    title: "Bearish",
                    detail:"Options are “bearish sentiment” when a call option is sold at/near bid price or a put option is bought at/near ask price. The buyer or seller of the options is expecting the option, or any financial instrument, will decrease in price.",
                },
                {
                    title: "How we calculate sentiment.",
                    childrens:[
                        {
                            isParagraph: true,
                            childrens: ["Calls options traded at the ask price = Bullish (Positive Sentiment)", "Calls options traded above the ask price = Very Bullish (Positive Sentiment)"]
                        },
                        {
                            isParagraph: true,
                            childrens: ["Calls options traded at the bid price = Bearish (Negative Sentiment)", "Calls options traded below the bid price = Very Bearish (Negative Sentiment)"]
                        },
                        {
                            isParagraph: true,
                            childrens: ["Puts options traded at the ask price = Bearish (Positive Sentiment)", "Puts options traded above the ask price = Very Bearish (Positive Sentiment)"]
                        },
                        {
                            isParagraph: true,
                            childrens: ["Puts options traded at the bid price = Bullish (Negative sentiment)", "Puts options traded below the bid price = Very Bullish (Negative Sentiment)"]
                        }
                    ]
                }
            ]
        },
        {
            id: 3,
            title: "Filter Options",
            childrens: [
                {
                    title: "Call",
                    detail:"Show only call options.",
                },
                {
                    title: "Puts",
                    detail:"Show only put options.",
                },
                {
                    title: "Weeklys",
                    detail:"Shows options trades that have a non standard expiration date. Option that uses a weekly expiration cycle.",
                },
                {
                    title: "Vol Over OI",
                    detail:"Shows only options trades where the volume is greater than open interest.. Also known as Volume over Open Interest.",
                },
                {
                    title: "Stock",
                    detail:"Shows options trades for symbols that are stocks.",
                },
                {
                    title: "ETF",
                    detail:"Shows options trades for symbols that are ETF’s.",
                },
                {
                    title: "Sweeps",
                    detail:"Shows options trades for Sweeps.",
                },
                {
                    title: "Golden Sweeps",
                    detail:"Shows options trades for Sweeps with premium greater than $1 million."
                },
                {
                    title: "Unusual",
                    detail:"Shows options trades that are marked unusual using our algorithm."
                }
            ]
        },
        {
            id: 4,
            title: "Fundamentals for Options Flow Trading",
            childrens:[
                {
                    isParagraph: true,
                    childrens: ["Do not assume large orders are fool proof trades that you should take. We don’t know what the intentions are behind these orders. It could be possible, traders could be hedging against their overall exposure to the market or closing their existing long or short positions."]
                },
                {
                    isParagraph: true,
                    childrens: ["Look for overall market or individual  stock sentiment."]
                },
                {
                    isParagraph: true,
                    childrens: ["Smart money likes to come into weakness. When retail trades are selling, market makers are coming in and buying. Analyze charts and spot catalysts where possible earnings, upcoming events, news and announcements."]
                },
                {
                    isParagraph: true,
                    childrens: ["Look for new stocks catching repeated money flow. Look at historical flow to see any bug or unusual buys. Repeated options flow with drive up the stock price very quickly."]
                }
            ]
        }
    ];


  return (
    <PageContainer heading="Learning Center">
          <GridContainer>
              {learningCenterData.map(function (learningCenterRow, index) {
                  return (
                      <Grid key={index} item xs={12} sm={6}>
                          <CmtAdvCard>
                              <CmtCardHeader className="pt-2 pb-2"
                                  title={
                                      <Typography
                                          {...{
                                              variant: 'h4',
                                              component: 'div',
                                          }} style={{padding: "15px 0px 15px 0px",fontSize:"24px"}}>
                                          {learningCenterRow.title}
                                      </Typography>
                                  }
                              />
                              <CmtAdvCardContent className={classes.cardContentRoot}>
                                  {learningCenterRow.childrens.map(function(rowChildren, childIndex){
                                      return (
                                        <LearningCenterAccordionChild key={index + "_" + childIndex} rowChildren={rowChildren} index={index} childIndex={childIndex}></LearningCenterAccordionChild>
                                      );
                                  })}
                              </CmtAdvCardContent>
                          </CmtAdvCard>
                      </Grid>)
              })}

              {learningCenterData.length === 0 && <Typography component="h1" style={{ marginTop: "10px", textAlign: "center" }}>No results in Learning Center's contained the term ''.</Typography>}
          </GridContainer>
    </PageContainer>
  );
}